import React from 'react'
import { useTranslation } from "react-i18next";

import { ReactComponent as Warning } from '../../../svg/warning.svg'

import s from './modal.module.scss'
import { OPERATION_TYPES } from '../../../../../constants/app';

const GoToAnotherRefundPageModal = ({ closeModal, handleGoToPurchaseRefundClick, currentOperationType }) => {
	const { t } = useTranslation()

	return (
		<div className={s.overlay}>
			<div className={s.wrapper}>
				<div className={s.top}>
					<Warning />
				</div>
				<p className={s.paragraph}>
					{currentOperationType === OPERATION_TYPES.PURCHASE_REFUND.KEY 
							? t('other.sale_refund_warning_text') 
							: t('other.purchase_refund_warning_text')
						}
				</p>
				<button
					className={`${s.btn} ${s.primaryBtn}`}
					onClick={handleGoToPurchaseRefundClick}
				>
					<span className={s.btn_text}>
						{currentOperationType === OPERATION_TYPES.PURCHASE_REFUND.KEY 
							? t('other.go_to_sale_refund') 
							: t('other.go_to_purchase_refund')
						}
					</span>
				</button>
        <button
					className={`${s.btn} ${s.secondaryBtn}`}
					onClick={closeModal}
				>
					<span className={s.btn_text}>{ t('main.close_modal') }</span>
				</button>
			</div>
		</div>
	)
}

export default GoToAnotherRefundPageModal
