exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".login_wrapper__1lQon {\n  position: relative;\n  width: 100vw;\n  min-height: 100vh;\n  height: 100vh;\n  display: flex;\n  align-items: stretch;\n  justify-content: center;\n  background-color: #F5F5F5; }\n\n.login_design_switch_button__2txSD {\n  z-index: 3;\n  position: absolute;\n  top: 20px;\n  right: 20px; }\n\n.login_logo__1l9MI {\n  margin: 30px 0;\n  display: none; }\n  .login_logo__1l9MI svg path {\n    fill: #B5B5B5; }\n  .login_logo_desktop__CkuVL {\n    margin: 30px 0;\n    display: block; }\n    .login_logo_desktop__CkuVL svg path {\n      fill: #FFFFFF; }\n\n.login_slider_wrapper__3GrqY, .login_form_wrapper__oSQv2 {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center; }\n\n.login_slider_wrapper__3GrqY {\n  background-color: #233039;\n  flex: 0 1 56%; }\n\n.login_form_wrapper__oSQv2 {\n  position: relative;\n  flex: 0 1 44%;\n  background-color: transparent; }\n\n.login_form_container__FbLAP {\n  width: 320px;\n  position: relative;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center; }\n\n@media (max-width: 1100px) {\n  .login_slider_wrapper__3GrqY {\n    z-index: 1;\n    position: absolute;\n    flex: auto;\n    width: 100%;\n    min-height: 100%;\n    background-color: #FAFAFA; }\n  .login_form_container__FbLAP {\n    width: calc(100vw - 60px); }\n  .login_logo__1l9MI {\n    display: block; }\n    .login_logo__1l9MI svg {\n      width: 97px; }\n    .login_logo_desktop__CkuVL svg {\n      width: 97px; }\n      .login_logo_desktop__CkuVL svg path {\n        fill: #B5B5B5; } }\n\n.login_langWrapper__1nhI1 {\n  width: 100%;\n  display: flex;\n  justify-content: flex-end;\n  padding: 30px; }\n  @media (max-width: 1100px) {\n    .login_langWrapper__1nhI1 {\n      justify-content: center;\n      padding: 30px 0 0 0; } }\n", ""]);

// exports
exports.locals = {
	"wrapper": "login_wrapper__1lQon",
	"design_switch_button": "login_design_switch_button__2txSD",
	"logo": "login_logo__1l9MI",
	"logo_desktop": "login_logo_desktop__CkuVL",
	"slider_wrapper": "login_slider_wrapper__3GrqY",
	"form_wrapper": "login_form_wrapper__oSQv2",
	"form_container": "login_form_container__FbLAP",
	"langWrapper": "login_langWrapper__1nhI1"
};