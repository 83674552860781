
import { getCurrentDate} from "../Store/snippets";
import moment from 'moment'

const getRange = (start = new Date(), end = new Date()) => {
    const miliseconds = end.getTime() - start.getTime()
    const seconds = parseInt(Math.floor(miliseconds / 1000));
    const minutes = parseInt(Math.floor(seconds / 60));
    const hours = parseInt(Math.floor(minutes / 60));
    const days = parseInt(Math.floor(hours / 24));
  return {miliseconds,seconds, minutes, hours, days};
}
export const findPeriodTypes = (range) => {
    const start = moment(range.startDate).startOf('day').toString();
    const end = moment(range.endDate).endOf('day').toString();

    const previous = moment(new Date()).subtract(1, "days").endOf('day').toString();
    const threeDays = moment(new Date()).subtract(2, "days").startOf('day').toString();

    const endWithoutTime = moment(end).format("YYYY-MM-DD");
    const startWithoutTime = moment(start).format("YYYY-MM-DD");

    const endToday = moment(new Date()).endOf('day').toString();

    const {days} = getRange(range.startDate, range.endDate);

    if(endWithoutTime === startWithoutTime && previous === end) {
        return 1
    }else if(threeDays === start && end === endToday) {
        return 2
    }else if(days === 7 && endToday === end) {
        return 3
    }else if((days === 30 || days === 31 || days === 28 || days === 29) && range.startDate.getDate() === range.endDate.getDate()) {
        return 4
    }else if(days >= 61 && days <= 93 && endToday === end) {
        return 5
    }else if((days === 365 || days === 366) && endToday === end) {
        return 6
    }else if(days >= 150 && days < 186 && endToday === end) {
        return 7
    }
    return 8;
}

export const getRangePeriod = (
        type, 
        range,
        name = ''
    ) => {
    const currentData = getCurrentDate(new Date())
    let { today, days, month } = currentData

    let date  = {
        from: '',
        to: '',
    };

    switch(type) {
        case 1: 
            today.setDate(days - 1);
            date = {
                from: moment(today).startOf('day').toString(),
                to: moment(today).endOf('day').toString(),
            }
            break;
        case 2:
            today.setDate(days - 2);
            date = {
                from: moment(today).startOf('day').toString(),
                to: new Date(),
            }
            break;
        case 3:
            today.setDate(days - 7);
            date = {
                from: moment(today).startOf('day').toString(),
                to: new Date(),
            }
            break;
        case 4:
            const currentWDay = new Date();
            today.setDate(currentWDay.getDate());
            today.setMonth(currentWDay.getMonth() - 1);
            date = {
                from: moment(today).startOf('day').toString(),
                to: new Date(),
            }
            break;
        case 5: 
            const currentMDay = new Date();

            if (month <= 2) {
            today.setDate(currentMDay.getDate());
            today.setMonth(currentMDay.getMonth() - 3);
            today.setFullYear(currentMDay.getFullYear() - 1);
            } else {
            today.setDate(currentMDay.getDate());
            today.setMonth(currentMDay.getMonth() - 3);
            }
            date = {
                from: moment(today).startOf('day').toString(),
                to: new Date(),
            }
            break;
        case 6: 
            const currentDay = new Date();

            if (month <= 5) {
            today.setDate(currentDay.getDate());
            today.setMonth(currentDay.getMonth() - 6);
            today.setFullYear(currentDay.getFullYear() - 1);
            } else {
            today.setDate(currentDay.getDate());
            today.setMonth(currentDay.getMonth() - 6);
            }
            date = {
                from: moment(today).startOf('day').toString(),
                to: new Date(),
            }
            break;

        case 7: 
            const currentYDay = new Date();
            today.setDate(currentYDay.getDate());
            today.setFullYear(currentYDay.getFullYear() - 1);
            date = {
                from: moment(today).startOf('day').toString(),
                to: new Date(),
            }
            break;
        case 8: 
            date = {
                from: moment(range.startDate).startOf('day').toString(),
                to: moment(range.endDate).endOf('day').toString(),
            }
            break;
        default:
            date  = {
                from: new Date(),
                to: new Date(),
            };
    }


    return {
        date: {
            from: moment(date.from).format('DD.MM.YYYY'),
            to: moment(date.to).format('DD.MM.YYYY')
        },
        dateISOstring: {
            FromDate: moment(date.from).format(`YYYY-MM-DDTHH:mm:ss${name}`),
            ToDate: moment(date.to).format(`YYYY-MM-DDTHH:mm:ss${name}`)
        }
    }
}