import React from "react"
import AddPositionService from "../modules/AddPositionService"
import Modal from '../../../Modal/operationModal';
import style from "../../styles/pages/container.module.scss"
import Error503Modal from "../../Modals/Error500Modal";
import { OPERATION_TYPES } from "../../../../constants/app";

const IncomeContainer = ({ context, history }) => {
  return (
    <div className={style.main}>
      <AddPositionService context={context} history={history} status={OPERATION_TYPES.INCOME.KEY} />
      {
        context.Modal.Header === '503 ошибка' ? (
          <Error503Modal />
        ) : (
          <Modal
            onClose={context.closeModal}
            onCheck={context.showCheck}
            onElectronCheck={context.electronShowCheck}
            show={context.Modal.Status}
            header={context.Modal.Header}
            body={context.Modal.Body}
            img={context.CheckImg}
            docId={context.CheckId}
            docLink={context.CheckLink}
            share={context.shareSocial}
          />
        )
      }
    </div>
  )
}

export default IncomeContainer;
