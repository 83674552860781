exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".searchfield_wrapper__2hyEA {\n  position: relative;\n  width: 100%;\n  min-width: 300px;\n  height: 44px;\n  display: flex;\n  align-items: center;\n  border: 1px solid transparent;\n  border-radius: 8px; }\n  .searchfield_wrapper__2hyEA:focus-within {\n    border-color: #00ADEE; }\n  .searchfield_wrapper__2hyEA i {\n    position: absolute;\n    right: 0;\n    top: 0;\n    min-width: 44px;\n    height: 100%;\n    border: 1px solid #D7D7D7;\n    background-color: #F5F5F5;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    border-radius: 0px 8px 8px 0px;\n    cursor: pointer; }\n  .searchfield_wrapper__2hyEA input {\n    position: absolute;\n    left: 0;\n    top: 0;\n    flex: 1 1;\n    height: 100%;\n    width: calc(100% - 44px);\n    border: 1px solid #D7D7D7;\n    border-right: none;\n    box-sizing: border-box;\n    border-radius: 8px 0 0 8px;\n    background-color: #fff;\n    font-style: normal;\n    font-weight: normal;\n    font-size: 16px;\n    line-height: 19px;\n    display: flex;\n    align-items: center;\n    padding-left: 16px; }\n    .searchfield_wrapper__2hyEA input::placeholder {\n      color: #8E8E8E; }\n\n@media (max-width: 1100px) {\n  .searchfield_wrapper__2hyEA {\n    width: 44px;\n    overflow: hidden; }\n    .searchfield_wrapper__2hyEA input {\n      transition: all 0.3s;\n      opacity: 0; }\n    .searchfield_wrapper__2hyEA i {\n      border-color: transparent; }\n    .searchfield_wrapper__2hyEA.searchfield_active__bhB5o {\n      width: 100%; }\n      .searchfield_wrapper__2hyEA.searchfield_active__bhB5o input {\n        transition: all 0.3s;\n        opacity: 1; }\n      .searchfield_wrapper__2hyEA.searchfield_active__bhB5o i {\n        border-color: #D7D7D7; } }\n", ""]);

// exports
exports.locals = {
	"wrapper": "searchfield_wrapper__2hyEA",
	"active": "searchfield_active__bhB5o"
};