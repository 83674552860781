import React, {useState} from 'react';
import s from './langToggler.module.scss'
// import { useTranslation } from "react-i18next";
import i18n from "i18next";

const LangToggler = () => {

	const availableLanguages = ['ru', 'kk']
	const [lang, setLang] = useState(i18n.language)

	const toggleLanguage = () => {
		const currentIndex = availableLanguages.indexOf(i18n.language);

		const nextIndex = (currentIndex + 1) % availableLanguages.length;

		const nextLanguage = availableLanguages[nextIndex];

		i18n.changeLanguage(nextLanguage);
		localStorage.setItem('i18nextLng', nextLanguage);
		setLang(nextLanguage)
	}

	return (
		<div className={s.wrapper}>
			{ availableLanguages.map(locale => (
				<button key={locale}
				className={ `${s.btn} ${lang === locale && s.active}` }
				onClick={toggleLanguage}
				>
				<span>{ locale === 'ru' ? 'Рус' : 'Қаз' }</span>
				</button>
			)) }
		</div>
	);
};

export default LangToggler;
