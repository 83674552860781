import React from 'react'
import { PieChart, Pie, Cell } from 'recharts'

const COLORS = ["#00ADEE", "#C2EEFF", "#C3C2FF"]

export default function Chart({ data }) {
  const pie = [
    { name: "Cash", value: Math.round(data.Cash) },
    { name: "NonCash", value: Math.round(data.NonCash) },
    { name: "Mobile", value: Math.round(data.Mobile) },
  ]
  return (
    <PieChart width={200} height={150}>
      <Pie
        data={pie}
        innerRadius={60}
        outerRadius={72}
        dataKey="value"
      >
        {pie.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
    </PieChart>
  )
}
