import React, { useRef } from 'react'
import s from './searchfield.module.scss'
import { Search } from '../../../Icons'

const SearchField = ({ searchValue, setSearchValue, searchOpen, setSearchOpen, query, placeholder, onChange }) => {
	const ref = useRef()

	const openSearchInput = () => {
		setSearchOpen(true)
		ref.current.focus()
		searchOpen && query()
	}

	const closeSearchInput = () => {
		setTimeout(() => 
			ref.current && !ref.current.contains(document.activeElement) && setSearchOpen(false)
		, 100)

		if (onChange) {
			onChange()
		}
	}

	const handleChange = (e) => {
		setSearchValue(e.target.value.trim())
		
		if (onChange) {
			onChange()
		}
	}

	return (
		<div className={!searchOpen ? s.wrapper : `${s.wrapper} ${s.active}`}>
			<input
				ref={ref}
				value={searchValue}
				onChange={handleChange}
				placeholder={placeholder}
				onBlur={closeSearchInput}
			/>
			<i
				onClick={openSearchInput}
				className={s.search_button}
			>
				<Search />
			</i>
		</div>
	)
}

export default SearchField
