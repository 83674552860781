import React, { useState } from 'react';

const OfdSettings = ({ ofds, changeOfdSettings }) => {

  let kkm = (JSON.parse(localStorage.getItem('KKM')))
  const [ofdSettings, setOfdSettings] = useState(
    {
      ofdId: kkm.Ofd.Id,
      rnm: kkm.Rnm,
      kkmid: kkm.IdCPCR,
      token: kkm.TokenCPCR
    }
  )

  const makeChanges = (key, value) => {
    if (key === 'ofdId') {
      setOfdSettings(
        {
          ...ofdSettings,
          ofdId: value
        }
      )
    }
    if (key === 'rnm') {
      setOfdSettings(
        {
          ...ofdSettings,
          rnm: value
        }
      )
    }
    if (key === 'kkmid') {
      setOfdSettings(
        {
          ...ofdSettings,
          kkmid: value
        }
      )
    }
    if (key === 'token') {
      setOfdSettings(
        {
          ...ofdSettings,
          token: value
        }
      )
    }
  }

  return (
    <div>
      <div class="heading">
        <h1 class="text-center display-4">Настройки ОФД</h1>
      </div>
      <div method="POST" class="pt-3">
        <div class="form-group row">
          <label htmlFor="OFD" class="col-sm-1 col-form-label">ОФД</label>
          <div class="col-sm-8">
            <select class="form-control" name="OFD" id="OFD" onChange={e => makeChanges('ofdId', e.target.value)} value={ofdSettings.ofdId}>
              {
                ofds.map((ofd) => (
                  <option key={ofd.Id} value={ofd.Id}>{ofd.Name}</option>
                ))
              }              
            </select>
          </div>
        </div>
        <div class="form-group row">
          <label htmlFor="RNMOFD" class="col-sm-1 col-form-label">РНМ</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" onChange={e => makeChanges('rnm', e.target.value)}  value={ofdSettings.rnm} name="RNMOFD" id="RNMOFD" required={true} />
          </div>
        </div>

        <div class="form-group row">
          <label htmlFor="IdOFD" class="col-sm-1 col-form-label">ККМ Id</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" onChange={e => makeChanges('kkmid', e.target.value)} value={ofdSettings.kkmid} name="IdOFD" id="IdOFD" required={true} />
            <small id="IdOFDHelpBlock" class="form-text text-muted">
              Идентификатор ККМ в ОФД
            </small>
          </div>

        </div>


        <div class="form-group row">
          <label htmlFor="TokenOFD" class="col-sm-1 col-form-label">Токен</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" onChange={e => makeChanges('token', e.target.value)} value={ofdSettings.token} name="TokenOFD" id="TokenOFD" required={true} />
          </div>
        </div>

        <div class="pt-3">
          <button class="btn btn-primary" onClick={() => changeOfdSettings(ofdSettings)}>Сохранить изменения</button>
        </div>
      </div>
    </div>
  )
}

export default OfdSettings;