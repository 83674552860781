import React from "react"
import { OperationProvider } from "../../../Store/OperationProvider";
import CheckContainer from "./CheckContainer"

const CheckWrapper = (props) => {
  return (
    <OperationProvider operation='Sale'>
      <CheckContainer {...props} />
    </OperationProvider>
  )
}

export default CheckWrapper;
