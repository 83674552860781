/* eslint-disable eqeqeq */
import React from 'react';
// import PrintButton from '../Buttons/PrintButton';
import Subnav from '../ReportsSubnav/ReportsSubnavLayout';
import { Link } from 'react-router-dom'
// import { BASE_URL } from '../../Store/snippets';
import MobileNav from '../MobileNav/MobileNav';
import Modal from "../Modal/opersLogModal";
// import ModalCheck from '../Modal/operationModalCheck';
// import PreloaderFullScreen from '../Preloader/Preloader';

const OpersLogLayout = ({ report: Report, handleChangeDateFrom, handleChangeDateTo, dateFrom, dateTo, date, idKkm, formatDate, getDuplicate, error, user: User, history, show, showCheck, closeModal, img, header }) => {

	return (
		<React.Fragment>
			<div className="container pt-5 pb-5">
				<div className="d-flex justify-content-end">
				</div>
				<div className="heading">
					<h1 className="text-center">Журнал операций</h1>
				</div>
				<Subnav active={"operations"} history={history} />
				<div className="dateRange-wrapper">
					<div className="date_range">
						<span>Выберите дату от: </span>
						<input className="date-from-input" type="date" value={dateFrom} onChange={handleChangeDateFrom}/>
						<span> до: </span>
						<input className="date-to-input" type="date" value={dateTo} onChange={handleChangeDateTo}/>
					</div>
				</div>
				{
					!error.status ?
						<div className="row">
							<div className="col-12">
								{
									Report.length > 0 ?
										Report.map(oper => {
											return (
												<div className="report__card" key={oper.Id}>
													<div className="report__card-row">
														<span>{oper.NumberDoc}</span>
														<span>{new Intl.NumberFormat('ru-RU').format(oper.Value)} ₸</span>
													</div>
													<div className="report__card-row-small">
														<span>{oper.TypeDocument.Name}</span>
														<span>{formatDate(new Date(oper.DateDocument))}</span>
													</div>
													<div className="report__card-menu">
														{
															oper.TypeDocument.Id === 1 &&
															<Link to={`/www/kkms/${idKkm}/refund`}>
																<span className="svg-handler">
																	<svg width="15" height="15" fill="none" xmlns="http://www.w3.org/2000/svg">
																		<path d="M12 6H3.08l2.7-3.38a1 1 0 1 0-1.56-1.24l-4 5a1 1 0 0 0 0 1.24l4 5A1 1 0 0 0 5 13a1 1 0 0 0 .78-1.62L3.08 8H12a1 1 0 0 1 1 1v5a1 1 0 1 0 2 0V9a3 3 0 0 0-3-3z" fill="#726D85" /></svg>
																</span>Возврат
															</Link>
														}

														{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
														<a href="#" onClick={() => getDuplicate(oper.Id)}>
															<span className="svg-handler">
																<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<path d="M18 21h-6a3 3 0 0 1-3-3v-6a3 3 0 0 1 3-3h6a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3zm-6-10a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1h-6z" fill="#726D85" />
																	<path d="M9.73 15H5.67A2.68 2.68 0 0 1 3 12.33V5.67A2.68 2.68 0 0 1 5.67 3h6.66A2.68 2.68 0 0 1 15 5.67V9.4h-2V5.67a.67.67 0 0 0-.67-.67H5.67a.67.67 0 0 0-.67.67v6.66a.67.67 0 0 0 .67.67h4.06v2z" fill="#726D85" /></svg>
															</span>Дубликат
														</a>
													</div>
												</div>
											)
										})
										:
										<div className="alert alert-light" role="alert">
											Операций нет. Вы не оформили ни одного чека
										</div>
								}

							</div>
						</div>
						:
						error.status == 410 ?
							<div className="text-center" style={{ paddingTop: '20%' }}>
								<h5><strong>{error.title}</strong></h5>
								<p>{error.text}</p>
								<Link to={`/www/kkms/${idKkm}`} className="btn btn-primary btn-lg btn-sm-block">Открыть смену</Link>
							</div>
							:
							<div className="alert alert-danger" style={{ margin: 'auto', maxWidth: '800px' }}>
								<h5>{error.title}</h5>
								<p>{error.text}</p>
							</div>
				}
				<Modal show={show} onClose={closeModal} img={img} header={header} showCheck={showCheck} />
			</div >
			<MobileNav isActive={true} />
		</React.Fragment >
	)

}

export default OpersLogLayout;
