import React, { Component } from 'react';
import { getUser, getCompany, getKkm, getBalances } from '../../Store/snippets';
import HeaderLayout from './HeaderLayout';
import Modal from '../Modal/closeShiftModal';
import ModalNew from '../NewDesign/NewShift/modals/newCloseShiftModal';
import {getAppModule} from "../../Store/appVersion";


class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user: {},
			kkm: {},
			company: {},
			balances: this.props.balances || {},
			isActive: false,
			errorText: this.props.errorText || false,
			modalIsOpen: false,
			mounted: false,
			pending: false,
			paymentsLink: '',
			kkmId: '',
			uid: ''
		};
	}

	logout = () => {
		if (localStorage.getItem('KKM_GARANT') !== null) {
			this.props.history.push('/www/garant')
			var phone = window.localStorage.getItem('phone_login');
			window.localStorage.clear();
			window.localStorage.setItem('phone_login', phone)
			window.localStorage.setItem('main_page_notification', "true")
		}else if (localStorage.getItem('KKM_LOMBARD') !== null){
			this.props.history.push('/www/lombard')
			phone = window.localStorage.getItem('phone_login');
			window.localStorage.clear();
			window.localStorage.setItem('phone_login', phone)
			window.localStorage.setItem('main_page_notification', "true")
		}else{
			this.props.history.push('/')
			phone = window.localStorage.getItem('phone_login');
			window.localStorage.clear();
			window.localStorage.setItem('phone_login', phone)
			window.localStorage.setItem('main_page_notification', "true")
		}
	}

	openModal = () => this.setState({
			...this.state,
			modalIsOpen: true
		})

	closeModal = () => this.setState({
			...this.state,
			modalIsOpen: false
		})

	closeShift = async () => this.setState(
		{
			...this.state,
			pending: true
		},
		state => this.props.history.push(`/www/kkms/${state.kkm.Id}/close`))

	openShift = async () => this.setState(
		{
			...this.state,
			pending: true
		},
		state => this.props.history.push(`/www/kkms/${state.kkm.Id}`))

	componentDidUpdate(prevProps) {
		if (prevProps.isActive !== this.props.isActive) {
			if (this.props.isActive) {
				const user = getUser(this.props.history);
				const kkm = getKkm(this.props.history);
				const company = getCompany(this.props.history);
				const balances = getBalances(this.props.history);

				this.setState({
					...this.state,
					user,
					balances,
					company,
					kkm
				});
			}
			this.setState({
				isActive: this.props.isActive
			})
		}
		prevProps.errorText !== this.props.errorText && this.setState({ errorText: this.props.errorText })
		prevProps.balances !== this.props.balances && this.setState({ balances: getBalances() })
	}

	async componentDidMount() {
		const user = JSON.parse(localStorage.getItem('USER'));
		const kkm = JSON.parse(localStorage.getItem('KKM'));

		let isShiftOpen = false;
		if ((kkm !== null && user !== null) && user.Lock === true && kkm.Lock === true && user.IdShift === kkm.IdShift && this.props.isActive === true) isShiftOpen = true;
		if (this.props.isActive && isShiftOpen) {
			const company = getCompany(this.props.history);
			const balances = getBalances(this.props.history);
			this.setState({
				...this.state,
				isActive: this.props.isActive,
				user,
				balances,
				company,
				kkm,
				mounted: true,
			})
		} else this.setState(
			{
				...this.state,
				user,
				mounted: true
			})
	}


	render() {
		return (
			<React.Fragment>
				{this.state.mounted
					&& <HeaderLayout
						{...this.state}
						modalIsOpen={this.modalIsOpen}
						closeShift={this.closeShift}
						openModal={this.openModal}
						logout={this.logout}
					/>
				}

				{
					getAppModule() ? (
						<ModalNew
							isOpen={this.state.modalIsOpen}
							closeModal={this.closeModal}
							closeShift={this.closeShift}
							pending={this.state.pending}
							balances={this.state.balances}
						/>
					) : (
						<Modal
							isOpen={this.state.modalIsOpen}
							closeModal={this.closeModal}
							closeShift={this.closeShift}
							pending={this.state.pending}
							balances={this.state.balances}
						/>
					)
				}
			</React.Fragment>
		)
	}
}

export default Header;
