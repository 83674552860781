import React, { useCallback, useEffect, useState } from 'react'
import useEmblaCarousel from 'embla-carousel-react'
import Autoplay from 'embla-carousel-autoplay'
import Button from '../Button'
import {ReactComponent as First} from './loginSlider/1.svg'
import {ReactComponent as Second} from './loginSlider/2.svg'
import {ReactComponent as Third} from './loginSlider/3.svg'
import {ReactComponent as Fourth} from './loginSlider/4.svg'
import s from './loginslider.module.scss'
import { useTranslation } from "react-i18next";

const LoginSlider = ({ closeTutorial }) => {
	const { t } = useTranslation()
	const [selectedIndex, setSelectedIndex] = useState(0)
	const [scrollSnaps, setScrollSnaps] = useState([])
	const [emblaRef, embla] = useEmblaCarousel({ loop: false }, [Autoplay({ stopOnLastSnap: true })])
	const [nextBtnEnabled, setNextBtnEnabled] = useState(true)

	const dummyData = [
		{
			id: 1,
			component: <First />,
			description: t('auth.no_more_cash_register'),
		},
		{
			id: 2,
			component: <Second />,
			description: t('auth.open_and_close_shift'),
		},
		{
			id: 3,
			component: <Third />,
			description: t('auth.issue_receipts_in_a_second'),
		},
		{
			id: 4,
			component: <Fourth />,
			description: t('auth.collect_X_and_Z_reports_in_real_time'),
		},
	]

	const scrollTo = useCallback((index) => embla && embla.scrollTo(index), [embla])
	const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla])

	const onSelect = useCallback(() => {
		if (!embla) return
		setSelectedIndex(embla.selectedScrollSnap())
		setNextBtnEnabled(embla.canScrollNext())
	}, [embla, setSelectedIndex])

	useEffect(() => {
		if (!embla) return
		onSelect()
		setScrollSnaps(embla.scrollSnapList())
		embla.on('select', onSelect)
	}, [embla, setScrollSnaps, onSelect])

	return (
		<div className={s.wrapper}>
			<h4 className={s.title}>{ t('auth.welcome_to_online_cash_register') }</h4>
			<div className={s.embla} ref={emblaRef}>
				<div className={s.embla__container}>
					{dummyData.map((item) => <div className={s.embla__slide} key={item.id}>
						{ item.component }
						</div>
					)}
				</div>
			</div>

			<div className={s.embla__dots}>
				{scrollSnaps.map((_, index) => (
					<DotButton
						key={index}
						selected={index === selectedIndex}
						onClick={() => scrollTo(index)}
					/>
				))}
			</div>

			{dummyData.map(
				(item, index) =>
					index === selectedIndex && (
						<p className={s.description} key={item.id}>
							{item.description}
						</p>
					)
			)}
			<div className={s.btn_container}>
				<Button
					className='btn__primary'
					marginBottom={32}
					onClick={nextBtnEnabled ? scrollNext : closeTutorial}
				>
					<span>Далее</span>
				</Button>
			</div>

			<button className={s.skip} onClick={closeTutorial}>
				Пропустить
			</button>
		</div>
	)
}

const DotButton = ({ selected, onClick }) => (
	<button
		className={!selected ? s.embla__dot : s.embla__dot + ' ' + s.selected}
		type='button'
		onClick={onClick}
	/>
)

export default LoginSlider
