import React from "react"
import AddPositionService from "../modules/AddPositionService"
import Modal from '../../../Modal/operationModal';
import Error503Modal from "../../Modals/Error500Modal";

const ExpenseContainer = ({ context, history }) => {
  const main = {
    marginTop: "20px",
    display: "flex",
    justifyContent: "center"
  }
  return (
    <div style={main}>
      <AddPositionService context={context} history={history} status={'expense'} />
      {
        context.Modal.Header === '503 ошибка' ? (
          <Error503Modal />
        ) : (
          <Modal
            onClose={context.closeModal}
            onCheck={context.showCheck}
            onElectronCheck={context.electronShowCheck}
            show={context.Modal.Status}
            header={context.Modal.Header}
            body={context.Modal.Body}
            img={context.CheckImg}
            docId={context.CheckId}
            docLink={context.CheckLink}
            share={context.shareSocial}
          />
        )
      }
    </div>
  )
}

export default ExpenseContainer;
