import React from 'react'
import style from '../../styles/pages/shift/topsales.module.scss'
import { useTranslation } from "react-i18next";

const TopSales = ({ topSales }) => {
  const { t } = useTranslation()
  return (
    <React.Fragment>
      <p className={style.title}>{ t('kassa.top_sales_of_shift') }</p>
      <div className={style.table}>
        <div className={style.head}>
          <div className={style.row}>
            <p className={style.name}>{ t('kassa.product_name') }</p>
            <p className={style.amount}>{ t('kassa.quantity') }</p>
            <p className={style.price}>{ t('kassa.amount') }</p>
          </div>
        </div>
        <div className={style.body}>
          {
            Array.isArray(topSales) && topSales.length ? (
              topSales.map(item => (
                <div className={style.row} key={item.IdUnit+item.Name}>
                  <p className={style.name}>{item.Name}</p>
                  <p className={style.amount}>{item.Qty}</p>
                  <p className={style.price}>{Number(item.Sum).toFixed(2)}</p>
                </div>
              ))
            ) : (
              <p>{ t('kassa.make_operation_to_see_top_sales') }</p>
            )
          }
        </div>
      </div>

      <div className={style.mobileTable}>
        {
          Array.isArray(topSales) ? (
            topSales.map(item => (
              <div className={style.article} key={'topSales:'+item.IdUnit+item.Name}>
                <p className={style.name}>{item.Name}</p>
                <div className={style.row}>
                  <p className={style.key}>{ t('kassa.quantity') }</p>
                  <p className={style.value}>{item.Qty}</p>
                </div>
                <div className={style.row}>
                  <p className={style.key}>{ t('kassa.amount') }</p>
                  <p className={style.value}>{Number(item.Sum).toFixed(2)}</p>
                </div>
              </div>
            ))
          ) : (
            <p>{ t('kassa.make_operation_to_see_top_sales') }</p>
          )
        }
      </div>
    </React.Fragment>
  )
}

export default TopSales;
