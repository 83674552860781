// const BASE_URL = localStorage.getItem('BASE_URL');
import axios from 'axios';
import { FISCAL_API_DEV_URL, FISCAL_API_PROD_URL } from '../constants/app';

const protocol = window.location.protocol;
const hostName = window.location.host;
let BASE_URL = FISCAL_API_DEV_URL;

if (process.env.NODE_ENV === 'production') {
    BASE_URL = `${protocol}//${hostName}`;

    if (window.app && !!window.cordova) {
        BASE_URL = FISCAL_API_PROD_URL;
    }
}

const AUTH_TOKEN = localStorage.getItem('tokken');
const headers = {
    'Authorization': `Bearer ${AUTH_TOKEN}`
}

const getHeaders = (history) => {

    let Token = localStorage.getItem('tokken');
    if (Token == null) {
        history.push('/');
        console.dir('Token in localStorage was empty');
        return
    }

    return {
        'Authorization': `Bearer ${Token}`
    }

    // const data = JSON.parse(localStorage.getItem('Tokken'));
    // if (data === null) {
    //     if (process.env.NODE_ENV === 'production') {
    //         history.push('/');
    //         return
    //     }
    //     throw 'FUNCTION STOP: user in localStorage is null, in production will be sent on auth';
    // }
    // return data;
}
const getUser = (history) => {
    const user = JSON.parse(localStorage.getItem('USER'))
    if (user === null) {
        if (process.env.NODE_ENV === 'production') {
            history.push('/');
            return
        }
        // throw 'FUNCTION STOP: user in localStorage is null, in production will be sent on auth';
    }
    return user;
}

const getCompany = (history) => {
    const company = JSON.parse(localStorage.getItem('COMPANY'));
    if (company === null) {
        if (process.env.NODE_ENV === 'production') {
            history.push('/www/comps_new');
            return
        }
        // throw 'FUNCTION STOP: Company in localStorage is null, in production will be sent on auth';
    }
    return company;
}

const getKkm = (history) => {
    const kkm = JSON.parse(localStorage.getItem('KKM'));
    if (kkm === null) {
        if (process.env.NODE_ENV === 'production') {
            history.push('/www/comps_new');
            return
        }
        // throw 'FUNCTION STOP: kkm in localStorage is null, in production will be sent on auth';
    }
    return kkm;
}

const getShift = (history) => {
    const shift = JSON.parse(localStorage.getItem('SHIFT'));
    if (shift === null) {
        if (process.env.NODE_ENV === 'production') {
            history.push(`/www/kkms/${getKkm().Id}`);
            return
        }
        // throw 'FUNCTION STOP: shift in localStorage is null, in production will be sent on auth';
    }
    return shift;
}

const getBalances = (history) => {
    const balance = JSON.parse(localStorage.getItem('BALANCES'));
    if (balance === null) {
        if (process.env.NODE_ENV === 'production') {
            history.push('/www/comps_new');
            return
        }
        // throw 'FUNCTION STOP: balances in localStorage is null, in production will be sent on companies';
    }
    return balance;
}

const getUid = async (history) => {
    const Token = localStorage.getItem('tokken');
    const headers = {
        'Authorization': `Bearer ${Token}`
    }
    const idKkm = localStorage.getItem('CURRENT_KKM_ID');
    if (idKkm === null) {
        if (process.env.NODE_ENV === 'production') {
            history.push('/www/comps_new');
            return
        }
        // throw 'FUNCTION STOP: CURRENT_KKM_ID in localStorage is null, in production will be sent on Companies';
    }

    let uid = localStorage.getItem(`Uid_${idKkm}`);
    if (uid) return uid;

    try {
        const uidResponse = await axios.get(`${BASE_URL}/uid/${idKkm}`, { headers: headers });
        uid = uidResponse.data.Data.Uid;
        localStorage.setItem(`Uid_${idKkm}`, uid);
    } catch (e) {
        // localStorage.removeItem(`Uid_${idKkm}`);
        throw e;
    }
    return uid;
}

const formatDate = (date, type) => {
    // const monthNames = [
    // 	"Январь", "Февраль", "Март",
    // 	"Апрель", "Май", "Июнь", "Июль",
    // 	"Август", "Сентябрь", "Октябрь",
    // 	"Ноябрь", "Декабрь"
    // ];

    const pad = (d) => {
        d = Number(d);
        return (d < 10) ? '0' + d.toString() : d.toString();
    }

    const day = pad(date.getDate());
    const monthIndex = pad(date.getMonth() + 1);
    const year = pad(date.getFullYear());
    const hour = pad(date.getHours());
    const mins = pad(date.getMinutes());

    if (type) {
        return `${day}.${monthIndex}.${year}`;
    }
    return ` ${hour}:${mins} ${day}.${monthIndex}.${year}`;
}

const getError = (error, history = {}) => {
    console.log('ERROR ======>>', error, error.response);
    if (error.response === undefined) {
        return {
            Status: '',
            Message: 'Проверьте соединение с интернетом.'
        }
    }

    if (error.response.status > 499) {
        return {
            Status: 503,
            Message: 'Отсутствует связь с фискальным накопителем.'
        }
    }


    if (error.response.status === 401) {
        history.push(`/`, { head: 'Ваша сессия истекла', message: 'В целях безопасности повторите вход' });
        const phone = window.localStorage.getItem('phone_login');
        window.localStorage.clear();
        window.localStorage.setItem('phone_login', phone)
        return {
            Status: error.response.status,
            Message: 'Истёк токен авторизации, перезайдите'
        }
    }

    if (error.response.status === 403) {
        return {
            Status: error.response.status,
            Message: 'Нет доступа к кассе, обратитесь в тех. поддержку'
        }
    }

    if (error.response.status === 410) {
        return {
            Status: error.response.status,
            Message: 'Смена закрыта'
        }
    }

    if (error.response.status === 429) {
        return {
            Status: error.response.status,
            Message: 'Операция не удалась. Касса заблокирована из-за работы с разных устройств'
        }
    }

    return {
        Status: error.response.status,
        Message: 'Что-то пошло не так. Обновите страницу, если ошибка повторится - обратитесь в тех. поддержку.'
    }
}

const formatAmount = (amount) => {
    return new Intl.NumberFormat('ru-RU').format(amount)
}

const getDataToString = (id, today, type) => {
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let days = today.getDate();

    if (days < 10) {
        days = "0" + days;
    }

    if (month < 10) {
        month = "0" + month;
    }

    const timeZoneOffset = -new Date().getTimezoneOffset() / 60; 
    const timeZone = timeZoneOffset >= 0 ? `+${String(timeZoneOffset).padStart(2, '0')}:00` : `-${String(-timeZoneOffset).padStart(2, '0')}:00`;

    let time = `23:59:59${timeZone}`;
    if (id === 2) {
        time = `00:00:00${timeZone}`;
    }

    if (type === 'z') {
        let time2 = "23:59:59";

        if (id === 2) {
            time2 = "00:00:00";
        }

        return `${year}-${month}-${days}T${time2}`;
    }

    return `${year}-${month}-${days}T${time}`;
};

const getCurrentDate = () => {
	let today = new Date();
	let days = today.getDate()
	let month = today.getMonth() + 1;
	let  year = today.getFullYear();
	let day = today.getDay();

	let weekdayFromToday = new Date(today)
	let yearFromToday = new Date(today)
	weekdayFromToday.setDate(days - 7)
	yearFromToday.setFullYear(year - 2)

	if(days < 10){
		days = "0"+ days;
	}

	if(month < 10){
		month = "0"+ month;
	}

	return {
		today,
		weekdayFromToday,
		yearFromToday,
		days,
		month,
		year,
		day,
	}
}


// const formatCurrency = (number) => new Intl.NumberFormat('ru-RU').format(number)

export { BASE_URL, AUTH_TOKEN, headers, getUser, getCompany, getKkm, getShift, getUid, getBalances, formatDate, getHeaders, getError, formatAmount, getDataToString, getCurrentDate };
