exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".mobileUserExit_wrapper__Yhcpg {\n  display: none;\n  background: #FFFFFF;\n  width: 100%;\n  height: 68px;\n  justify-content: space-between;\n  padding-left: 20px; }\n  .mobileUserExit_wrapper__Yhcpg .mobileUserExit_leftSide__1CI5j {\n    display: flex; }\n    .mobileUserExit_wrapper__Yhcpg .mobileUserExit_leftSide__1CI5j .mobileUserExit_userBlock__1L0j8 {\n      margin-left: 20px;\n      display: flex;\n      flex-direction: column;\n      justify-content: center; }\n      .mobileUserExit_wrapper__Yhcpg .mobileUserExit_leftSide__1CI5j .mobileUserExit_userBlock__1L0j8 p {\n        margin-bottom: 0; }\n        .mobileUserExit_wrapper__Yhcpg .mobileUserExit_leftSide__1CI5j .mobileUserExit_userBlock__1L0j8 p.mobileUserExit_name__1XBOo {\n          color: #000000;\n          letter-spacing: -0.2px;\n          font-weight: 500;\n          font-size: 16px;\n          line-height: 140%; }\n        .mobileUserExit_wrapper__Yhcpg .mobileUserExit_leftSide__1CI5j .mobileUserExit_userBlock__1L0j8 p.mobileUserExit_type__1aXTj {\n          color: #8E8E8E;\n          letter-spacing: -0.2px;\n          font-weight: 400;\n          font-size: 14px;\n          line-height: 140%; }\n  .mobileUserExit_wrapper__Yhcpg .mobileUserExit_rightSide__3Hb8Z {\n    border-left: 1px solid #ECECEC;\n    width: 84px;\n    display: flex;\n    align-items: center;\n    justify-content: center; }\n\n@media (max-width: 768px) {\n  .mobileUserExit_wrapper__Yhcpg {\n    display: flex; } }\n", ""]);

// exports
exports.locals = {
	"wrapper": "mobileUserExit_wrapper__Yhcpg",
	"leftSide": "mobileUserExit_leftSide__1CI5j",
	"userBlock": "mobileUserExit_userBlock__1L0j8",
	"name": "mobileUserExit_name__1XBOo",
	"type": "mobileUserExit_type__1aXTj",
	"rightSide": "mobileUserExit_rightSide__3Hb8Z"
};