/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import BarcodeScanner from '../../../Modal/BarcodeScanner';
import { Store } from '../../../../Store/OperationProvider';

export default class Precheck extends Component {

    state = {
      open: false,
      showScanner: false,
    }

    setShowScanner = () => {
        this.setState({
          showScanner: !this.state.showScanner
        })
    }

    handleInputText = (e, functionHandler, val) => {
        functionHandler({ value: e.target.value, name: val });
    };

    // handleInputNumber = (e, functionHandler, val) => {
    //     if (e.target.value == Number(e.target.value)) {
    //         functionHandler({ value: e.target.value, name: val });
    //     }
    // };

    handleInputNumber = (e, functionHandler, val) => {
      if(e.target.value.slice(-4, -3) !== "."){
        functionHandler({ value: e.target.value, name: val });
      }
    };

    handleInputQty = (e, functionHandler, val) => {
        if (e.target.value === Number(e.target.value)) {
            let qty = Number(e.target.value);
            // if (e.target.value < 1) {
            //     qty = 1;
            // }
            functionHandler({ value: qty, name: val });
        }
    };

    handleOpenToggle = () => {
        // document.getElementById("myDropdown").classList.toggle("show");
      this.setState({
        open: true
      })
    };

    handleCloseToggle = () => {
      setTimeout(() =>
        this.setState({
          open: false
        }), 180
      )
    }

    handleClick = context => e => {
      context.Articles.map(
        art =>
          art.Id === e.target.value &&
          this.setState({
            selectedOption: {
              label: art.Name,
              value: art.Id
            },
            currentArticle: art
          }, () => {
            context.setArticle(art);
          })
      );
    }

    setQr = context => code => {
        context.Articles.map(art => (
          art.Qr === code &&
          this.setState({
            currentArticle: art
          }, () => {
            context.setArticle(art);
          })
        ))
    }

    handleInputChange = (e, func, val, objName) => {
        func({ value: e.target.value, name:val, objName: objName });
    };

    toggleHover = () => {
        this.setState({
            hover: !this.state.hover
        })
    }

    state = {
        hover: false
    }

    render() {
        const styles = {
            color: 'red',
            fontSize: '14px',
            marginTop: '5px'
        }
        const popupStyle = {
            background: "#343434",
            borderRadius: "3px",
            boxShadow: "0px 1px 2px rgba(117, 117, 133, 0.12)",
            width: "300px",
            height: "50px",
            padding: "6px 10px",
            color: "#E1E1E6",
            fontSize: "14px",
            lineHeight: "19px",
            position: "absolute",
            display: this.state.hover ? 'block' : 'none',
            left: "15%",
            top: "120%"
        }
        return (
            <Store.Consumer>
                {
                    context => (
                        <React.Fragment>
                            <h4 className="mb-3">
                                Добавить позицию
                                <span />
                            </h4>

                            {!context.PositionErrors.isValid &&
                                (
                                    <div className="NewSale__alert alert alert-warning mb-3 shadow">
                                        <h5 className="alert-heading">Ошибка при добавлении позиции</h5>
                                        <p className="text-danger mb-1">{context.PositionErrors.Name}</p>
                                        <p className="text-danger mb-1">{context.PositionErrors.Price}</p>
                                        <p className="text-danger mb-1">{context.PositionErrors.Markup}</p>
                                        <p className="text-danger mb-1">{context.PositionErrors.Discount}</p>

                                    </div>
                                )
                            }

                            <form className="needs-validation" noValidate>

                                <div className="form-row pt-2">
                                    <div className="form-group col-md-7">
                                        <label htmlFor="inputEmail4">Наименование</label>
                                        <div className="input-wrapper-barcode">
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                className={`barcode-input form-control form-control-lg ${context.PositionErrors.Name && 'is-invalid'}`}
                                                id="inputEmail4"
                                                placeholder="Название товара или услуги"
                                                value={context.Position.Name ? context.Position.Name : ""}
                                                onChange={e => { this.handleInputText(e, context.positionInputHandler, 'Name') }}
                                                onFocus={this.handleOpenToggle}
                                                onBlur={() => this.handleCloseToggle()}
                                            />
                                            <div className="sale-barcode" onClick={this.setShowScanner}>
                                                <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M0.666992 0.333374H2.33366V13.6667H0.666992V0.333374ZM4.00033 0.333374H5.66699V13.6667H4.00033V0.333374ZM6.50033 0.333374H9.00033V13.6667H6.50033V0.333374ZM9.83366 0.333374H11.5003V13.6667H9.83366V0.333374ZM12.3337 0.333374H14.0003V13.6667H12.3337V0.333374ZM14.8337 0.333374H17.3337V13.6667H14.8337V0.333374Z" fill="#575757"/>
                                                </svg>
                                            </div>
                                        </div>
                                        {this.state.open && (
                                          <div id="myDropdown2" className="dropdownContainer">
                                          {context.Articles.map(art => (
                                            <li key={art.Id} onClick={this.handleClick(context)} value={art.Id} type="none" className="select-row">
                                              {art.Name}
                                            </li>
                                          ))}
                                        </div>
                                        )}
                                        <BarcodeScanner setCode={this.setQr(context)} setShowScanner={this.setShowScanner} showScanner={this.state.showScanner} />
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label htmlFor="inputPassword4">Цена</label>
                                        <input min="0" autoComplete="off" type="number" className={`form-control form-control-lg ${context.PositionErrors.Price && 'is-invalid'}`}
                                            inputMode="numeric"
                                            id="" placeholder="0"
                                            value={context.Position.Price === 0 ? '' : context.Position.Price}
                                            onChange={e => { this.handleInputNumber(e, context.priceInputHandler, 'Price') }}
                                            onFocus={this.handleFocus}
                                        />
                                    </div>
                                    <div className="form-group col-md-2">
                                        <label htmlFor="">Количество</label>
                                        <input autoComplete="off" type="number" className={`form-control form-control-lg ${context.PositionErrors.Qty && 'is-invalid'}`}
                                            id=""
                                            // type="number"
                                            inputMode="numeric"
                                            placeholder="1.00"
                                            value={context.Position.Qty === 0 ? '' : context.Position.Qty}
                                            onChange={e => { this.handleInputQty(e, context.positionInputHandler, 'Qty') }}
                                            onFocus={(e) => { e.target.placeholder = ''; }}
                                            onBlur={(e) => { e.target.placeholder = '1.00'; }}
                                        />
                                    </div>
                                </div>
                                <div className="form-row d-md-flex">
                                    <div className="form-group col-md-12">
                                        <label htmlFor="selectSection">Единица измерения</label>
                                        <select id="selectSection" className="form-control"
                                            value={context.Position.Unit.Id}
                                            onChange={e => context.unitChangeHandler(Number(e.target.value))}>
                                            {
                                                context.Units.map((Unit) => {
                                                    return <option key={Unit.Id} value={Unit.Id}>{Unit.NameRU}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>

                                <div className="form-row d-md-block">
                                    <div className="form-group col-md-12">
                                        <label htmlFor="selectSection">Секция</label>
                                        <select id="selectSection" className="form-control"
                                            value={context.Position.Section.Id}
                                            onChange={e => context.sectionChangeHandler(Number(e.target.value))}>
                                            {/*<option value="" disabled hidden>Выбирите секцию...</option>*/}
                                            {
                                                context.Sections.map((Section, index) => {
                                                    return <option key={index} value={Section.Id}>{Section.Name}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>

                                <div className="pt-2 pb-3">
                                    <button type="button" className="btn btn-primary btn-sm mr-2 btn-sm-block"
                                        onClick={(e) => context.addPreCheckPosition(e, "purchase")}>
                                        Добавить в предчек
                                        <span className="d-none d-md-inline" role="img" aria-label="right-emoji">👉</span>
                                        <span className="d-md-none" role="img" aria-label="up-emoji">👆</span>
                                    </button>

                                    <small className="d-none d-sm-inline">или&#8194;</small>

                                    <button type="button" className="btn btn-outline-secondary btn-sm  btn-sm-block"
                                        onClick={() => context.resetPosition()}>
                                        Очистить форму
                                    </button>
                                    <input className="invisible" type="submit" style={{ position: 'absolute' }} />
                                </div>

                                <div className="form-group d-md-block">
                                    <label htmlFor="inputDomain">Вид деятельности</label>
                                    <select id="inputDomain" className="form-control"
                                        value={context.Position.Domain.Id}
                                        onChange={e => context.domainChangeHandler(Number(e.target.value))}>
                                        {/*<option value="" disabled hidden>Выбирите вид деятельности...</option>*/}
                                        {
                                            context.Domains.map((Domain, index) => {
                                                return <option key={index} value={Domain.Id}>{Domain.Name}</option>
                                            })
                                        }

                                    </select>
                                </div>

                                <div className="form-group d-md-block">
                                    <label htmlFor="inputDomain" style={{position: 'relative'}}>
                                        ИИН/БИН покупателя
                                        <span style={{marginLeft: "7px", cursor: "pointer"}} onMouseEnter={this.toggleHover} onMouseLeave={this.toggleHover}>
                                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9.00008 17.3333C4.39758 17.3333 0.666748 13.6025 0.666748 8.99999C0.666748 4.39749 4.39758 0.666656 9.00008 0.666656C13.6026 0.666656 17.3334 4.39749 17.3334 8.99999C17.3334 13.6025 13.6026 17.3333 9.00008 17.3333ZM9.00008 15.6667C10.7682 15.6667 12.4639 14.9643 13.7141 13.714C14.9644 12.4638 15.6667 10.7681 15.6667 8.99999C15.6667 7.23188 14.9644 5.53619 13.7141 4.28594C12.4639 3.0357 10.7682 2.33332 9.00008 2.33332C7.23197 2.33332 5.53628 3.0357 4.28604 4.28594C3.03579 5.53619 2.33341 7.23188 2.33341 8.99999C2.33341 10.7681 3.03579 12.4638 4.28604 13.714C5.53628 14.9643 7.23197 15.6667 9.00008 15.6667ZM8.16675 4.83332H9.83342V6.49999H8.16675V4.83332ZM8.16675 8.16666H9.83342V13.1667H8.16675V8.16666Z" fill="#8E8E8E"/>
                                            </svg>
                                        </span>
                                        <div style={popupStyle}>
                                            Указание ИИН/БИН покупателя опционально, по запросу покупателя.
                                        </div>
                                    </label>
                                    <input autoComplete="off"
                                        inputMode="numeric"
                                        type="text"
                                        className="form-control"
                                        required
                                        placeholder="0"
                                        maxLength="12"
                                        value={context.CustomerIin}
                                        onChange={e => { this.handleInputChange(e, context.iinHandler) }}
                                    />
                                    {   
                                        context.CustomerIinError && <div style={styles}>Введите корректный БИН/ИИН</div>
                                    }
                                </div>
                            </form>
                        </React.Fragment>
                    )
                }

            </Store.Consumer>
        )
    }
}
