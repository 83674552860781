import React from "react"
import { Store } from "../../../Store/OperationProvider";
// import Header from '../../Header/Header';
// import MobileNav from '../../MobileNav/MobileNav';
import Check from "./Check";
import HeaderVersion from "../HeaderVersion/HeaderVersion";

const CheckContainer = (props) => {
  const main = {
    backgroundColor: "#F5F5F5",
    minHeight: "calc(100vh - 160px)"
  }
  return (
    <Store.Consumer>
      {
        context => (
          <React.Fragment>
            <HeaderVersion isActive={true} balances={context.Balances} {...props} />
            <div style={main}>
              <div className="container">
                <Check context={context} history={props.history} />
              </div>
            </div>
          </React.Fragment>
        )
      }
    </Store.Consumer>
  )
}

export default CheckContainer;
