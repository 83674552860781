import React from 'react';
import style from '../styles/pages/cashbox/NewSettingsTabs.module.scss'

const SettingsTabs = ({ navsList, activeNav, setActiveNav, setStep }) => {
  const clickToTab = (id) => {
    setActiveNav(id)
    setStep(id)
  }

  return (
    <React.Fragment>
        <div className={`${style['navs-wrapper']} ${style['mt-20']}`}>
          <div className={style['navs']} style={{ marginTop: '24px' }}>
            {
              navsList.map(nav => (
                <div className={`${style.nav} ${activeNav === nav.id ? style.active : ''}`} key={nav.id} onClick={() => clickToTab(nav.id)}>
                  <span>
                    {nav.name}
                  </span>
                </div>
              ))
            }
          </div>
        </div>
    </React.Fragment>
  )
}

export default SettingsTabs;
