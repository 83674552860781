import React, {useEffect, useState} from "react";
import BarcodeScanner from "../../../Modal/BarcodeScanner";
import style from "../../styles/pages/cashbox/modals/newArticlesModal.module.scss"
import OutsideClicker from "../helpers/OutsideClicker";
import { ReactComponent as BarcodeLine } from '../../svg/barcode-line.svg'
import { ReactComponent as ArrowDropdownFill } from '../../svg/arrow-drop-down-fill.svg'
import { ReactComponent as CloseLine } from '../../svg/close-line.svg'
import { useTranslation } from "react-i18next";

const NewArticlesEditModal = ({ addArticle, sections = [], units = [], currentArticle, onClose }) => {
	const { t } = useTranslation()

	const [Name, setName] = useState("")
	const [Qr, setQr] = useState("")
	const [IdSection, setIdSection] = useState(
		sections && sections.length > 0 ? sections[0].Id : null
	);
	const [IdUnit, setIdUnit] = useState(
		units && units.length > 0 ? units[0].Id : null
	);
	const [Price, setPrice] = useState("")
	const [Discount, setDiscount] = useState("")
	const [Markup, setMarkup] = useState("")
	const [Active, setActive] = useState(false)
	const [NameError, setNameError] = useState("")
	const [BarcodeError, setBarcodeError] = useState("")
	const [PriceError, setPriceError] = useState("")
	const [showScanner, setShowScanner] = useState(false)
	const [showUnits, setShowUnits] = useState(false)
	const [showSections, setShowSections] = useState(false)

	useEffect(() => {
		setNameError("")
		setBarcodeError("")
		if (currentArticle.Id) {
			setName(currentArticle.Name)
			setQr(currentArticle.Qr)
			setIdSection(currentArticle.IdSection)
			setIdUnit(currentArticle.IdUnit)
			setPrice(currentArticle.Price)
			setDiscount(currentArticle.Discount)
			setMarkup(currentArticle.Markup)
			setActive(currentArticle.Active)
		} else {
			setName("")
			setQr("")
			setIdSection(
				sections && sections.length > 0 ? sections[0].Id : null
			);
			setIdUnit(
				units && units.length > 0 ? units[0].Id : null
			);
			setPrice("")
			setDiscount("")
			setMarkup("")
			setActive(false)
		}
	}, [currentArticle])

	const createArticle = () => {
		let data = {
			Name,
			Qr,
			"IdSection": parseInt(IdSection),
			"IdUnit": parseInt(IdUnit),
			"Price": Price ? parseFloat(Price) : 0,
			"Discount": Discount ? parseFloat(Discount) : 0,
			"Markup": Markup ? parseFloat(Markup) : 0,
			Active,
			Id: currentArticle.Id || undefined
		}
		if (Name) {
			setNameError("")
		} else {
			setNameError(t('other.enter_item_name'))
		}

		if (Price) {
			setPriceError("")
		} else {
			setPriceError(t('other.enter_item_price'))
		}

		if (Qr) {
			if (Qr.length === 8 || Qr.length === 13) {
				setBarcodeError("")
			} else {
				setBarcodeError(t('other.barcode_length_8_or_13'))
			}
		} else {
			setBarcodeError("")
		}

		if (Qr) {
			if (Name &&  Price && (Qr.length === 8 || Qr.length === 13)) {
				addArticle(data).then(() => onClose())
			}
		} else {
			if (Name && Price) {
				addArticle(data).then(() => onClose())
			}
		}
	}

	const onlyNumber = (e) => {
		let keyCode = (e.keyCode ? e.keyCode : e.which);
		if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
			e.preventDefault();
		}
	}


	const setPriceValue = (price) => {
		if (!isNaN(parseInt(price))) {
			setPrice(price)
		}
	}
	const setDiscountValue = (price) => {
		if (!isNaN(parseInt(price))) {
			setDiscount(price)
		}
	}
	const setMarkupValue = (price) => {
		if (!isNaN(parseInt(price))) {
			setMarkup(price)
		}
	}

	return (
		<React.Fragment>
			{
				!showScanner ? (
					<div
						className={style.modal}
					>
						<div className={style['modal__overlay']}>
							<div className={`${style.card} ${style['card--white']}`}>
								<div
									className={style['modal__btn--close']}
									onClick={onClose}
								>
									<CloseLine/>
								</div>

								<h2 className={`${style['heading-secondary']} ${style['text-center']}`}>
									{
										currentArticle.Id ? t('kassa_settings.item_editor') : t('kassa_settings.add_new_item')
									}
								</h2>
								<div className={style.form}>
									<div className={`${style['form__group']} ${style['mt-30']}`}>
										<label htmlFor="Qr" className={style['form__label']}>Штрих-код</label>
										<div className={style['input-wrapper']}>
											<input
												type="text"
												placeholder={ t('kassa_settings.enter_barcode') }
												className={style['input-wrapper__input']}
												maxLength="13"
												value={Qr}
												onChange={e => setQr(e.target.value.replace(/\D/,''))}
												id="Qr"
												name="Qr"
											/>
											<button className={style['input-wrapper__btn']} onClick={() => setShowScanner(true)}>
												<BarcodeLine/>
											</button>
											{
												BarcodeError && <p className={style['form__error']}>{BarcodeError}</p>
											}
										</div>
									</div>
									<div className={style['form__group']}>
										<label htmlFor="Name" className={style['form__label']}>Название товара</label>
										<input
											type="text"
											placeholder={ t('kassa_settings.enter_name') }
											className={`${style['form__input']} ${NameError ? style['form__input--error'] : ''}`}
											value={Name}
											onChange={e => setName(e.target.value)} id="Name" name="Name"
										/>
										{
											NameError && <p className={style['form__error']}>{NameError}</p>
										}
									</div>

									<div className={style['form__group']}>
										<label htmlFor="Name" className={style['form__label']}>Единица изменения</label>
										<OutsideClicker setShow={setShowUnits}>
											<div
												className={style['form__select-wrapper']}
												onClick={() => setShowUnits(!showUnits)}
											>
												<div className={style['form__select']}>
													<p className={style['paragraph--lead']}>
														{units && units.find(unit => unit.Id === IdUnit)
														? units.find(unit => unit.Id === IdUnit).NameRU
														: t('other.not_specified')}
													</p>
													<ArrowDropdownFill/>
												</div>
												{
													showUnits && (
														<div className={style['form__select-list']}>
															{
																units.map(unit => {
																	return (
																		<div
																			className={style['form__select-item']}
																			key={unit.Id}
																			onClick={() => setIdUnit(unit.Id)}
																		>
																			{unit.NameRU}
																		</div>
																	)
																})
															}
														</div>
													)
												}
											</div>
										</OutsideClicker>
									</div>

									<div className={style['form__wrapper']}>
										<div className={style['form__group']}>
											<label htmlFor="Price" className={style['form__label']}>Цена, ₸</label>
												<input
													type="text"
													className={`${style['form__input']} ${PriceError ? style['form__input--error'] : ''}`}
													value={Price}
													onKeyPress={(e) => onlyNumber(e)}
													onChange={e => setPriceValue(e.target.value)}
													id="Price"
													name="Price"
													placeholder="00.00"
												/>
											{
												PriceError && <p className={style['form__error']}>{PriceError}</p>
											}
										</div>

										<div className={style['form__group']}>
											<label htmlFor="Name" className={style['form__label']}>Секция, %</label>
											<OutsideClicker setShow={setShowSections}>
												<div
													className={style['form__select-wrapper']}
													onClick={() => setShowSections(!showSections)}
												>
													<div className={style['form__select']}>
														<p className={style['paragraph--lead']}>{ sections.find(section => section.Id === IdSection) ? sections.find(section => section.Id === IdSection).Name : t('other.not_specified')}</p>
														<ArrowDropdownFill/>
													</div>
													{
														showSections && (
															<div className={style['form__select-list']}>
																{
																	sections.map(section => {
																		return (
																			<div
																				className={style['form__select-item']}
																				key={section.Id}
																				onClick={() => setIdSection(section.Id)}
																			>
																				{section.Name}
																			</div>
																		)
																	})
																}
															</div>
														)
													}
												</div>
											</OutsideClicker>
										</div>
									</div>

									<div className={style['form__wrapper']}>
										<div className={style['form__group']}>
											<label htmlFor="Discount" className={style['form__label']}>Скидка, %</label>
												<input
													type="text"
													step=".01"
													className={style['form__input']}
													value={Discount}
													onKeyPress={(e) => onlyNumber(e)}
													onChange={e => setDiscountValue(e.target.value)}
													id="Discount"
													name="Discount"
													placeholder="00.00"
												/>
										</div>

										<div className={style['form__group']}>
											<label htmlFor="Markup" className={style['form__label']}>Наценка, %</label>
												<input
													className={style['form__input']}
													value={Markup}
													onKeyPress={(e) => onlyNumber(e)}
													onChange={e => setMarkupValue(e.target.value)}
													id="Markup"
													name="Markup"
													placeholder="00.00"
												/>
										</div>
									</div>

									<div className={style['mt-40']}>
										<div className={style['flex-2']}>
											<p className={style['paragraph--lead']}>Товар доступен для продажи</p>
											<div>
												<div
													className={Active ? style['type-check'] : style['type-check-off']}
													onClick={() => setActive(!Active)}
												>
													<div className={style['type-circle']}></div>
												</div>
											</div>
										</div>
										<p className={`${style.paragraph} ${style['mt-20']}`}>
											{
												Active ? (
													t('kassa_settings.selection_list')
												) : (
													t('kassa_settings.not_displayed')
												)
											}
										</p>
									</div>

									<div className={style['form__group']}>
										<div>
											<button
												onClick={onClose}
												className={`${style.btn} ${style['btn--icon']} ${style['btn--block']}`}
											>
												Закрыть
											</button>
										</div>
									</div>
									<div className={style['form__group']}>
										<div>
											<button
												onClick={() => createArticle()}
												className={`${style.btn} ${style['btn--primary']} ${style['btn--block']}`}
											>
												Сохранить
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				)
				:
				(
					<BarcodeScanner  setCode={setQr} setShowScanner={setShowScanner} showScanner={showScanner} />
				)
			}

		</React.Fragment>

	)
}

export default NewArticlesEditModal
