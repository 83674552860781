import React, { useEffect, useRef, useState} from "react";
import s from './NewHeader.module.scss'
import {Link, useHistory} from "react-router-dom";
import Logo from "../../Logo/Logo";
import { ReactComponent as  RiArrowRightSFill } from '../svg/arrow-right-s-fill.svg'
import { ReactComponent as  RiArrowDownSFillWhite } from '../svg/arrow-down-s-fill-white.svg'
import { ReactComponent as  RiUserLine } from '../svg/user-line.svg'
import { ReactComponent as  RiLogoutBoxRLine } from '../svg/logout-box-r-line.svg'
import { ReactComponent as  RiComputerLine } from '../svg/computer-line.svg'
import { ReactComponent as  RiTimerLine } from '../svg/timer-line.svg'
import { ReactComponent as  RiCoinLine } from '../svg/coin-line.svg'
import { ReactComponent as  RiWallet3Line } from '../svg/wallet-3-line.svg'
import { ReactComponent as  RiBankCard2Line } from '../svg/bank-card-2-line.svg'
import { ReactComponent as  RiFilePaperLine } from '../svg/file-paper-line.svg'
import Modal from "../../../Components/NewDesign/NewShift/modals/newCloseShiftModal";
import {OperationProvider, Store} from "../../../Store/OperationProvider";
import LangToggler from "../LangToggler";
import { useTranslation } from "react-i18next";

const NewHeader = (props) => {
	const { t } = useTranslation()

	const operationTitleList = [
		{ id: 0, title: t('main.sale') },
		{ id: 1, title: t('main.purchase') },
		{ id: 2, title: t('main.income') },
		{ id: 3, title: t('main.expense_and_indexation') },
		{ id: 4, title: t('main.refund_of_sale') },
		{ id: 5, title: t('main.refund_of_purchase') },
	]

	const operationBtn = useRef(null);
	const [closeShiftModalIsOpen, setCloseShiftModalIsOpen] = useState(false)
	const [operationBtnIsShown, setOperationBtnIsShown] = useState(true)
	const [headerState, setHeaderState] = useState({
		userName: JSON.parse(localStorage.getItem('USER')).Name,
		kkmId: JSON.parse(localStorage.getItem('OPEN_SHIFT_KKM_ID')),
		shiftIndex: JSON.parse(localStorage.getItem('SHIFT_INDEX')),
		balances: JSON.parse(localStorage.getItem('OPEN_SHIFT_BALANCES')),
		shiftIsOpen: JSON.parse(localStorage.getItem('USER')).Lock,
		modalIsOpen: false,
		pending: false,
	})

	const history = useHistory()

	useEffect(() => {
		if (props.shiftIsOpen === undefined) {
			return
		}

		if (props.shiftIsOpen) {
			setHeaderState({
				...headerState,
				userName: JSON.parse(localStorage.getItem('USER')).Name,
				kkmId: JSON.parse(localStorage.getItem('OPEN_SHIFT_KKM_ID')),
				shiftIndex: JSON.parse(localStorage.getItem('SHIFT_INDEX')),
				balances: JSON.parse(localStorage.getItem('OPEN_SHIFT_BALANCES')),
				shiftIsOpen: props.shiftIsOpen,
			})
		} else {
			setHeaderState({
				...headerState,
				shiftIsOpen: props.shiftIsOpen,
			})
		}
	}, [props.shiftIsOpen])

	useEffect(() => {
		if (props.balances !== undefined) {
			if (Object.keys(props.balances).length !== 0) {
				localStorage.setItem('OPEN_SHIFT_BALANCES', JSON.stringify(props.balances))
			}
		}
	}, [props.balances])

	const goToOperation = (id) => {
		const url = `/www/kkms/${headerState.kkmId}`
		switch (id) {
			case 0:
				history.push(`${url}/sale_new`)
				break;
			case 1:
				history.push(`${url}/purchase_new`)
				break;
			case 2:
				history.push(`${url}/income_new`)
				break;
			case 3:
				history.push(`${url}/expense_new`)
				break;
			case 4:
				history.push(`${url}/refund_new`)
				break;
			case 5:
				history.push(`${url}/purchase_refund_new`)
				break;
			default: return;
		}
	}

	const logout = () => {
		if (localStorage.getItem('KKM_GARANT') !== null) {
			props.history.push('/www/garant')
		} else if (localStorage.getItem('KKM_LOMBARD') !== null){
			props.history.push('/www/lombard')
		} else{
			props.history.push('/')
		}

		const phone = window.localStorage.getItem('phone_login');
		window.localStorage.clear();
		window.localStorage.setItem('phone_login', phone)
	}

	const toggleShiftModal = () => setCloseShiftModalIsOpen(!closeShiftModalIsOpen)

	const closeShift = async () => {
		setHeaderState({
			...headerState,
			pending: true,
			shiftIsOpen: false,
		})

		history.push(`/www/kkms/${headerState.kkmId}/close_new`);

		localStorage.removeItem('OPEN_SHIFT_KKM_ID')
		localStorage.removeItem('OPEN_SHIFT_RECEIPT_COUNT')
		localStorage.removeItem('OPEN_SHIFT_BALANCES')
	}

	const getTotalBalance = (balances) => {
		if (balances) {
			return balances.reduce((totalBalances, balance) => totalBalances + balance.Amount, 0);
		}
	}

	return (
		<OperationProvider>
			<Store.Consumer>
				{
					context => (
						<React.Fragment>
							<header className={ `${ s.header} ${ !headerState.shiftIsOpen ? s.header__closed : ''}` }>
								{
									headerState.shiftIsOpen
										?
										<div className={s.header_wrapper}>
											<div className={ s.header_item }>
												<Link to={ '/www/comps_new' }>
													<Logo fill={'#FFFFFF'} />
												</Link>
												<div className={ s.header_itemBox }>
													<div className={ s.header_infoBox }>
														<RiComputerLine />
														<div className={ s.header_infoDesc }>
															<h3>{ t('main.cash_register_number') } №{ headerState.kkmId }</h3>
															<span>{ t('main.balance') }: { getTotalBalance(headerState.balances) } ₸</span>
														</div>
													</div>
													<div className={ s.header_infoBox } >
														<RiTimerLine />
														<div className={ s.header_infoDesc }>
															<h3>{ t('main.shift') } №{ headerState.shiftIndex }</h3>
															<span>{ t('main.printed_receipts') }: { context.totalReceiptCount }</span>
														</div>
													</div>
													<div className={ s.headerPopup }>
														<div className={ s.headerPopup_top }>
															<h3 className={ s.headerPopup_title }>
																{ t('main.cash_register_number') } №{ headerState.kkmId }
															</h3>

															<div className={ s.headerPopup_topItemWrapper }>
																<div className={ s.headerPopup_topItemBox }>
																	<div className={ s.headerPopup_topItem }>
																		<div>
																			<RiCoinLine />
																			<span>{ t('main.balance') }</span>
																		</div>
																		<span>{ getTotalBalance(headerState.balances) }</span>
																	</div>
																	<div className={ s.headerPopup_topItem }>
																		<div>
																			<RiWallet3Line />
																			<span>{ t('main.cash') }</span>
																		</div>
																		<span>{ headerState.balances && headerState.balances.length && headerState.balances[0].Amount } ₸</span>
																	</div>
																	<div className={ s.headerPopup_topItem }>
																		<div>
																			<RiBankCard2Line />
																			<span>{ t('main.cashless') }</span>
																		</div>
																		<span>{ headerState.balances && headerState.balances.length && headerState.balances[1].Amount } ₸</span>
																	</div>
																</div>
																<div className={ s.headerPopup_topItemBox }>
																	<div className={ s.headerPopup_topItem }>
																		<span>{ t('main.shift') }</span>
																		<div>
																			<span>{ headerState.shiftIndex }</span>
																		</div>
																	</div>
																	<div className={ s.headerPopup_topItem }>
																		<RiFilePaperLine />
																		<span>{ t('main.number_of_receipts') }:</span>
																		<span><strong>{ context.totalReceiptCount }</strong></span>
																	</div>
																</div>
															</div>
														</div>
														<div className={ s.headerPopup_bottom }>
															<button
																className={ s.headerPopup_btn }
																onClick={() => history.push(`/www/shifts/${headerState.kkmId}/report`)}
															>
																<span>{ t('main.all_reports') }</span>
																<RiArrowRightSFill />
															</button>
															<button
																className={ s.headerPopup_btn }
																onClick={() => {
																	history.push(`/www/shifts/${headerState.kkmId}/report`)
																	localStorage.setItem('reportStep', '2')
																}}
															>
																<span>{ t('main.x_report') }</span>
																<RiArrowRightSFill />
															</button>
															<button
																className={s.headerPopup_btn__main} width={ 200 }
																onClick={toggleShiftModal}
															>
																<span>{ t('main.close_shift') }</span>
															</button>
														</div>
													</div>
												</div>
											</div>

											{ operationBtnIsShown && <LangToggler /> }

											<div className={ s.header_item }>
												{
													operationBtnIsShown
													&&
													<div className={ s.operationBtn} ref={ operationBtn }>
														<div className={ s.operationBtn_btnBox }>
															<button
																className={ `${ s.operationBtn_btn } ${ s.operationBtn_btn__lg }` }
																onClick={ () => goToOperation(0) }
															>
																{ t('main.new_sale') }
															</button>
															<button
																className={ `${ s.operationBtn_btn } ${ s.operationBtn_btn__sm }` }
															>
																<RiArrowDownSFillWhite />
															</button>
														</div>
														<div className={ s.operationBtn_dropdown }>
															{ operationTitleList.map(item => {
																return (
																	<button
																		key={ item.id }
																		className={ s.operationBtn_btnDropdown }
																		onClick={ () => goToOperation(item.id) }
																	>
																		<span>{ item.title }</span>
																	</button>
																)
															})}
														</div>
													</div>
												}
												<div
													className={ s.user }
													onMouseEnter={ () => setOperationBtnIsShown(false) }
													onMouseLeave={ () => setOperationBtnIsShown(true) }
												>
													<div className={ s.user_logo } >
														<span>{ headerState.userName.charAt(0) }</span>
													</div>
													<div className={ s.user_expanded }>
														<div className={ s.user_expandedItem }>
															<RiUserLine />
															<span>{ headerState.userName }</span>
														</div>
														<div className={ s.user_divider }></div>
														<button
															className={ s.user_expandedBtn }
															onClick={ logout }
														>
															<span>{ t('close_shift.logout') }</span>
															<RiLogoutBoxRLine />
														</button>
													</div>
												</div>
											</div>

											<Modal
												isOpen={closeShiftModalIsOpen}
												balances={headerState.balances}
												closeModal={toggleShiftModal}
												pending={headerState.pending}
												closeShift={closeShift}
											/>
										</div>
										:
										<div className={s.header_wrapper}>
											<div className={ s.header_item }>
												<Link to={ '/www/comps_new' }>
													<Logo fill={'#343434'} />
												</Link>
												<div className={ s.header_rightWrapper }>
													<LangToggler />

													<div
														className={ s.user }
														onMouseEnter={ () => setOperationBtnIsShown(false) }
														onMouseLeave={ () => setOperationBtnIsShown(true) }
													>
														<div className={ s.user_logo } >
															<span>{ headerState.userName.charAt(0) }</span>
														</div>
														<div className={ s.user_expanded }>
															<div className={ s.user_expandedItem }>
																<RiUserLine />
																<span>{ headerState.userName }</span>
															</div>
															<div className={ s.user_divider }></div>
															<button
																className={ s.user_expandedBtn }
																onClick={ logout }
															>
																<span>{ t('close_shift.logout') }</span>
																<RiLogoutBoxRLine />
															</button>
														</div>
													</div>
												</div>
											</div>
										</div>
								}
							</header>
						</React.Fragment>
					)
				}
			</Store.Consumer>
		</OperationProvider>
	)
}

export default NewHeader
