/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import Dropzone from "dropzone"
import { BASE_URL, getHeaders } from '../../../../Store/snippets';
import { ReactComponent as DefaultImg } from "../images/defaultdrop.svg";
import { ReactComponent as HoverImg } from "../images/hoverdrop.svg";
import { ReactComponent as ErrorImg } from "../images/errordrop.svg";
import { ReactComponent as Warning } from "../images/warning.svg";
import { ReactComponent as Loader } from "../images/loader.svg";
import { ReactComponent as QuestionMark } from "../images/questionmark.svg";
import { ReactComponent as FilledWarning } from "../images/filledwarning.svg";
import { useTranslation } from "react-i18next";
import { DIGITAL_OCEAN_URL } from '../../../../constants/app';

const DropzoneModal = ({ history, setShowDropzone, fetchArticles, setSnackbar, setSnackText }) => {
  const { t } = useTranslation()

  let headers = getHeaders(history);
  let kkm = JSON.parse(localStorage.getItem('KKM'))
  useEffect(() => {
    let myDropzone = new Dropzone("#myDropzone",
    {
      autoProcessQueue: false,
      url: `${BASE_URL}/kkms/${kkm.Id}/articles`,
      previewTemplate: `
        <div class="dz-details" id="dz-details">
          <div class="dz-image">
            <img data-dz-thumbnail class='dzImg'>
            <svg width="21" height="24" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.902955 1.97755L14.1008 0.00556628C14.1753 -0.00561375 14.2512 9.05795e-05 14.3234 0.0222926C14.3956 0.0444947 14.4624 0.0826756 14.5192 0.134248C14.5761 0.18582 14.6217 0.249579 14.6529 0.321202C14.6842 0.392826 14.7003 0.470641 14.7003 0.549373V23.4508C14.7003 23.5294 14.6842 23.6071 14.653 23.6787C14.6218 23.7502 14.5763 23.8139 14.5196 23.8654C14.4628 23.917 14.3962 23.9552 14.3241 23.9775C14.252 23.9998 14.1762 24.0056 14.1018 23.9946L0.901905 22.0226C0.651617 21.9853 0.422596 21.8548 0.25691 21.655C0.0912249 21.4552 2.42598e-06 21.1995 0 20.935V3.06517C2.42598e-06 2.80064 0.0912249 2.54499 0.25691 2.34519C0.422596 2.14539 0.651617 2.01485 0.901905 1.97755H0.902955ZM15.7503 2.11268H19.9501C20.2285 2.11268 20.4956 2.22843 20.6925 2.43445C20.8894 2.64048 21 2.91991 21 3.21128V20.7889C21 21.0802 20.8894 21.3597 20.6925 21.5657C20.4956 21.7717 20.2285 21.8875 19.9501 21.8875H15.7503V2.11268ZM8.61062 12.0001L11.5505 7.60568H9.0306L7.35068 10.1171L5.67077 7.60568H3.15089L6.09075 12.0001L3.15089 16.3945H5.67077L7.35068 13.8831L9.0306 16.3945H11.5505L8.61062 12.0001Z" fill="#2E7D32"/>
            </svg>
          </div>
          <div class="dz-info">
            <div class="dz-filename">
              <span data-dz-name></span>
            </div>
            <div class="dz-size">
              <span data-dz-size></span>
            </div>
          </div>
          <div class="dz-remove-custom" id="dz-remove-custom">
            <div data-dz-remove>
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9 17C4.58172 17 1 13.4183 1 9C1 4.58172 4.58172 1 9 1C13.4183 1 17 4.58172 17 9C17 13.4183 13.4183 17 9 17ZM9.00005 15.5452C12.615 15.5452 15.5455 12.6147 15.5455 8.99978C15.5455 5.38483 12.615 2.45433 9.00005 2.45433C5.38509 2.45433 2.45459 5.38483 2.45459 8.99978C2.45459 12.6147 5.38509 15.5452 9.00005 15.5452ZM6.60518 12.4236L9.00001 10.0288L11.3948 12.4236L12.4234 11.3951L10.0285 9.00027L12.4234 6.60544L11.3948 5.57692L9.00001 7.97176L6.60518 5.57692L5.57666 6.60544L7.97149 9.00027L5.57666 11.3951L6.60518 12.4236Z" fill="#8E8E8E"/>
              </svg>
            </div>
          </div>
        </div>
      `,
    });
    myDropzone.on("addedfiles", files => {
      document.getElementById('dz-default').classList.remove('blue')
      document.getElementById('hovImg').style.display = 'none'
      if (files.length === 1) {
        if (files[0].type != "application/vnd.ms-excel" && files[0].type != "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
          document.getElementById('defImg').style.display = 'none'
          document.getElementById('errImg').style.display = 'block'
          document.getElementById('dz-default').classList.add('red')
          document.getElementById('errorsize').style.display = 'none'
          document.getElementById('errorformat').style.display = 'flex'
          // this.removeAllFiles()
        } else if (files[0].size > 1e+7) {
          document.getElementById('defImg').style.display = 'none'
          document.getElementById('errImg').style.display = 'block'
          document.getElementById('dz-default').classList.add('red')
          document.getElementById('errorformat').style.display = 'none'
          document.getElementById('errorsize').style.display = 'flex'
          // this.removeAllFiles()
        } else {
          document.getElementById('errorformat').style.display = 'none'
          document.getElementById('errorsize').style.display = 'none'
          document.getElementById('errImg').style.display = 'none'
          document.getElementById('defImg').style.display = 'none'
          document.getElementById('dz-default').style.display = 'none'
          document.getElementById('loader').style.display = 'flex'

          fetch(`${BASE_URL}/kkms/${kkm.Id}/articles?check=1`, {
              method: "POST",
              headers: headers,
              body: files[0]
          })
          .then(res => res.json())
          .then(data => {
              if (data.Status === 200) {
                  document.getElementById('loader').style.display = 'none'
                  document.getElementById('help-wrapper').style.display = 'none'
                  document.getElementById('docTitle').style.display = 'block'
                  document.getElementById('modal').classList.add('short')
                  document.getElementById('dz-details').style.display = 'flex'
                  setSaveDisabled(false)
                  window.sendfile = files[0]
              } else {
                  data.Data.reportUnits.forEach(r => {
                      let p = document.createElement('p')
                      p.textContent = r.reportMessage
                      document.getElementById('list').appendChild(p)
                  })
                  document.getElementById('loader').style.display = 'none'
                  document.getElementById('help-wrapper').style.display = 'none'
                  document.getElementById('docTitle').style.display = 'block'
                  document.getElementById('modal').classList.add('middle')
                  document.getElementById('error-wrapper').style.display = 'block'
                  document.getElementById('dz-details').classList.add('err')
                  document.getElementById('dz-details').style.display = 'flex'

              }
          })
        }
      } else {
        myDropzone.removeAllFiles()
      }
    })
    myDropzone.on("removedfile", file => {
      document.getElementById('hovImg').style.display = 'none'
      document.getElementById('defImg').style.display = 'block'
      document.getElementById('dz-default').style.display = 'flex'
      document.getElementById('docTitle').style.display = 'none'
      document.getElementById('help-wrapper').style.display = 'block'
      document.getElementById('modal').classList.remove('short')
      document.getElementById('modal').classList.remove('middle')
      document.getElementById('error-wrapper').style.display = 'none'
      document.getElementById('dz-default').classList.remove('blue')
      setSaveDisabled(true)
    })
    myDropzone.on("dragover", file => {
      myDropzone.removeAllFiles()
      document.getElementById('defImg').style.display = 'none'
      document.getElementById('errImg').style.display = 'none'
      document.getElementById('hovImg').style.display = 'block'
      document.getElementById('errorformat').style.display = 'none'
      document.getElementById('errorsize').style.display = 'none'
      document.getElementById('dz-default').classList.add('blue')
      document.getElementById('dz-default').classList.remove('red')

    })
    myDropzone.on("dragleave", file => {
      document.getElementById('defImg').style.display = 'block'
      document.getElementById('errImg').style.display = 'none'
      document.getElementById('hovImg').style.display = 'none'
      document.getElementById('dz-default').classList.remove('blue')
    })
  }, [])

  const [saveDisabled, setSaveDisabled] = useState(true)

  const downloadExample = () => {
    window.open(`${DIGITAL_OCEAN_URL}/shared/Zagruzka-nomenklatury-primer-v2.xlsx`)
  }

  const closeDropzone = () => {
    setShowDropzone(false)
  }

  const saveFile = () => {
    setSaveDisabled(true)
    document.getElementById('saveloader').style.display = "block";
    fetch(`${BASE_URL}/kkms/${kkm.Id}/articles?check=0`, {
        method: "POST",
        headers: headers,
        body: window.sendfile
    })
    .then(res => res.json())
    .then(data => {
      if (data.Status === 200) {
        setShowDropzone(false)
        fetchArticles()
        setSnackText(`${ t('other.successfully_imported_items') }: ${data.Data.count}`)
        setSnackbar(true)
        setTimeout(() => {
          setSnackText('')
          setSnackbar(false)
        }, 5000);
      }
    })
  }

  return (
    <div className="articles-modal" id="modal">
      <h1>{ t('kassa_settings.import_items') }</h1>
      <p className="docTitle" id="docTitle">{ t('other.uploaded_files') }</p>
      <div id="myDropzone" className="dropzone">
        <div className="dz-default dz-message" id="dz-default">
          <DefaultImg id="defImg" />
          <HoverImg id="hovImg" />
          <ErrorImg id="errImg" />
          <div className="errorformat" id="errorformat">
            <Warning />
            <p>{ t('other.invalid_file_format') }</p>
          </div>
          <div className="errorformat" id="errorsize">
            <Warning />
            <p>{ t('other.file_too_large') }</p>
          </div>
          <p className="title">{ t('kassa_settings.choose_file') }</p>
          <p className="info">{ t('other.file_size_limit') }</p>
        </div>
      </div>
      <div className="loader" id="loader">
        <Loader id="loader-img" />
        <p>{ t('other.uploading_your_file') }</p>
      </div>
      <div className="help-wrapper" id="help-wrapper">
        <div className="help">
          <div className="text">
            <QuestionMark id="questionmark" />
            <p>{ t('kassa_settings.download_template') }</p>
          </div>
          <button className="upload" onClick={() => downloadExample()}>
            { t('kassa_settings.download_excel_template') }
          </button>
        </div>
        <div className="help">
          <div className="text">
            <QuestionMark id="questionmark" />
            <p>{ t('kassa_settings.results') }</p>
          </div>
        </div>
      </div>
      <div className="error-wrapper" id="error-wrapper">
        <div className="header">
          <FilledWarning id="filledwarning" />
          <p>{ t('other.rows_not_loaded_edit_file_and_upload_again') }</p>
        </div>
        <hr />
        <div className="list" id="list"></div>
      </div>
      <div className="footer">
        <button className="transparent" onClick={() => closeDropzone()}>{ t('kassa_settings.cancel') }</button>
        <button className="save" id="save" disabled={saveDisabled} onClick={() => saveFile()}>
          <span>{ t('kassa_settings.save') }</span>
          <Loader id="saveloader" />
        </button>
      </div>
    </div>
  )
}

export default DropzoneModal;
