import React, {useState} from "react";
import style from "../../styles/pages/report/duplicates/NewReportZDuplicateInfo.module.scss";
import {ReactComponent as ArrowDownSLine} from "../../svg/arrow-down-s-line.svg";
import {ReactComponent as ArrowUpSLine} from "../../svg/arrow-up-s-line.svg";
import {formatAmount} from "../../../../Store/snippets";
import { useTranslation } from "react-i18next";

const NewReportZDuplicateInfo = ({ reportZChoosen, user }) => {
	const { t } = useTranslation()

	const [infoMobileIsCollapsed, setInfoMobileIsCollapsed] = useState(false)
	const [receiptMobileIsCollapsed, setReceiptMobileIsCollapsed] = useState(false)

	const reportZReceiptTitles = [
		{
			title: t('close_shift.service_income'),
			type: 'Incomes'
		},
		{
			title: t('close_shift.service_expense'),
			type: 'Expenses'
		},
		{
			title: t('close_shift.sales'),
			type: 'Sales'
		},
		{
			title: t('close_shift.sales_return'),
			type: 'Refunds'
		},
		{
			title: t('close_shift.purchases'),
			type: 'Purchases'
		},
		{
			title: t('close_shift.purchase_returns'),
			type: 'PurchaseRefunds'
		},
	]

	const reportZReceiptRenderList = reportZReceiptTitles.map((reportZItem, index) => {
		return (
			<div key={index} className={`${style['table__list']}`}>
				<div className={`${style['table__item']} ${style['table__item--2']}`}>
					{ reportZItem.title }
				</div>
				{
					reportZChoosen.Total[reportZItem.type].Qty > 0
						?
						<React.Fragment>
							<div className={`${style['table__item']} ${style['table__item--2']}`}>{ t('close_shift.number_of_receipts') }</div>
							<div className={`${style['table__item']} ${style['table__item--2']}`}>{reportZChoosen.Total[reportZItem.type].Qty}</div>
							<div className={`${style['table__item']} ${style['table__item--2']}`}>{ t('close_shift.total_amount') }</div>
							<div className={`${style['table__item']} ${style['table__item--2']}`}>{formatAmount(reportZChoosen.Total[reportZItem.type].Amount)} ₸</div>
						</React.Fragment>
						:
						<div className={`${style['table__item']} ${style['table__item--2']}`}>{ t('close_shift.no_receipts') }</div>
				}
			</div>
		)
	})
	return (
		<React.Fragment>
			<div className={`${style.card} ${style['card--white-nopadding']}`}>
				<div className={style.table}>
					<div className={`${style['table__content']} ${infoMobileIsCollapsed && style['collapsed']}`}>
						<div
							className={style['table__header']}
							onClick={() => {
								setInfoMobileIsCollapsed(!infoMobileIsCollapsed)
							}}
						>
							<div>{ t('close_shift.main_information') }</div>
							<button className={`${style.btn} ${style['btn--icon']}`}>
								<ArrowDownSLine/>
							</button>
							<button className={`${style.btn} ${style['btn--icon']}`}>
								<ArrowUpSLine/>
							</button>
							<p className={style.paragraph}>{ t('other.click_for_details') }</p>
						</div>
						<div className={`${style['table__body']}`}>
							<div className={`${style['table__list']}`}>
								<div className={`${style['table__item']} ${style['table__item--1']}`}>
									{ t('close_shift.organization_name') }
								</div>
								<div className={`${style['table__item']} ${style['table__item--1']}`}>
									{reportZChoosen.Kkm.Company.FullName}
								</div>
								<div className={`${style['table__item']} ${style['table__item--1']}`}>
									{ t('close_shift.tax_id') }
								</div>
								<div className={`${style['table__item']} ${style['table__item--1']}`}>
									{reportZChoosen.Kkm.Company.Bin}
								</div>
								<div className={`${style['table__item']} ${style['table__item--1']}`}>
									{ t('close_shift.kkm') }
								</div>
								<div className={`${style['table__item']} ${style['table__item--1']}`}>
									{reportZChoosen.Kkm.Id}
								</div>
								<div className={`${style['table__item']} ${style['table__item--1']}`}>
									{ t('close_shift.kgd_registration_number').split('|')[0] }
									<br/>
									{ t('close_shift.kgd_registration_number').split('|')[1] }
								</div>
								<div className={`${style['table__item']} ${style['table__item--1']}`}>
									{reportZChoosen.Kkm.Rnm}
								</div>
								<div className={`${style['table__item']} ${style['table__item--1']}`}>
									{ t('close_shift.cash_register_name') }
								</div>
								<div className={`${style['table__item']} ${style['table__item--1']}`}>
									{reportZChoosen.Kkm.Name}
								</div>
								<div className={`${style['table__item']} ${style['table__item--1']}`}>
									{ t('close_shift.trading_point_address') }
								</div>
								<div className={`${style['table__item']} ${style['table__item--1']}`}>
									{reportZChoosen.Kkm.Address.Town.Name}, {reportZChoosen.Kkm.Address.Street}
									{reportZChoosen.Kkm.Address.House} {reportZChoosen.Kkm.Address.Flat}
								</div>
								<div className={`${style['table__item']} ${style['table__item--1']}`}>
									{ t('close_shift.cashier') }
								</div>
								<div className={`${style['table__item']} ${style['table__item--1']}`}>
									{user.Name}
								</div>
							</div>
							<div className={`${style['table__list']}`}>
								<div className={`${style['table__item']} ${style['table__item--1']}`}>
									{ t('close_shift.balance_at_opening') }
								</div>
								<div className={`${style['table__item']} ${style['table__item--1']}`}>
									{new Intl.NumberFormat('ru-RU').format(reportZChoosen.BalanceOpen)} ₸
								</div>
								<div className={`${style['table__item']} ${style['table__item--1']}`}>
									{ t('close_shift.balance_at_closing') }
								</div>
								<div className={`${style['table__item']} ${style['table__item--1']}`}>
									{new Intl.NumberFormat('ru-RU').format(reportZChoosen.BalanceClose)} ₸
								</div>
								<div className={`${style['table__item']} ${style['table__item--1']}`}>
									{ t('close_shift.issued_receipts_alt') }
								</div>
								<div className={`${style['table__item']} ${style['table__item--1']}`}>
									{reportZChoosen.Count}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className={`${style.card} ${style['card--white-nopadding']} ${style['ml-20']}`}>
				<div className={`${style['table__content']} ${receiptMobileIsCollapsed && style['collapsed']}`}>
					<div className={style['table__content']}>
						<div
							className={style['table__header']}
							onClick={() => {
								setReceiptMobileIsCollapsed(!receiptMobileIsCollapsed)
							}}
						>
							<div>{ t('close_shift.issued_receipts') }</div>
							<button className={`${style.btn} ${style['btn--icon']}`}>
								<ArrowDownSLine/>
							</button>
							<button className={`${style.btn} ${style['btn--icon']}`}>
								<ArrowUpSLine/>
							</button>
							<p className={style.paragraph}>{ t('other.click_for_details') }</p>
						</div>
						<div className={style['table__body']}>
							{reportZReceiptRenderList}
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	)
}

export default NewReportZDuplicateInfo
