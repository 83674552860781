/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import { Store } from '../../../../Store/OperationProvider'

export default class Precheck extends Component {

    handleInputChange = (e, func, val, objName) => {
        func({ value: e.target.value, name:val, objName: objName });
    };

    handleFocus = (event) => event.target.select();

    toggleHover = () => {
        this.setState({
            hover: !this.state.hover
        })
    }

    state = {
        hover: false
    }
    
    render() {
        const styles = {
            color: 'red',
            fontSize: '14px',
            marginTop: '5px'
        }
        const popupStyle = {
            background: "#343434",
            borderRadius: "3px",
            boxShadow: "0px 1px 2px rgba(117, 117, 133, 0.12)",
            width: "300px",
            height: "50px",
            padding: "6px 10px",
            color: "#E1E1E6",
            fontSize: "14px",
            lineHeight: "19px",
            position: "absolute",
            display: this.state.hover ? 'block' : 'none',
            left: "15%",
            top: "40%"
        }
        return (
            <Store.Consumer>
                {
                context => (
                     <React.Fragment>
                        <h4 className="mb-3">Внесённая сумма</h4>
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <label htmlFor="cc-name">
                                    Наличными
                                    <span/>
                                </label>
                                <input
                                autoComplete="off" 
                                    type="number"
                                    className="form-control" id="cc-name" placeholder="" value={context.Income.Cash}
                                    required
                                    onChange={e => {this.handleInputChange(e, context.cashRefundsHandler, 'Cash', 'Income')}}
                                    onFocus={this.handleFocus}
                                />
                                <div className="invalid-feedback">
                                    Обязательное поле
                                </div>
                            </div>

                            <div className="col-md-6 mb-3">
                                <label htmlFor="cc-number">
                                    Безналичными
                                    <span/>💳
                                </label>
                                <input
                                autoComplete="off"  type="number" className="form-control" id="cc-number"
                                       placeholder="" value={context.Income.NonCash} required
                                       onChange={e => {this.handleInputChange(e, context.cashRefundsHandler, 'NonCash', 'Income')}}
                                       onFocus={this.handleFocus}
                                />
                                <div className="invalid-feedback">
                                    Обязательное поле
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <label htmlFor="cc-name">
                                    ИИН/БИН покупателя
                                    <span style={{marginLeft: "7px", cursor: "pointer"}} onMouseEnter={this.toggleHover} onMouseLeave={this.toggleHover}>
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.00008 17.3333C4.39758 17.3333 0.666748 13.6025 0.666748 8.99999C0.666748 4.39749 4.39758 0.666656 9.00008 0.666656C13.6026 0.666656 17.3334 4.39749 17.3334 8.99999C17.3334 13.6025 13.6026 17.3333 9.00008 17.3333ZM9.00008 15.6667C10.7682 15.6667 12.4639 14.9643 13.7141 13.714C14.9644 12.4638 15.6667 10.7681 15.6667 8.99999C15.6667 7.23188 14.9644 5.53619 13.7141 4.28594C12.4639 3.0357 10.7682 2.33332 9.00008 2.33332C7.23197 2.33332 5.53628 3.0357 4.28604 4.28594C3.03579 5.53619 2.33341 7.23188 2.33341 8.99999C2.33341 10.7681 3.03579 12.4638 4.28604 13.714C5.53628 14.9643 7.23197 15.6667 9.00008 15.6667ZM8.16675 4.83332H9.83342V6.49999H8.16675V4.83332ZM8.16675 8.16666H9.83342V13.1667H8.16675V8.16666Z" fill="#8E8E8E"/>
                                        </svg>
                                    </span>
                                    <div style={popupStyle}>
                                        Указание ИИН/БИН покупателя опционально, по запросу покупателя.
                                    </div>
                                </label>
                                <input autoComplete="off"
                                    inputMode="numeric"
                                    type="text"
                                    className="form-control"
                                    required
                                    placeholder="0"
                                    maxLength="12"
                                    value={context.CustomerIin}
                                    onChange={e => { this.handleInputChange(e, context.iinHandler) }}
                                />
                                {   
                                    context.CustomerIinError && <div style={styles}>Введите корректный БИН/ИИН</div>
                                }
                            </div>
                        </div>
                    </React.Fragment>
                    )
                }
            </Store.Consumer>
        )
    }
}
