import React from 'react';
import Spinner from '../Common/Spinner';
// import Modal from '../Modal/closeShiftModal';
// import { BASE_URL } from '../../Store/snippets';
import { Link } from 'react-router-dom';
// import MobileNav from '../MobileNav/MobileNav';

const NewShiftLayoutMobile = ({ user, balances, kkm, shift, logout, shiftOpen, shiftBlocked, shiftErrorText, pending, shiftExpired, openShift, openModal }) => {
	return (
		<React.Fragment>
			<div className="container pt-4">
				<div className="row pb-3">
					<div className="col-12">
						<h4 className="text-center">Касса</h4>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<div className="d-flex justify-content-between align-items-center pb-3">
							<div className="m-cashbox__name">{user.Name ? user.Name : user.PhoneLogin}</div>
							<Link to="/" className="btn btn-secondary">Выйти</Link>
						</div>
					</div>
				</div>
			</div>
			<div className="container m-cashbox__user-data">
				<div className="row">
					<div className="col-12">
						<Link to='/www/comps' className="btn btn-counter btn-block">
							{kkm.Company.FullName}
							<span>
								<svg width="7" height="12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.5 6a1 1 0 0 1-.29.71l-4 4A1.005 1.005 0 0 1 .79 9.29L4.1 6 .92 2.7a1 1 0 1 1 1.44-1.38l3.86 4A1 1 0 0 1 6.5 6z" fill="#726D85" /></svg>
							</span>
						</Link>
						<Link to='/www/comps' className="btn btn-counter btn-block">
							Касса №{kkm.Id}
							<span>
								<svg width="7" height="12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.5 6a1 1 0 0 1-.29.71l-4 4A1.005 1.005 0 0 1 .79 9.29L4.1 6 .92 2.7a1 1 0 1 1 1.44-1.38l3.86 4A1 1 0 0 1 6.5 6z" fill="#726D85" /></svg>
							</span>
						</Link>
					</div>
				</div>
			</div>
			<div className="container">
				<div className="row">
					<div className="col-12">
						<div className="m-cashbox__info">
							<div className="">Смена №{kkm.ShiftIndex}</div>
							{balances.map((balance) => {
								return (
									<div className="d-flex justify-content-between" key={balance.Id}>
										<div className="cash-title">
											<span className="svg-handler d-none">
												<svg width="20" height="19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16 12a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" fill="#46425C" /><path fillRule="evenodd" clipRule="evenodd" d="M3 5h14a3 3 0 0 1 3 3v8a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V8a3 3 0 0 1 3-3zm-.707 2.293A1 1 0 0 0 2 8v8a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1H3a1 1 0 0 0-.707.293z" fill="#FBFAFF" /><path d="M15.055 2.245a3 3 0 0 0-4.133-.954L4.984 5l1.06 1.696 5.936-3.71a1 1 0 0 1 1.378.319l2.12 3.392 1.696-1.06-2.12-3.392zM16 12a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" fill="#FBFAFF" /></svg>
											</span>  {balance.TypeBalance.Name}
										</div>
										<div className="cash-number"> {new Intl.NumberFormat('ru-RU').format(balance.Amount)} ₸ </div>
									</div>
								);
							})}
						</div>
						{shiftErrorText && (
							<div
								className={`alert ${kkm.IdStatusKkm === 1 ? 'alert-warning' : 'alert-danger'}   `}
								role="alert"
							>
								{shiftErrorText}
							</div>
						)}
						{
							!shiftBlocked && (
								<React.Fragment>
									{!shiftOpen &&
										(
											<React.Fragment>
												{pending ?
													<button className="btn btn-primary btn-lg  mr-sm-2 btn-sm-block" disabled >
														Открываем <Spinner className="mr-2" />
													</button>

													:
													<button
														className="btn btn-primary btn-lg btn-sm-block mr-sm-2"
														onClick={openShift}
													>
														Открыть смену
													</button>
												}
											</React.Fragment>
										)}
								</React.Fragment>
							)
						}
						{
							shiftOpen &&
							<React.Fragment>
								{pending ?
									<button
										className="btn btn-warning btn-lg  mr-sm-4 btn-sm-block"
										data-toggle="modal"
										data-target="#closeShiftDialog"
										disabled
									>
										Закрываем <Spinner className="" />
									</button>
									:
									<button
										className="btn btn-warning btn-lg  mr-sm-4 btn-sm-block"
										data-toggle="modal"
										data-target="#closeShiftDialog"
										onClick={openModal}
									>
										Закрыть смену
                     							</button>
								}
							</React.Fragment>
						}
					</div>
				</div>
			</div>

		</React.Fragment>
	)
}

export default NewShiftLayoutMobile;
