exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".mobileAddPosition_btnWrapper__2QeSU {\n  width: 100vw;\n  height: 82px;\n  background: #FFFFFF;\n  margin: 0 -15px 8px;\n  padding: 16px; }\n  .mobileAddPosition_btnWrapper__2QeSU .mobileAddPosition_addPositionBtn__dLTcK {\n    background: #F2FCFF;\n    border-radius: 8px;\n    border: 1px solid #00ADEE;\n    height: 48px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    width: 100%; }\n    .mobileAddPosition_btnWrapper__2QeSU .mobileAddPosition_addPositionBtn__dLTcK:active {\n      background: #D5F3FF; }\n    .mobileAddPosition_btnWrapper__2QeSU .mobileAddPosition_addPositionBtn__dLTcK p {\n      color: #00ADEE;\n      font-weight: 600;\n      font-size: 16px;\n      line-height: 19px;\n      margin: 0;\n      margin-left: 10px; }\n\n.mobileAddPosition_addPositionWrapper__1FZH_ {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100vw;\n  height: 100vh;\n  background: rgba(88, 88, 88, 0.65);\n  z-index: 1; }\n  .mobileAddPosition_addPositionWrapper__1FZH_ .mobileAddPosition_addPositionModal__3VARS {\n    position: fixed;\n    bottom: 0;\n    background: #FFFFFF;\n    width: 100%;\n    height: 100%;\n    max-height: 664px;\n    overflow: auto; }\n", ""]);

// exports
exports.locals = {
	"btnWrapper": "mobileAddPosition_btnWrapper__2QeSU",
	"addPositionBtn": "mobileAddPosition_addPositionBtn__dLTcK",
	"addPositionWrapper": "mobileAddPosition_addPositionWrapper__1FZH_",
	"addPositionModal": "mobileAddPosition_addPositionModal__3VARS"
};