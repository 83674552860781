import React from 'react';
// import NumberFormat from 'react-number-format';
import NameInput from './nameInput/NameInput';
import { Store } from '../../../../Store/OperationProvider';
//styles
import "./_.scss"
import { useTranslation } from "react-i18next";

const AddPosition = ({ PositionErrors, Position, DomainIndex, Domains, Sections, Units, sectionLS, domainLS, ExciseChecked, exciseCheck, sortSections, addPreCheckPosition, resetPosition, domainChangeHandler, positionInputHandler, priceInputHandler, exciseCodeInputHandler, positionDiscountMarkupHandler, inputNumberHandler, sectionChangeHandler, unitChangeHandler, handleInputText, handleInputExciseCode, handleInputDiscountMarkup, handleInputNumber, handleInputQty, handleFocus, moreThenZero, hidePlaceholderOnFocus }) => {
    const { t } = useTranslation()

    return (
        <Store.Consumer>
            {
                context => (
                    <React.Fragment>

                <h4 className="mb-3">
                    Добавить позицию
                        <span />
                </h4>

                {!PositionErrors.isValid &&
                    (
                        <div className="NewSale__alert alert alert-warning mb-3 shadow">
                            <h5 className="alert-heading">Ошибка при добавлении позиции</h5>
                            <p className="text-danger mb-1">{PositionErrors.Name}</p>
                            <p className="text-danger mb-1">{PositionErrors.Price}</p>
                            <p className="text-danger mb-1">{PositionErrors.Markup}</p>
                            <p className="text-danger mb-1">{PositionErrors.Discount}</p>
                            <p className="text-danger mb-1">{PositionErrors.DiscountPercent}</p>
                        </div>
                    )
                }

                <form className="needs-validation" noValidate onSubmit={(e) => addPreCheckPosition(e)}>

                    <div className="form-row pt-2">
                        <div className="form-group col-md-7">
                            <label htmlFor="inputEmail4">Наименование</label>
                            <NameInput errorField={PositionErrors.Name} position={Position}/>
                            <div className="form-row d-md-flex" style={{marginTop: "15px"}}>
                                <div className="form-group col-md-12">
                                    <label htmlFor="selectSection">Единица измерения</label>
                                    <select id="selectSection" className="form-control"
                                        value={Position.Unit.Id}
                                        onChange={e => unitChangeHandler(Number(e.target.value))}>
                                        {
                                            Units.map((Unit) => {
                                                return <option key={Unit.Id} value={Unit.Id}>{Unit.NameRU}</option>
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div style={{paddingLeft: "20px", margin: "20px 0"}}>
                                <input type="checkbox" className="form-check-input" id="exciseCheckBox" onClick={exciseCheck}/>
                                <label className="form-check-label" htmlFor="exciseCheckBox">Подакцизный товар</label>
                            </div>
                            {ExciseChecked === true ?
                            <div className="excise-input">
                                <input className="form-control form-control-lg"
                                    autoComplete="off"
                                    id="excise"
                                    placeholder="Акцизный код"
                                    value={Position.ProductCode}
                                    onChange={e => { handleInputExciseCode(e, exciseCodeInputHandler, 'ProductCode') }}
                                    required
                                />
                            </div>
                            : null}
                        </div>
                        <div className="form-group col-md-3">
                            <label>Цена</label>
                            <input autoComplete="off" className={`form-control form-control-lg${PositionErrors.Price ? ' is-invalid' : ''}`}
                                inputMode="numeric"
                                id="cena" placeholder="0" min="0"
                                value={Position.Price === 0 ? '' : Position.Price}
                                onChange={e => { handleInputNumber(e, priceInputHandler, 'Price') }}
                                type="number"
                            />
                        </div>
                        <div className="form-group col-md-2">
                            <label htmlFor="">Количество</label>
                            <input autoComplete="off" type="number" className={`form-control form-control-lg${PositionErrors.Qty ? ' is-invalid' : ''}`}
                                id=""
                                inputMode="numeric"
                                placeholder="1.00"
                                value={Position.Qty === 0 ? '' : Position.Qty}
                                onChange={e => { handleInputQty(e, positionInputHandler, 'Qty') }}
                                onFocus={(e) => { e.target.placeholder = ''; }}
                                onBlur={(e) => { e.target.placeholder = '1.00'; }}
                            />
                        </div>
                    </div>

                    <div className="form-row d-md-flex">
                        <div className="form-group col-md-8">
                            <label htmlFor="selectSection">Секция</label>
                            <select id="selectSection" className="form-control"
                                value={Position.Section.Id}
                                onChange={e => sectionChangeHandler(Number(e.target.value))}>
                                {/*<option value="" disabled hidden>Выбирите секцию...</option>*/}
                                {
                                    Sections.map((Section, index) => {
                                        return <option key={Section.Id} value={Section.Id}>{Section.Name}</option>
                                    })
                                    // :
                                    // Sections.map((Section, index) => {
                                    //     return <option key={Section.Id} value={Section.Id}>{Section.Name}</option>
                                    // })
                                }
                            </select>
                        </div>
                        <div className="form-group col-md-2">
                            <label htmlFor=""><span className="d-md-none d-lg-inline">%</span> наценка</label>
                            <input autoComplete="off" min="0" type="number" className={`form-control${PositionErrors.Markup ? ' is-invalid' : ''}`} id=""
                                inputMode="numeric"
                                placeholder="0.00"
                                value={Position.MarkupPercent === 0 ? '' : Position.MarkupPercent}
                                onChange={e => { handleInputDiscountMarkup(e, positionDiscountMarkupHandler, 'Markup') }}
                            // onFocus={handleFocus}
                            />
                        </div>
                        <div className="form-group col-md-2">
                            <label htmlFor=""><span className="d-md-none d-lg-inline">%</span> скидка</label>
                            <input autoComplete="off" min="0" type="number" className={`form-control${PositionErrors.Discount ? ' is-invalid' : ''}`} id="" placeholder="0.00"
                                inputMode="numeric"
                                value={Position.DiscountPercent === 0 ? '' : Position.DiscountPercent}
                                onChange={e => { handleInputDiscountMarkup(e, positionDiscountMarkupHandler, 'Discount') }}
                            // onFocus={handleFocus}
                            />
                        </div>
                    </div>

                    <div className="pt-2 pb-3">
                        <button type="button" className="btn btn-primary btn-sm mr-2 btn-sm-block"
                            onClick={(e) => addPreCheckPosition(e, "sale")}>
                            Добавить в предчек
                                <span role='img' aria-label='point-right' className="d-none d-md-inline">👉</span>
                                <span role='img' aria-label='point-up' className="d-md-none d-lg-none">👆</span>
                        </button>

                        <small className="d-none d-sm-inline">или&#8194;</small>

                        <button type="button" className="btn btn-outline-secondary btn-sm btn-sm-block"
                            onClick={() => resetPosition()}>
                            Очистить форму
                            </button>
                        <input className="invisible" type="submit" style={{ position: 'absolute' }} />
                    </div>

                    <div className="form-group d-md-block">
                        <label htmlFor="inputDomain">{ t('other.activity_type') }</label>
                        <select id="inputDomain" className="form-control"
                            value={Position.Domain.Id}
                            onChange={e => domainChangeHandler(Number(e.target.value))}>
                            {
                                Domains.map((Domain, index) => {
                                    return <option key={Domain.Name} value={Domain.Id}>{Domain.Name}</option>
                                })
                            }

                        </select>
                    </div>
                </form>
            </React.Fragment>
            )
        }

        </Store.Consumer>
    )
}
export default AddPosition;
