import React from 'react';
// import { Link } from 'react-router-dom';
// import Header from '../Header/Header';
// import {getAppModuleUrl} from "../../Store/appVersion";
import HeaderVersion from "../NewDesign/HeaderVersion/HeaderVersion";
import { useTranslation } from "react-i18next";

const CashboxSettingsForbiden = () => {
  const { t } = useTranslation()

  let kkm = (JSON.parse(localStorage.getItem('KKM')))

  return (
    <React.Fragment>
      <HeaderVersion isActive={true} />
      <main role="main" className="container">
        <div className="container pt-5">
          <h1 className="mt-5">{ t('other.access_restricted') }</h1>
          <p className="lead">{ t('other.programming_mode_available_only_with_closed_shift') }</p>
          <p>{ t('other.close_shift_to_access_this_mode') }</p>

          <hr/>

          <a href={`/www/kkms/${kkm.Id}/sale}`} className="btn btn-primary btn-lg btn-sm-block">
            { t('other.activity_type') }
          </a>

          <a href={`/www/kkms/${kkm.Id}/close`} className="btn btn-warning btn-lg ml-md-3 btn-sm-block">
            { t('settings.return_to_operation_mode') }
          </a>
        </div>
      </main>
    </React.Fragment>
  )
}

export default CashboxSettingsForbiden;
