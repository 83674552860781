/* eslint-disable eqeqeq */
import React from 'react';
import { ReactComponent as LogoFiscal } from '../../Svg/logo.svg';
import { Link } from 'react-router-dom';
import MaskedInput from 'react-text-mask';
import axios from 'axios';
import { FISCAL_DEV_HOST, PULT_API_DEV_URL, PULT_API_PROD_URL } from '../../../constants/app';


const protocol = window.location.protocol;
const hostName = window.location.host;
let BASE_URL = PULT_API_DEV_URL;
if (process.env.NODE_ENV === 'production') {
    BASE_URL = PULT_API_PROD_URL;
    if (window.app && !!window.cordova) {
        BASE_URL = `${protocol}//api.pult24.kz`;
    }
}

if (hostName == FISCAL_DEV_HOST) {
    BASE_URL = PULT_API_DEV_URL;
}

export default class PassRecover extends React.Component {

    state = {
        inputPhoneNumber: "",
    }

    handleChangePhone = (e) => {
        this.setState({
            inputPhoneNumber: e.target.value
        }, () => sessionStorage.setItem("PASS_REC", JSON.stringify({Phone: this.state.inputPhoneNumber})))
    }

    handleClick = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${BASE_URL}/auth/register`,
            {
                "name": "Вася Пупкин",
                "phone": this.state.inputPhoneNumber,
            })
            if(response.status == 200 && this.state.inputPhoneNumber.length > 0){
                this.props.history.push('/www/passrecconfirmation')
                sessionStorage.setItem("USER_DATA", JSON.stringify( {Name: this.state.inputName, Phone: this.state.inputPhoneNumber, Email: this.state.inputEmail} ))
            }
            localStorage.setItem('form_id', response.data.form_id);
            // await axios.post(`https://api.amplitude.com/2/httpapi`,
            // {
            //     api_key: "d314c6311f60a96a3d7ec5a856b85143",
            //     events: [
            //         {
            //             event_type: "enter phone number",
            //             event_category: "signup",
            //             event_name: "enter phone number",
            //             device_id: "10001",
            //             user_id: this.state.inputPhoneNumber,
            //         }
            //     ]
            // })
        } catch (error) {
                console.log(error)
        }
    }

    render(){
        return(
            <div className="auth text-center">
                <form className="form-signin">
                    <div className="reg-header">
                        <LogoFiscal/>
                        <Link to='/' className="reg-header-enterText">Вход</Link>
                    </div>
                    <div className="reg-form-title">
                        <h1 className='h3 mb-1 font-weight-normal'>Восстановление пароля</h1>
                        <p style={{
                            fontSize: "15px",
                            margin: "9px 0 30px"
                        }} className="text-muted">
                            Введите номер телефона коммерсанта Pult24 или кассира Fiscal24
                        </p>
                    </div>
                    <label htmlFor="inputPhone" className="sr-only">
                        Номер телефона
                    </label>
                    <MaskedInput
                        type="regTel"
                        showMask={false}
                        guide={true}
                        // placeholderChar={'*'}
                        placeholderChar={'\u2000'}
                        mask={['+', '7', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]}
                        inputMode="tel"
                        id="inputPhone"
                        name="login"
                        className="form-control"
                        placeholder="Номер телефона"
                        autoComplete="phone"
                        required
                        autoFocus
                        // value={login}
                        // onChange={handleLogin}
                        // autoFocus
                        onFocus={(e) => e.target.value = "+7"}
                        onBlur={(e) => e.target.placeholder = "Номер телефона"}
                        onChange={this.handleChangePhone}
                    />
                    <button className={`mt-4 btn btn-primary btn-block`} type="button" onClick={this.handleClick}>
                        Продолжить
                    </button>
                    <div className="bottomPhrases">
                        <p className={`mb-3 text-muted`}>
                            &copy; 2016-2020 Fiscal24
                        </p>
                        <p className="text-muted">
                            v 0.1.3
                        </p>
                    </div>
                </form>
            </div>
        )
    }
}