import React from 'react';
import { NavLink } from 'react-router-dom';
import { getKkm } from '../../Store/snippets';

const ReportsSubnavLayout = ({ active, history }) => {
	const kkm = getKkm(history);
	return (
		<div className="row d-lg-none">
			<div className="col-12">
				<div className="mobile-row__menu">
					<ul className="nav">
						<li className="nav-item">
							<NavLink to={`/www/shifts/${kkm.IdShift}/log`} className={`nav-link ${active === 'operations' && 'acitve'}`}>Журнал</NavLink>
						</li>
						<li className="nav-item">
							<NavLink to={`/www/shifts/${kkm.IdShift}/reports/x`} className={`nav-link ${active === 'report-x' && 'acitve'}`}>X-отчет</NavLink>
						</li>
						<li className="nav-item">
							<NavLink to={`/www/shifts/${kkm.IdShift}/reports/sections`} className={`nav-link ${active === 'report-sections' && 'acitve'}`}>По секциям</NavLink>
						</li>
						<li className="nav-item">
							<NavLink to={`/www/kkms/${kkm.Id}/zlog`} className={`nav-link ${active === 'shifts' && 'acitve'}`}>Архив Z-отчетов</NavLink>
						</li>
						{/* <li className="nav-item">
						<a href={`/www/shifts/${User.IdShift}/reports/cashiers`} className="nav-link">По кассирам</a>
					</li> */}
					</ul>
				</div>
			</div>
		</div>
	)
}

export default ReportsSubnavLayout;