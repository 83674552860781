import React from "react";
import s from './Error503Modal.module.scss'
import CosmoHuman from './img/interstellar.svg'
import { useTranslation } from "react-i18next";

const Error503Modal = () => {
    const { t } = useTranslation()

    return (
        <div className={s.wrapper}>
            <div className={s.modal}>
                <div className={s.image}>
                    <img src={CosmoHuman} alt="" />
                </div>
                <div className={s.content}>
                    <p className={s.title}>
                        { t('other.technical_break') }
                    </p>
                    <p className={s.subtitle}>
                        { t('other.apologies_for_disruptions') }
                        <br />
                        { t('other.on_support') }
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Error503Modal;
