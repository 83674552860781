exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".tooltip_tooltip__25Zif {\n  width: 416px;\n  height: auto;\n  padding: 16px;\n  background: #343434;\n  border-radius: 4px; }\n  .tooltip_tooltip__25Zif .tooltip_operation__woeJ3:first-child {\n    border-bottom: 1px solid #464646;\n    padding-bottom: 12px;\n    margin-bottom: 12px; }\n  .tooltip_tooltip__25Zif .tooltip_operation__woeJ3 .tooltip_title__116rL {\n    display: flex;\n    align-items: center; }\n    .tooltip_tooltip__25Zif .tooltip_operation__woeJ3 .tooltip_title__116rL p {\n      margin-bottom: 0;\n      color: #EEEEEE;\n      font-weight: 600;\n      font-size: 16px;\n      line-height: 19px; }\n    .tooltip_tooltip__25Zif .tooltip_operation__woeJ3 .tooltip_title__116rL .tooltip_amount__iWaXA {\n      margin-left: 6px;\n      padding: 1px 5px;\n      background: #575757;\n      border-radius: 20px;\n      color: #EEEEEE;\n      font-size: 12px;\n      line-height: 15px;\n      display: flex;\n      align-items: center;\n      justify-content: center; }\n  .tooltip_tooltip__25Zif .tooltip_operation__woeJ3 .tooltip_data__1-6W8 {\n    display: flex;\n    align-items: center;\n    margin-top: 8px; }\n    .tooltip_tooltip__25Zif .tooltip_operation__woeJ3 .tooltip_data__1-6W8 .tooltip_block__1FmQK {\n      width: 25%; }\n      .tooltip_tooltip__25Zif .tooltip_operation__woeJ3 .tooltip_data__1-6W8 .tooltip_block__1FmQK .tooltip_blockTitle__fNVvb {\n        color: #8E8E8E;\n        font-weight: normal;\n        font-size: 10px;\n        line-height: 12px;\n        margin-bottom: 0; }\n      .tooltip_tooltip__25Zif .tooltip_operation__woeJ3 .tooltip_data__1-6W8 .tooltip_block__1FmQK .tooltip_sum__KDf05 {\n        color: #FFFFFF;\n        font-weight: normal;\n        font-size: 12px;\n        line-height: 15px;\n        margin-bottom: 0;\n        margin-top: 2px; }\n\n@media only screen and (max-width: 415px) {\n  .tooltip_tooltip__25Zif {\n    width: 105%; } }\n", ""]);

// exports
exports.locals = {
	"tooltip": "tooltip_tooltip__25Zif",
	"operation": "tooltip_operation__woeJ3",
	"title": "tooltip_title__116rL",
	"amount": "tooltip_amount__iWaXA",
	"data": "tooltip_data__1-6W8",
	"block": "tooltip_block__1FmQK",
	"blockTitle": "tooltip_blockTitle__fNVvb",
	"sum": "tooltip_sum__KDf05"
};