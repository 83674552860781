import React, { useState } from 'react';
import style from '../../styles/pages/cashbox/tabs/NewCashboxManage.module.scss'
// import OutsideClicker from "../../NewOperations/helpers/OutsideClicker";
import { ReactComponent as ArrowDropDownFill } from '../../svg/arrow-drop-down-fill.svg'
import { ReactComponent as LoaderLine } from '../../svg/loader-line.svg'
import { useTranslation } from "react-i18next";


const NewCashboxManage = ({ changeCashboxSettings, pending }) => {
  const { t } = useTranslation()

  let kkm = (JSON.parse(localStorage.getItem('KKM')))
  const [name, setName] = useState(kkm.Name)
  const [nameError, setNameError] = useState("")
  const [autorenew, setAutorenew] = useState(kkm.Autorenew)

  const submitCashboxChanges = () => {
    if (!name.trim().length) {
      setNameError(t('other.enter_cash_register_name'))
      return
    }
    setNameError("")
    changeCashboxSettings(name, autorenew)
  }

  return (
    <div className={style.wrapper}>
      <div className={`${style.card} ${style['card--white']} ${style['card--md']}`}>

        <div className={style['form__group']}>
          <label htmlFor="Name" className={style['form__label']}>{ t('kassa_settings.name') }</label>
          <input
            type="text"
            className={`${style['form__input']} ${nameError ? style['form__input--error'] : ''}`}
            name="Name"
            value={name}
            onChange={e => setName(e.target.value)}
          />
          { nameError && <p className={style['form__error']}>{nameError}</p> }
        </div>

        <div className={style['form__group']}>
          <label htmlFor="IdOFD" className={style['form__label']}>ЗНМ</label>
          <input type="text" className={style['form__input']} id="IdKKM" value={kkm.Znm} disabled={true} />
        </div>

        <div className={style['form__group']}>
          <label htmlFor="Address" className={style['form__label']}>{ t('kassa_settings.address') }</label>
          <input type="text" className={style['form__input']} id="Address" value={`${kkm.Address.Town.Name} ${kkm.Address.Street} - ${kkm.Address.House}, ${kkm.Address.Flat}`} disabled={true} />
        </div>

        <div className={style['form__group']}>
          <label htmlFor="Place" className={style['form__label']}>{ t('kassa_settings.place_of_use') }</label>
            <div
              className={`${style['form__select-wrapper']}`}
            >
              <div className={`${style['form__select']} ${style['form__select--disabled']}`}>
                <p className={style['paragraph--lead']}>{kkm.PlaceUsed.Id}</p>
                <ArrowDropDownFill/>
              </div>
            </div>
        </div>

        <div className={`${style['mt-40']} ${style['form__bottom']}`}>
          <div className={style['flex-2']}>
            <p className={style['paragraph--lead']}>{ t('kassa_settings.automatic_shift_closure') }</p>
            <div>
              <div
                className={autorenew ? style['type-check'] : style['type-check-off']}
                onClick={() => setAutorenew(!autorenew)}
              >
                <div className={style['type-circle']}></div>
              </div>
            </div>
          </div>
          <p className={`${style.paragraph} ${style['mt-20']}`}>{ t('kassa_settings.shift_will_be_closed_automatically') }</p>
        </div>

        <div className={style['btn-wrapper']}>
          { pending ?

            <button
              className={`${style.btn} ${style['btn--primary']} ${style['btn--block']} ${style['mt-40']}`}
            >
              { t('kassa_settings.saving_changes') }
              <LoaderLine/>
            </button>
            :
            <button
              className={`${style.btn} ${style['btn--primary']} ${style['btn--block']} ${style['mt-40']}`}
              onClick={submitCashboxChanges}
            >
              { t('kassa_settings.save_changes') }
            </button>
          }
        </div>
      </div>
    </div>
  )
}

export default NewCashboxManage;
