import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import ru from '../locales/ru'
import kk from '../locales/kk'

const savedLanguage = localStorage.getItem('i18nextLng') || 'ru';

i18n
	.use(initReactI18next)
	.init({
	resources: {
		ru: {
			translation: ru,
		},
		kk: {
			translation: kk,
		},
	},
	lng: savedLanguage,
	fallbackLng: "ru",
	interpolation: {
		escapeValue: false,
	},
});

export default i18n;
