import React from 'react';

function PreloaderFullScreen(params) {
	return (
		<div className="text-primary" style={
			{
				width: '100vw',
				height: '100vh',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center'
			}
		}>
			<div className="spinner-border mr-3" role="status">
				<span className="sr-only">Loading...</span>
			</div>
			Загрузка...
		</div>
	)
}

export default PreloaderFullScreen;