import React from 'react';
import { ReactComponent as Warning } from '../Svg/alert-triangle.svg';
import Spinner from '../Common/Spinner';

const Modal = (props) => {
	if (!props.isOpen) {
		return null
	} else {
		return (
			<div className="fsc-modal__background modal-bg-reveal">
				<div className="fsc-modal__content fsc-modal__content_close-shift">
					<div className="fsc-modal__content_simple">
						<div className="fsc-modal__title fsc-modal__title--border-bottom">
							<span className="fsc-modal__title_icon--warning fsc-modal__title_icon">
								<Warning style={{ width: '1.25rem', height: '1.25rem' }} />
							</span>
							<span className="fsc-modal__title_text">Внимание! Вы собираетесь закрыть смену!</span>
						</div>
						<div className="fsc-modal__body">
							<p>
								Итоги закрытия смены будут отправлены в налоговую.<br />
								Cверьтесь с балансами кассы:<br />
								{props.balances.map((balance) => {
									return (
										<React.Fragment key={balance.Id}>
											<span >
												{balance.TypeBalance.Name} <strong>{new Intl.NumberFormat('ru-RU').format(balance.Amount)}</strong> ₸
											</span>
											<br />
										</React.Fragment>
									);
								})}
							</p>
						</div>
						<div className="fsc-modal__buttons">
							<button className="btn btn-ghost btn-lg" onClick={props.closeModal}>Отменить</button>
							{props.pending ?
								<button
									className="btn btn-primary btn-lg"
									disabled
								>
									Закрываем <Spinner className="" />
								</button>
								:
								<button className="btn btn-primary btn-lg" onClick={props.closeShift}>Закрыть смену</button>
							}
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default Modal;
