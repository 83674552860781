import React, { Component } from 'react';
import Sale from './sale/Sale';
import { OperationProvider } from "../../Store/OperationProvider";

class NewSale extends Component {
    render() {
        return (
            <div>
                <OperationProvider operation='Sale'>
                    <Sale {...this.props} />
                </OperationProvider>
            </div>
        )
    }
}

export default NewSale;
