import React from 'react';
import PrintButtonLayout from './PrintButtonLayout';

function PrintButton() {
	function print() {
		window.print();
	}

	return (
		<PrintButtonLayout print={print} />
	)
}

export default PrintButton;