import React from 'react';
import {OperationProvider, Store} from "../../../../Store/OperationProvider";
import RefundOperation from "./RefundOperation"
// import context from "react-redux/lib/components/Context";

const NewRefundOperation = (props) => {
  return (
    <OperationProvider operation='Refund'>
      <Store.Consumer>
        { context => ( <RefundOperation context={context} {...props} /> ) }
      </Store.Consumer>
    </OperationProvider>
  )
}

export default NewRefundOperation;
