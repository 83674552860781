const appModuleUrl = {
	new: {
		operations: {
			sale: 'sale_new',
			purchase: 'purchase_new',
			income: 'income_new',
			expense: 'expense_new',
			refund: 'refund_new',
			purchase_refund: 'purchase_refund_new'
		},
		settings: 'settings_new',
		shiftClose: 'close_new',
		shift: 'shift_new',
		comps: 'comps_new',
		reports: {
			operations: 'report',
			reportX: 'report',
			reportZ: 'report',
			sections: 'report',
		}
	},
	old : {
		operations: {
			sale: 'sale',
			purchase: 'purchase',
			income: 'income',
			expense: 'expense',
			refund: 'refund',
			purchase_refund: 'purchase_refund'
		},
		settings: 'settings',
		shiftClose: 'close',
		shift: '',
		comps: 'comps',
		reports: {
			operations: 'log',
			reportX: 'reports/x',
			reportZ: 'zlog',
			sections: 'reports/sections',
		}
	}
}

export const setAppVersion = (type) => {
	console.log('set app version')
	localStorage.setItem(
		'appVersion',
		JSON.stringify(
			{
				isNew: type
			}
		))
}

export const getAppModule = () => {
	let appVersion = JSON.parse(localStorage.getItem('appVersion'))
	if (appVersion === null) {
		appVersion = {
			isNew: true
		}
		localStorage.setItem('appVersion', JSON.stringify(appVersion))
	}

	return appVersion.isNew
}


export const getAppModuleUrl = () => getAppModule() ? appModuleUrl.new : appModuleUrl.old
