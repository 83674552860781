import React, { Component } from 'react';
import axios from 'axios';
import { BASE_URL, getHeaders, formatDate, getUser, getError } from '../../Store/snippets';
import ShiftsLogLayout from './ShiftsLogLayout';
import ShiftsLogLayoutMobile from './ShiftsLogLayoutMobile';
import Dublicate from './DublicateZLayout'
import PreloaderFullScreen from '../Preloader/Preloader';
let headers;

const dateFrom = new Date();
		var dd = dateFrom.getDate();
		var ddd = dateFrom.getDate() + 1;
		var mm = dateFrom.getMonth() + 1;
		var yyyy = dateFrom.getFullYear();
		var hh = dateFrom.getHours();
		var min = dateFrom.getMinutes();
		
		if(dd < 10){
			dd = "0"+ dd;
		}
		if(ddd < 10){
			ddd = "0"+ ddd;
		}
		if(mm < 10){
			mm = "0"+ mm;
		}
		if(hh < 10){
			hh = "0"+ hh;
		}
		if(min < 10){
			min = "0"+ min;
		}
const today = yyyy + '-' + mm + '-' + dd;
let tomorrow;
if(dd === new Date(yyyy, mm, 0).getDate()){
	mm = dateFrom.getMonth() + 2;
	if(mm < 10){
		mm = "0"+ mm;
	}
	ddd = "01";
	tomorrow = yyyy + '-' + mm + '-' + ddd;
} else{
	tomorrow = yyyy + '-' + mm + '-' + ddd;
}

class ShiftsLog extends Component {
	constructor(props) {
		super(props);
		this.state = {
			report: {},
			user: {},
			date: '',
			dateFrom: today,
			dateTo: tomorrow,
			idKkm: null,
			reportChoosen: null,
			mounted: false,
			shiftNumber: '',
			error: {
				status: false,
				title: '',
				text: ''
			}
		};
	}

	showDuplicate = (idx) => {
		if (this.state.reportChoosen !== null) {
			this.setState({
				...this.state,
				reportChoosen: null
			})
		} else {
			this.setState({
				...this.state,
				reportChoosen: idx
			})
		}
	}

	handleChangeDateFrom = (e) => {
		this.setState({
			dateFrom: e.target.value
		})
	}

	handleChangeDateTo = (e) => {
		this.setState({
			dateTo: e.target.value
		})
	}

	async componentDidMount() {
		headers = getHeaders(this.props.history);
		const company = JSON.parse(localStorage.getItem('COMPANY'));
		const idKkm = JSON.parse(localStorage.getItem('CURRENT_KKM_ID'));
		const shiftNumber = localStorage.getItem("SHIFT_INDEX");
		const user = getUser(this.props.history);

		axios.get(`${BASE_URL}/kkms/${idKkm}/zlog?datefrom=${this.state.dateFrom}T00:00:00&dateto=${this.state.dateTo}T23:59:59`,
			{ headers }).then(resp => {
				this.setState({
					...this.state,
					user,
					company,
					report: resp.data.Data,
					idKkm,
					shiftNumber,
					mounted: true
				})
			}).catch(err => {
				const resp = getError(err, this.props.history);
				this.setState({
					...this.state,
					error: {
						...this.state.error,
						status: true,
						title: `Ошибка: ${resp.Status}`,
						text: resp.Message
					},
					mounted: true
				})
			});
	}

	componentDidUpdate(prevProps, prevState){
		headers = getHeaders(this.props.history);
		const company = JSON.parse(localStorage.getItem('COMPANY'));
		const idKkm = JSON.parse(localStorage.getItem('CURRENT_KKM_ID'));
		const user = getUser(this.props.history);

		if(prevState.dateFrom !== this.state.dateFrom || prevState.dateTo !== this.state.dateTo) {
			axios.get(`${BASE_URL}/kkms/${idKkm}/zlog?datefrom=${this.state.dateFrom}T00:00:00&dateto=${this.state.dateTo}T23:59:59`,
			{ headers }).then(resp => {
				this.setState({
					...this.state,
					user,
					company,
					report: resp.data.Data,
					idKkm,
					mounted: true
				})
			}).catch(err => {
				const resp = getError(err, this.props.history);
				this.setState({
					...this.state,
					error: {
						...this.state.error,
						status: true,
						title: `Ошибка: ${resp.Status}`,
						text: resp.Message
					},
					mounted: true
				})
			});
		}
	}

	render() {
		return (
			this.state.mounted ?
				(
					this.state.reportChoosen === null ?
						(
							window.matchMedia("(min-width: 1100px)").matches ?
								<ShiftsLogLayout {...this.state} handleChangeDateFrom={this.handleChangeDateFrom} handleChangeDateTo={this.handleChangeDateTo} formatDate={formatDate} showDuplicate={this.showDuplicate} {...this.props} />
								:
								<ShiftsLogLayoutMobile {...this.state} formatDate={formatDate} handleChangeDateFrom={this.handleChangeDateFrom} handleChangeDateTo={this.handleChangeDateTo} showDuplicate={this.showDuplicate} {...this.props} />
						)
						:
						<Dublicate report={this.state.report[this.state.reportChoosen]} formatDate={formatDate} showDuplicate={this.showDuplicate} idKkm={this.state.idKkm} {...this.props} />
				)
				: <PreloaderFullScreen />
		)
	}
}

export default ShiftsLog;