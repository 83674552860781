import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom";
import PreCheck from "../modules/PreCheck"
import MobilePreCheck from "../modules/MobilePreCheck"
import SearchField from "../../NewCompanies/SearchField";
import Pays from "../modules/Pays"
import Modal from '../../../Modal/operationModal';
import style from "../../styles/pages/container.module.scss"
import Error503Modal from "../../Modals/Error500Modal"
import { useTranslation } from "react-i18next";
import { OPERATION_TYPES } from "../../../../constants/app";

const PurchaseRefundContainer = ({context, history, searchedOperationData, fetchOperationInfo}) => {
  const { t } = useTranslation()
  const idKkm = JSON.parse(localStorage.getItem('CURRENT_KKM_ID'));

  const goTo = () => {
    history.push('refund_new')
  }

  const [searchedValue, setSearchedValue] = useState('');
  const [refundIsFromReport, setRefundIsFromReport] = useState(false)

  useEffect(() => {
    if (localStorage.getItem('PositionDetailsFromReport') !== null) {
      setRefundIsFromReport(true)
    }
  }, [])


  const handleSearch = () => {
    if (searchedValue && String(searchedValue).length > 3) {
      fetchOperationInfo(searchedValue);
    }
  }

  return (
    <div className={style.main}>
      {
      !refundIsFromReport
      &&
        <>
        <div className={style.chooseRefund}>
          <button onClick={() => goTo()}>
            { t('receipt.refund_of_sale') }
          </button>
          <button className={style.active}>
            { t('receipt.refund_of_purchase') }
          </button>
        </div>

        <div className={style.searchBar}>
          <div className={`${style.block} ${style.header}`}>
            <p className={style.title}>
              { t('main.title_search_refund') }
            </p>
          </div>

          <div className={style.block}>
            <SearchField
                  searchValue={searchedValue}
                  setSearchValue={setSearchedValue}
                  searchOpen
                  setSearchOpen={() => {}}
                  query={handleSearch}
                  placeholder={t('report.search_on_receipt_number')}
                />
            <p className={style.info}>
              { t('main.info_search_refund') }
            </p>
          </div>

          <div className={style.block}>
            <Link
              className={style.openOperationLog}
              to={`/www/shifts/${idKkm}/report`}
            >
              { t('main.go_to_operation_log') }
            </Link>
          </div>
        </div>
        </>
      }
      {
        window.matchMedia("(min-width: 1100px)").matches ? (
          <PreCheck context={context} operationType={OPERATION_TYPES.PURCHASE_REFUND.KEY} />
        ) : (
          <MobilePreCheck context={context} operationType={OPERATION_TYPES.PURCHASE_REFUND.KEY} />
        )
      }
      <Pays
        context={context}
        history={history}
        status={OPERATION_TYPES.PURCHASE_REFUND.KEY}
        showPayTypes={true}
        adaptive={!window.matchMedia("(min-width: 1100px)").matches && true}
        searchedOperationData={searchedOperationData}
        addPositionIsHidden
        PurchaseRefund
      />
      {
        context.Modal.Header === '503 ошибка' ? (
          <Error503Modal />
        ) : (
          <Modal
            onClose={context.closeModal}
            onCheck={context.showCheck}
            onElectronCheck={context.electronShowCheck}
            show={context.Modal.Status}
            header={context.Modal.Header}
            body={context.Modal.Body}
            img={context.CheckImg}
            docId={context.CheckId}
            docLink={context.CheckLink}
            share={context.shareSocial}
          />
        )
      }
    </div>
  )
}

export default PurchaseRefundContainer;
