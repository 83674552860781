/* eslint-disable eqeqeq */
import React from 'react';
// import { Link } from 'react-router-dom';
// import Subnav from '../ReportsSubnav';
// import ReportsSubnav from '../ReportsSubnav';
// import { BASE_URL } from '../../Store/snippets';
import MobileNav from '../MobileNav/MobileNav';
// import Header from '../Header/Header';
import Subnav from '../ReportsSubnav/ReportsSubnavLayout';
// import { Link } from 'react-router-dom';
import Workbook from 'react-excel-workbook';
//styles
import "../../Styles/layout/dateRange.scss"
//svg
import { ReactComponent as DonwloadSvg } from '../Svg/download.svg'
// import {setAppVersion} from "../../Store/appVersion";

const ShiftsLogLayout = ({ report: Report, handleChangeDateFrom, handleChangeDateTo, dateFrom, dateTo, idKkm, error, formatDate, user, showDuplicate, history, shiftNumber }) => {
	// const goNew = () => {
	// 	history.push(`/www/shifts/${idKkm}/report`)
	// 	localStorage.setItem('reportStep', '1')
	// 	setAppVersion(true)
	// }


	// const oldVersion = {
	// 	float: "right",
	// 	marginTop: "10px",
	// 	width: "106px",
	// 	height: "38px",
	// 	background: "#FFFFFF",
	// 	borderRadius: "8px",
	// 	display: "flex",
	// 	justifyContent: "space-between",
	// 	alignItems: "center",
	// 	padding: "4px 13px 4px 8px",
	// 	border: "1px solid #D7D7D7",
	// 	cursor: "pointer"
	// }
	// const oldVersionText = {
	// 	color: "#575757",
	// 	lineHeight: "15px",
	// 	fontSize: "12px",
	// 	margin: "0"
	// }


	return (

		<React.Fragment>
			<MobileNav isActive={true} />
			<div className="container pt-5 pb-5">
				<div className="d-flex justify-content-end">
					{/* <div style={oldVersion} onClick={goNew}>
						<p style={oldVersionText}>Новый вид<br />сайта</p>
						<svg width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M4.6665 4L0.666504 8V0L4.6665 4Z" fill="#8E8E8E"/>
						</svg>
					</div> */}
				</div>
				<div className="heading">
					<h1 className="text-center">Архив Z-отчётов</h1>
				</div>
				<Subnav active={"shifts"} history={history} />
				<div className="m-cashbox__user-data">

					<div className="dateRange-wrapper">
						{/* <Link to={`/www/kkms/${idKkm}`} className="back-to-kassa">← Вернуться в кассу</Link> */}
						<div className="date_range">
							<span>Выберите дату от: </span>
							<input className="date-from-input" type="date" min="2019-01-01" value={dateFrom} onChange={handleChangeDateFrom} />
							<span> до: </span>
							<input className="date-to-input" type="date" value={dateTo} onChange={handleChangeDateTo}/>
						</div>
					</div>

					<div className="firstRaw" style={{marginBottom: "20px"}}>
						<span className="firstRawSpan">
							Последняя смена: &nbsp;
							<strong>{shiftNumber && shiftNumber}</strong>
						</span>
					</div>

					<div className="excelButton">
						<Workbook filename={`${dateFrom} - ${dateTo}.xlsx`} element={<button className="downloadExcel">Скачать Excel-файл &nbsp; {<DonwloadSvg/>}</button>}>
							<Workbook.Sheet data={Report} name="Архив Z-отчетов">
								<Workbook.Column label="Номер смены" value={row => row.ShiftIndex} />
								<Workbook.Column label="Открыта" value={row => formatDate(new Date(row.DateOpen))} />
								<Workbook.Column label="Закрыта" value={row => formatDate(new Date(row.DateClose))} />
								<Workbook.Column label="Принято" value={row => String(row.BalanceOpen).replace(/(\d)(?=(\d{3})+$)/g, '$1 ')} />
								<Workbook.Column label="Сдано" value={row => String(row.BalanceClose).replace(/(\d)(?=(\d{3})+$)/g, '$1 ')} />
								<Workbook.Column label="Кол-во операций" value={row => row.Count} />
								<Workbook.Column label="Кассир" value={row => row.User.Name} />
							</Workbook.Sheet>
						</Workbook>
					</div>
					{/* <a target="_blank" href="https://pult24.kz" rel="noopener noreferrer" className="btn btn-counter btn-block" style={{ height: 'auto', textAlign: 'left' }}>
						30 последних отчётов по закрытым сменам. Полный архив доступен в кабинете предпринимателя
							<span className="ml-4">
								<svg width="7" height="12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.5 6a1 1 0 0 1-.29.71l-4 4A1.005 1.005 0 0 1 .79 9.29L4.1 6 .92 2.7a1 1 0 1 1 1.44-1.38l3.86 4A1 1 0 0 1 6.5 6z" fill="#726D85" /></svg>
							</span>
					</a> */}
				</div>
				{
					!error.status ?
						Report.length > 0 ?
							<React.Fragment>
								{
									Report.map((report, idx) => {
										return (
											<div className="shift-log-card" key={report.Id} role="button" onClick={() => showDuplicate(idx)} style={{background: report.ShiftIndex == shiftNumber && "#E6E6FA"}}>
												<div className="shift-log-card-row">
													<div className="shift-log-card-row__decor">
														<svg width="12" height="61" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11 6A5 5 0 111 6a5 5 0 0110 0zM9 57a3 3 0 11-6 0 3 3 0 016 0z" stroke="#A39EB8" strokeWidth="2" /><path d="M6 11v43" stroke="#A39EB8" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="0.15 4" /><path d="M8 6a2 2 0 11-4 0 2 2 0 014 0z" fill="#00ADEE" /></svg>
													</div>
													<div>
														<div className="shift-log-card-date">{formatDate(new Date(report.DateOpen))}</div>
														<div className="shift-log-card-balance">
															{String(report.BalanceOpen).replace(/(\d)(?=(\d{3})+$)/g, '$1 ')}₸
															<span className="svg-handler ml-1"><svg width="8" height="4" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.646.354L.854 3.146A.5.5 0 001.207 4h5.586a.5.5 0 00.353-.854L4.354.354a.5.5 0 00-.708 0z" fill="#1DB848" /></svg></span>
														</div>
													</div>
													<div className="d-flex">
														<div className="shift-log-card-number">№ {report.ShiftIndex}</div>
														<div className="shift-log-card-duplicate"><svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="4" y="6" width="12" height="16" rx="2" stroke="#A39EB8" strokeWidth="2" /><path fillRule="evenodd" clipRule="evenodd" d="M10 3h8a1 1 0 011 1v12a1 1 0 01-1 1h-1v2h1a3 3 0 003-3V4a3 3 0 00-3-3h-8a3 3 0 00-3 3v1h2V4a1 1 0 011-1z" fill="#A39EB8" /></svg></div>
													</div>
												</div>
												<div className="shift-log-card-row">
													<div>
														<div className="shift-log-card-date">{formatDate(new Date(report.DateClose))}</div>
														<div className="shift-log-card-balance">
															{String(report.BalanceClose).replace(/(\d)(?=(\d{3})+$)/g, '$1 ')}₸
															<span className="svg-handler ml-1"><svg width="8" height="4" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.646 3.646L.854.854A.5.5 0 011.207 0h5.586a.5.5 0 01.353.854L4.354 3.646a.5.5 0 01-.708 0z" fill="#EB271C" /></svg></span>
														</div>
													</div>
													<div>
														<div className="shift-log-card-operations">Операций: {report.Count}</div>
														<div className="shift-log-card-name">{report.User.Name}</div>
													</div>
												</div>
											</div>
										)
									})
								}
							</React.Fragment>
							:
							<div className="alert alert-light" role="alert">
								За данный  промежуток времени кассы не закрывались
                					</div>

						:
						<div className="alert alert-danger" style={{ margin: 'auto', maxWidth: '800px' }}>
							<h5>{error.title}</h5>
							<p>{error.text}</p>
						</div>
				}
			</div>
		</React.Fragment>

	)
}

export default ShiftsLogLayout;
