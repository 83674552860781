import Header from "../../Header/Header";
import React, {useEffect, useState} from "react";
import NewHeader from "../NewHeader/NewHeader";
import {getAppModule} from "../../../Store/appVersion";
import {useHistory} from "react-router-dom";

const HeaderVersion = (props) => {
	const history = useHistory()
	const [isNew, setIsNew] = useState(false)

	useEffect(() => {
		setIsNew(getAppModule())
	}, [])

	return (
		<React.Fragment>
			{
				isNew
						?
					<NewHeader history={history} { ...props } />
						:
				<Header history={history} { ...props } />
			}
		</React.Fragment>
	)
}

export default HeaderVersion;
