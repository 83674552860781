exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Error503Modal_wrapper__SSzqd {\n  background: rgba(0, 0, 0, 0.65);\n  width: 100vw;\n  height: 100vh;\n  position: absolute;\n  top: 0;\n  left: 0;\n  z-index: 1;\n  display: flex;\n  align-items: center;\n  justify-content: center; }\n\n.Error503Modal_modal__3cHJj {\n  background: #FFFFFF;\n  box-shadow: 0px 0px 40px rgba(153, 153, 153, 0.1);\n  border-radius: 8px;\n  width: 686px;\n  height: 505px;\n  padding: 40px; }\n\n.Error503Modal_image__12Qb3 {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 300px; }\n\n.Error503Modal_content__kW3U2 {\n  margin-top: 24px;\n  text-align: center; }\n  .Error503Modal_content__kW3U2 .Error503Modal_title__3nGB8 {\n    color: #263238;\n    line-height: 29px;\n    font-size: 24px;\n    font-weight: 600;\n    font-family: 'Inter';\n    font-style: normal; }\n  .Error503Modal_content__kW3U2 .Error503Modal_subtitle__2ieSL {\n    margin-top: 24px;\n    color: #555555;\n    font-family: 'Inter';\n    font-style: normal;\n    font-weight: 400;\n    font-size: 16px;\n    line-height: 24px; }\n", ""]);

// exports
exports.locals = {
	"wrapper": "Error503Modal_wrapper__SSzqd",
	"modal": "Error503Modal_modal__3cHJj",
	"image": "Error503Modal_image__12Qb3",
	"content": "Error503Modal_content__kW3U2",
	"title": "Error503Modal_title__3nGB8",
	"subtitle": "Error503Modal_subtitle__2ieSL"
};