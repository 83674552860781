import React, { useState, useEffect } from "react"
import style from "../../styles/pages/mobileAddPosition.module.scss"
import { ReactComponent as MobilePlus } from "../images/mobilePlus.svg"
import AddPosition from "./AddPosition"
import { useTranslation } from "react-i18next";

const MobileAddPosition = ({ context, status, show }) => {
  const { t } = useTranslation()

  const [showModal, setShow] = useState(false)

  useEffect(() => {
    showModal ? (
      document.body.style.overflow = 'hidden'
    ) : (
      document.body.style.overflow = 'auto'
    )
  }, [showModal])

  const closeModal = () => {
    setShow(false)
  }

  const stop = e => {
    e.stopPropagation();
  }

  return (
    <React.Fragment>
      <div className={style.btnWrapper}>
        <button className={style.addPositionBtn} onClick={() => setShow(true)}>
          <MobilePlus />
          <p>{ t('other.add_position') }</p>
        </button>
      </div>

      {
        showModal && (
          <div className={style.addPositionWrapper} onClick={() => closeModal()}>
            <div className={style.addPositionModal} onClick={(e) => stop(e)}>
              <AddPosition
                context={context}
                status={status}
                show={show}
                adaptive={true}
                closeModal={() => closeModal()}
              />
            </div>
          </div>
        )
      }
    </React.Fragment>
  )
}

export default MobileAddPosition;
