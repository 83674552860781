exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".shift_wrapper__DRV93 {\n  background-color: #F5F5F5;\n  min-height: calc(100vh - 185px);\n  padding-top: 20px; }\n  .shift_wrapper__DRV93 .shift_container__1J18z {\n    max-width: 1120px;\n    width: 100%;\n    margin-top: 20px;\n    margin-right: auto;\n    margin-left: auto;\n    overflow: hidden;\n    font-family: 'Inter', sans-serif; }\n  .shift_wrapper__DRV93 .shift_oldVersionWrapper__1kcHq {\n    display: flex;\n    justify-content: flex-end;\n    margin-bottom: 20px; }\n    .shift_wrapper__DRV93 .shift_oldVersionWrapper__1kcHq .shift_oldVersion__1MBc2 {\n      display: flex;\n      align-items: center;\n      justify-content: space-between;\n      width: 106px;\n      height: 38px;\n      background: white;\n      border-radius: 8px;\n      padding: 4px 13px 4px 8px;\n      border: 1px solid #d7d7d7;\n      cursor: pointer; }\n      .shift_wrapper__DRV93 .shift_oldVersionWrapper__1kcHq .shift_oldVersion__1MBc2 p {\n        color: #575757;\n        line-height: 15px;\n        font-size: 12px;\n        margin: 0px; }\n  .shift_wrapper__DRV93 .shift_body__1Q5WZ {\n    display: flex;\n    justify-content: center; }\n\n.shift_mobileWrapper__GbZLZ {\n  background-color: #F5F5F5;\n  padding-top: 20px; }\n  .shift_mobileWrapper__GbZLZ .shift_body__1Q5WZ {\n    margin-top: 15px;\n    margin-bottom: 40px; }\n", ""]);

// exports
exports.locals = {
	"wrapper": "shift_wrapper__DRV93",
	"container": "shift_container__1J18z",
	"oldVersionWrapper": "shift_oldVersionWrapper__1kcHq",
	"oldVersion": "shift_oldVersion__1MBc2",
	"body": "shift_body__1Q5WZ",
	"mobileWrapper": "shift_mobileWrapper__GbZLZ"
};