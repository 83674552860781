exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Sale_NewSale__30yZS {\n    padding-bottom: 3rem;\n    overflow-x: hidden;\n}", ""]);

// exports
exports.locals = {
	"NewSale": "Sale_NewSale__30yZS"
};