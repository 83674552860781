import React from 'react';
import { Link } from 'react-router-dom';
// import { BASE_URL } from '../../Store/snippets'
import PrintButton from '../Buttons/PrintButton';
import MobileNav from '../MobileNav/MobileNav';
// import Header from '../Header/Header';
import HeaderVersion from "../NewDesign/HeaderVersion/HeaderVersion";

const ReportZlayout = ({ report: Report, user: User, error, date, idKkm, formatDate, history }) => {
	return (
		<React.Fragment>
			{
				window.matchMedia("(min-width: 1100px)").matches ?
					<HeaderVersion isActive={false} />
					:
					<MobileNav isActive={true} />
			}
			{
				!error.status ?
					<div className="container pt-5 pb-5">
						<div className="heading">
							<h1 className="text-center">Z-отчёт</h1>
							<h3 className="text-center">
								<span className="badge badge-primary badge-wrap">
									<span>Смена закрыта</span>
									<br />
									<span>{date}</span>
								</span>
							</h3>
						</div>
						<div className="pt-2 pb-3 d-flex justify-content-between align-items-center">
							<Link to={`/www/kkms/${idKkm}`} replace={true} className="btn btn-secondary btn-lg btn-sm-block mb-3">← Вернуться в кассу</Link>
							<PrintButton />
						</div>

						<div className="row pt-0">
							<div className="col-md-6">

								<div className="card bReportCard" style={{display: 'block'}}>
									<div className="card-header">
										Основная информация
									</div>
									<div className="card-body">
										<div className="table-wrapper">
											<table className="table table-sm table-borderless table-responsive-sm">
												<tbody>
													<tr>
														<td><strong>Наименование организации</strong></td>
														<td>{Report.Kkm.Company.FullName}</td>
													</tr>
													<tr>
														<td><strong>ИИН/БИН</strong></td>
														<td>{Report.Kkm.Company.Bin}</td>
													</tr>
													<tr>
														<td><strong>ККМ</strong></td>
														<td>{Report.Kkm.Id}</td>
													</tr>
													<tr>
														<td><strong>Регистрационный номер в КГД</strong></td>
														<td>{Report.Kkm.Rnm}</td>
													</tr>
													<tr>
														<td><strong>Название кассы</strong></td>
														<td>{Report.Kkm.Name}</td>
													</tr>
													<tr>
														<td><strong>Адрес торговой точки</strong></td>
														<td>{Report.Kkm.Address.Town.Name}, {Report.Kkm.Address.Street}
															{Report.Kkm.Address.House} {Report.Kkm.Address.Flat}</td>
													</tr>
													<tr>
														<td><strong>Кассир</strong></td>
														<td>{User.Name}</td>
													</tr>
												</tbody>
											</table>
										</div>
										<hr />
										<div className="table-wrapper">
											<table className="table table-sm table-borderless table-responsive-sm">
												<tbody>
													<tr>
														<td><strong>Баланс на момент открытия смены</strong></td>
														<td>{new Intl.NumberFormat('ru-RU').format(Report.BalanceOpen)} ₸</td>
													</tr>
													<tr>
														<td><strong>Баланс на момент закрытия смены</strong></td>
														<td>{new Intl.NumberFormat('ru-RU').format(Report.BalanceClose)} ₸</td>
													</tr>
													<tr>
														<td><strong>Оформлено чеков</strong></td>
														<td>{Report.Count}</td>
													</tr>
												</tbody>
											</table>
										</div>
										<hr />
										<div className="table-wrapper">
											<table className="table table-sm table-borderless table-responsive-sm">
												<tbody>
													<tr>
														<td><strong>Необнуляемая сумма Продаж</strong></td>
														<td style={{whiteSpace2: 'nowrap'}}>{new Intl.NumberFormat('ru-RU').format(Report.Total.Sales.Cumulative)} ₸</td>
													</tr>
													<tr>
														<td><strong>Необнуляемая сумма Покупок</strong></td>
														<td style={{whiteSpace2: 'nowrap'}}>{new Intl.NumberFormat('ru-RU').format(Report.Total.Purchases.Cumulative)} ₸</td>
													</tr>
													<tr>
														<td><strong>Необнуляемая сумма Служебных расходов</strong></td>
														<td style={{whiteSpace2: 'nowrap'}}>{new Intl.NumberFormat('ru-RU').format(Report.Total.Expenses.Cumulative)} ₸</td>
													</tr>
													<tr>
														<td><strong>Необнуляемая сумма Служебных приходов</strong></td>
														<td style={{whiteSpace2: 'nowrap'}}>{new Intl.NumberFormat('ru-RU').format(Report.Total.Incomes.Cumulative)} ₸</td>
													</tr>
													<tr>
														<td><strong>Необнуляемая сумма Возвратов продаж</strong></td>
														<td style={{whiteSpace2: 'nowrap'}}>{new Intl.NumberFormat('ru-RU').format(Report.Total.Refunds.Cumulative)} ₸</td>
													</tr>
													<tr>
														<td><strong>Необнуляемая сумма Возвратов покупок</strong></td>
														<td style={{whiteSpace2: 'nowrap'}}>{new Intl.NumberFormat('ru-RU').format(Report.Total.PurchaseRefunds.Cumulative)} ₸</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>

							<div className="col-md-6">
								<div className="card bReportCard" style={{display: 'block'}}>
									<div className="card-header">
										Оформленные чеки
									</div>
									<div className="card-body bReportCard__body">

										<h5 className="border-bottom pb-2">Служебный приход</h5>
										<div className="table-wrapper pb-2">
											<table className="table table-sm table-borderless table-responsive-sm">
												{Report.Total.Incomes.Qty > 0 ?
													<tbody>
														<tr>
															<td><strong>Кол-во чеков</strong></td>
															<td style={window.matchMedia("(min-width: 1100px)").matches ? { width: '40%' } : {}}>{Report.Total.Incomes.Qty}</td>
														</tr>
														<tr>
															<td><strong>Общая сумма</strong></td>
															<td>{new Intl.NumberFormat('ru-RU').format(Report.Total.Incomes.Amount)} ₸</td>
														</tr>
													</tbody>
													:
													<tbody>
														<tr>
															<td><strong>Чеков нет</strong></td>
														</tr>
													</tbody>
												}
											</table>
										</div>

										<h5 className="border-bottom pb-2">
											Служебный расход
											</h5>
										<div className="table-wrapper pb-2">
											<table className="table table-sm table-borderless table-responsive-sm">
												{Report.Total.Expenses.Qty > 0 ?
													<tbody>
														<tr>
															<td><strong>Кол-во чеков</strong></td>
															<td style={window.matchMedia("(min-width: 1100px)").matches ? { width: '40%' } : {}}>{Report.Total.Expenses.Qty}</td>
														</tr>
														<tr>
															<td><strong>Общая сумма</strong></td>
															<td>{new Intl.NumberFormat('ru-RU').format(Report.Total.Expenses.Amount)} ₸</td>
														</tr>
													</tbody>
													:
													<tbody>
														<tr>
															<td><strong>Чеков нет</strong></td>
														</tr>
													</tbody>
												}
											</table>
										</div>

										<h5 className="border-bottom pb-2">
											Продажи
										</h5>
										<div className="table-wrapper pb-2">
											<table className="table table-sm table-borderless table-responsive-sm">
												{Report.Total.Sales.Qty > 0 ?
													<tbody>
														<tr>
															<td><strong>Кол-во чеков</strong></td>
															<td style={window.matchMedia("(min-width: 1100px)").matches ? { width: '40%' } : {}}>{Report.Total.Sales.Qty}</td>
														</tr>
														<tr>
															<td><strong>Общая сумма</strong></td>
															<td>{new Intl.NumberFormat('ru-RU').format(Report.Total.Sales.Amount)} ₸</td>
														</tr>
													</tbody>
													:
													<tbody>
														<tr>
															<td><strong>Чеков нет</strong></td>
														</tr>
													</tbody>
												}
											</table>
										</div>

										<h5 className="border-bottom pb-2">
											Возвраты продаж
										</h5>
										<div className="table-wrapper pb-2">
											<table className="table table-sm table-borderless table-responsive-sm">
												{Report.Total.Refunds.Qty > 0 ?
													<tbody>
														<tr>
															<td><strong>Кол-во чеков</strong></td>
															<td style={window.matchMedia("(min-width: 1100px)").matches ? { width: '40%' } : {}}>{Report.Total.Refunds.Qty}</td>
														</tr>
														<tr>
															<td><strong>Общая сумма</strong></td>
															<td>{new Intl.NumberFormat('ru-RU').format(Report.Total.Refunds.Amount)} ₸</td>
														</tr>
													</tbody>
													:
													<tbody>
														<tr>
															<td><strong>Чеков нет</strong></td>
														</tr>
													</tbody>
												}

											</table>
										</div>

										<h5 className="border-bottom pb-2">
											Покупки
										</h5>
										<div className="table-wrapper pb-2">
											<table className="table table-sm table-borderless table-responsive-sm">
												{Report.Total.Purchases.Qty > 0 ?
													<tbody>
														<tr>
															<td><strong>Кол-во чеков</strong></td>
															<td style={window.matchMedia("(min-width: 1100px)").matches ? { width: '40%' } : {}}>{Report.Total.Purchases.Qty}</td>
														</tr>
														<tr>
															<td><strong>Общая сумма</strong></td>
															<td>{new Intl.NumberFormat('ru-RU').format(Report.Total.Purchases.Amount)} ₸</td>
														</tr>
													</tbody>
													:
													<tbody>
														<tr>
															<td><strong>Чеков нет</strong></td>
														</tr>
													</tbody>
												}
											</table>
										</div>
										<h5 className="border-bottom pb-2">
											Возвраты покупок
										</h5>
										<div className="table-wrapper pb-2">
											<table className="table table-sm table-borderless table-responsive-sm">
												{Report.Total.PurchaseRefunds.Qty > 0 ?
													<tbody>
														<tr>
															<td><strong>Кол-во чеков</strong></td>
															<td style={window.matchMedia("(min-width: 1100px)").matches ? { width: '40%' } : {}}>{Report.Total.PurchaseRefunds.Qty}</td>
														</tr>
														<tr>
															<td><strong>Общая сумма</strong></td>
															<td>{new Intl.NumberFormat('ru-RU').format(Report.Total.PurchaseRefunds.Amount)} ₸</td>
														</tr>
													</tbody>
													:
													<tbody>
														<tr>
															<td><strong>Чеков нет</strong></td>
														</tr>
													</tbody>
												}

											</table>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="pt-5">
							<div className="card">
								<div className="card-header" style={{textAlign: "center"}}>
									Отчет по секциям
								</div>
								<div className="card-body">
									<div className="table-wrapper">
										{
											Report.Operations.length > 0 ?
												<table className="table table-responsive-sm">
													<thead>
														<tr>
															<th>Секция</th>
															<th>Сумма продаж</th>
															<th>Сумма покупок</th>
															<th>Сумма возврата продаж</th>
															<th>Сумма возврата покупок</th>
														</tr>
													</thead>
													<tbody>
														{
															Object.entries(Report.Sections)
															.filter(section =>  
																section[1].Sales || 
																section[1].Purchases || section[1].Refunds || 
																section[1].PurchaseRefunds
															)
															.map(section => (
																<tr key={section[0]}>
																	<td>{section[0]} {section[1].Name ? `(${section[1].Name})` : ""}</td>
																	<td>{section[1].Sales ? `${new Intl.NumberFormat('ru-RU').format(section[1].Sales)} ₸` : "0 ₸"}</td>
																	<td>{section[1].Purchases ? `${new Intl.NumberFormat('ru-RU').format(section[1].Purchases)} ₸` : "0 ₸"}</td>
																	<td>{section[1].Refunds ? `${new Intl.NumberFormat('ru-RU').format(section[1].Refunds)} ₸` : "0 ₸"}</td>
																	<td>{section[1].PurchaseRefunds ? `${new Intl.NumberFormat('ru-RU').format(section[1].PurchaseRefunds)} ₸` : "0 ₸"}</td>
																</tr>
															))
														}
													</tbody>
												</table>
												:
												<table>
													<thead>
														<tr>
															<th>
																Операций не производилось
															</th>
														</tr>
													</thead>
												</table>
										}
									</div>
								</div>
							</div>
						</div>
						<div className="pt-5">
							<div className="card">
								<div className="card-header" style={{textAlign: "center"}}>
									Cписок операций
								</div>
								<div className="card-body">
									<div className="table-wrapper">
										{
											Report.Operations.length > 0 ?
												<table className="table table-responsive-sm">
													<thead>
														<tr>
															<th>Тип операции</th>
															<th>Дата транзакции</th>
															<th>Номер транзакции</th>
															<th>Сумма</th>
														</tr>
													</thead>
													<tbody>
														{
															Report.Operations.map(operation => {
																return (
																	<tr key={operation.NumberDoc}>
																		<td>{operation.TypeDocument.Name}</td>
																		<td>{formatDate(new Date(operation.DateDocument))}</td>
																		<td>{operation.NumberDoc}</td>
																		<td>{new Intl.NumberFormat('ru-RU').format(operation.Value)}</td>
																	</tr>
																)
															})
														}
													</tbody>
												</table>
												:
												<table>
													<thead>
														<tr>
															<th>
																Операций не производилось
													</th>
														</tr>
													</thead>
												</table>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
					:
					<React.Fragment>
						<div className="alert alert-danger" style={{ margin: 'auto', maxWidth: '800px', display: 'flex', flexDirection: 'column' }}>
							<h5>{error.title}</h5>
							<p>{error.text}</p>
							<div className="d-sm-flex">
								<Link to={`/www/kkms/${idKkm}/`} replace={true} className="btn btn-warning btn-lg ml-auto d-none d-lg-block">Вернуться на кассу</Link>
								{/* <Link to={`/www/kkms/${idKkm}/`} replace={true} className="btn btn-warning btn-lg btn-sm-block ml-auto ml-md-3">Повторить закрытие смены</Link> */}
							</div>
						</div>

					</React.Fragment>
			}
			<MobileNav />
		</React.Fragment>
	)
}

export default ReportZlayout;
