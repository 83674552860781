import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Logo from './icon_fiscal.svg';

export default class CodePushContainer extends Component {
  static propTypes = {
    children: PropTypes.object.isRequired,
  }

  state = {
    load: false,
    syncMessage: '',
    progress: false,
  }

  componentWillMount() {
    if (window.app && window.device) {
      const { device: { platform } } = window;
      if (platform === 'Android' || platform.toUpperCase() === 'IOS') {
        window.app.codePushStatusDidChange = this.codePushStatusDidChange.bind(this);
        window.app.codePushDownloadDidProgress = this.codePushDownloadDidProgress.bind(this);
        window.app.initialize();
        // Раскоментировать для дебага
        setTimeout(() => {
          this.setState({ progress: false, load: true });
        }, 3000);
        return;
      }
      window.app.initialize();
    }
    this.setState({ progress: false, load: true });
  }

  codePushStatusDidChange(syncStatus) {
    const { app: { language }, SyncStatus } = window;
    switch (syncStatus) {
      case SyncStatus.CHECKING_FOR_UPDATE:
        this.setState({ syncMessage: language.checkUpdate });
        break;
      case SyncStatus.DOWNLOADING_PACKAGE:
        this.setState({ syncMessage: language.download });
        break;
      case SyncStatus.AWAITING_USER_ACTION:
        this.setState({ syncMessage: language.waitting });
        break;
      case SyncStatus.INSTALLING_UPDATE:
        this.setState({ syncMessage: language.install });
        break;
      case SyncStatus.UP_TO_DATE:
        this.setState({ syncMessage: language.successUpdate, load: true, progress: false });
        break;
      case SyncStatus.UPDATE_IGNORED:
        this.setState({ syncMessage: language.cancel, progress: false });
        break;
      case SyncStatus.UPDATE_INSTALLED:
        this.setState({ syncMessage: language.successInstall, progress: false });
        break;
      case SyncStatus.UNKNOWN_ERROR:
        this.setState({ syncMessage: language.error, progress: false, load: true });
        break;
      default:
    }
  }

  codePushDownloadDidProgress(progress) {
    this.setState({ progress });
  }

  render() {
    const { progress, load } = this.state;
    if (load) {
      return this.props.children;
    }

    let progressView;

    if (progress) {
      const { receivedBytes, totalBytes } = progress;

      progressView = (
        <span>{parseInt(receivedBytes * 100 / totalBytes, 10)}</span>
      );
    }

    return (
      <div className="code-push p-3">
        <img src={Logo} alt=""/>
        <div className="text-center">{this.state.syncMessage}</div>
        {
          progress && <div style={{ whiteSpase: 'nowrap' }}>Прогресс {progressView || 0}</div>
        }
      </div>
    );
  }
}
