import React from 'react'
import s from './Button.module.scss'

const Button = ({className, onClick, children, marginBottom, marginTop, borderRadius, height, width, disabled}) => {
    return (
        <button
            className={`${s.btn} ${s[className]}`}
            onClick={onClick}
            style={{marginBottom: marginBottom ? marginBottom : '', marginTop: marginTop ? marginTop : '', borderRadius: borderRadius ? borderRadius : '', height: height ? height : '', width: width ? width : '' }}
            disabled={disabled}
        >
            {children}
        </button>
    )
}

export default Button
