import React, { useState } from 'react';
import { useTranslation } from "react-i18next";

const Sections = ({ sections, addSection, toggleSection}) => {
  const { t } = useTranslation()

  const [Name, setName] = useState("")
  const [Nds, setNds] = useState("")

  const handleClick = () => {
    let data = {
      Name,
      Nds: parseInt(Nds)
    }
    addSection(data)
    setName("")
    setNds("")
  }

  return (
    <div>
      <div class="heading">
        <h1 class="text-center display-4">{ t('kassa_settings.sections') }</h1>
      </div>
      <div class="pt-2">
        <h2>{ t('other.add_new_section_alt') }</h2>

        <div class="form-row">
            <div class="form-group col-md-4">
                <label htmlFor="SectionName">{ t('kassa_settings.name') }</label>
                <input type="text" name="SectionName" class="form-control" id="SectionName" value={Name} onChange={(e) => setName(e.target.value)} />
            </div>
            <div class="form-group col-md-2">
                <label htmlFor="SectionNDS">{ t('kassa_settings.vat_rate') } в %</label>
                <input type="number" min="0" name="SectionNDS" class="form-control" id="SectionNDS" value={Nds} onChange={(e) => setNds(e.target.value)} />
            </div>
        </div>

        <button onClick={() => handleClick()} class="btn btn-primary mb-2">{ t('kassa_settings.add') }</button>
      </div>

      <div class="pt-5">
        <div class="card">
          <div class="card-header">
            { t('kassa_settings.kkm_sections') }
          </div>
          <div class="card-body">
            <div class="table-wrapper">
              <table class="table table-responsive-sm">
                <thead>
                  <tr>
                    <th>{ t('kassa_settings.name') }</th>
                    <th>{ t('kassa_settings.vat_rate') }</th>
                    <th>{ t('kassa_settings.status') }</th>
                    <th>{ t('kassa_settings.actions') }</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    sections.map(sec => (
                      <tr key={sec.Id}>
                        <td>{sec.Name}</td>
                        <td>{sec.Nds}%</td>
                        <td>
                          {
                            sec.Active ? (
                              <span class="badge badge-success">{ t('kassa_settings.active') }</span>
                            ) : (
                              <span class="badge badge-secondary">{ t('kassa_settings.disabled') }</span>
                            )
                          }
                        </td>
                        <td>
                          {
                            sec.Active ? (
                              <div class="btn btn-outline-danger btn-sm" onClick={() => toggleSection(sec.Id)}>{ t('kassa_settings.disable') }</div>
                            ) : (
                              <div class="btn btn-outline-success btn-sm"onClick={() => toggleSection(sec.Id)} >{ t('kassa_settings.enable') }</div>
                            )
                          }
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
     </div>
    </div>
  )
}

export default Sections;
