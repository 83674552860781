import React, { Component } from 'react';
import axios from 'axios';
import {BASE_URL, getUser, getError } from '../../../Store/snippets';
import CompaniesList from './CompanyList';
import PreloaderFullScreen from '../../Preloader/Preloader';
import { FISCAL_DEV_HOST, PULT_API_PROD_URL, PULT_DEV_URL } from '../../../constants/app';

let Token, headers;

const hostName = window.location.host;

let PULT_URL = PULT_DEV_URL;
if (process.env.NODE_ENV === 'production') {
	if(hostName === FISCAL_DEV_HOST) {
		PULT_URL = PULT_DEV_URL;
	} else {
		PULT_URL = PULT_API_PROD_URL;
	}
	if (window.app && !!window.cordova) {
			PULT_URL = PULT_API_PROD_URL;
	}
}

let isElectron = require("is-electron");
class Companies extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user: {},
			companies: [],
			shiftOpen: false,
			usersCompanyId: null,
			choosenCompanyId: null,
			companyName: '',
			kkms: [],
			error: false,
			errorData: '',
			kkmLoading: false,
			mounted: false,
			currentExpiredDates: [],
			pending: false,
			copied: false,
			znmForCopy: 0,
			finishing: false,
			balances: {},
		};
		this.controller = new AbortController()
	}

	selectCompanyById = (companies, id) => {
		localStorage.setItem('COMPANY', JSON.stringify(companies[id]));
	}


	selectCompany = async (e) => {
		const idx = Number(e.currentTarget.dataset.idx);
		const choosenCompanyId = Number(this.state.companies[idx].Id);
		this.setState({
			...this.state,
			choosenCompanyId,
			kkmLoading: true
		})
		localStorage.setItem('COMPANY', JSON.stringify(this.state.companies[idx]));

		try {
			const kkmsResponse = await axios.get(`${BASE_URL}/kkms?companies=${choosenCompanyId}`, { headers })
			let kkms = kkmsResponse.data.Data.Kkms;
			kkms = this.getKkmsWithLabels(kkms);
			kkms.forEach(kkm => {
				let responseArr = [];
				this.setState({
					pending: true
				})
				axios.get(`${PULT_URL}/fiscal/main/kkms/${kkm.Id}/expired`, {headers}).then((response) => {
					responseArr.push(response.data)
					this.setState({
						...this.state,
						currentExpiredDates: responseArr,
						pending: false
					}, ()=>kkms = this.getKkmsWithDates(kkms))
				})
			})
			this.setState({
				...this.state,
				companyName: this.state.companies[idx].ShortName,
				kkms,
				kkmLoading: false
			});
		} catch {
			console.log(e);
		}
	};

	copyZnm = (e, znm) => {
		e.stopPropagation();
		const el = document.createElement('textarea');
		el.value = znm;
		document.body.appendChild(el);
		el.select();
		document.execCommand('copy');
		document.body.removeChild(el);
		this.setState({
			copied: true,
			znmForCopy: znm
		}, () => {
			setTimeout(() => {
				this.setState({
					copied: false,
					znmForCopy: 0
				})
			}, 3000)
		})
	}

	openKkm = (e) => {
		const idKkm = Number(e.currentTarget.id);
		const idx = e.currentTarget.dataset['idx'];
		localStorage.setItem(`KKM`, JSON.stringify(this.state.kkms[idx]));
		localStorage.setItem(`CURRENT_KKM_ID`, JSON.stringify(idKkm));
		this.props.history.push(`/www/kkms/${idKkm}/shift_new`, { test: 'state' });
	}

	getKkmsWithLabels = (kkms) => {
		const user = getUser(this.props.history);
		return kkms.map(kkm => {
			if (kkm.IdStatusKkm === 1) return { ...kkm, LabelText: 'Касса в процессе регистрации', LabelType: user.Lock ? 'secondary' : 'danger' };
			if (kkm.IdStatusKkm === 3) {
				if (kkm.IsActive === false) return { ...kkm, LabelText: 'Касса заблокирована в ОФД и в K24B', LabelType: user.Lock ? 'secondary' : 'danger' };
				return { ...kkm, LabelText: 'Касса заблокирована в ОФД', LabelType: user.Lock ? 'secondary' : 'danger' };
			}
			if (kkm.IsActive === false) return { ...kkm, LabelText: 'Касса заблокирована в K24B', LabelType: user.Lock ? 'secondary' : 'danger' };
			if (kkm.Lock === false) return { ...kkm, LabelText: 'Касса доступна', LabelType: user.Lock ? 'available' : 'primary' };
			if (kkm.IdShift !== user.IdShift) return { ...kkm, LabelText: 'Смена открыта другим кассиром', LabelType: user.Lock ? 'secondary' : 'warning'};
			return { ...kkm, LabelText: user.Lock ? 'Ваша смена' : 'Касса доступна', LabelType: user.Lock ? 'success' : 'secondary' };
		})
	}

	getKkmsWithDates = (kkms) => {

		kkms.forEach(kkm => {
			this.state.currentExpiredDates.forEach((item) => {
				if(Object.keys(item)[0] === kkm.Id.toString() && Object.values(item)[0]){
					const year = new Date(Object.values(item)[0]).getFullYear().toString();
					let month = new Date(Object.values(item)[0]).getMonth() + 1;
					if (month < 10) {
						month = `0${month}`
					}
					let day = new Date(Object.values(item)[0]).getDate()
					if (day < 10) {
						day = `0${day}`
					}
					// kkm.ExpiredDate = day + '.' + month.toString() + '.' + year;
					if(new Date(Object.values(item)[0]) > new Date() && new Date(Object.values(item)[0]) - new Date() <= 3 && new Date(Object.values(item)[0]) - new Date() > 0) {
						kkm.ExpiredDate = 'Осталось' + Math.round(Math.abs(new Date(Object.values(item)[0]) - new Date()) / 36e5 / 24) + `${Math.round(Math.abs(new Date(Object.values(item)[0]) - new Date()) / 36e5 / 24) === 1 ? 'день' : 'дня'}`;
					}else if(new Date(Object.values(item)[0]) > new Date() && new Date(Object.values(item)[0]) - new Date() > 3) {
						kkm.ExpiredDate = 'Оплачена до ' + day + '.' + month + '.' + year;
					}else{
						kkm.ExpiredDate = 'Требуется оплата'
					}
				}
			})
		})
		this.setState({
			...this.state,
			kkms,
		});
		return kkms
	}

	componentDidMount() {
		let company, companies, usersCompanyId, choosenCompanyId, kkms, shift;

		Token = localStorage.getItem('tokken');
		headers = {
			'Authorization': `Bearer ${Token}`,
			'Content-Type': 'application/json',
		}

		const user = getUser(this.props.history);
		const query = async () => {
			try {
				const companiesResponse = await axios.get(`${BASE_URL}/companies`, { headers, signal: this.controller.signal });
				companies = companiesResponse.data.Data.Companies;

				if (companies == null || companies < 1) {
					throw new Error(JSON.stringify({
						body: { response: { data: { Message: 'У вас нет ни одного зарегистрированного юр. лица. Обратитесь в тех поддержку' }}}
					}))
				}
				if (user.Lock === true) {
					const shiftResponse = await axios.get(`${BASE_URL}/shifts/${user.IdShift}`, { headers, signal: this.controller.signal });

					shift = shiftResponse.data.Data;
					usersCompanyId = shift.IdCompany;
					choosenCompanyId = shift.IdCompany;

					const kkmResponse = await axios.get(`${BASE_URL}/kkms/${shift.IdKkm}`, { headers, signal: this.controller.signal });

					const kkm = kkmResponse.data.Data.Kkm;
					const idKkm = kkmResponse.data.Data.Kkm.Id;

					localStorage.setItem(`CURRENT_KKM_ID`, JSON.stringify(idKkm));
					localStorage.setItem(`KKM`, JSON.stringify(kkm));

					if (localStorage.getItem(`Uid_${idKkm}`) === null) {
						const uidResponse = await axios.get(`${BASE_URL}/uid/${idKkm}`, { headers, signal: this.controller.signal });
						const uid = uidResponse.data.Data.Uid;
						localStorage.setItem(`Uid_${idKkm}`, uid);
					}

					localStorage.setItem('SHIFT', JSON.stringify(shift));
					localStorage.setItem('OPEN_SHIFT_KKM_ID', JSON.stringify(shift.IdKkm));
					localStorage.setItem('SHIFT_INDEX', JSON.stringify(shift.ShiftIndex));

					if (localStorage.getItem('OPEN_SHIFT_BALANCES') === null) {
						const balancesResponse = await axios.get(`${BASE_URL}/kkms/${shift.IdKkm}/balances`, { headers, signal: this.controller.signal });
						const balances = await balancesResponse.data.Data.Balances;
						localStorage.setItem(`OPEN_SHIFT_BALANCES`, JSON.stringify(balances));

						this.setState({
							...this.state,
							balances: balances,
						})
					}

					if (localStorage.getItem('OPEN_SHIFT_RECEIPT_COUNT') === null) {
						const chartResponse = await axios.get(`${BASE_URL}/kkms/${shift.IdKkm}/chartsdata`, { headers, signal: this.controller.signal });
						const dashboardData = chartResponse.data.Data
						const totalReceiptCount = dashboardData.Sells.TotalReceipts + dashboardData.Refunds.TotalReceipts
						localStorage.setItem(`OPEN_SHIFT_RECEIPT_COUNT`, totalReceiptCount);
					}
				} else {
					choosenCompanyId = companies[0].Id
				}

				company = companies.length > 0 ? companies.filter(company => company.Id === choosenCompanyId)[0] : companies[0];
				localStorage.setItem('COMPANY', JSON.stringify(company));

				const kkmsResponse = await axios.get(`${BASE_URL}/kkms?companies=${choosenCompanyId}`, { headers, signal: this.controller.signal })

				kkms = kkmsResponse.data.Data.Kkms;
				kkms = this.getKkmsWithLabels(kkms)

				let responseArr = [];
				kkms.forEach(async(kkm) => {
					this.setState({
						pending: true
					})
					try {
						await axios.get(`${PULT_URL}/fiscal/main/kkms/${kkm.Id}/expired`, {headers, signal: this.controller.signal}).then((response) => {
							responseArr.push(response.data)
							this.setState({
								currentExpiredDates: responseArr,
								pending: false
							}, ()=>kkms = this.getKkmsWithDates(kkms))
						})
					} catch (error) {
						console.error(error)
					}
				})

				this.setState({
					...this.state,
					companies,
					usersCompanyId,
					choosenCompanyId,
					companyName: companies[0].ShortName,
					kkms,
					user,
					mounted: true
				});

			} catch (error) {
				const data = getError(error, this.props.history)
				this.setState({
					...this.state,
					errorData: data,
					error: true,
					mounted: true
				})
			}
		}

		query()

		if(isElectron() || localStorage.getItem('KKM_GARANT') || localStorage.getItem('KKM_LOMBARD') !== null) {
			const idKkm = JSON.parse(localStorage.getItem("CURRENT_KKM_ID"));
			const idx = 0;
			localStorage.setItem(`KKM`, JSON.stringify(this.state.kkms[idx]));
			this.props.history.push(`/www/kkms/${idKkm}`, { test: 'state' });
		}
	}

	componentWillUnmount() {
		this.controller.abort()
	}

	render() {
		return (
			this.state.mounted ?
				<React.Fragment>
					<CompaniesList {...this.state} copy={this.copy} selectCompany={this.selectCompany} openKkm={this.openKkm} copyZnm={this.copyZnm} getKkmsWithLabels={this.getKkmsWithLabels} {...this.props} />
				</React.Fragment>
				: <PreloaderFullScreen />
		);
	}
}

export default Companies;
