import React, { Component } from 'react';
// import Footer from '../footer/Footer';
import { OperationProvider } from "../../Store/OperationProvider";
import Expense from "./views/Expense";

class ExpenseWrapper extends Component {
    render() {
        return (
            <div>
                {/*<Navbar path="/new-shift" />*/}
                <OperationProvider operation='Expense'>
                    <Expense {...this.props} />
                </OperationProvider>
                {/* <Footer/> */}
            </div>
        )
    }
}

export default ExpenseWrapper;