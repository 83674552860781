import React, { useState } from 'react';

const CashboxManage = ({ changeCashboxName }) => {

  let kkm = (JSON.parse(localStorage.getItem('KKM')))
  const [name, setName] = useState(kkm.Name)
  
  return (
    <div>
      <div className="heading">
        <h1 className="text-center display-4">Управление кассой</h1>
      </div>
      <div className="pt-4">
        <div className="form-group row">
          <label htmlFor="IdOFD" className="col-sm-2 col-form-label">ЗНМ</label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="IdKKM" value={kkm.Znm} disabled={true} />
            <small className="form-text text-muted">
              ЗНМ — заводской номер машины
            </small>
          </div>
        </div>

        <div className="form-group row">
          <label htmlFor="Address" className="col-sm-2 col-form-label">Адрес</label>
          <div className="col-sm-6">
            <input type="text" className="form-control" id="Address" value={`${kkm.Address.Town.Name} ${kkm.Address.Street} - ${kkm.Address.House}, ${kkm.Address.Flat}`} disabled={true} />
          </div>
        </div>

        <div className="form-group row">
          <label htmlFor="Name" className="col-sm-2 col-form-label">Название</label>
          <div className="col-sm-6">
            <input type="text" className="form-control" name="Name" value={name} onChange={e => setName(e.target.value)} />
          </div>
        </div>

        <fieldset className="form-group">
          <div className="row">
            <legend className="col-form-label col-sm-2 pt-0">Место использования</legend>
            <div className="col-sm-6">
              <select className="form-control" name="PlaceUsed" id="PlaceUsed" value={kkm.PlaceUsed.Id} disabled={true}>
                <option value={kkm.PlaceUsed.Id} disabled={true} hidden={true}>{kkm.PlaceUsed.Name}</option>
              </select>
            </div>
          </div>
        </fieldset>

        <div className="form-group row">
          <label htmlFor="Lang" className="col-sm-2 col-form-label">Язык интерфейса</label>
          <div className="col-sm-3">
            <select className="form-control" id="Lang">
              <option>Русский</option>
            </select>
          </div>
        </div>
        <div className="pt-3">
          <button className="btn btn-primary btn-sm-block" onClick={() => changeCashboxName(name)}>Сохранить изменения</button>
        </div>
      </div>
    </div>
  )
}

export default CashboxManage;