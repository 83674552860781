import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import "../../Styles/layout/_opersLogModal.scss"

export default class Example extends React.Component {
    render() {
        let isElectron = require("is-electron");
        return (
            <Modal show={this.props.show}
                // centered
                onHide={this.props.onClose}
                className="operLogModal"
                style={{position: "fixed", top: "20px", minWidth: "300px"}}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {this.props.header}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    {
                         this.props.img && isElectron() &&
                         <Button variant="primary" onClick={this.props.electonShowCheck} className="d-none d-lg-block">
                             Распечатать
                        </Button>
					}
                    {
                        this.props.img && !isElectron() &&
                        <Button variant="primary" onClick={this.props.showCheck} className="d-none d-lg-block">
                            Распечатать
                        </Button>
                    }

					<Button variant="secondary" onClick={this.props.onClose}>
						Закрыть
                	</Button>
				</Modal.Footer>
                <Modal.Body>
                    <p className='text-center d-print-block'>
						{
							this.props.img &&
							<img style={{ width: "250px" }} id="reciept" src={'data:image/png;base64, ' + this.props.img} alt="Check" />
						}
					</p>
                </Modal.Body>
            </Modal>
        )
    }
}