import React, {useEffect, useState} from 'react';
import style from "../../styles/pages/cashbox/tabs/NewCashiers.module.scss";
import NewPagination from "../NewPagination";
import { ReactComponent as SearchLine } from '../../svg/search-line.svg'
import { ReactComponent as LockLine } from '../../svg/lock-line.svg'
import { ReactComponent as UnlockLine } from '../../svg/unlock-line.svg'
import { useTranslation } from "react-i18next";

const NewCashiers = ({ cashiers, toggleCashier, searchCashiers, fetchCashiers }) => {
  const { t } = useTranslation()

  const [searchKey, setSearchKey] = useState("")
  const [showCashiers, setShowCashiers] = useState(false)
  const [cashiersList, setCashiersList] = useState(cashiers.slice(0, 10))
  const [activePage, setActivePage] = useState(1)
  const [searchBtnClicked, setSearchBtnClicked] = useState(false)
  const [searchSuggestionClicked, setSearchSuggestionClicked] = useState(false)

  useEffect(() => {
    setCashiersList(cashiers.slice(10 * (activePage-1), 10 * activePage))
  }, [cashiers])

  useEffect(() => {
    if (!searchBtnClicked) {
      fetchCashiers()
    }
  }, [searchBtnClicked])

  useEffect(() => {
    if (searchSuggestionClicked) {
      doSearch()
    }
  }, [searchSuggestionClicked])

  const doSearch = () => {
    searchCashiers(searchKey)
    setSearchBtnClicked(true)
    setSearchSuggestionClicked(false)
  }

  const setPage = (page) => {
    setActivePage(page)
    setCashiersList(cashiers.slice(10 * (page-1), 10 * page))
  }


  const getCashierHeaderText = (cashiers) => {
    if (!cashiers.length) {
      return `0 ${ t('kassa_settings.cashiers_alt') }`
    }

    if (cashiers.length < 5) {
      return `${cashiers.length} ${ t('kassa_settings.cashier_alt_2') }`
    }

    if (cashiers.length === 1) {
      return `${cashiers.length} ${ t('kassa_settings.cashier_alt_2') }`
    }

    return `${cashiers.length} ${ t('kassa_settings.cashiers_alt') }`
  }

  const cashiersRenderList = cashiersList.map((cashier, idx) => {
    return (
      <div className={style['table__list']} key={idx}>
        <div className={style['table__item']}>
          <span>{cashier.User.Name}</span>
          <div className={style['table__hide-text']}></div>
        </div>
        { cashier.Active ?
          <div className={style['table__item']}>
            <button
              className={`${style.btn} ${style['btn--off']}`}
              onClick={() => toggleCashier(cashier.Id)}
            >
              <LockLine/>
              { t('kassa_settings.block') }
            </button>
          </div>
          :
          <div className={style['table__item']}>
            <button
              className={`${style.btn} ${style['btn--on']}`}
              onClick={() => toggleCashier(cashier.Id)}
            >
              <UnlockLine/>
              { t('kassa_settings.unblock') }
            </button>
          </div>
        }
      </div>
    )
  })

  const searchedCashiersList =
    <div className={`${style['form__select-list']}`}>
      {
        cashiers
          .filter(cashier => cashier.User.Name.toLowerCase().includes(searchKey.toLowerCase()))
          .map(cashier => {
            return (
              <div
                className={style['form__select-item']}
                key={cashier.User.Id}
                onClick={() => {
                  setSearchKey(cashier.User.Name)
                  setSearchSuggestionClicked(true)
                }}
              >
                {cashier.User.Name}
              </div>
            )
          })
      }
    </div>

  return (
    <div className={style.table}>
      <div className={`${style['table__top']} ${style['my-20']}`}>
        <div className={style['table__info']}>
          <h1 className={style['heading-primary']}>{getCashierHeaderText(cashiers)}</h1>
        </div>
        <div className={style['table__actions']}>
          <div className={`${style['input-wrapper']}`}>
            <div>
              <input
                className={style['input-wrapper__input']}
                type="text"
                placeholder={ t('kassa_settings.search') }
                value={searchKey}
                onChange={e => setSearchKey(e.target.value)}
                onFocus={() => {
                  if (searchBtnClicked) {
                    setShowCashiers(!showCashiers)
                    setSearchBtnClicked(false)
                  }
                }}
                onBlur={() => setTimeout(() => setShowCashiers(!showCashiers), 400)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.target.blur()
                    doSearch()
                    setSearchBtnClicked(true)
                    setShowCashiers(false)
                  }
                }}
              />
              <button
                className={style['input-wrapper__btn']}
                onClick={doSearch}
              >
                <SearchLine/>
              </button>
              { (showCashiers && searchKey.trim()) && searchedCashiersList }
            </div>
          </div>
        </div>
      </div>


        { cashiers.length ?
          <div className={`${style.card} ${style['card--white-nopadding']}`}>
            <div className={style['table__content']}>
              <div className={style['table__header']}>
                <div>{ t('kassa_settings.cashier') }</div>
                <div>{ t('kassa_settings.actions') }</div>
              </div>
              <div className={style['table__body']}>
                { cashiersRenderList }
                {
                  cashiersList.length ? (
                    <NewPagination
                      setPage={setPage}
                      list={cashiers}
                      activePage={activePage}
                    />
                  ) : null
                }
              </div>
            </div>
          </div>
          :
          <div className={`${style.card} ${style['card--white-nopadding']} ${style['card--transparent']}`}>
            <div className={`${style['text-center']} ${style['pa-40']}`}>
              <p className={`${style.paragraph} ${style['paragraph--sub']}`}>
                { t('other.cashiers_not_found') }
                <br/>
                { t('other.you_can_add_cashiers_in_kassa24_business_account_go_to_cash_register_settings').split('|')[0] }
                <br/>
                { t('other.you_can_add_cashiers_in_kassa24_business_account_go_to_cash_register_settings').split('|')[1] }
              </p>
            </div>
          </div>
        }

    </div>
  )
}

export default NewCashiers;
