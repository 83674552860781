import React, {useState, useEffect, useRef} from 'react';
import DropzoneModal from '../modules/DropzoneModal'
import style from "../../styles/pages/cashbox/tabs/NewArticles.module.scss";
import NewArticlesEditModal from "../../NewOperations/Modal/NewArticlesEditModal";
import '../styles/pagination.scss'
import NewPagination from "../NewPagination";
import { ReactComponent as EditLine } from '../../svg/edit-line.svg'
import { ReactComponent as SearchLine } from '../../svg/search-line.svg'
import { ReactComponent as ArrowDropDownFill } from '../../svg/arrow-drop-down-fill.svg'
import { ReactComponent as AddLine } from '../../svg/add-line.svg'
import { ReactComponent as FileExcel2Fill } from '../../svg/file-excel-2-fill.svg'
import { useTranslation } from "react-i18next";


const addZeroes = (num) => {
  const dec = num.toString().split('.')[1]
  const len = dec && dec.length > 2 ? dec.length : 2
  return Number(num).toFixed(len)
}

const NewArticles = ({ articles, showDropzone, setShowDropzone, fetchArticles, addArticle, edit, setEdit, sections, units, searchArticles, setSnackbar, setSnackText }) => {
  const { t } = useTranslation()

  const [currentArticle, setCurrentArticle] = useState({})
  const [searchKey, setSearchKey] = useState("")
  const [articlesList, setArticlesList] = useState(articles.slice(0, 10))
  const [showArticleModal, setShowArticleModal] = useState(false)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [showArticles, setShowArticles] = useState(false)
  const [activePage, setActivePage] = useState(1)
  const [searchBtnClicked, setSearchBtnClicked] = useState(false)
  const [searchSuggestionClicked, setSearchSuggestionClicked] = useState(false)
  const node = useRef()

  useEffect(() => {
    setArticlesList(articles.slice(10 * (activePage-1), 10 * activePage))
  }, [articles])

  useEffect(() => {
    document.addEventListener("click", handleDropdown);

    return () => {
      document.removeEventListener("click", handleDropdown);
    }
  }, [])

  useEffect(() => {
    if (!searchBtnClicked) {
      fetchArticles()
    }
  }, [searchBtnClicked])

  useEffect(() => {
    if (searchSuggestionClicked) {
      doSearch()
    }
  }, [searchSuggestionClicked])

  const handleDropdown = (e) => {
    if (node.current.contains(e.target)) {
      return
    }

    setIsDropdownOpen(false)
  }

  const openDropzone = () => {
    setShowDropzone(true)
  }

  const createArticle = () => {
    setCurrentArticle({})
    setShowArticleModal(true)
  }

  const editArticle = art => {
    setCurrentArticle(art)
    setShowArticleModal(true)
  }

  const onClose = () => {
    setShowArticleModal(false)
  }

  const doSearch = () => {
    searchArticles(searchKey)
    setSearchBtnClicked(true)
    setSearchSuggestionClicked(false)
  }

  const setPage = (page) => {
    setActivePage(page)
    setArticlesList(articles.slice(10 * (page-1), 10 * page))
  }

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen)
  }

  const articlesRenderList = articlesList.map(article => {
    return (
      <div className={style['table__list']} key={article.Id}>
        <div className={`${style['table__item']} ${style.barcode}`}><span>{article.Qr || t('other.not_specified')}</span></div>
        <div className={`${style['table__item']} ${style.name}`}>
          <div className={style['table__name-text']}>
            {article.Name}
            <div className={style['table__hide-text']}></div>
          </div>
          <div className={style['table__full-text']}>{article.Name}</div>
        </div>
        <div className={`${style['table__item']} ${style.unit}`}>
          <span>{ article.IdUnit ? units.find(unit => unit.Id === article.IdUnit).NameRU : t('other.not_specified')}</span>
          <div className={style['table__hide-text']}></div>
        </div>
        <div className={`${style['table__item']} ${style.price}`}><span>{addZeroes(article.Price)}</span></div>
        <div className={`${style['table__item']} ${style.section}`}>
          <span>{ sections.map(section => section.Id === article.IdSection && section.Name)}</span>
          <div className={style['table__hide-text']}></div>
        </div>
        <div className={`${style['table__item']} ${style.discount}`}><span>{addZeroes(article.Discount)}</span></div>
        <div className={`${style['table__item']} ${style.markup}`}><span>{addZeroes(article.Markup)}</span></div>
        <div className={`${style['table__item']} ${style.status}`}>
          { article.Active ?
              <div className={`${style['table__badge']} ${style['table__badge--success']}`}>{ t('kassa_settings.yes') }</div>
            :
              <div className={`${style['table__badge']} ${style['table__badge--danger']}`}>{ t('kassa_settings.no') }</div>
          }
        </div>
        <div className={`${style['table__item']} ${style.change}`}>
          <button
            className={`${style.btn} ${style['btn--edit']}`}
            onClick={() => editArticle(article)}
          >
          <EditLine/>
          </button>
        </div>
      </div>
    )
  })

  const articlesRenderMobileList = articlesList.map(article => {
    return (
      <div className={style['table__list']} key={article.Id}>

        <div className={style['table__item-wrapper']}>
          <div className={style['table__item']}>{ t('kassa_settings.barcode') }</div>
          <div className={style.dot}></div>
          <div className={`${style['table__item']}`}><span>{article.Qr || t('other.not_specified')}</span></div>
        </div>

        <div className={style['table__item-wrapper']}>
          <div className={style['table__item']}>{ t('kassa_settings.product_name') }</div>
          <div className={style.dot}></div>
          <div className={`${style['table__item']}`}>
            <div className={style['table__name-text']}>
              {article.Name}
              <div className={style['table__hide-text']}></div>
            </div>
            <div className={style['table__full-text']}>{article.Name}</div>
          </div>
        </div>

        <div className={style['table__item-wrapper']}>
          <div className={style['table__item']}>{ t('kassa_settings.unit_of_measurement') }</div>
          <div className={style.dot}></div>
          <div className={`${style['table__item']}`}>
            <span>{ article.IdUnit ? units.find(unit => unit.Id === article.IdUnit).NameRU : t('other.not_specified')}</span>
          </div>
        </div>

        <div className={style['table__item-wrapper']}>
          <div className={style['table__item']}>{ t('kassa_settings.price_alt') }</div>
          <div className={style.dot}></div>
          <div className={`${style['table__item']}`}><span>{addZeroes(article.Price)}</span></div>
        </div>

        <div className={style['table__item-wrapper']}>
          <div className={style['table__item']}>{ t('kassa_settings.section') }</div>
          <div className={style.dot}></div>
          <div className={`${style['table__item']}`}>
            <span>{ sections.map(section => section.Id === article.IdSection && section.Name)}</span>
            <div className={style['table__hide-text']}></div>
          </div>
        </div>

        <div className={style['table__item-wrapper']}>
          <div className={style['table__item']}>{ t('kassa_settings.discount_alt') }</div>
          <div className={style.dot}></div>
          <div className={`${style['table__item']}`}><span>{addZeroes(article.Discount)}</span></div>
        </div>

        <div className={style['table__item-wrapper']}>
          <div className={style['table__item']}>{ t('kassa_settings.markup_alt') }</div>
          <div className={style.dot}></div>
          <div className={`${style['table__item']}`}><span>{addZeroes(article.Markup)}</span></div>
        </div>

        <div className={style['table__item-wrapper']}>
          <div className={style['table__item']}>{ t('kassa_settings.in_sale') }</div>
          <div className={style.dot}></div>
          <div className={`${style['table__item']} ${style.status}`}>
            { article.Active ?
              <div className={`${style['table__badge']} ${style['table__badge--success']}`}>{ t('kassa_settings.yes') }</div>
              :
              <div className={`${style['table__badge']} ${style['table__badge--danger']}`}>{ t('kassa_settings.no') }</div>
            }
          </div>
        </div>

        <div className={`${style['table__item-wrapper']}`}>
          <button
            className={`${style.btn} ${style['btn--gray']}`}
            onClick={() => editArticle(article)}
          >
            <EditLine/>
            <span>{ t('kassa_settings.redact') }</span>
          </button>
        </div>
      </div>
    )
  })

  const searchedArticlesList =
      <div className={`${style['form__select-list']}`}>
        {
          articles
            .filter(article => article.Name.toLowerCase().includes(searchKey.toLowerCase()))
            .map(article => {
              return (
                <div
                  className={style['form__select-item']}
                  key={article.Id}
                  onClick={() => {
                    setSearchKey(article.Name)
                    setSearchSuggestionClicked(true)
                  }}
                >
                  {article.Name}
                </div>
              )
            })
        }
      </div>


  const getArticleHeaderText = (articles) => {
    if (!articles.length) {
      return `0 ${ t('kassa_settings.items_alt') }`
    }

    if (articles.length < 5) {
      return `${articles.length} ${ t('kassa_settings.item_alt') }`
    }

    if (articles.length === 1) {
      return `${articles.length} ${ t('kassa_settings.item') }`
    }

    return `${articles.length} ${ t('kassa_settings.items_alt') }`
  }

  return (
    <React.Fragment>
      <div className={style.table}>
        <div className={`${style['table__top']} ${style['my-20']}`}>
          <div className={style['table__info']}>
            <h1 className={style['heading-primary']}>{getArticleHeaderText(articles)}</h1>
          </div>
          <div className={style['table__actions']}>
            <div className={`${style['input-wrapper']} ${style['mr-20']}`}>
              <input
                className={style['input-wrapper__input']}
                type="text"
                placeholder={ t('kassa_settings.search') }
                value={searchKey}
                onChange={e => setSearchKey(e.target.value)}
                onFocus={() => {
                  if (searchBtnClicked) {
                    setSearchBtnClicked(false)
                  }
                  setShowArticles(!showArticles)
                }}
                onBlur={() => setTimeout(() => setShowArticles(!showArticles), 400)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.target.blur()
                    doSearch()
                    setSearchBtnClicked(true)
                    setShowArticles(false)
                  }
                }}
              />
              <button
                className={style['input-wrapper__btn']}
                onClick={doSearch}
              >
                <SearchLine/>
              </button>
              { (showArticles && searchKey.trim()) && searchedArticlesList }
            </div>
            <div className={style['btn-dropdown']} ref={node}>
              <div className={style['btn-dropdown__btn-box']}>
                <button
                  className={`${style['btn-dropdown__btn']}
                  ${style['btn-dropdown__btn--lg']}`}
                  onClick={() => createArticle()}
                >
                  + { t('kassa_settings.add_item') }
                </button>
                <button
                  className={`${style['btn-dropdown__btn']} ${style['btn-dropdown__btn--sm']}`}
                  onClick={() => toggleDropdown()}
                >
                  <ArrowDropDownFill/>
                </button>
              </div>
              { isDropdownOpen &&
              <div className={style['btn-dropdown__list']}>
                <button
                  className={`${style.btn} ${style['btn--secondary']} ${style['btn--block']}`}
                  onClick={() => createArticle()}
                >
                  <AddLine/>
                  { t('kassa_settings.add_item') }
                </button>
                <button
                  className={`${style.btn} ${style['btn--secondary']} ${style['btn--block']} ${style['mt-4']}`}
                  onClick={() => openDropzone(true)}
                >
                  <FileExcel2Fill/>
                  { t('kassa_settings.import_items') }
                </button>
              </div>
              }

            </div>
          </div>
        </div>


          { articles.length ?
            <div className={`${style.card} ${style['card--white-nopadding']}`}>
              <div className={style['table__content']}>
                <div className={style['table__header']}>
                  <div className={style.barcode}>{ t('kassa_settings.barcode') }</div>
                  <div className={style.name}>{ t('kassa_settings.product_name') }</div>
                  <div className={style.unit}>{ t('kassa_settings.unit_of_measurement') }</div>
                  <div className={style.price}>{ t('kassa_settings.price_alt') }</div>
                  <div className={style.section}>{ t('kassa_settings.section') }</div>
                  <div className={style.discount}>{ t('kassa_settings.discount_alt') }</div>
                  <div className={style.markup}>{ t('kassa_settings.markup_alt') }</div>
                  <div className={style.status}>{ t('kassa_settings.in_sale') }</div>
                  <div className={style.change}>{ t('kassa_settings.edit') }</div>
                </div>
                <div className={style['table__body--desktop']}>
                  { articlesRenderList }
                  {
                    articlesList.length ? (
                      <NewPagination
                        setPage={setPage}
                        list={articles}
                        activePage={activePage}
                      />
                    ) : null
                  }
                </div>

                <div className={style['table__body--mobile']}>
                  { articlesRenderMobileList }
                  {
                    articlesList.length ? (
                      <NewPagination
                        setPage={setPage}
                        list={articles}
                        activePage={activePage}
                      />
                    ) : null
                  }
                </div>
              </div>
            </div>
            :
            <div className={`${style.card} ${style['card--white-nopadding']} ${style['card--transparent']}`}>
              <div className={`${style['text-center']} ${style['pa-40']}`}>
                <p className={`${style.paragraph} ${style['paragraph--sub']}`}>
                  { t('other.no_items_found_add_by_clicking_add_item').split('|')[0] }
                  <br/>
                  { t('other.no_items_found_add_by_clicking_add_item').split('|')[1] }
                </p>
              </div>
            </div>
          }
      </div>
      {
        showDropzone ? (
          <div className="articles-modal-wrapper">
            <DropzoneModal
              setShowDropzone={setShowDropzone}
              fetchArticles={() => fetchArticles()}
              setSnackbar={setSnackbar}
              setSnackText={setSnackText}
            />
          </div>
        ) : null
      }
      {showArticleModal && (
          <NewArticlesEditModal
            onClose={() => onClose()}
            addArticle={(article) => addArticle(article)}
            sections={sections}
            units={units}
            currentArticle={currentArticle}
          />
      )}
    </React.Fragment>
  )
}

export default NewArticles;
