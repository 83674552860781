import axios from 'axios';
import { BASE_URL, headers } from '../Store/snippets';

const KassaClient = axios.create({
  baseURL: BASE_URL,
  timeout: 30000,
  headers
});

async function queryCashBoxes(query, params) {
	try {
		const cashBoxes = await KassaClient.get(`/kkms?search=${query}`, params);
		return cashBoxes.data.Data.Kkms
	} catch(error) {
		console.log(error)
	}
}

const downloadXLSFile = async (kkm_id, params) => {
	const AUTH_TOKEN = localStorage.getItem('tokken');
    const header= {
			'Authorization': `Bearer ${AUTH_TOKEN}`, 
			'Content-Type': 'blob'
	};
    const config = {
			method: 'GET', 
			url: `${BASE_URL}/kkms/${kkm_id}/docs/xlsx/bytime?datefrom=${params.date_from}&dateto=${params.date_to}`, 
			responseType: 'arraybuffer', 
			headers: header
		};
    try {
        const response = await axios(config);
		return response;
    } catch (error) {
        console.log(error);
    }
}
export { queryCashBoxes, downloadXLSFile}
