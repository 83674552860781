import React from "react";

const Loader = () => {
	return (
		<div className="text-primary" style={
			{
				width: '100%',
				height: '50vh',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center'
			}
		}>
			<div>
				<div className="spinner-border mr-3" role="status">
					<span className="sr-only">Loading...</span>
				</div>
				Загрузка...
			</div>
		</div>
	)
}

export default Loader
