/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import { getUser, getKkm } from '../../Store/snippets';

import MobileNavLayout from './MobileNavLayout';

class MobileNav extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isActive: false,
			user: {},
			kkm: {},
			isOpen: false
		};
	}

	toggleMenu = (e) => {
		// let checkbox = document.getElementById('mob_menu');
		// console.log(checkbox.checked);
		// checkbox.checked = false
		// console.log(checkbox.checked);

		// console.log(document.getElementById('mob_menu').checked);
		// console.log(document.getElementById('mob_menu').checked);

		// console.log(this.menuCheck);
		// this.menuCheck.current.checked = false;
		this.setState({
			...this.state,
			isOpen: !this.state.isOpen
		})
	}

	componentDidUpdate(prevProps) {
		if (prevProps.isActive !== this.props.isActive) {
			this.setState({
				isActive: this.props.isActive
			})
		}
	}

	async componentDidMount() {
		const user = getUser(this.props.history);
		const kkm = getKkm(this.props.history);
		let isShiftOpen = false;
		if (user.Lock === true && kkm.Lock === true && user.IdShift == kkm.IdShift && this.props.isActive === true) isShiftOpen = true;
		if (this.props.isActive && isShiftOpen) {
			await this.setState({
				isActive: this.props.isActive,
				user,
				kkm
			})
		} else {
			await this.setState({
				user,
				kkm
			})
		}
	}
	render() {
		return <MobileNavLayout {...this.state} toggleMenu={this.toggleMenu} />
	}
}

export default MobileNav;