import firebase from 'firebase';

const config = {
    apiKey: "AIzaSyB1eOrl1QW673BTD6hLSofrj8xUSPe9GME",
    authDomain: "fiscal24-registration.firebaseapp.com",
    databaseURL: "https://fiscal24-registration.firebaseio.com",
    projectId: "fiscal24-registration",
    storageBucket: "fiscal24-registration.appspot.com",
    messagingSenderId: "971316937613",
    appId: "1:971316937613:web:55ecbf39f5be30766773c4",
    measurementId: "G-50DDQG1BX0"
};

const fire = firebase.initializeApp(config);

export default fire;