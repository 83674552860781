import React, { Component } from 'react';
import PreCheckPosition from "./precheckPosition/PreCheckPosition";
import Total from "../total/Total";
// import style from "./Prechek.module.css"
// import Card from "../../../selectCashbox/SelectCashbox";
import { Store } from "../../../../Store/OperationProvider"

export default class Precheck extends Component {
    render() {
        return (
            <Store.Consumer>
                {
                    context => (
                        <React.Fragment>
                            <h4 className="d-flex justify-content-between align-items-center mb-3">
                                <span className="text-muted">Предчек</span>
                                {context.PreCheck.length === 0 && <span className="badge badge-primary badge-pill">{context.PreCheck.length} позиций</span>}
                                {context.PreCheck.length === 1 && <span className="badge badge-primary badge-pill">{context.PreCheck.length} позиция</span>}
                                {context.PreCheck.length > 1 && context.PreCheck.length < 5 && <span className="badge badge-primary badge-pill">{context.PreCheck.length} позиции</span>}
                                {context.PreCheck.length > 4 && <span className="badge badge-primary badge-pill">{context.PreCheck.length} позиций</span>}
                            </h4>

                            <ul className="list-group mb-3 text-monospace">
                                {context.PreCheck.map((check, index) =>

                                    <PreCheckPosition
                                        key={index}
                                        index={index}
                                        Name={check.Name}
                                        Price={check.Price}
                                        Markup={check.Markup}
                                        MarkupPercent={check.MarkupPercent}
                                        Discount={check.Discount}
                                        DiscountPercent={check.DiscountPercent}
                                        Qty={check.Qty}
                                        Section={check.Section}
                                        Unit={check.Unit}
                                        Storno={check.Storno}
                                        Total={check.Total}
                                        Nds={check.Nds}
                                    />
                                )}
                                <Total
                                    cash={context.Income.Cash}
                                    nonCash={context.Income.NonCash}
                                    change={context.Change}
                                    final={context.Total}
                                />
                            </ul>
                        </React.Fragment>
                    )
                }
            </Store.Consumer>

        )
    }
}