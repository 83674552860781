import React from 'react'
import style from "../styles/pages/cashbox/NewSettingsTabs.module.scss";
const NewReportTab = ({ navList, activeNav, setActiveNav, setStep, kkm, user }) => {

	const clickToTab = (id) => {
		if ((!user.Lock || kkm.IdShift !== user.IdShift) && id === 2) {
			return
		}
		setActiveNav(id)
		setStep(id)
		localStorage.setItem('reportStep', JSON.stringify(id))
	}

	return (
		<React.Fragment>
			<div className={`${style['navs-wrapper']} ${style['mt-20']}`}>
				<div className={style['navs']}>
					{
						navList.map((nav, index) => (
							<div
								className={`${style.nav} ${activeNav === nav.id ? style.active : ''} ${(!user.Lock || kkm.IdShift !== user.IdShift) && (nav.id === 2) ? style.disabled : ''}`}
								key={nav.id}
								onClick={() => clickToTab(nav.id)}
							>
								<a alt="" href="#!" onClick={e => e.preventDefault()} title={nav.name}>
									{nav.name}
								</a>
							</div>
						))
					}
				</div>
			</div>
		</React.Fragment>
	)
}

export default NewReportTab
