import React, { Component } from 'react';
// import Navbar from '../navbar/Navbar';
// import Footer from '../footer/Footer';
import PurchaseForm from './views/PurchaseForm';
import { OperationProvider } from "../../Store/OperationProvider";


class Purchase extends Component {
    render() {
        return (
            <div>
                {/*<Navbar path="/new-shift" />*/}
                <OperationProvider operation='Purchase'>
                    <PurchaseForm {...this.props} />
                </OperationProvider>
                {/* <Footer /> */}
            </div>
        )
    }
}

export default Purchase;