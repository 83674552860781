exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".modal_overlay__3ilPv {\n  position: fixed;\n  top: 0;\n  left: 0;\n  height: 100vh;\n  width: 100vw;\n  background: rgba(0, 0, 0, 0.6);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  z-index: 100; }\n\n.modal_wrapper__WmksK {\n  max-width: 369px;\n  width: 100%;\n  background-color: #FFFFFF;\n  border-radius: 8px;\n  padding: 20px; }\n\n.modal_top__189p6 {\n  display: flex;\n  justify-content: center;\n  align-items: center; }\n\n.modal_title__1KcsL {\n  font-weight: 600;\n  font-size: 20px;\n  line-height: 20px;\n  color: #212529; }\n\n.modal_primaryBtn__21r0_ {\n  background-color: #00ADEE;\n  color: #FFFFFF; }\n\n.modal_secondaryBtn__26T9T {\n  background: #F5F5F5;\n  border: 1px solid #D7D7D7;\n  color: #575757; }\n\n.modal_paragraph__2Q2_A {\n  font-size: 17px;\n  line-height: 140%;\n  color: #343434;\n  margin: 16px 0 20px 0;\n  font-weight: 600;\n  text-align: center; }\n\n.modal_btn__3XIs3 {\n  border: 1px solid transparent;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  height: 50px;\n  border-radius: 8px;\n  padding: 16px;\n  cursor: pointer;\n  margin-bottom: 10px; }\n  .modal_btn__3XIs3 span {\n    text-decoration: none;\n    font-weight: 500;\n    font-size: 16px;\n    line-height: 16px; }\n  .modal_btn_mobileText__3IFNw {\n    display: none; }\n\n@media only screen and (max-width: 415px) {\n  .modal_overlay__3ilPv {\n    align-items: flex-end; }\n  .modal_wrapper__WmksK {\n    border-radius: 0;\n    padding: 20px 16px 24px 16px; }\n  .modal_title__1KcsL {\n    font-size: 18px;\n    line-height: 16px; }\n  .modal_paragraph__2Q2_A {\n    margin: 20px 0; }\n  .modal_btn__3XIs3 span {\n    font-weight: 600;\n    font-size: 16px;\n    line-height: 19px; } }\n", ""]);

// exports
exports.locals = {
	"overlay": "modal_overlay__3ilPv",
	"wrapper": "modal_wrapper__WmksK",
	"top": "modal_top__189p6",
	"title": "modal_title__1KcsL",
	"primaryBtn": "modal_primaryBtn__21r0_",
	"secondaryBtn": "modal_secondaryBtn__26T9T",
	"paragraph": "modal_paragraph__2Q2_A",
	"btn": "modal_btn__3XIs3",
	"btn_mobileText": "modal_btn_mobileText__3IFNw"
};