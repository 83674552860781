exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".loginform_title__3lW0L {\n  font-size: 24px;\n  font-weight: 700;\n  text-align: center;\n  color: #575757;\n  margin-bottom: 30px; }\n\n.loginform_container_input__ysCH1, .loginform_container_label__2FD1M {\n  position: relative;\n  width: 100%;\n  display: flex;\n  align-items: center; }\n\n.loginform_container_label__2FD1M {\n  justify-content: space-between; }\n  .loginform_container_label__2FD1M a {\n    font-style: normal;\n    font-weight: normal;\n    font-size: 13px;\n    line-height: 16px;\n    display: flex;\n    align-items: center;\n    color: #00ADEE;\n    text-decoration: none; }\n\n.loginform_container_input__ysCH1 {\n  margin-bottom: 8px; }\n  .loginform_container_input__ysCH1 svg {\n    top: 50%;\n    transform: translateY(-50%);\n    right: 16px;\n    position: absolute;\n    cursor: pointer; }\n\n.loginform_link__eRjRM {\n  font-style: normal;\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 19px;\n  text-align: center;\n  color: #8E8E8E;\n  text-decoration: none; }\n\n@media (max-width: 1100px) {\n  .loginform_title__3lW0L {\n    font-size: 20px; } }\n", ""]);

// exports
exports.locals = {
	"title": "loginform_title__3lW0L",
	"container_input": "loginform_container_input__ysCH1",
	"container_label": "loginform_container_label__2FD1M",
	"link": "loginform_link__eRjRM"
};