/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import style from './Sale.module.css';
import Precheck from './precheck/Precheck';
import MobileNav from '../../MobileNav/MobileNav';
import Summ from './summ/Summ';
import AddPositionContainer from "./addPosition/AddPositionContainer";
import Modal from '../../Modal/operationModal';
import ModalCheck from '../../Modal/operationModalCheck';
import { Store } from "../../../Store/OperationProvider";
import Spinner from '../../Common/Spinner';
import { ReactComponent as QRButton } from "../../Svg/qr-code.svg";
import axios from 'axios';
import {getHeaders} from '../../../Store/snippets'
import { Link } from 'react-router-dom';
import {setAppVersion} from "../../../Store/appVersion";
import HeaderVersion from "../../NewDesign/HeaderVersion/HeaderVersion";
import { FISCAL_API_DEV_URL, FISCAL_API_PROD_URL } from '../../../constants/app';

const urlParams = new URLSearchParams(window.location.search);
const linkUid = urlParams.get('uid');

const protocol = window.location.protocol;
const hostName = window.location.host;
let BASE_URL = FISCAL_API_DEV_URL;
if (process.env.NODE_ENV === 'production') {
    BASE_URL = `${protocol}//${hostName}`;

    if (window.app && !!window.cordova) {
        BASE_URL = FISCAL_API_PROD_URL;
    }
}

let headers;

class Sale extends Component {
    async componentDidMount() {
        headers = getHeaders(this.props.history);
        const kkmid = window.location.pathname.split("/")[3];
        linkUid && localStorage.setItem(`Uid_${kkmid}`, linkUid);

        const kkmResponse = await axios.get(`${BASE_URL}/kkms/${kkmid}`, { headers });
        const kkm = kkmResponse.data.Data.Kkm;
        localStorage.setItem(`KKM`, JSON.stringify(kkm));

        const balancesResponse = await axios.get(`${BASE_URL}/kkms/${kkmid}/balances`, { headers });
        const balances = await balancesResponse.data.Data.Balances;
        localStorage.setItem(`BALANCES`, JSON.stringify(balances));
    }

    goNew = () => {
        this.props.history.push('sale_new')
        setAppVersion(true)
    }

    render() {
        let kkmid = JSON.parse(localStorage.getItem("KKM"))

        return (
            <Store.Consumer>
                {
                    context => (
                        <React.Fragment>
                            {
                                window.matchMedia("(min-width: 1100px)").matches ?
                                    <HeaderVersion isActive={true} balances={context.Balances} {...this.props} />
                                    :
                                    <MobileNav isActive={true} />
                            }

                            <div className={`${style.NewSale}`}>
                                <div className="container pt-4 d-print-none">
                                    <div className='d-flex justify-content-between'>
                                        <Link className="btn btn-secondary btn-lg btn-sm-block mb-3" to={`/www/kkms/${kkmid.Id}`} style={{paddingLeft: "11px", paddingRight: "11px"}}>
                                            ← Вернуться в кассу
                                        </Link>
                                    </div>
                                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                                        <h1 className="h2">Новая продажа</h1>
                                    </div>

                                    {
                                        context.GlobalError ?
                                            <div className="alert alert-danger" style={{ margin: 'auto', maxWidth: '800px' }}>
                                                <h5>{context.GlobalErrorTitle}</h5>
                                                <p>{context.GlobalError}</p>
                                            </div>
                                            :

                                            <div className="row">

                                                <div className="col-md-7 order-md-1 pr-3">

                                                    <AddPositionContainer />
                                                    <hr className="mb-4 mt-0 mt-md-4" />
                                                    {
                                                        context.PreCheck.length > 0 &&
                                                        <React.Fragment>
                                                            <Summ />
                                                            <hr className="mb-4" />
                                                        </React.Fragment>
                                                    }
                                                    {
                                                        !context.IsAvailableForSale &&
                                                        <div className="mb-4 mt-1 alert alert-danger">
                                                            {
                                                                context.Total === 0 &&
                                                                <span>Для проведения операции нужно заполнить минимум 1 позицию</span>
                                                            }
                                                            {
                                                                context.Lack > 0 &&
                                                                <span>Для проведения операции не хватает {context.Lack.toFixed(2)} тенге</span>
                                                            }
                                                        </div>

                                                    }
                                                    <div className="row">
                                                        <div className="col-md-7">
                                                            <button
                                                                className="btn btn-success btn-lg btn-block mb-3 mb-md-0"
                                                                onClick={() => context.makeSale('sales')}
                                                                // disabled={!context.IsSaleButtonEnabled}
                                                                disabled={context.PreCheck.length === 0}
                                                            >
                                                                Оформить чек
                                                                {
                                                                    !context.IsSaleButtonEnabled &&
                                                                    <Spinner />
                                                                }
                                                            </button>
                                                        </div>
                                                        <div className="col-md-5">
                                                            {context.PreCheck.length > 0 &&
                                                                <button
                                                                    className="btn btn-outline-secondary btn-lg btn-block mb-3 mb-md-0 QR_button"
                                                                    onClick={() => context.showQRPrecheck()}
                                                                >
                                                                    <QRButton style={{marginRight: "13px"}}/>
                                                                    Оплатить через Plus24
                                                                </button>
                                                            }
                                                        </div>
                                                    </div>


                                                </div>

                                                <div className="col-md-5 order-md-2 mb-5">
                                                    <Precheck />
                                                </div>
                                            </div>
                                    }
                                </div>

                                <Modal onClose={context.closeModal} onCheck={context.showCheck} onElectronCheck={context.electronShowCheck} show={context.Modal.Status} header={context.Modal.Header} body={context.Modal.Body} img={context.CheckImg} docId={context.CheckId} docLink={context.CheckLink} share={context.shareSocial} />
                                <ModalCheck onClose={context.closeModal} show={context.ModalCheck.Status} body={context.Modal.Body} img={context.CheckImg} />
                            </div>
                        </React.Fragment>
                    )
                }
            </Store.Consumer>
        )
    }
}

export default Sale;
