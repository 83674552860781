import React, { Component } from 'react';
// import Check from './Check';
import { BASE_URL } from '../../Store/snippets';
class CheckPage extends Component { 
    state = {
        img: ''
    }

    componentDidMount() {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        if(params.id) {
            fetch(`${BASE_URL}/www/receiptbyid/${params.id}`)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                this.setState({
                    img: data.Data.Reciept
                })
            })
        }
    }

    render() {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        if(params.id) {
            if(this.state.img !== 'Check API Error: 404') {
                return (
                    <div className="check-page_wrapper" style={{display: "flex", justifyContent: "center"}}>
                        <img style={{ height: "90vh" }} id="receipt" src={'data:image/png;base64, ' + this.state.img} alt="Check"  />
                    </div>
                )
            }else{
                return (
                    <div className="check-page_wrapper empty" style={{display: "flex", justifyContent: "center", marginTop: "100px"}}>
                        Срок годности чека истек
                    </div>
                )
            }
        }else{
            return (
                <div className="check-page_wrapper empty" style={{display: "flex", justifyContent: "center", marginTop: "100px"}}>
                    Неверная ссылка
                </div>
            )
        }
    }
}

export default CheckPage;