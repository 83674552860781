exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Footer_footer__EE4og {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: 0;\n  height: 115px;\n  margin-top: auto; }\n  .Footer_footer__EE4og.Footer_new__1khdJ {\n    background: #F5F5F5; }\n  .Footer_footer__EE4og p {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    padding: 0;\n    margin: 0;\n    font-style: normal;\n    font-weight: normal;\n    font-size: 14px;\n    line-height: 140%;\n    letter-spacing: -0.2px;\n    color: #8E8E8E; }\n    .Footer_footer__EE4og p span {\n      margin-left: 8px; }\n\n@media only screen and (max-width: 415px) {\n  .Footer_footer__EE4og {\n    display: none; } }\n", ""]);

// exports
exports.locals = {
	"footer": "Footer_footer__EE4og",
	"new": "Footer_new__1khdJ"
};