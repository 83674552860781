import './styles/pagination.scss'
import Pagination from "react-js-pagination";
import React, {useEffect, useState} from "react";
import {ReactComponent as ArrowLeftLine} from "../svg/arrow-left-s-line.svg";
import {ReactComponent as ArrowRightSLine} from "../svg/arrow-right-s-line.svg";
import { useTranslation } from "react-i18next";

const NewPagination = ({list, setPage, activePage}) => {
	const [enteredPage, setEnteredPage] = useState(1)
	const [pageCount, setPageCount] = useState(list.length >= 10 ? `${(activePage * 10) - 10}-${activePage * 10} из ${list.length}` : `0-${list.length} из ${list.length}`)

	const { t } = useTranslation()

	useEffect(() => {
		setPageCount(list.length >= 10 ? `${(activePage * 10) - 10}-${activePage * 10} из ${list.length}` : `0-${list.length} из ${list.length}`)
	}, [list])

	const setPagination = (page) => {
		setPage(page)
		if (page * 10 > list.length) {
			setPageCount(`${(page * 10) - 10}-${list.length} из ${list.length}`)
			return
		}
		setPageCount(`${(page * 10) - 10}-${page * 10} из ${list.length}`)
	}

	return (
		<div className="page-bottom">
			<p className="page-bottom__title">{pageCount}</p>

			<div className="page-bottom__action">
				<p className="page-bottom__title">{ t('other.go_to_page') }</p>
				<div className="page-bottom__action-group">
					<div className="page-bottom__search-box">
						<input
							className="page-bottom__input"
							placeholder="1"
							onChange={e => setEnteredPage(+e.target.value)}
						/>
						<button
							className="page-bottom__btn"
							onClick={() => setPagination(enteredPage)}
						>
							›
						</button>
					</div>

					<div className="page-bottom__divider"></div>
					<Pagination
						activePage={activePage}
						itemsCountPerPage={10}
						totalItemsCount={list.length}
						onChange={(p) => setPagination(p)}
						hideFirstLastPages={true}
						prevPageText={<span><ArrowLeftLine /></span>}
						nextPageText={<span><ArrowRightSLine /></span>}
						itemClass="pagination__list-item"
						itemClassPrev="pagination__list-item--prev"
						itemClassNext="pagination__list-item--next"
					/>
				</div>
			</div>
		</div>
	)
}

export default NewPagination
