import React from 'react';
import { ReactComponent as PrintSvg } from '../Svg/printer.svg';

function PrintButtonLayout({ print }) {
	return (
		<button className="btn btn-primary d-none d-lg-inline-block" onClick={print}>
			Распечатать
		<PrintSvg width='2rem' className="ml-1 d-none d-sm-inline-block" style={{ 'fill': 'white' }} />
		</button>
	)
}

export default PrintButtonLayout;