
import React, { Component } from 'react';
// import Navbar from '../navbar/Navbar';
// import Footer from '../footer/Footer';
import PurchaseRefundForm from './views/PurchaseRefundForm';
import { OperationProvider } from "../../Store/OperationProvider";


class Refund extends Component {
    render() {
        return (
            <div>
                {/*<Navbar path="/new-shift" />*/}
                <OperationProvider operation='Purchase_Refund'>
                    <PurchaseRefundForm {...this.props} />
                </OperationProvider>
                {/* <Footer /> */}
            </div>
        )
    }
}

export default Refund;