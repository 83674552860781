exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".operation_main__3maGf, .operation_mainShort__18WA- {\n  background-color: #F5F5F5;\n  min-height: calc(100vh - 185px); }\n\n.operation_container__yZpKJ {\n  max-width: 1120px;\n  width: 100%;\n  margin-top: 20px;\n  padding-left: 15px;\n  padding-right: 15px;\n  margin-right: auto;\n  margin-left: auto;\n  overflow: hidden;\n  font-family: 'Inter', sans-serif; }\n\n@media only screen and (max-width: 415px) {\n  .operation_main__3maGf {\n    height: 100vh; }\n  .operation_mainShort__18WA- {\n    height: calc(100vh - 100px); } }\n", ""]);

// exports
exports.locals = {
	"main": "operation_main__3maGf",
	"mainShort": "operation_mainShort__18WA-",
	"container": "operation_container__yZpKJ"
};