exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".backToBtn_backTo__3j-3Z {\n  border-radius: 8px;\n  border: 1px solid #D7D7D7;\n  height: 44px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 0 13px 0 15px; }\n  .backToBtn_backTo__3j-3Z p {\n    color: #575757;\n    font-size: 14px;\n    line-height: 17px;\n    margin: 0;\n    margin-left: 6px; }\n\n.backToBtn_mobileBackTo__vQyZr {\n  display: none; }\n\n@media only screen and (max-width: 415px) {\n  .backToBtn_backTo__3j-3Z {\n    display: none; }\n  .backToBtn_mobileBackTo__vQyZr {\n    display: block; } }\n", ""]);

// exports
exports.locals = {
	"backTo": "backToBtn_backTo__3j-3Z",
	"mobileBackTo": "backToBtn_mobileBackTo__vQyZr"
};