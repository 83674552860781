/* eslint-disable eqeqeq */
import React from 'react';

const SettingsTabs = ({ navsList, activeNav, setActiveNav, setStep }) => {

  const clickToTab = (id) => {
    setActiveNav(id)
    setStep(id)
  }

  return (
    <React.Fragment>
        <div className="navs-wrapper">
          {
            navsList.map(nav => (
              <div className="nav" key={nav.id} onClick={() => clickToTab(nav.id)}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, no-script-url */}
                <a href="javascript:void(0);" className={`${activeNav == nav.id ? 'active' : ''}`} title={nav.name}>
                  {nav.name}
                </a>
              </div>
            ))
          }
        </div>
    </React.Fragment>
  )
}

export default SettingsTabs;