import React, { Component } from 'react';
import axios from 'axios';
import { BASE_URL, getHeaders, formatDate, getUser, getKkm } from '../../Store/snippets';
import ReportSectionsLayout from './ReportSectionsLayout';
import PreloaderFullScreen from '../Preloader/Preloader';
import { getError } from '../../Store/snippets';
let headers;

class ReportSections extends Component {
	constructor(props) {
		super(props);
		this.state = {
			report: {},
			kkmte: '',
			mounted: false,
			error: {
				status: false,
				title: '',
				text: '',
			}
		};
	}

	async componentDidMount() {
		headers = getHeaders(this.props.history);
		const currentDate = new Date();
		const idKkm = JSON.parse(localStorage.getItem('CURRENT_KKM_ID'));
		const date = formatDate(currentDate);
		const user = getUser(this.props.history);
		const kkm = getKkm(this.props.history);

		axios.get(`${BASE_URL}/shifts/${kkm.IdShift}/sections/reports`, { headers }).then(resp => {
			const sections = resp.data.Data.Sections;
			Object.keys(sections).map(section => {
				return Object.keys(sections[section])
					.filter(key => key !== 'Name')
					.map(key => {
						// console.log(sections[section][key], typeof sections[section][key]);
						if (typeof sections[section][key] !== 'string') {
							return sections[section][key] = 0;
						} else {
							return sections[section][key] = Number(sections[section][key]).toFixed(2)
					}
				})
			})
			this.setState({
				...this.state,
				idKkm,
				report: sections,
				kkm,
				user,
				date,
				mounted: true
			})
		}).catch(err => {
			const resp = getError(err, this.props.history);
			this.setState({
				...this.state,
				user,
				idKkm,
				error: {
					...this.state.error,
					status: true,
					title: `Ошибка: ${resp.Status}`,
					text: resp.Message
				},
				mounted: true
			})
		});
	}

	render() {
		return (
			this.state.mounted ? <ReportSectionsLayout {...this.state} BASE_URL={BASE_URL} {...this.props} />
				: <PreloaderFullScreen />
		)
	}
}

export default ReportSections;