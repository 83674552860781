/* eslint-disable eqeqeq */
import React, { Component } from 'react'
import style from './Sale.module.css'
import AddPosition from './addPosition/AddPosition'
import Precheck from './precheck/Precheck'

import { Store } from '../../../Store/OperationProvider'
import Modal from '../../Modal/operationModal'
import ModalCheck from '../../Modal/operationModalCheck'
import Spinner from '../../Common/Spinner'
import MobileNav from '../../MobileNav/MobileNav'
import { Link } from 'react-router-dom'
import HeaderVersion from '../../NewDesign/HeaderVersion/HeaderVersion'

class PurchaseForm extends Component {
	render() {
		let kkmid = JSON.parse(localStorage.getItem('KKM'))

		return (
			<Store.Consumer>
				{(context) => (
					<React.Fragment>
						{window.matchMedia('(min-width: 1100px)').matches ? (
							<HeaderVersion isActive={true} balances={context.Balances} {...this.props} />
						) : (
							<MobileNav isActive={true} />
						)}
						<div className={`${style.NewSale}`}>
							<div className='container pt-4 d-print-none'>
								<div className='d-flex justify-content-between'>
									<Link
										class='btn btn-secondary btn-lg btn-sm-block mb-3'
										to={`/www/kkms/${kkmid.Id}`}
										style={{ paddingLeft: '11px', paddingRight: '11px' }}
									>
										← Вернуться в кассу
									</Link>
								</div>
								<div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom'>
									<h1 className='h2'>Покупка товара</h1>
								</div>
								{context.GlobalError ? (
									<div className='alert alert-danger' style={{ margin: 'auto', maxWidth: '800px' }}>
										<h5>{context.GlobalErrorTitle}</h5>
										<p>{context.GlobalError}</p>
									</div>
								) : (
									<div className='row'>
										<div className='col-md-7 order-md-1 pr-3'>
											<AddPosition />
											<hr className='mb-5 mt-0 mt-md-5' />
											{context.OperationErrorText && (
												<div className='mb-4 mt-1 alert alert-danger'>
													{context.OperationErrorText}
												</div>
											)}

											<div className='row'>
												<div className='col-md-7'>
													<button
														className='btn btn-success btn-lg btn-block mb-3 mb-md-0'
														onClick={() => context.makePurchase('purchases')}
														disabled={!context.IsSaleButtonEnabled}
													>
														Оформить чек
														{!context.IsSaleButtonEnabled && <Spinner />}
													</button>
												</div>
												<div className='col-md-5'>
													<button
														className='btn btn-outline-secondary btn-lg btn-block mb-3 mb-md-0'
														onClick={() => context.resetPreCheck()}
													>
														Сбросить <span className='d-md-none d-lg-inline'>предчек</span>
													</button>
												</div>
											</div>
										</div>

										<div className='col-md-5 order-md-2 mb-5 '>
											<Precheck />
										</div>
									</div>
								)}
							</div>
							<Modal
								onClose={context.closeModal}
								onCheck={context.showCheck}
								onElectronCheck={context.electronShowCheck}
								show={context.Modal.Status}
								header={context.Modal.Header}
								body={context.Modal.Body}
								img={context.CheckImg}
								docId={context.CheckId}
								docLink={context.CheckLink}
								share={context.shareSocial}
							/>
							<ModalCheck
								onClose={context.closeModal}
								show={context.ModalCheck.Status}
								body={context.Modal.Body}
								img={context.CheckImg}
							/>
						</div>
					</React.Fragment>
				)}
			</Store.Consumer>
		)
	}
}

export default PurchaseForm
