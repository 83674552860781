import React from 'react';

const Cashiers = ({ cashiers, toggleCashier }) => {
  return (
    <div>
      <div class="heading">
        <h1 class="text-center display-4">Кассиры</h1>
      </div>
      <div class="pt-5">
        <div class="card">
          <div class="card-header">
            Кассиры
          </div>
          <div class="card-body">
            <div class="table-wrapper">
              <table class="table table-responsive-sm">
                <thead>
                  <tr>
                    <th>Кассир</th>
                    <th>Статус</th>
                    <th>Действия</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    cashiers.map(c => (
                      <tr key={c.Id}>
                        <td>{c.User.Name}</td>
                        <td>
                          {c.Active ? (
                            <span class="badge badge-success">Активен</span>
                          ) : (
                            <span class="badge badge-danger">Заблокирован</span>
                          )}
                        </td>
                        <td>
                          {c.Active ? (
                            <p class="btn btn-outline-danger btn-sm" onClick={() => toggleCashier(c.Id)}>Заблокировать</p>
                          ) : (
                            <p class="btn btn-outline-success btn-sm" onClick={() => toggleCashier(c.Id)}>Разблокировать</p>
                          )}
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Cashiers;