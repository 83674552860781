import React from 'react';
import { Link } from 'react-router-dom';
import Workbook from 'react-excel-workbook';
//styles
import "../../Styles/layout/dateRange.scss"
import Modal from "../Modal/opersLogModal";
//svg
import { ReactComponent as DonwloadSvg } from '../Svg/download.svg'
import HeaderVersion from "../NewDesign/HeaderVersion/HeaderVersion";


const OpersLogLayout = ({ report: Report, handleChangeDateFrom, handleChangeDateTo, dateFrom, dateTo, date, idKkm, formatDate, getDuplicate, error, user: User, history, show, showCheck, closeModal, img, header }) => {

	return (
		<React.Fragment>
			{
				window.matchMedia("(min-width: 1100px)").matches &&
				<HeaderVersion isActive={true} />
			}
			<div className="container pt-5 pb-5">
				<Link to={`/www/kkms/${idKkm}`} className="back-to-kassa" style={{width: "193px"}}>← Вернуться в кассу</Link>
				<div className="heading">
					<h1 className="text-center">Журнал операций</h1>
				</div>
				<div className="dateRange-wrapper">
					<div className="excelButton">
						<Workbook filename={`${dateFrom} - ${dateTo}.xlsx`} element={<button className="downloadExcel">Скачать Excel-файл &nbsp; {<DonwloadSvg/>}</button>}>
							<Workbook.Sheet data={Report} name="Журнал операций">
								<Workbook.Column label="Тип операции" value={row => row.TypeDocument.Name} />
								<Workbook.Column label="Дата" value={row => formatDate(new Date(row.DateDocument))} />
								<Workbook.Column label="Номер чека" value={row => row.NumberDoc} />
								<Workbook.Column label="Сумма" value={row => new Intl.NumberFormat('ru-RU').format(row.Value)} />
							</Workbook.Sheet>
						</Workbook>
					</div>
					<div className="date_range">
						<span>Выберите дату от: </span>
						<input className="date-from-input" type="date" min="2019-01-01" value={dateFrom} onChange={handleChangeDateFrom}/>
						<span> до: </span>
						<input className="date-to-input" type="date" value={dateTo} onChange={handleChangeDateTo}/>
					</div>
				</div>
						<div className="card">
							<div className="card-header">
								Cписок операций за смену
							</div>
							<div className="card-body">
								<div className="table-wrapper">
									{
										Report && Report.length > 0 ?
											<table className="table table-responsive-sm">
												<thead>
													<tr>
														<th>Тип операции</th>
														<th>Дата</th>
														<th>Номер чека</th>
														<th className="text-right">Сумма</th>
														<th className="text-right">Действия</th>
													</tr>
												</thead>
												<tbody>
													{
														Report.map(oper => {
															return (
																<tr key={oper.Id}>
																	<td>{oper.TypeDocument.Name}</td>
																	<td>{formatDate(new Date(oper.DateDocument))}</td>
																	<td>{oper.NumberDoc}</td>
																	<td className="text-right">{new Intl.NumberFormat('ru-RU').format(oper.Value)} ₸</td>
																	<td className="text-right">
																		{
																			oper.TypeDocument.Id !== 7 &&
																				<button onClick={() => getDuplicate(oper.Id)} className="btn btn-outline-primary btn-sm">Дубликат</button>
																		}
																		{
																			oper.TypeDocument.Id === 1 &&
																			<span>
																				&nbsp;<Link to={`/www/kkms/${idKkm}/refund`} className="btn btn-outline-danger btn-sm">Возврат</Link>
																			</span>

																		}
																	</td>
																</tr>
															)

														})
													}
												</tbody>
											</table>
											:
											<div className="alert alert-light" role="alert">
												Операций нет. Вы не оформили ни одного чека
                    						</div>
									}
								</div>
							</div>
						</div>
				{/* // 		:
				// 		error.status === 410 ?
				// 			<div className="text-center" style={{ paddingTop: '20%' }}>
				// 				<h5><strong>Журнал операций не доступен</strong></h5>
				// 				<p>Нельзя посмотреть журнал операций на кассе с закрытой сменой</p>
				// 				<Link to={`/www/kkms/${idKkm}`} className="btn btn-primary btn-lg btn-sm-block">Открыть смену</Link>
				// 			</div>
				// 			:
				// 			<div className="alert alert-danger" style={{ margin: 'auto', maxWidth: '800px' }}>
				// 				<h5>{error.title}</h5>
				// 				<p>{error.text}</p>
				// 			</div>
				// } */}
				<Modal show={show} onClose={closeModal} img={img} header={header} showCheck={showCheck} />
			</div >
		</React.Fragment>
	)

}

export default OpersLogLayout;
