import React from 'react'

const Logo = () => {
	return <svg width="110" height="12" viewBox="0 0 110 12" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M0.111345 0.607271C0.181446 0.532985 0.280404 0.493652 0.399988 0.493652H1.58758C1.71129 0.493652 1.80614 0.532985 1.87624 0.607271C1.94634 0.681558 1.98344 0.786438 1.98344 0.913162V6.63758L4.2638 3.64866C4.37926 3.49134 4.53595 3.41267 4.73801 3.41267H6.21424C6.3297 3.41267 6.41217 3.43454 6.4699 3.4826C6.52763 3.53067 6.55651 3.59184 6.55651 3.66613C6.55651 3.74041 6.51527 3.8278 6.43692 3.93268L3.94627 6.99153L6.59362 10.5398C6.66372 10.6447 6.69671 10.7321 6.69671 10.8064C6.69671 10.8807 6.66784 10.9418 6.60599 10.9899C6.54414 11.038 6.46166 11.0598 6.35444 11.0598H4.89058C4.68852 11.0598 4.52358 10.9768 4.404 10.8064L2.68858 8.54283L1.9752 9.40804V10.636C1.9752 10.767 1.94221 10.8676 1.86798 10.9418C1.79788 11.0161 1.70305 11.0555 1.57934 11.0555H0.391745C0.268038 11.0555 0.173191 11.0205 0.10309 10.9418C0.0329887 10.8676 0 10.767 0 10.636V0.917536C0.00412358 0.782073 0.0412439 0.681558 0.111345 0.607271Z" fill="#343434"/>
		<path d="M13.5417 10.9421C13.4716 10.8678 13.4344 10.7673 13.4344 10.6362V10.2866C13.2571 10.54 12.9643 10.7585 12.552 10.9421C12.1396 11.13 11.6984 11.2217 11.2324 11.2217C10.4943 11.2217 9.84691 11.0469 9.29435 10.6974C8.74179 10.3478 8.31706 9.86271 8.02841 9.23783C7.73976 8.63043 7.59131 7.95748 7.59131 7.21461C7.59131 6.45864 7.73976 5.78133 8.03254 5.17393C8.32531 4.5709 8.74591 4.09023 9.29022 3.73627C9.83454 3.38232 10.4819 3.20752 11.2283 3.20752C11.7108 3.20752 12.1479 3.29491 12.5314 3.47407C12.919 3.64886 13.2159 3.88485 13.4262 4.17325V3.79743C13.4262 3.66634 13.4592 3.56583 13.5334 3.49154C13.6035 3.41725 13.6983 3.37795 13.8221 3.37795H14.9808C15.1045 3.37795 15.1993 3.41725 15.2694 3.49154C15.3395 3.56583 15.3766 3.66634 15.3766 3.79743V10.6405C15.3766 10.7716 15.3436 10.8721 15.2694 10.9464C15.1993 11.0207 15.1045 11.06 14.9808 11.06H13.8221C13.7066 11.0513 13.6118 11.0163 13.5417 10.9421ZM12.9643 5.63275C12.6015 5.23073 12.119 5.03409 11.5211 5.03409C10.9149 5.03409 10.4325 5.2351 10.0696 5.63275C9.71084 6.03477 9.5294 6.55914 9.5294 7.21024C9.5294 7.84386 9.71496 8.36386 10.082 8.76589C10.449 9.17228 10.9314 9.37329 11.517 9.37329C12.1314 9.37329 12.618 9.18101 12.9726 8.7921C13.3272 8.40756 13.5045 7.87881 13.5045 7.20587C13.5128 6.55914 13.3313 6.03477 12.9643 5.63275Z" fill="#343434"/>
		<path d="M17.245 10.2818C17.1295 10.1988 17.0718 10.0939 17.0718 9.97595C17.0718 9.88418 17.1048 9.78804 17.1666 9.69627L17.7315 8.90097C17.8181 8.77861 17.9212 8.71744 18.0367 8.71744C18.1233 8.71744 18.2016 8.73931 18.2759 8.78737C18.5934 8.97528 18.9274 9.12383 19.2861 9.23308C19.6408 9.34669 19.983 9.39913 20.3088 9.39913C20.6057 9.39913 20.8572 9.33358 21.0552 9.20248C21.2531 9.07139 21.352 8.90971 21.352 8.71307C21.352 8.53828 21.2613 8.4072 21.0758 8.3198C20.8902 8.23678 20.6552 8.17123 20.3624 8.12316L19.9542 8.06635C19.348 7.96584 18.8697 7.84348 18.5191 7.70365C18.1686 7.56381 17.847 7.34097 17.5584 7.03508C17.2408 6.69861 17.0841 6.23977 17.0841 5.65421C17.0841 4.78025 17.381 4.15539 17.9748 3.78396C18.5686 3.41252 19.2696 3.22461 20.0779 3.22461C20.5851 3.22461 21.0593 3.29454 21.4964 3.43438C21.9294 3.57421 22.3541 3.78832 22.7664 4.07672C22.8902 4.15101 22.952 4.25152 22.952 4.38262C22.952 4.46564 22.919 4.56178 22.8448 4.66229L22.2922 5.44447C22.2056 5.56683 22.1025 5.62362 21.9871 5.62362C21.9005 5.62362 21.818 5.60178 21.7356 5.55372C21.1294 5.2085 20.5562 5.03808 20.0078 5.03808C19.7686 5.03808 19.5542 5.09052 19.3562 5.19976C19.1583 5.30901 19.0593 5.44447 19.0593 5.61053C19.0593 5.81591 19.1789 5.96449 19.4222 6.05625C19.6655 6.14802 19.9995 6.22667 20.4325 6.29221C21.1335 6.42331 21.6572 6.55003 21.9953 6.67676C22.3335 6.80348 22.6345 7.01324 22.8984 7.31038C23.1788 7.6556 23.319 8.12316 23.319 8.72182C23.319 9.52149 23.0592 10.1376 22.5438 10.5702C22.0242 11.0028 21.2902 11.2213 20.3418 11.2213C19.1583 11.2344 18.1315 10.9155 17.245 10.2818Z" fill="#343434"/>
		<path d="M24.783 10.2818C24.6676 10.1988 24.6099 10.0939 24.6099 9.97595C24.6099 9.88418 24.6428 9.78804 24.7047 9.69627L25.2696 8.90097C25.3562 8.77861 25.4593 8.71744 25.5748 8.71744C25.6614 8.71744 25.7397 8.73931 25.8139 8.78737C26.1315 8.97528 26.4655 9.12383 26.8242 9.23308C27.1788 9.34669 27.5211 9.39913 27.8469 9.39913C28.1438 9.39913 28.3953 9.33358 28.5932 9.20248C28.7912 9.07139 28.8901 8.90971 28.8901 8.71307C28.8901 8.53828 28.7994 8.4072 28.6139 8.3198C28.4283 8.23678 28.1933 8.17123 27.9005 8.12316L27.4922 8.06635C26.8861 7.96584 26.4077 7.84348 26.0572 7.70365C25.7067 7.56381 25.3851 7.34097 25.0964 7.03508C24.7789 6.69861 24.6222 6.23977 24.6222 5.65421C24.6222 4.78025 24.9191 4.15539 25.5129 3.78396C26.1067 3.41252 26.8077 3.22461 27.616 3.22461C28.1232 3.22461 28.5974 3.29454 29.0345 3.43438C29.4674 3.57421 29.8922 3.78832 30.3045 4.07672C30.4282 4.15101 30.4901 4.25152 30.4901 4.38262C30.4901 4.46564 30.4571 4.56178 30.3829 4.66229L29.8303 5.44447C29.7437 5.56683 29.6406 5.62362 29.5252 5.62362C29.4386 5.62362 29.3561 5.60178 29.2736 5.55372C28.6675 5.2085 28.0943 5.03808 27.5458 5.03808C27.3067 5.03808 27.0923 5.09052 26.8943 5.19976C26.6964 5.30901 26.5974 5.44447 26.5974 5.61053C26.5974 5.81591 26.717 5.96449 26.9603 6.05625C27.2036 6.14802 27.5376 6.22667 27.9706 6.29221C28.6716 6.42331 29.1953 6.55003 29.5334 6.67676C29.8715 6.80348 30.1726 7.01324 30.4365 7.31038C30.7169 7.6556 30.8571 8.12316 30.8571 8.72182C30.8571 9.52149 30.5973 10.1376 30.0819 10.5702C29.5623 11.0028 28.8283 11.2213 27.8798 11.2213C26.6964 11.2344 25.6696 10.9155 24.783 10.2818Z" fill="#343434"/>
		<path d="M38.0111 10.9421C37.941 10.8678 37.9039 10.7673 37.9039 10.6362V10.2866C37.7266 10.54 37.4338 10.7585 37.0215 10.9421C36.6091 11.13 36.1679 11.2217 35.7019 11.2217C34.9638 11.2217 34.3164 11.0469 33.7638 10.6974C33.2113 10.3478 32.7865 9.86271 32.4979 9.23783C32.2092 8.63043 32.0608 7.95748 32.0608 7.21461C32.0608 6.45864 32.2092 5.78133 32.502 5.17393C32.7948 4.5709 33.2154 4.09023 33.7597 3.73627C34.304 3.38232 34.9514 3.20752 35.6978 3.20752C36.1803 3.20752 36.6174 3.29491 37.0009 3.47407C37.3885 3.64886 37.6854 3.88485 37.8957 4.17325V3.79743C37.8957 3.66634 37.9287 3.56583 38.0029 3.49154C38.073 3.41725 38.1678 3.37795 38.2915 3.37795H39.4502C39.5739 3.37795 39.6688 3.41725 39.7389 3.49154C39.809 3.56583 39.8461 3.66634 39.8461 3.79743V10.6405C39.8461 10.7716 39.8131 10.8721 39.7389 10.9464C39.6688 11.0207 39.5739 11.06 39.4502 11.06H38.2915C38.1761 11.0513 38.0812 11.0163 38.0111 10.9421ZM37.438 5.63275C37.0751 5.23073 36.5926 5.03409 35.9947 5.03409C35.3885 5.03409 34.9061 5.2351 34.5432 5.63275C34.1844 6.03477 34.003 6.55914 34.003 7.21024C34.003 7.84386 34.1886 8.36386 34.5556 8.76589C34.9226 9.17228 35.405 9.37329 35.9906 9.37329C36.605 9.37329 37.0916 9.18101 37.4462 8.7921C37.8008 8.40756 37.9781 7.87881 37.9781 7.20587C37.9864 6.55914 37.8008 6.03477 37.438 5.63275Z" fill="#343434"/>
		<path d="M45.4376 5.35307C45.4789 5.39677 45.4995 5.45796 45.4995 5.53225V6.1615C45.4995 6.23579 45.4789 6.29695 45.4376 6.34065C45.3964 6.38435 45.3387 6.40621 45.2686 6.40621H41.6563C41.5862 6.40621 41.5285 6.38435 41.4873 6.34065C41.446 6.29695 41.4254 6.23579 41.4254 6.1615V5.38804C41.4254 5.27005 41.4667 5.16956 41.5574 5.09527L42.3037 4.4529L43.4789 3.45222C43.7717 3.20751 43.9655 2.99777 44.0686 2.82734C44.1676 2.65255 44.2171 2.46027 44.2171 2.24178C44.2171 1.9927 44.1387 1.80481 43.982 1.67371C43.8253 1.54262 43.6274 1.48144 43.3882 1.48144C43.0212 1.48144 42.745 1.72178 42.5677 2.19809C42.5223 2.31608 42.4522 2.37724 42.3615 2.37724C42.345 2.37724 42.3202 2.37288 42.2749 2.35977L41.545 2.17187C41.4254 2.14129 41.3677 2.07136 41.3677 1.96648C41.3677 1.92278 41.3718 1.89221 41.3842 1.87036C41.4749 1.63002 41.6027 1.40716 41.7635 1.19304C41.9244 0.978924 42.1223 0.795389 42.3573 0.642446C42.6542 0.454545 42.9965 0.358398 43.3882 0.358398C43.8171 0.358398 44.1882 0.445788 44.5016 0.62495C44.815 0.799741 45.05 1.04008 45.2067 1.33723C45.3593 1.62127 45.4376 1.92278 45.4376 2.24178C45.4376 2.61758 45.3346 2.97154 45.1243 3.31238C44.9181 3.65323 44.6088 4.0028 44.2006 4.36549L43.8872 4.63645L43.549 4.91172C43.3923 5.05155 43.2357 5.18265 43.0872 5.29626H45.2686C45.3428 5.28752 45.3964 5.30937 45.4376 5.35307Z" fill="#343434"/>
		<path d="M51.0087 4.06396C51.0499 4.10766 51.0706 4.16883 51.0706 4.24311V4.86799C51.0706 4.94228 51.0499 5.00347 51.0087 5.04717C50.9675 5.09087 50.9097 5.1127 50.8396 5.1127H50.3695V6.15709C50.3695 6.23137 50.3489 6.29254 50.3077 6.33624C50.2664 6.37993 50.2087 6.40179 50.1386 6.40179H49.384C49.3139 6.40179 49.2562 6.37993 49.2149 6.33624C49.1737 6.29254 49.1531 6.23137 49.1531 6.15709V5.1127H46.6418C46.5717 5.1127 46.514 5.09087 46.4728 5.04717C46.4315 5.00347 46.4109 4.94228 46.4109 4.86799V4.24311C46.4109 4.13387 46.448 4.03336 46.5181 3.9416L49.3386 0.576848C49.4087 0.485083 49.5036 0.437012 49.6232 0.437012H50.1386C50.2087 0.437012 50.2664 0.458871 50.3077 0.502569C50.3489 0.546267 50.3695 0.607433 50.3695 0.681719V3.99403H50.8396C50.9097 3.9984 50.9675 4.02027 51.0087 4.06396ZM47.9985 3.99841H49.1572V2.55638L47.9985 3.99841Z" fill="#343434"/>
		<path d="M60.5426 4.43469C61.1653 5.12949 61.4787 6.05151 61.4787 7.21825C61.4787 8.38061 61.1653 9.307 60.5426 9.99306C59.9199 10.6791 59.1612 11.0287 58.2788 11.0287C57.2973 11.0287 56.4932 10.5524 55.8706 9.59542L55.7427 10.5961C55.6974 10.7753 55.6149 10.8627 55.4912 10.8627H54.2789C54.1139 10.8627 54.0273 10.7753 54.0273 10.5961V1.01313C54.0273 0.838341 54.1098 0.746582 54.2789 0.746582H55.6479C55.8129 0.746582 55.8994 0.833972 55.8994 1.01313V4.70562C56.5922 3.8404 57.4087 3.4078 58.3447 3.4078C59.1818 3.40343 59.9199 3.74863 60.5426 4.43469ZM59.0581 8.80886C59.4127 8.42869 59.5901 7.89557 59.5901 7.21825C59.5901 6.5453 59.4086 6.01655 59.0581 5.63201C58.7035 5.25184 58.2623 5.05957 57.7344 5.05957C57.2025 5.05957 56.7448 5.25622 56.3819 5.64513C56.0149 6.03404 55.8293 6.57153 55.8293 7.25759C55.8293 7.94364 56.0108 8.47675 56.3737 8.83508C56.7407 9.1934 57.1819 9.37693 57.7097 9.37693C58.254 9.3813 58.7035 9.18903 59.0581 8.80886Z" fill="#343434"/>
		<path d="M69.2764 3.58785C69.4414 3.58785 69.528 3.67524 69.528 3.8544V10.5926C69.528 10.7718 69.4455 10.8592 69.2764 10.8592H67.9486C67.8084 10.8592 67.7259 10.7718 67.6971 10.5926L67.6064 9.88471C66.959 10.6451 66.1549 11.0252 65.2106 11.0252C63.5611 11.0252 62.6746 9.80169 62.6746 7.54251V3.85003C62.6746 3.67523 62.7571 3.58348 62.9261 3.58348H64.357C64.5261 3.58348 64.6085 3.67087 64.6085 3.85003V7.54251C64.6085 8.58252 65.124 9.1943 66.0023 9.1943C66.9095 9.1943 67.5363 8.52134 67.594 7.10553V3.84568C67.594 3.67089 67.6765 3.5791 67.8456 3.5791H69.2764V3.58785Z" fill="#343434"/>
		<path d="M72.035 10.6884C71.5484 10.4917 71.1608 10.247 70.8845 9.94113C70.7691 9.81878 70.7608 9.69643 70.8598 9.56534L71.3422 8.74381C71.4247 8.59524 71.5525 8.57339 71.7134 8.69138C71.936 8.90113 72.2329 9.07154 72.5917 9.20264C72.9504 9.33373 73.305 9.39928 73.6556 9.39928C74.3731 9.39928 74.7854 9.11961 74.7854 8.6302C74.7854 8.29809 74.4762 8.0796 74.1999 8.00532C74.0638 7.97036 73.8329 7.91794 73.5071 7.85676C72.6741 7.69945 72.0309 7.45474 71.5896 7.12264C71.1484 6.79053 70.9216 6.3011 70.9216 5.65437C70.9216 4.9552 71.1732 4.40897 71.668 4.01569C72.1628 3.62241 72.8308 3.42578 73.6844 3.42578C74.6164 3.42578 75.4287 3.69234 76.1297 4.22109C76.2699 4.30848 76.2905 4.43957 76.1833 4.59688L75.6967 5.39219C75.6143 5.52328 75.4906 5.5495 75.3421 5.47522C74.872 5.14311 74.3525 4.97268 73.7752 4.97705C73.0288 4.97705 72.6577 5.19554 72.6577 5.62815C72.6577 5.92529 72.9587 6.12631 73.2556 6.20496C73.404 6.24429 73.6102 6.29237 73.8865 6.34918C73.9442 6.35355 73.9937 6.37103 74.0267 6.38414C74.8597 6.56767 75.4823 6.82111 75.8988 7.14011C76.3153 7.4591 76.5215 7.94853 76.5215 8.59526C76.5215 9.30753 76.2699 9.8887 75.7792 10.3301C75.2803 10.7714 74.5793 10.9899 73.6721 10.9899C73.07 10.9812 72.5216 10.885 72.035 10.6884Z" fill="#343434"/>
		<path d="M78.7564 2.37717C78.4471 2.37717 78.1874 2.26356 77.9688 2.02322C77.7503 1.79162 77.6431 1.51196 77.6431 1.1886C77.6431 0.869603 77.7503 0.589921 77.9688 0.353952C78.1874 0.117983 78.4513 0 78.7564 0C79.0574 0 79.3214 0.113614 79.544 0.353952C79.7626 0.589921 79.8698 0.869603 79.8698 1.1886C79.8698 1.51633 79.7626 1.79162 79.544 2.02322C79.3214 2.25919 79.0574 2.37717 78.7564 2.37717ZM78.0307 10.859C77.8657 10.859 77.7791 10.7715 77.7791 10.5924V3.85416C77.7791 3.67937 77.8616 3.58761 78.0307 3.58761H79.4616C79.6265 3.58761 79.7131 3.675 79.7131 3.85416V10.5924C79.7131 10.7715 79.6306 10.859 79.4616 10.859H78.0307Z" fill="#343434"/>
		<path d="M87.6306 4.32177C88.113 4.9248 88.3563 5.78565 88.3563 6.88684V10.5924C88.3563 10.7716 88.2739 10.859 88.1048 10.859H86.6739C86.5048 10.859 86.4224 10.7716 86.4224 10.5924V6.88684C86.4224 5.84683 85.9234 5.24817 85.041 5.24817C84.0926 5.24817 83.4493 5.908 83.3998 7.32382V10.5968C83.3998 10.7759 83.3173 10.8634 83.1482 10.8634H81.7174C81.5524 10.8634 81.4658 10.7759 81.4658 10.5968V3.85857C81.4658 3.68378 81.5483 3.59202 81.7174 3.59202H83.0575C83.1812 3.59202 83.2637 3.67941 83.3091 3.85857L83.3874 4.55338C84.0101 3.79741 84.7729 3.42597 85.6801 3.42597C86.4966 3.41723 87.144 3.71437 87.6306 4.32177Z" fill="#343434"/>
		<path d="M95.3913 4.45309C96.0181 5.15226 96.3356 6.06556 96.3356 7.19296C96.3356 7.31969 96.3274 7.42457 96.3232 7.49885C96.2985 7.66928 96.2078 7.75228 96.0469 7.75228H91.2924C91.4656 8.91465 92.1378 9.49148 93.313 9.49148C94.0635 9.49148 94.682 9.17685 95.1562 8.56071C95.28 8.42962 95.3995 8.42523 95.5109 8.54759L96.1212 9.17247C96.2366 9.31667 96.2243 9.4565 96.0841 9.60945C95.3459 10.549 94.3769 11.0165 93.1645 11.0165C92.0883 11.0165 91.2141 10.6626 90.5378 9.96342C89.8657 9.26426 89.5234 8.35971 89.5234 7.25852C89.5234 6.17918 89.8368 5.26589 90.4677 4.52302C91.0945 3.78016 91.9316 3.40873 92.9625 3.40873C93.948 3.40436 94.7563 3.74956 95.3913 4.45309ZM91.2636 6.51128H94.6903C94.5707 5.47126 93.9522 4.91192 93.0202 4.91192C92.0718 4.91192 91.3997 5.48437 91.2636 6.51128Z" fill="#343434"/>
		<path d="M98.4053 10.6884C97.9188 10.4917 97.5312 10.247 97.2549 9.94113C97.1394 9.81878 97.1312 9.69643 97.2301 9.56534L97.7126 8.74381C97.7951 8.59524 97.9229 8.57339 98.0837 8.69138C98.3064 8.90113 98.6033 9.07154 98.962 9.20264C99.3208 9.33373 99.6754 9.39928 100.026 9.39928C100.743 9.39928 101.156 9.11961 101.156 8.6302C101.156 8.29809 100.847 8.0796 100.57 8.00532C100.434 7.97036 100.203 7.91794 99.8775 7.85676C99.0445 7.69945 98.4012 7.45474 97.96 7.12264C97.5188 6.79053 97.292 6.3011 97.292 5.65437C97.292 4.9552 97.5435 4.40897 98.0383 4.01569C98.5332 3.62241 99.2012 3.42578 100.055 3.42578C100.987 3.42578 101.799 3.69234 102.5 4.22109C102.64 4.30848 102.661 4.43957 102.554 4.59688L102.067 5.39219C101.985 5.52328 101.861 5.5495 101.712 5.47522C101.242 5.14311 100.723 4.97268 100.145 4.97705C99.3991 4.97705 99.028 5.19554 99.028 5.62815C99.028 5.92529 99.329 6.12631 99.6259 6.20496C99.7744 6.24429 99.9805 6.29237 100.257 6.34918C100.315 6.35355 100.364 6.37103 100.397 6.38414C101.23 6.56767 101.853 6.82111 102.269 7.14011C102.686 7.4591 102.892 7.94853 102.892 8.59526C102.892 9.30753 102.64 9.8887 102.15 10.3301C101.651 10.7714 100.95 10.9899 100.042 10.9899C99.4404 10.9812 98.8961 10.885 98.4053 10.6884Z" fill="#343434"/>
		<path d="M104.908 10.6884C104.422 10.4917 104.034 10.247 103.758 9.94113C103.642 9.81878 103.634 9.69643 103.733 9.56534L104.216 8.74381C104.298 8.59524 104.426 8.57339 104.587 8.69138C104.809 8.90113 105.106 9.07154 105.465 9.20264C105.824 9.33373 106.178 9.39928 106.529 9.39928C107.246 9.39928 107.659 9.11961 107.659 8.6302C107.659 8.29809 107.349 8.0796 107.073 8.00532C106.937 7.97036 106.706 7.91794 106.38 7.85676C105.547 7.69945 104.904 7.45474 104.463 7.12264C104.022 6.79053 103.795 6.3011 103.795 5.65437C103.795 4.9552 104.046 4.40897 104.541 4.01569C105.036 3.62241 105.704 3.42578 106.558 3.42578C107.49 3.42578 108.302 3.69234 109.003 4.22109C109.143 4.30848 109.164 4.43957 109.057 4.59688L108.57 5.39219C108.488 5.52328 108.364 5.5495 108.215 5.47522C107.745 5.14311 107.226 4.97268 106.648 4.97705C105.902 4.97705 105.531 5.19554 105.531 5.62815C105.531 5.92529 105.832 6.12631 106.129 6.20496C106.277 6.24429 106.484 6.29237 106.76 6.34918C106.818 6.35355 106.867 6.37103 106.9 6.38414C107.733 6.56767 108.356 6.82111 108.772 7.14011C109.189 7.4591 109.395 7.94853 109.395 8.59526C109.395 9.30753 109.143 9.8887 108.652 10.3301C108.154 10.7714 107.453 10.9899 106.545 10.9899C105.943 10.9812 105.395 10.885 104.908 10.6884Z" fill="#343434"/>
	</svg>	
}

export default Logo
