import React, {useEffect, useState} from 'react';
import style from '../../styles/pages/cashbox/tabs/NewSections.module.scss'
import NewSectionModal from "../../NewOperations/Modal/NewSectionModal";
import NewPagination from "../NewPagination";
import { ReactComponent as SearchLine } from '../../svg/search-line.svg'
import { useTranslation } from "react-i18next";

const addZeroes = (num) => {
  const dec = num.toString().split('.')[1]
  const len = dec && dec.length > 2 ? dec.length : 2
  return Number(num).toFixed(len)
}

const NewSections = ({ sections, addSection, toggleSection, searchSections, fetchSections}) => {
  const { t } = useTranslation()

  const [Name, setName] = useState("")
  console.log('Name', Name)
  const [Nds, setNds] = useState("")
  console.log('Nds', Nds)
  const [searchKey, setSearchKey] = useState("")
  const [showModal, setShowModal] = useState(false)
  const [showSections, setShowSections] = useState(false)
  const [activePage, setActivePage] = useState(1)
  const [sectionsList, setSectionsList] = useState(sections.slice(0, 10))
  const [searchBtnClicked, setSearchBtnClicked] = useState(false)
  const [searchSuggestionClicked, setSearchSuggestionClicked] = useState(false)

  useEffect(() => {
    setSectionsList(sections.slice(10 * (activePage-1), 10 * activePage))
  }, [sections])

  useEffect(() => {
    if (!searchBtnClicked) {
      fetchSections()
    }
  }, [searchBtnClicked])

  useEffect(() => {
    if (searchSuggestionClicked) {
      doSearch()
    }
  }, [searchSuggestionClicked])

  const addNewSection = (data) => {
    addSection(data)
    setName("")
    setNds("")
  }

  const doSearch = () => {
    searchSections(searchKey)
    setSearchBtnClicked(true)
    setSearchSuggestionClicked(false)
  }

  const onClose = () => {
    setShowModal(false)
  }


  const setPage = (page) => {
    setActivePage(page)
    setSectionsList(sections.slice(10 * (page-1), 10 * page))
  }

  const sectionsRenderList = sectionsList.map(section => {
      return (
        <div className={style['table__list']} key={section.Id}>
          <div className={style['table__item']}><span>{ section.Name }</span></div>
          <div className={`${style['table__item']} ${style['table__item--desktop']}`}><span>{ addZeroes(section.Nds) }</span></div>
          <div className={`${style['table__item']} ${style['table__item--mobile']}`}><span>{ t('kassa_settings.vat') }: { addZeroes(section.Nds) }%</span></div>
          <div className={style['table__item']}>
            { section.Active ?
              <button
                className={`${style.btn} ${style['btn--on']}`}
                onClick={() => toggleSection(section.Id)}
              >
                { t('kassa_settings.disable') }
              </button>
              :
              <button
                  className={`${style.btn} ${style['btn--off']}`}
                  onClick={() => toggleSection(section.Id)}
              >
                { t('kassa_settings.enable') }
              </button>
            }
          </div>
        </div>
      )
    })

  const searchedArticlesList =
    <div className={`${style['form__select-list']}`}>
      {
        sections
          .filter(section => section.Name.toLowerCase().includes(searchKey.toLowerCase()))
          .map(section => {
            return (
              <div
                className={style['form__select-item']}
                key={section.Id}
                onClick={() => {
                  setSearchKey(section.Name)
                  setSearchSuggestionClicked(true)
                }}
              >
                {section.Name}
              </div>
            )
          })
      }
    </div>

  const getSectionHeaderText = (sections) => {
    if (!sections.length) {
      return `0 ${ t('kassa_settings.sections_alt') }`
    }

    if (sections.length === 1) {
      return `${sections.length} ${ t('kassa_settings.section_alt') }`
    }

    return `${sections.length} ${ t('kassa_settings.sections_alt') }`
  }


  return (
    <React.Fragment>
      <div className={style.table}>
        <div className={`${style['table__top']} ${style['my-20']}`}>
          <div className={style['table__info']}>
            <h1 className={style['heading-primary']}>{getSectionHeaderText(sections)}</h1>
          </div>
          <div className={style['table__actions']}>
            <div className={`${style['input-wrapper']} ${style['mr-20']}`}>
              <div>
                <input
                  className={style['input-wrapper__input']}
                  type="text"
                  placeholder={ t('kassa_settings.search') }
                  value={searchKey}
                  onChange={e => setSearchKey(e.target.value)}
                  onFocus={() => {
                    setShowSections(!showSections)
                    if (searchBtnClicked) {
                      setSearchBtnClicked(false)
                    }
                  }}
                  onBlur={() => setTimeout(() => setShowSections(!showSections), 400)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.target.blur()
                      doSearch()
                      setSearchBtnClicked(true)
                      setShowSections(false)
                    }
                  }}
                />
                <button
                  className={style['input-wrapper__btn']}
                  onClick={doSearch}
                >

                  <SearchLine/>
                </button>
                { (showSections && searchKey.trim()) && searchedArticlesList }
              </div>
            </div>
            <button
              onClick={() => setShowModal(true)}
              className={`${style.btn} ${style['btn--primary']}`}
            >
              + { t('kassa_settings.add_section') }
            </button>
          </div>
        </div>


          { sections.length ?
            <div className={`${style.card} ${style['card--white-nopadding']}`}>
              <div className={style['table__content']}>
                <div className={style['table__header']}>
                  <div>{ t('kassa_settings.name') }</div>
                  <div>{ t('kassa_settings.vat_rate') }</div>
                  <div>{ t('kassa_settings.actions') }</div>
                </div>
                <div className={style['table__body']}>
                  { sectionsRenderList }
                  {
                    sectionsList.length ? (
                      <NewPagination
                        setPage={setPage}
                        list={sections}
                        activePage={activePage}
                      />
                    ) : null
                  }
                </div>
              </div>
            </div>
            :
            <div className={`${style.card} ${style['card--white-nopadding']} ${style['card--transparent']}`}>
              <div className={`${style['text-center']} ${style['pa-40']}`}>
                <p className={`${style.paragraph} ${style['paragraph--sub']}`}>
                  { t('kassa_settings.not_found').split('|')[0] }
                  <br/>
                  { t('kassa_settings.not_found').split('|')[1] }
                </p>
              </div>
            </div>
          }
      </div>
      {showModal && (
        <NewSectionModal
          onClose={() => onClose()}
          addNewSection={addNewSection}
        />
      )}
    </React.Fragment>
  )
}

export default NewSections;
