import React from 'react';
// import Spinner from '../Common/Spinner';
// import Modal from '../Modal/closeShiftModal';
// import { BASE_URL } from '../../Store/snippets';
import { Link } from 'react-router-dom';
import {getAppModuleUrl} from "../../Store/appVersion";
import { useTranslation } from "react-i18next";

const MobileNavLayout = ({ kkm, isActive, toggleMenu, isOpen }) => {
	const { t } = useTranslation()

	return (
		<React.Fragment>
			<div className="mobile-nav d-lg-none">
				<div className="mobile-nav__buttons">
					<Link to={`/www/shifts/${kkm.Id}/${getAppModuleUrl().reports.operations}`} className="mobile-nav__btn">
						<div className="mobile-nav__btn-text">
							<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15 16H9a1 1 0 0 0 0 2h6a1 1 0 0 0 0-2zM9 14h3a1 1 0 0 0 0-2H9a1 1 0 0 0 0 2z" fill="#A39EB8" /><path d="M19.74 8.33l-5.44-6a1 1 0 0 0-.74-.33h-7A2.53 2.53 0 0 0 4 4.5v15A2.53 2.53 0 0 0 6.56 22h10.88A2.53 2.53 0 0 0 20 19.5V9a1 1 0 0 0-.26-.67zM14 5l2.74 3h-2a.79.79 0 0 1-.74-.85V5zm3.44 15H6.56a.532.532 0 0 1-.56-.5v-15a.53.53 0 0 1 .56-.5H12v3.15A2.79 2.79 0 0 0 14.71 10H18v9.5a.53.53 0 0 1-.56.5z" /></svg>
							<div className="ml-1">{ t('main.reports') }</div>
						</div>
					</Link>
					{isActive &&
						<React.Fragment>
							<input type="checkbox" id="mob_menu" defaultChecked={isOpen} />
							<label htmlFor="mob_menu" className="mobile-nav__btn mobile-nav__menu-controller" onClick={toggleMenu}>
								<div className="mobile-nav__btn-circle"></div>
								<div className="mobile-nav__menu">
									<div className="mobile-nav__arrow">
										<svg width="28" height="10" viewBox="0 0 28 10" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M0 3.82416C0 5.11207 0.853768 6.24393 2.09212 6.59775L12.9011 9.68603C13.6193 9.89124 14.3807 9.89124 15.0989 9.68603L25.9079 6.59775C27.1462 6.24393 28 5.11207 28 3.82416C28 1.90771 26.1657 0.52409 24.323 1.05058L15.0989 3.68603C14.3807 3.89124 13.6193 3.89124 12.9011 3.68603L3.67703 1.05058C1.83432 0.52409 0 1.90771 0 3.82416Z" fill="#EAE6F5"/>
										</svg>
									</div>
									<Link to={`/www/kkms/${kkm.Id}/${getAppModuleUrl().operations.sale}`} className="btn btn-primary btn-lg btn-block">{ t('main.new_sale') }</Link>
									<Link to={`/www/kkms/${kkm.Id}/${getAppModuleUrl().operations.purchase}`} className="btn btn-secondary btn-lg btn-block">{ t('main.new_purchase') }</Link>
									<div className="mobile-nav__menu-sqr">
										<Link to={`/www/kkms/${kkm.Id}/${getAppModuleUrl().operations.income}`} className="btn btn-sqr btn-secondary">
											<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M18.7703 13.36C18.6863 13.2587 18.5832 13.175 18.4669 13.1137C18.3505 13.0523 18.2232 13.0145 18.0922 13.0025C17.9613 12.9904 17.8292 13.0043 17.7036 13.0433C17.578 13.0824 17.4613 13.1458 17.3603 13.23L13.0003 16.86V5C13.0003 4.73478 12.8949 4.48043 12.7074 4.29289C12.5198 4.10536 12.2655 4 12.0003 4C11.7351 4 11.4807 4.10536 11.2932 4.29289C11.1056 4.48043 11.0003 4.73478 11.0003 5V16.86L6.64027 13.23C6.53915 13.146 6.42247 13.0826 6.29689 13.0437C6.17131 13.0047 6.03928 12.9909 5.90835 13.003C5.77742 13.0151 5.65015 13.0528 5.5338 13.1141C5.41746 13.1753 5.31432 13.2589 5.23027 13.36C5.14623 13.4611 5.08292 13.5778 5.04397 13.7034C5.00502 13.829 4.99118 13.961 5.00325 14.0919C5.01532 14.2229 5.05307 14.3501 5.11432 14.4665C5.17558 14.5828 5.25916 14.686 5.36027 14.77L11.3603 19.77L11.5103 19.86L11.6403 19.93C11.8719 20.0194 12.1286 20.0194 12.3603 19.93L12.4903 19.86L12.6403 19.77L18.6403 14.77C18.7415 14.6861 18.8252 14.583 18.8866 14.4666C18.9479 14.3503 18.9857 14.2229 18.9978 14.092C19.0099 13.961 18.996 13.8289 18.957 13.7033C18.9179 13.5777 18.8545 13.461 18.7703 13.36Z" fill="#726D85" />
											</svg>
											<div>{ t('main.income') }</div>
										</Link>
										<Link to={`/www/kkms/${kkm.Id}/${getAppModuleUrl().operations.expense}`} className="btn btn-sqr btn-secondary">
											<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.77 10.64a1 1 0 0 1-1.41.13L13 7.14V19a1 1 0 0 1-2 0V7.14l-4.36 3.63a1.001 1.001 0 1 1-1.28-1.54l6-5 .15-.09.13-.07a1 1 0 0 1 .72 0l.13.07.15.09 6 5a1.002 1.002 0 0 1 .13 1.41z" fill="#726D85" /></svg>
											<div>{ t('main.expense') }</div>
										</Link>
										<Link to={`/www/kkms/${kkm.Id}/${getAppModuleUrl().operations.refund}`} className="btn btn-sqr btn-secondary">
											<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16 10H7.08l2.7-3.38a1 1 0 1 0-1.56-1.24l-4 5a1 1 0 0 0 0 1.24l4 5A1 1 0 0 0 9 17a1 1 0 0 0 .78-1.62L7.08 12H16a1 1 0 0 1 1 1v5a1 1 0 1 0 2 0v-5a3 3 0 0 0-3-3z" fill="#726D85" /></svg>
											<div>{ t('main.refund') }</div>
										</Link>
									</div>
								</div>
								<div className="mobile-nav__overlay" onClick={toggleMenu}></div>
							</label>
						</React.Fragment>
					}
					<Link to={`/www/kkms/${kkm.Id}/${getAppModuleUrl().shift}`} className="mobile-nav__btn">
						<div className="mobile-nav__btn-text">
							<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19 9a3 3 0 0 0-2.82 2H3a1 1 0 0 0 0 2h13.18A3 3 0 1 0 19 9zm0 4a1 1 0 1 1 0-2.002A1 1 0 0 1 19 13zM3 7h1.18a3 3 0 0 0 5.64 0H21a1 1 0 1 0 0-2H9.82a3 3 0 0 0-5.64 0H3a1 1 0 1 0 0 2zm4-2a1 1 0 1 1 0 2 1 1 0 0 1 0-2zM21 17h-7.18a3 3 0 0 0-5.64 0H3a1 1 0 0 0 0 2h5.18a3 3 0 0 0 5.64 0H21a1 1 0 0 0 0-2zm-10 2a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" /></svg>
							<div className="ml-2">Касса</div>
						</div>
					</Link>
				</div>
			</div>
		</React.Fragment>
	)
}
export default MobileNavLayout;
