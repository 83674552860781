import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';



const ProtectedRoute = ({ component: Component, ...rest }) => {
	let user = JSON.parse(localStorage.getItem('USER'))
	const location = useLocation()

	return (
		<Route {...rest} render={ props => 
			user !== null
			? <Component {...props} />
			: <Redirect
					to={{
						pathname: '/',
						state: { from: location }
					}}
				/>
		}
		/>
	)
}

export default ProtectedRoute;