/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// import Header from '../Header/Header';
import SettingsTabs from './modules/SettingsTabs';
import CashboxManage from './tabs/CashboxManage';
import OfdSettings from './tabs/OfdSettings';
import Sections from './tabs/Sections';
import Cashiers from './tabs/Cashiers';
import Articles from './tabs/Articles';
import * as axios from 'axios';
import { BASE_URL, getHeaders } from '../../Store/snippets';
// import {setAppVersion} from "../../Store/appVersion";
import HeaderVersion from "../NewDesign/HeaderVersion/HeaderVersion";

const CashboxSettings = ({ history }) => {
  let headers = getHeaders(history);
  let kkm = (JSON.parse(localStorage.getItem('KKM')))

  const [activeNav, setActiveNav] = useState(1);
  const [step, setStep] = useState(1);
  const [sections, setSections] = useState([]);
  const [articles, setArticles] = useState([]);
  const [ofds, setOfds] = useState([]);
  const [cashiers, setCashiers] = useState([]);
  const [units, setUnits] = useState([]);
  const [showDropzone, setShowDropzone] = useState(false);
  const [snackbar, setSnackbar] = useState(false)
  const [snackText, setSnackText] = useState("")

  useEffect(() => {
    const sections = axios.get(`${BASE_URL}/kkms/${kkm.Id}/sections?all=1`, { headers: headers })
    const articles = axios.get(`${BASE_URL}/kkms/${kkm.Id}/settings/articles`, { headers: headers })
    const cashiers = axios.get(`${BASE_URL}/kkms/${kkm.Id}/settings/cashiers?all=1`, { headers: headers })
    const ofds = axios.get(`${BASE_URL}/kkms/${kkm.Id}/settings/ofds`, { headers: headers })
    const units = axios.get(`${BASE_URL}/kkms/${kkm.Id}/units`, { headers: headers })
    Promise.all([sections, articles, ofds, cashiers, units]).then(([sec, art, ofds, cashiers, units]) => {
      setSections(sec.data.Data.Sections)
      setArticles(art.data.Data.reverse())
      setOfds(ofds.data.Data)
      setCashiers(cashiers.data.Data)
      setUnits(units.data.Data)
    })
  }, [])

  let navsList = [
    {
      id: 1,
      name: "Управление кассой"
    },
    {
      id: 2,
      name: "Настройки ОФД"
    },
    {
      id: 3,
      name: "Секции"
    },
    {
      id: 4,
      name: "Кассиры"
    },
    {
      id: 5,
      name: "Номенклатура"
    },
  ]

  const fetchArticles = () => {
    const a = axios.get(`${BASE_URL}/kkms/${kkm.Id}/settings/articles`, { headers: headers })
    a.then(art => {
      setArticles(art.data.Data.reverse())

    })
  }

  const searchArticles = (key) => {
    const a = axios.get(`${BASE_URL}/kkms/${kkm.Id}/settings/articles?search=${key}`, { headers: headers })
    a.then(art => {
      setArticles(art.data.Data.reverse())
    })
  }

  const changeCashboxName = (name) => {
    let formData = new FormData()
    formData.append('Name', name)
    axios.post(`${BASE_URL}/kkms/${kkm.Id}`,
      formData,
      { headers: headers }
    )
    .then(res => {
      if (res.data.Status === 200) {
        kkm.Name = name
        localStorage.setItem('KKM', JSON.stringify(kkm))
        setSnackText('Настройки кассы сохранены')
        setSnackbar(true)
        setTimeout(() => {
          setSnackText('')
          setSnackbar(false)
        }, 3000);
      }
    })
  }

  const changeOfdSettings = (ofdSettings) => {
    let formData = new FormData()
    formData.append('IdOfd', ofdSettings.ofdId)
    formData.append('Rnm', ofdSettings.rnm)
    formData.append('IdCPCR', ofdSettings.kkmid)
    formData.append('TokenCPCR', ofdSettings.token)
    axios.post(`${BASE_URL}/kkms/${kkm.Id}/settings/ofds`,
      formData,
      { headers: headers }
    )
    .then(res => {
      if (res.data.Status === 200) {
        ofds
          .filter(ofd => ofd.Id == ofdSettings.ofdId)
          .map(ofd => kkm.Ofd = ofd)

        kkm.Rnm = ofdSettings.rnm
        kkm.IdCPCR = ofdSettings.kkmid
        kkm.TokenCPCR = ofdSettings.token
        localStorage.setItem('KKM', JSON.stringify(kkm))
        setSnackText('Настройки ОФД сохранены')
        setSnackbar(true)
        setTimeout(() => {
          setSnackText('')
          setSnackbar(false)
        }, 3000);
      }
    })
  }

  const addSection = (section) => {
    axios.post(`${BASE_URL}/kkms/${kkm.Id}/sections`,
      section,
      { headers: headers }
    )
    .then(res => {
      if (res.data.Status === 200) {
        axios.get(`${BASE_URL}/kkms/${kkm.Id}/sections?all=1`, { headers: headers })
          .then(sec => {
            setSections(sec.data.Data.Sections)
            setSnackText('Секция успешно добавлена')
            setSnackbar(true)
            setTimeout(() => {
              setSnackText('')
              setSnackbar(false)
            }, 3000);
          })
      }
    })
  }

  const toggleSection = (id) => {
    axios.get(`${BASE_URL}/kkms/${kkm.Id}/settings/sections/${id}/toggle?all=1`, { headers: headers })
      .then(res => {
        if (res.data.Status === 200) {
          axios.get(`${BASE_URL}/kkms/${kkm.Id}/sections?all=1`, { headers: headers })
            .then(sec => {
              setSections(sec.data.Data.Sections)
            })
        }
      })
  }

  const toggleCashier = (id) => {
    axios.get(`${BASE_URL}/kkms/${kkm.Id}/settings/cashiers/${id}/toggle?all=1`, { headers: headers })
      .then(res => {
        if (res.data.Status === 200) {
          axios.get(`${BASE_URL}/kkms/${kkm.Id}/settings/cashiers?all=1`, { headers: headers })
            .then(sec => {
              setCashiers(sec.data.Data)
            })
        }
      })
  }

  const addArticle = (article) => {
    return axios.post(`${BASE_URL}/kkms/${kkm.Id}/settings/articles`,
      article,
      { headers: headers }
    )
    .then(res => {
      if (res.data.Status === 200) {
        axios.get(`${BASE_URL}/kkms/${kkm.Id}/settings/articles`, { headers: headers })
          .then(sec => {
            setArticles(sec.data.Data.reverse())
            if (article.Id) {
              setSnackText('Товар успешно обновлен')
            } else {
              setSnackText('Товар успешно добавлен')
            }
            setSnackbar(true)
            setTimeout(() => {
              setSnackText('')
              setSnackbar(false)
            }, 3000);
          })
      }
    })
  }

  const style = {
    display: 'flex',
    justifyContent: 'end',
    alignContent: 'center',
  }

  // const oldVersion = {
  //   float: "right",
  //   marginTop: "10px",
  //   width: "106px",
  //   height: "38px",
  //   background: "#FFFFFF",
  //   borderRadius: "8px",
  //   display: "flex",
  //   justifyContent: "space-between",
  //   alignItems: "center",
  //   padding: "4px 13px 4px 8px",
  //   border: "1px solid #D7D7D7",
  //   cursor: "pointer"
  // }
  // const oldVersionText = {
  //   color: "#575757",
  //   lineHeight: "15px",
  //   fontSize: "12px",
  //   margin: "0"
  // }


  // const goNew = () => {
  //   history.push(`/www/kkms/${kkm.Id}/settings_new`)
  //   setAppVersion(true)
  // }

  return (
    <React.Fragment>
      <HeaderVersion isActive={true} />
      <main role="main" className="container">
        <SettingsTabs navsList={navsList} activeNav={activeNav} setActiveNav={setActiveNav} setStep={setStep} />
        <div style={style}>
          {/* <div style={oldVersion} onClick={() => goNew()}>
            <p style={oldVersionText}>Новый вид<br />сайта</p>
            <svg width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4.6665 4L0.666504 8V0L4.6665 4Z" fill="#8E8E8E"/>
            </svg>
          </div> */}
        </div>
        <Link className="btn btn-secondary btn-lg btn-sm-block" to={`/www/kkms/${kkm.Id}`} role="button">
          ← Вернуться в кассу
        </Link>
        {
          step === 1 ? (
            <CashboxManage kkm={kkm} changeCashboxName={(name) => changeCashboxName(name)} />
          ) : (
            step === 2
          ) ? (
            <OfdSettings ofds={ofds} changeOfdSettings={(ofdSettings) => changeOfdSettings(ofdSettings)} />
          ) : (
            step === 3
          ) ? (
            <Sections sections={sections} addSection={(section) => addSection(section) } toggleSection={(id) => toggleSection(id)} />
          ) : (
            step === 4
          ) ? (
            <Cashiers cashiers={cashiers} toggleCashier={(id) => toggleCashier(id)} />
          ) : (
            step === 5
          ) ? (
            <Articles
              articles={articles}
              showDropzone={showDropzone}
              setShowDropzone={setShowDropzone}
              fetchArticles={() => fetchArticles()}
              addArticle={(article) => addArticle(article)}
              sections={sections}
              units={units}
              searchArticles={(key) => searchArticles(key)}
              setSnackbar={setSnackbar}
              setSnackText={setSnackText}
            />
          ) : (
            null
          )
        }
      </main>
      {
        snackbar && (
          <div id="snackbar">
            <div className="leftside">
                <img src="/static/swagger/toasticon.svg" alt="" />
                <p>{snackText}</p>
                {/* <p>Успешно импортировано товаров: <span id="scsscount"></span></p> */}
            </div>
            {/* <div className="close-toast">
                <button className="transparent" onClick={() => setSnackbar(false)}>Закрыть</button>
            </div> */}
        </div>
        )
      }
    </React.Fragment>
  )
}

export default CashboxSettings;
