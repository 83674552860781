exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".actionBtn_blueBtn__2-VPi {\n  background: #00ADEE;\n  color: #FFFFFF;\n  margin-bottom: 10px;\n  height: 50px;\n  width: 100%;\n  border-radius: 8px;\n  border: none;\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 19px; }\n  .actionBtn_blueBtn__2-VPi svg {\n    margin-right: 8px; }\n  .actionBtn_blueBtn__2-VPi:hover {\n    background: #0493C9; }\n  .actionBtn_blueBtn__2-VPi:disabled {\n    background: #8E8E8E; }\n\n.actionBtn_greyBtn__34fjm {\n  height: 48px;\n  background: #F5F5F5;\n  border: 1px solid #D7D7D7;\n  color: #575757;\n  margin-bottom: 10px;\n  width: 100%;\n  border-radius: 8px;\n  border: none;\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 19px; }\n  .actionBtn_greyBtn__34fjm svg {\n    margin-right: 8px; }\n  .actionBtn_greyBtn__34fjm:hover {\n    border: 1px solid #8E8E8E; }\n  .actionBtn_greyBtn__34fjm:active {\n    background: #575757;\n    color: #F5F5F5; }\n\n.actionBtn_redBtn__2XGkX {\n  background: #E6342A;\n  border: 1px solid #D7D7D7;\n  color: #FFFFFF;\n  margin-bottom: 10px;\n  height: 50px;\n  width: 100%;\n  border-radius: 8px;\n  border: none;\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 19px; }\n  .actionBtn_redBtn__2XGkX svg {\n    margin-right: 8px; }\n\n@keyframes actionBtn_spin__fstKP {\n  100% {\n    transform: rotate(360deg); } }\n  .actionBtn_redBtn__2XGkX:active {\n    color: #FFFFFF; }\n", ""]);

// exports
exports.locals = {
	"blueBtn": "actionBtn_blueBtn__2-VPi",
	"greyBtn": "actionBtn_greyBtn__34fjm",
	"redBtn": "actionBtn_redBtn__2XGkX",
	"spin": "actionBtn_spin__fstKP"
};