import React, { Component } from 'react';
// import Footer from '../footer/Footer';
import { OperationProvider } from "../../Store/OperationProvider";
import Income from "./views/Income";

class IncomeWrapper extends Component {
    render() {
        return (
            <div>
                {/*<Navbar path="/new-shift" />*/}
                <OperationProvider operation='Income'>
                    <Income {...this.props} />
                </OperationProvider>
                {/* <Footer /> */}
            </div>
        )
    }
}

export default IncomeWrapper;