import React, { useEffect, useState } from 'react'
import { useLocation, useHistory } from "react-router-dom"
import LoginForm from './LoginForm'
import LoginSlider from './LoginSlider'
import Logo from '../SvgComponent/Logo'
import Footer from '../../Footer/Footer'
import { SignIn } from './Actions'
import s from './login.module.scss'
import LangToggler from "../LangToggler";

const Login = (props) => {
  const [showTutorial, setShowTutorial] = useState(true)
  const [loading, setLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const location = useLocation()
  const history = useHistory()
  const prevPage = (location.state && location.state['from'] && location.state['from'].pathname) ? location.state['from'].pathname : '/www/comps_new'

  const sign_in = async (data) => {
    setLoading(true)
    const res = await SignIn(data)
    if (!res.response) {
      setLoading(false)
      setIsError(true)
    } else history.push(prevPage)
  }

  useEffect(() => {localStorage.getItem('USER') && history.push(prevPage)},[])

  return (
    <main className={s.wrapper}>
      { showTutorial &&
        <section className={s.slider_wrapper}>
          <div className={s.logo_desktop}><Logo /></div>
          <LoginSlider closeTutorial={() => setShowTutorial(false)} />
        </section>
      }
      <section className={s.form_wrapper}>
        <div className={s.langWrapper}>
          <LangToggler />
        </div>
        <div className={s.logo}><Logo /></div>
        <div className={s.form_container}>
          <LoginForm login={data => sign_in(data)} isLoading={loading} isError={isError} />
        </div>
				<Footer />
      </section>
    </main>
  )
}

export default Login
