import React from 'react';
import './styles.scss';

const RadioButton = ({ name, id, value, onChange, checked, text }) => (
  <label htmlFor={id} className="custom-radio">
    <input
      type="radio"
      name={name}
      id={id}
      value={value}
      onChange={onChange}
      checked={checked}
    />
    <span className="custom-radio-button" />
    {text}
  </label>
);

export default RadioButton;