exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".langToggler_wrapper__1Mglj {\n  display: flex;\n  overflow: hidden;\n  border-radius: 8px; }\n\n.langToggler_btn__1rQG0 {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: 12px 20px;\n  font-weight: 600;\n  font-size: 14px;\n  line-height: 17px;\n  color: #4D4E4F;\n  border: 1px solid #D7D7D7;\n  background: #E5E5E5; }\n  .langToggler_btn__1rQG0.langToggler_active__KA_1D {\n    background: #00ADEE;\n    color: #FFFFFF;\n    border: 1px solid #00ADEE; }\n", ""]);

// exports
exports.locals = {
	"wrapper": "langToggler_wrapper__1Mglj",
	"btn": "langToggler_btn__1rQG0",
	"active": "langToggler_active__KA_1D"
};