import React from 'react';
import PrintButton from '../Buttons/PrintButton';
import Subnav from '../ReportsSubnav/ReportsSubnavLayout';
import { Link } from 'react-router-dom';
// import Header from '../Header/Header';
import MobileNav from '../MobileNav/MobileNav';
// import {setAppVersion} from "../../Store/appVersion";
import HeaderVersion from "../NewDesign/HeaderVersion/HeaderVersion";

const ReportSectionsLayout = ({ report: sections, error, date, idKkm, history }) => {
	// const goNew = () => {
	// 	history.push(`/www/shifts/${idKkm}/report`)
	// 	localStorage.setItem('reportStep', '4')
	// 	setAppVersion(true)
	// }

	// const oldVersion = {
	// 	float: "right",
	// 	marginTop: "10px",
	// 	width: "106px",
	// 	height: "38px",
	// 	background: "#FFFFFF",
	// 	borderRadius: "8px",
	// 	display: "flex",
	// 	justifyContent: "space-between",
	// 	alignItems: "center",
	// 	padding: "4px 13px 4px 8px",
	// 	border: "1px solid #D7D7D7",
	// 	cursor: "pointer"
	// }
	// const oldVersionText = {
	// 	color: "#575757",
	// 	lineHeight: "15px",
	// 	fontSize: "12px",
	// 	margin: "0"
	// }

	return (
		<React.Fragment>
			{
				window.matchMedia("(min-width: 1100px)").matches ?
					<HeaderVersion isActive={true} />
					:
					<MobileNav isActive={true} />
			}
			<div className="container pt-5 pb-5">
				<div className="heading">
					<h1 className="text-center">Отчёт по секциям</h1>
					<h3 className="text-center">
						<span className="badge badge-primary">
							{date}
						</span>
					</h3>
				</div>
				<div className="d-flex justify-content-end">
					{/* <div style={oldVersion} onClick={goNew}>
						<p style={oldVersionText}>Новый вид<br />сайта</p>
						<svg width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M4.6665 4L0.666504 8V0L4.6665 4Z" fill="#8E8E8E"/>
						</svg>
					</div> */}
				</div>
				<div className="pt-2 pb-3 d-flex justify-content-between align-items-center">
					<Link to={`/www/kkms/${idKkm}`} class="btn btn-secondary btn-lg btn-sm-block mb-3">← Вернуться в кассу</Link>
					<PrintButton />
				</div>

				<React.Fragment>
					<Subnav active="report-sections" history={history} />
					{
						!error.status ?
							<div className="pt-0">
								<div className="card bReportCard">
									<div className="card-header">
										Итоги по секциям за смену
								</div>
									<div className="card-body bReportCard__body">
										{
											Object.keys(sections).map(key => {
												return (
													<React.Fragment key={key}>
														<h5 className="border-bottom pb-2">
															Секция: {key} {sections[key].Name}
														</h5>
														<div className="table-wrapper pb-2">
															<table className="table table-sm table-borderless table-responsive-sm">
																<tbody>
																	<tr>
																		<td style={{ width: '60%' }}><strong>Продажи</strong></td>
																		<td>{new Intl.NumberFormat('ru-RU').format(sections[key].Sales)} ₸</td>
																	</tr>
																	<tr>
																		<td><strong>Возвратов продаж</strong></td>
																		<td>{new Intl.NumberFormat('ru-RU').format(sections[key].Refunds)} ₸</td>
																	</tr>
																	<tr>
																		<td><strong>Покупки</strong></td>
																		<td>{new Intl.NumberFormat('ru-RU').format(sections[key].Purchases)} ₸</td>
																	</tr>
																	<tr>
																		<td><strong>Возвратов покупок</strong></td>
																		<td>{new Intl.NumberFormat('ru-RU').format(sections[key].PurchaseRefunds)} ₸</td>
																	</tr>
																	<tr>
																		<td><strong>Служебный приход</strong></td>
																		<td>{new Intl.NumberFormat('ru-RU').format(sections[key].Incomes)} ₸</td>
																	</tr>
																	<tr>
																		<td><strong>Служебный расход</strong></td>
																		<td>{new Intl.NumberFormat('ru-RU').format(sections[key].Expenses)} ₸</td>
																	</tr>
																</tbody>
															</table>
														</div>
													</React.Fragment>
												)
											})
										}
									</div>
								</div>
							</div>
							:
							<div className="alert alert-danger" style={{ margin: 'auto', maxWidth: '800px' }}>
								<h5>{error.title}</h5>
								<p>{error.text}</p>
							</div>
					}
				</React.Fragment>



			</div>
		</React.Fragment>
	)
}

export default ReportSectionsLayout;
