import React from 'react';
import Spinner from '../Common/Spinner';
import { Link } from 'react-router-dom';
const Testing = ({ connection, connectionErrorText: text, user }) => {
	return (
		<main role="main" className="container">
			<div className="jumbotron shadow newshift">
				<h1 className="pb-2">Тестирование</h1>
				{
					connection ?
						(
							<div className={`alert alert-secondary`} role="alert">
								<h4 className="alert-heading pb-2">Проводим автоматическое тестирование...</h4>
								<p><Spinner className="gray mr-2" /> Основные блоки и узлы</p>
								<p><Spinner className="gray mr-2" /> Программное обеспечение ККМ</p>
								<p><Spinner className="gray mr-2" /> Контроль целостности данных сохраненных в накопителе фискальных данных</p>
								<p><Spinner className="gray mr-2" /> Связь с сервером оператора фискальных данных</p>
							</div>
						)
						:
						(
							<>
								<div className="d-flex justify-content-between align-items-center pb-3 d-lg-none">
									<div className="m-cashbox__name">{user.Name}</div>
									<Link to="/" className="btn btn-secondary">Выйти</Link>
								</div>
								<div className={`alert alert-danger`} role="alert">
									<h4 className="alert-heading pb-2">Ошибка тестирования</h4>
									<p>{text}</p>
								</div>
							</>

						)
				}

			</div>
		</main>
	);
};

export default Testing;
