exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Prechek_lhCondensed__2LfaU {\n    transition: all .1s ease-in-out;\n    line-height: 1.25;\n    zoom: 1;\n}\n\n.Prechek_lhCondensed__2LfaU:hover {\n    cursor: pointer;\n    transform: scale(1.2);\n    z-index: 999;\n    transition: all .2s ease-in-out;\n    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;\n}\n\n.Prechek_lhCondensed__2LfaU:hover .Prechek_s_p__139O7 { display:block !important; }\n\n.Prechek_s_d__SUK74 {\n    opacity: .15;\n}\n\n.Prechek_s_i__2H373 {\n    font-size: 88%;\n}\n\n.Prechek_s_t__2Orh2 {\n    font-size: 135%;\n}\n\n.Prechek_storno__23nsz {\n    text-decoration: line-through;\n}\n.Prechek_storned__3AnQj {\n    color: #E6342A;\n    text-decoration-line: line-through;\n}", ""]);

// exports
exports.locals = {
	"lhCondensed": "Prechek_lhCondensed__2LfaU",
	"s_p": "Prechek_s_p__139O7",
	"s_d": "Prechek_s_d__SUK74",
	"s_i": "Prechek_s_i__2H373",
	"s_t": "Prechek_s_t__2Orh2",
	"storno": "Prechek_storno__23nsz",
	"storned": "Prechek_storned__3AnQj"
};