exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".check_main__3gHDQ {\n  margin-top: 20px;\n  padding-bottom: 20px;\n  display: flex;\n  justify-content: center; }\n\n.check_spinnerWrapper__y28Ft {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100px; }\n  .check_spinnerWrapper__y28Ft .check_text__2-I9n {\n    margin-left: 10px;\n    margin-bottom: 0; }\n  .check_spinnerWrapper__y28Ft svg {\n    animation: check_spin__33TJB 3s linear infinite; }\n\n@keyframes check_spin__33TJB {\n  0% {\n    transform: rotate(0deg); }\n  100% {\n    transform: rotate(360deg); } }\n\n@media only screen and (max-width: 415px) {\n  .check_main__3gHDQ {\n    flex-direction: column;\n    align-items: center;\n    padding-bottom: 70px; } }\n", ""]);

// exports
exports.locals = {
	"main": "check_main__3gHDQ",
	"spinnerWrapper": "check_spinnerWrapper__y28Ft",
	"text": "check_text__2-I9n",
	"spin": "check_spin__33TJB"
};