import React from "react";
import style from '../../styles/pages/report/duplicates/NewReportZDuplicate.module.scss'
import NewReportZDuplicateTop from "./NewReportZDuplicateTop";
import NewReportZDuplicateSections from "./NewReportZDuplicateSections";
import NewReportZDuplicateInfo from "./NewReportZDuplicateInfo";
import NewReportZDuplicateOperations from "./NewReportZDuplicateOperations";

const NewReportZDuplicate = ({ reportZChoosen, user, setReportZDuplicateIsOpen}) => {
	return (
		<React.Fragment>
			<NewReportZDuplicateTop reportZChoosen={reportZChoosen} setReportZDuplicateIsOpen={setReportZDuplicateIsOpen} />
			<div className={`${style['flex-4']} ${style['mt-20']}`}>
				<NewReportZDuplicateInfo reportZChoosen={reportZChoosen} user={user} />
				<NewReportZDuplicateSections reportZChoosen={reportZChoosen} />
				<NewReportZDuplicateOperations reportZChoosen={reportZChoosen} />
			</div>
		</React.Fragment>
	)
}

export default NewReportZDuplicate
