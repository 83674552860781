import React from 'react';
import { Modal, ModalBody } from 'react-bootstrap';
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import { ReactComponent as Logo } from '../Svg/white-logo.svg'
import { useTranslation } from "react-i18next";

const BarcodeScanner = ({ setCode, setShowScanner, showScanner }) => {
  const { t } = useTranslation()

  return (
    <Modal
      show={true && showScanner}
      centered
      onHide={() => setShowScanner(false)}
      className="modal-article-barcode"
    >
      <ModalBody>
      <BarcodeScannerComponent
        width="534px"
        height="519px"
        onUpdate={(err, result) => {
          if (result) {
            setCode(result.text)
            setShowScanner(false)
          }
        }}
      />
      <div className='video-cover'>
        <p className="title">{ t('receipt.scan_barcode') }</p>
        <div className='scanner-hole'></div>
        <Logo />
        <p className="cancel" onClick={() => setShowScanner(false)}>{ t('receipt.cancel') }</p>
      </div>
      </ModalBody>
    </Modal>
  )
}

export default BarcodeScanner;
