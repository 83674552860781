exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".newdesignbtn_wrapper__RSI3u {\n  position: absolute;\n  border: none;\n  width: 110px;\n  height: 42px;\n  top: 50%;\n  left: 100%;\n  transform: translateY(-50%);\n  font-size: 12px;\n  line-height: 15px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: #575757;\n  border-radius: 8px;\n  background: #ECECEC; }\n  .newdesignbtn_wrapper__RSI3u svg {\n    width: 8px;\n    height: 8px; }\n", ""]);

// exports
exports.locals = {
	"wrapper": "newdesignbtn_wrapper__RSI3u"
};