import React from 'react';
// import { BASE_URL } from '../../Store/snippets';
import PrintButton from '../Buttons/PrintButton';
import { Link } from 'react-router-dom';
import Subnav from '../ReportsSubnav/ReportsSubnavLayout';
// import Header from '../Header/Header';
import MobileNav from '../MobileNav/MobileNav';
// import {setAppVersion} from "../../Store/appVersion";
import HeaderVersion from "../NewDesign/HeaderVersion/HeaderVersion";

const ReportXlayout = ({ report: Report, user: User, error, date, goBack, history, idKkm, formatDate }) => {

	// const goNew = () => {
	// 	history.push(`/www/shifts/${idKkm}/report`)
	// 	localStorage.setItem('reportStep', '2')
	// 	setAppVersion(true)
	// }


	// const oldVersion = {
	// 	float: "right",
	// 	marginTop: "10px",
	// 	width: "106px",
	// 	height: "38px",
	// 	background: "#FFFFFF",
	// 	borderRadius: "8px",
	// 	display: "flex",
	// 	justifyContent: "space-between",
	// 	alignItems: "center",
	// 	padding: "4px 13px 4px 8px",
	// 	border: "1px solid #D7D7D7",
	// 	cursor: "pointer"
	// }
	// const oldVersionText = {
	// 	color: "#575757",
	// 	lineHeight: "15px",
	// 	fontSize: "12px",
	// 	margin: "0"
	// }

	return (
		<React.Fragment>
			{
				window.matchMedia("(min-width: 1100px)").matches ?
					<HeaderVersion isActive={true} />
					:
					<MobileNav isActive={true} />
			}
			<div className="container pt-5 pb-5">
				<div className="heading">
					<h1 className="text-center">X-отчёт</h1>
					<h3 className="text-center">
						<span className="badge badge-primary">
							{date}
						</span>
					</h3>
					<div className="d-flex justify-content-end">
						{/* <div style={oldVersion} onClick={goNew}>
							<p style={oldVersionText}>Новый вид<br />сайта</p>
							<svg width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M4.6665 4L0.666504 8V0L4.6665 4Z" fill="#8E8E8E"/>
							</svg>
						</div> */}
					</div>
					<div className="pt-2 pb-3 d-flex justify-content-between align-items-center">
						<Link to={`/www/kkms/${idKkm}`} class="btn btn-secondary btn-lg btn-sm-block mb-3">← Вернуться в кассу</Link>
						{/* <button onClick={goBack}></button> */}
						<PrintButton />
					</div>
				</div>
				<Subnav active="report-x" history={history} />
				{
					!error.status ?
						<React.Fragment>
							<div className="row pt-0">
								<div className="col-md-6">
									<div className="card bReportCard">
										<div className="card-header">
											Основная информация
									</div>
										<div className="card-body">
											<div className="table-wrapper">
												<table className="table table-sm table-borderless table-responsive-sm">
													<tbody>
														<tr>
															<td><strong>Наименование организации</strong></td>
															<td>{Report.Kkm.Company.FullName}</td>
														</tr>
														<tr>
															<td><strong>ИИН/БИН</strong></td>
															<td>{Report.Kkm.Company.Bin}</td>
														</tr>
														<tr>
															<td><strong>ККМ</strong></td>
															<td>{Report.Kkm.Id}</td>
														</tr>
														<tr>
															<td><strong>Регистрационный номер в КГД</strong></td>
															<td>{Report.Kkm.Rnm}</td>
														</tr>
														<tr>
															<td><strong>Название кассы</strong></td>
															<td>{Report.Kkm.Name}</td>
														</tr>
														<tr>
															<td><strong>Адрес торговой точки</strong></td>
															<td>{Report.Kkm.Address.Town.Name}, {Report.Kkm.Address.Street}
																{Report.Kkm.Address.House} {Report.Kkm.Address.Flat}</td>
														</tr>
														<tr>
															<td><strong>Кассир</strong></td>
															<td>{User.Name}</td>
														</tr>
													</tbody>
												</table>
											</div>
											<hr />
											<div className="table-wrapper">
												<table className="table table-sm table-borderless table-responsive-sm">
													<tbody>
														<tr>
															<td><strong>Баланс на момент открытия смены</strong></td>
															<td style={{ whiteSpace: 'nowrap' }}>{new Intl.NumberFormat('ru-RU').format(Report.BalanceOpen)} ₸</td>
														</tr>
														{/* <tr>
												<td><strong>Баланс на момент запроса Х-отчета</strong></td>
												<td>{Number(Report.BalanceClose).toFixed(2)}</td>
											</tr> */}
														<tr>
															<td><strong>Оформлено чеков</strong></td>
															<td>{Report.Count}</td>
														</tr>
													</tbody>
												</table>
											</div>

										</div>
									</div>
								</div>

								<div className="col-md-6">
									<div className="card bReportCard">
										<div className="card-header">
											Оформленные чеки
									</div>
										<div className="card-body bReportCard__body">

											<h5 className="border-bottom pb-2">Служебный приход</h5>
											<div className="table-wrapper pb-2">
												<table className="table table-sm table-borderless table-responsive-sm">
													{Report.Total.Incomes.Qty > 0 ?
														<tbody>
															<tr>
																<td><strong>Кол-во чеков</strong></td>
																<td style={window.matchMedia("(min-width: 1100px)").matches ? { width: '40%' } : {}}>{Report.Total.Incomes.Qty}</td>
															</tr>
															<tr>
																<td><strong>Общая сумма</strong></td>
																<td>{new Intl.NumberFormat('ru-RU').format(Report.Total.Incomes.Amount)} ₸</td>
															</tr>
														</tbody>
														:
														<tbody>
															<tr>
																<td><strong>Чеков нет</strong></td>
															</tr>
														</tbody>
													}
												</table>
											</div>

											<h5 className="border-bottom pb-2">
												Служебный расход
											</h5>
											<div className="table-wrapper pb-2">
												<table className="table table-sm table-borderless table-responsive-sm">
													{Report.Total.Expenses.Qty > 0 ?
														<tbody>
															<tr>
																<td><strong>Кол-во чеков</strong></td>
																<td style={window.matchMedia("(min-width: 1100px)").matches ? { width: '40%' } : {}}>{Report.Total.Expenses.Qty}</td>
															</tr>
															<tr>
																<td><strong>Общая сумма</strong></td>
																<td>{new Intl.NumberFormat('ru-RU').format(Report.Total.Expenses.Amount)} ₸</td>
															</tr>
														</tbody>
														:
														<tbody>
															<tr>
																<td><strong>Чеков нет</strong></td>
															</tr>
														</tbody>
													}
												</table>
											</div>

											<h5 className="border-bottom pb-2">
												Продажи
								</h5>
											<div className="table-wrapper pb-2">
												<table className="table table-sm table-borderless table-responsive-sm">
													{Report.Total.Sales.Qty > 0 ?
														<tbody>
															<tr>
																<td><strong>Кол-во чеков</strong></td>
																<td style={window.matchMedia("(min-width: 1100px)").matches ? { width: '40%' } : {}}>{Report.Total.Sales.Qty}</td>
															</tr>
															<tr>
																<td><strong>Общая сумма</strong></td>
																<td>{new Intl.NumberFormat('ru-RU').format(Report.Total.Sales.Amount)} ₸</td>
															</tr>
														</tbody>
														:
														<tbody>
															<tr>
																<td><strong>Чеков нет</strong></td>
															</tr>
														</tbody>
													}
												</table>
											</div>

											<h5 className="border-bottom pb-2">
												Возвраты продаж
											</h5>
											<div className="table-wrapper pb-2">
												<table className="table table-sm table-borderless table-responsive-sm">
													{Report.Total.Refunds.Qty > 0 ?
														<tbody>
															<tr>
																<td><strong>Кол-во чеков</strong></td>
																<td style={window.matchMedia("(min-width: 1100px)").matches ? { width: '40%' } : {}}>{Report.Total.Refunds.Qty}</td>
															</tr>
															<tr>
																<td><strong>Общая сумма</strong></td>
																<td>{new Intl.NumberFormat('ru-RU').format(Report.Total.Refunds.Amount)} ₸</td>
															</tr>
														</tbody>
														:
														<tbody>
															<tr>
																<td><strong>Чеков нет</strong></td>
															</tr>
														</tbody>
													}

												</table>
											</div>

											<h5 className="border-bottom pb-2">
												Покупки
							</h5>
											<div className="table-wrapper pb-2">
												<table className="table table-sm table-borderless table-responsive-sm">
													{Report.Total.Purchases.Qty > 0 ?
														<tbody>
															<tr>
																<td><strong>Кол-во чеков</strong></td>
																<td style={window.matchMedia("(min-width: 1100px)").matches ? { width: '40%' } : {}}>{Report.Total.Purchases.Qty}</td>
															</tr>
															<tr>
																<td><strong>Общая сумма</strong></td>
																<td>{new Intl.NumberFormat('ru-RU').format(Report.Total.Purchases.Amount)} ₸</td>
															</tr>
														</tbody>
														:
														<tbody>
															<tr>
																<td><strong>Чеков нет</strong></td>
															</tr>
														</tbody>
													}
												</table>
											</div>
											<h5 className="border-bottom pb-2">
												Возвраты покупок
											</h5>
											<div className="table-wrapper pb-2">
												<table className="table table-sm table-borderless table-responsive-sm">
													{Report.Total.PurchaseRefunds.Qty > 0 ?
														<tbody>
															<tr>
																<td><strong>Кол-во чеков</strong></td>
																<td style={window.matchMedia("(min-width: 1100px)").matches ? { width: '40%' } : {}}>{Report.Total.PurchaseRefunds.Qty}</td>
															</tr>
															<tr>
																<td><strong>Общая сумма</strong></td>
																<td>{new Intl.NumberFormat('ru-RU').format(Report.Total.PurchaseRefunds.Amount)} ₸</td>
															</tr>
														</tbody>
														:
														<tbody>
															<tr>
																<td><strong>Чеков нет</strong></td>
															</tr>
														</tbody>
													}

												</table>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="pt-5">
								<div className="card">
									<div className="card-header">
										Cписок операций
								</div>
									<div className="card-body">
										<div className="table-wrapper">
											{
												Report.Operations.length > 0 ?
													<table className="table table-responsive-sm">
														<thead>
															<tr>
																<th>Наименование</th>
																<th>Дата транзакции</th>
																<th>Номер транзакции</th>
																<th className="text-right">Сумма</th>
															</tr>
														</thead>
														<tbody>
															{
																Report.Operations.map(operation => {
																	return (
																		<tr key={operation.NumberDoc}>
																			<td>{operation.TypeDocument.Name}</td>
																			<td>{formatDate(new Date(operation.DateDocument))}</td>
																			<td>{operation.NumberDoc}</td>
																			<td className="text-right">{new Intl.NumberFormat('ru-RU').format(operation.Value)} ₸</td>
																		</tr>
																	)
																})
															}
														</tbody>
													</table>
													:
													<table>
														<thead>
															<tr>
																<th>
																	Операций не производилось
													</th>
															</tr>
														</thead>
													</table>
											}
										</div>
									</div>
								</div>
							</div>
						</React.Fragment>
						:
						error.status === 410 ?
							<div className="text-center" style={{ paddingTop: '20%' }}>
								<h5><strong>{error.title}</strong></h5>
								<p>{error.text}</p>
								<Link to={`/www/kkms/${idKkm}`} className="btn btn-primary btn-lg btn-sm-block">Открыть смену</Link>
								{/* <a href={`${BASE_URL}/www/kkms/${idKkm}`} className="btn btn-primary btn-lg btn-sm-block">Открыть смену</a> */}
							</div>
							:
							<div className="alert alert-danger" style={{ margin: 'auto', maxWidth: '800px' }}>
								<h5>{error.title}</h5>
								<p>{error.text}</p>
							</div>
				}
			</div>
		</React.Fragment>
	)
}

export default ReportXlayout;
