import React from "react";
const Logo = ({ fill, width }) => {
	return (
		<React.Fragment>
			<svg width={width ? `${width}` : "110"} height="12" viewBox="0 0 110 12" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M0.111345 0.607454C0.181446 0.533168 0.280404 0.493835 0.399988 0.493835H1.58758C1.71129 0.493835 1.80614 0.533168 1.87624 0.607454C1.94634 0.681741 1.98344 0.786621 1.98344 0.913345V6.63776L4.2638 3.64884C4.37926 3.49153 4.53595 3.41285 4.73801 3.41285H6.21424C6.3297 3.41285 6.41217 3.43472 6.4699 3.48279C6.52763 3.53085 6.55651 3.59202 6.55651 3.66631C6.55651 3.7406 6.51527 3.82799 6.43692 3.93286L3.94627 6.99172L6.59362 10.54C6.66372 10.6449 6.69671 10.7323 6.69671 10.8066C6.69671 10.8809 6.66784 10.942 6.60599 10.9901C6.54414 11.0382 6.46166 11.06 6.35444 11.06H4.89058C4.68852 11.06 4.52358 10.977 4.404 10.8066L2.68858 8.54301L1.9752 9.40823V10.6361C1.9752 10.7672 1.94221 10.8677 1.86798 10.942C1.79788 11.0163 1.70305 11.0556 1.57934 11.0556H0.391745C0.268038 11.0556 0.173191 11.0207 0.10309 10.942C0.0329887 10.8677 0 10.7672 0 10.6361V0.917719C0.00412358 0.782256 0.0412439 0.681741 0.111345 0.607454Z" fill={fill ? fill : 'white'}/>
				<path d="M13.5417 10.9419C13.4716 10.8677 13.4344 10.7671 13.4344 10.636V10.2865C13.2571 10.5399 12.9643 10.7584 12.552 10.9419C12.1396 11.1298 11.6984 11.2216 11.2324 11.2216C10.4943 11.2216 9.84691 11.0468 9.29435 10.6972C8.74179 10.3476 8.31706 9.86259 8.02841 9.23771C7.73976 8.63031 7.59131 7.95736 7.59131 7.21449C7.59131 6.45852 7.73976 5.78121 8.03254 5.1738C8.32531 4.57077 8.74591 4.09011 9.29022 3.73615C9.83454 3.3822 10.4819 3.2074 11.2283 3.2074C11.7108 3.2074 12.1479 3.29479 12.5314 3.47395C12.919 3.64874 13.2159 3.88473 13.4262 4.17313V3.79731C13.4262 3.66622 13.4592 3.5657 13.5334 3.49142C13.6035 3.41713 13.6983 3.37783 13.8221 3.37783H14.9808C15.1045 3.37783 15.1993 3.41713 15.2694 3.49142C15.3395 3.5657 15.3766 3.66622 15.3766 3.79731V10.6404C15.3766 10.7715 15.3436 10.872 15.2694 10.9463C15.1993 11.0206 15.1045 11.0599 14.9808 11.0599H13.8221C13.7066 11.0512 13.6118 11.0162 13.5417 10.9419ZM12.9643 5.63263C12.6015 5.23061 12.119 5.03397 11.5211 5.03397C10.9149 5.03397 10.4325 5.23498 10.0696 5.63263C9.71084 6.03465 9.5294 6.55902 9.5294 7.21012C9.5294 7.84374 9.71496 8.36374 10.082 8.76576C10.449 9.17215 10.9314 9.37317 11.517 9.37317C12.1314 9.37317 12.618 9.18089 12.9726 8.79198C13.3272 8.40744 13.5045 7.87869 13.5045 7.20574C13.5128 6.55901 13.3313 6.03465 12.9643 5.63263Z" fill={fill ? fill : 'white'}/>
				<path d="M17.245 10.2821C17.1295 10.1991 17.0718 10.0942 17.0718 9.97619C17.0718 9.88442 17.1048 9.78828 17.1666 9.69652L17.7315 8.90121C17.8181 8.77886 17.9212 8.71769 18.0367 8.71769C18.1233 8.71769 18.2016 8.73955 18.2759 8.78762C18.5934 8.97552 18.9274 9.12407 19.2861 9.23332C19.6408 9.34693 19.983 9.39937 20.3088 9.39937C20.6057 9.39937 20.8572 9.33382 21.0552 9.20273C21.2531 9.07163 21.352 8.90995 21.352 8.71331C21.352 8.53852 21.2613 8.40744 21.0758 8.32005C20.8902 8.23702 20.6552 8.17147 20.3624 8.1234L19.9542 8.06659C19.348 7.96609 18.8697 7.84372 18.5191 7.70389C18.1686 7.56406 17.847 7.34121 17.5584 7.03533C17.2408 6.69885 17.0841 6.24001 17.0841 5.65446C17.0841 4.7805 17.381 4.15563 17.9748 3.7842C18.5686 3.41277 19.2696 3.22485 20.0779 3.22485C20.5851 3.22485 21.0593 3.29479 21.4964 3.43462C21.9294 3.57446 22.3541 3.78856 22.7664 4.07697C22.8902 4.15126 22.952 4.25177 22.952 4.38286C22.952 4.46589 22.919 4.56203 22.8448 4.66253L22.2922 5.44472C22.2056 5.56707 22.1025 5.62387 21.9871 5.62387C21.9005 5.62387 21.818 5.60203 21.7356 5.55396C21.1294 5.20875 20.5562 5.03833 20.0078 5.03833C19.7686 5.03833 19.5542 5.09076 19.3562 5.20001C19.1583 5.30925 19.0593 5.44472 19.0593 5.61077C19.0593 5.81615 19.1789 5.96473 19.4222 6.0565C19.6655 6.14826 19.9995 6.22691 20.4325 6.29246C21.1335 6.42355 21.6572 6.55028 21.9953 6.677C22.3335 6.80372 22.6345 7.01348 22.8984 7.31063C23.1788 7.65584 23.319 8.1234 23.319 8.72206C23.319 9.52173 23.0592 10.1379 22.5438 10.5705C22.0242 11.0031 21.2902 11.2216 20.3418 11.2216C19.1583 11.2347 18.1315 10.9157 17.245 10.2821Z" fill={fill ? fill : 'white'}/>
				<path d="M24.783 10.2821C24.6676 10.1991 24.6099 10.0942 24.6099 9.97619C24.6099 9.88442 24.6428 9.78828 24.7047 9.69652L25.2696 8.90121C25.3562 8.77886 25.4593 8.71769 25.5748 8.71769C25.6614 8.71769 25.7397 8.73955 25.8139 8.78762C26.1315 8.97552 26.4655 9.12407 26.8242 9.23332C27.1788 9.34693 27.5211 9.39937 27.8469 9.39937C28.1438 9.39937 28.3953 9.33382 28.5932 9.20273C28.7912 9.07163 28.8901 8.90995 28.8901 8.71331C28.8901 8.53852 28.7994 8.40744 28.6139 8.32005C28.4283 8.23702 28.1933 8.17147 27.9005 8.1234L27.4922 8.06659C26.8861 7.96609 26.4077 7.84372 26.0572 7.70389C25.7067 7.56406 25.3851 7.34121 25.0964 7.03533C24.7789 6.69885 24.6222 6.24001 24.6222 5.65446C24.6222 4.7805 24.9191 4.15563 25.5129 3.7842C26.1067 3.41277 26.8077 3.22485 27.616 3.22485C28.1232 3.22485 28.5974 3.29479 29.0345 3.43462C29.4674 3.57446 29.8922 3.78856 30.3045 4.07697C30.4282 4.15126 30.4901 4.25177 30.4901 4.38286C30.4901 4.46589 30.4571 4.56203 30.3829 4.66253L29.8303 5.44472C29.7437 5.56707 29.6406 5.62387 29.5252 5.62387C29.4386 5.62387 29.3561 5.60203 29.2736 5.55396C28.6675 5.20875 28.0943 5.03833 27.5458 5.03833C27.3067 5.03833 27.0923 5.09076 26.8943 5.20001C26.6964 5.30925 26.5974 5.44472 26.5974 5.61077C26.5974 5.81615 26.717 5.96473 26.9603 6.0565C27.2036 6.14826 27.5376 6.22691 27.9706 6.29246C28.6716 6.42355 29.1953 6.55028 29.5334 6.677C29.8715 6.80372 30.1726 7.01348 30.4365 7.31063C30.7169 7.65584 30.8571 8.1234 30.8571 8.72206C30.8571 9.52173 30.5973 10.1379 30.0819 10.5705C29.5623 11.0031 28.8283 11.2216 27.8798 11.2216C26.6964 11.2347 25.6696 10.9157 24.783 10.2821Z" fill={fill ? fill : 'white'}/>
				<path d="M38.0111 10.9419C37.941 10.8677 37.9039 10.7671 37.9039 10.636V10.2865C37.7266 10.5399 37.4338 10.7584 37.0215 10.9419C36.6091 11.1298 36.1679 11.2216 35.7019 11.2216C34.9638 11.2216 34.3164 11.0468 33.7638 10.6972C33.2113 10.3476 32.7865 9.86259 32.4979 9.23771C32.2092 8.63031 32.0608 7.95736 32.0608 7.21449C32.0608 6.45852 32.2092 5.78121 32.502 5.1738C32.7948 4.57077 33.2154 4.09011 33.7597 3.73615C34.304 3.3822 34.9514 3.2074 35.6978 3.2074C36.1803 3.2074 36.6174 3.29479 37.0009 3.47395C37.3885 3.64874 37.6854 3.88473 37.8957 4.17313V3.79731C37.8957 3.66622 37.9287 3.5657 38.0029 3.49142C38.073 3.41713 38.1678 3.37783 38.2915 3.37783H39.4502C39.5739 3.37783 39.6688 3.41713 39.7389 3.49142C39.809 3.5657 39.8461 3.66622 39.8461 3.79731V10.6404C39.8461 10.7715 39.8131 10.872 39.7389 10.9463C39.6688 11.0206 39.5739 11.0599 39.4502 11.0599H38.2915C38.1761 11.0512 38.0812 11.0162 38.0111 10.9419ZM37.438 5.63263C37.0751 5.23061 36.5926 5.03397 35.9947 5.03397C35.3885 5.03397 34.9061 5.23498 34.5432 5.63263C34.1844 6.03465 34.003 6.55902 34.003 7.21012C34.003 7.84374 34.1886 8.36374 34.5556 8.76576C34.9226 9.17215 35.405 9.37317 35.9906 9.37317C36.605 9.37317 37.0916 9.18089 37.4462 8.79198C37.8008 8.40744 37.9781 7.87869 37.9781 7.20574C37.9864 6.55901 37.8008 6.03465 37.438 5.63263Z" fill={fill ? fill : 'white'}/>
				<path d="M45.4376 5.35298C45.4789 5.39668 45.4995 5.45787 45.4995 5.53216V6.16141C45.4995 6.2357 45.4789 6.29686 45.4376 6.34056C45.3964 6.38426 45.3387 6.40612 45.2686 6.40612H41.6563C41.5862 6.40612 41.5285 6.38426 41.4873 6.34056C41.446 6.29686 41.4254 6.2357 41.4254 6.16141V5.38795C41.4254 5.26996 41.4667 5.16946 41.5574 5.09518L42.3037 4.4528L43.4789 3.45213C43.7717 3.20742 43.9655 2.99767 44.0686 2.82725C44.1676 2.65246 44.2171 2.46018 44.2171 2.24169C44.2171 1.99261 44.1387 1.80471 43.982 1.67362C43.8253 1.54253 43.6274 1.48135 43.3882 1.48135C43.0212 1.48135 42.745 1.72169 42.5677 2.198C42.5223 2.31599 42.4522 2.37715 42.3615 2.37715C42.345 2.37715 42.3202 2.37279 42.2749 2.35968L41.545 2.17178C41.4254 2.14119 41.3677 2.07126 41.3677 1.96639C41.3677 1.92269 41.3718 1.89211 41.3842 1.87027C41.4749 1.62993 41.6027 1.40707 41.7635 1.19295C41.9244 0.978833 42.1223 0.795297 42.3573 0.642354C42.6542 0.454453 42.9965 0.358307 43.3882 0.358307C43.8171 0.358307 44.1882 0.445696 44.5016 0.624858C44.815 0.79965 45.05 1.03999 45.2067 1.33714C45.3593 1.62117 45.4376 1.92269 45.4376 2.24169C45.4376 2.61749 45.3346 2.97145 45.1243 3.31229C44.9181 3.65314 44.6088 4.00271 44.2006 4.3654L43.8872 4.63635L43.549 4.91163C43.3923 5.05146 43.2357 5.18256 43.0872 5.29617H45.2686C45.3428 5.28743 45.3964 5.30928 45.4376 5.35298Z" fill={fill ? fill : 'white'}/>
				<path d="M51.0087 4.06396C51.0499 4.10766 51.0706 4.16883 51.0706 4.24311V4.86799C51.0706 4.94228 51.0499 5.00347 51.0087 5.04717C50.9675 5.09087 50.9097 5.1127 50.8396 5.1127H50.3695V6.15709C50.3695 6.23137 50.3489 6.29254 50.3077 6.33624C50.2664 6.37993 50.2087 6.40179 50.1386 6.40179H49.384C49.3139 6.40179 49.2562 6.37993 49.2149 6.33624C49.1737 6.29254 49.1531 6.23137 49.1531 6.15709V5.1127H46.6418C46.5717 5.1127 46.514 5.09087 46.4728 5.04717C46.4315 5.00347 46.4109 4.94228 46.4109 4.86799V4.24311C46.4109 4.13387 46.448 4.03336 46.5181 3.9416L49.3386 0.576848C49.4087 0.485083 49.5036 0.437012 49.6232 0.437012H50.1386C50.2087 0.437012 50.2664 0.458871 50.3077 0.502569C50.3489 0.546267 50.3695 0.607433 50.3695 0.681719V3.99403H50.8396C50.9097 3.9984 50.9675 4.02027 51.0087 4.06396ZM47.9985 3.99841H49.1572V2.55638L47.9985 3.99841Z" fill={fill ? fill : 'white'}/>
				<path d="M60.5426 4.43457C61.1653 5.12936 61.4787 6.05139 61.4787 7.21812C61.4787 8.38049 61.1653 9.30688 60.5426 9.99293C59.9199 10.679 59.1612 11.0286 58.2788 11.0286C57.2973 11.0286 56.4932 10.5523 55.8706 9.59529L55.7427 10.596C55.6974 10.7751 55.6149 10.8625 55.4912 10.8625H54.2789C54.1139 10.8625 54.0273 10.7751 54.0273 10.596V1.01301C54.0273 0.838219 54.1098 0.74646 54.2789 0.74646H55.6479C55.8129 0.74646 55.8994 0.833849 55.8994 1.01301V4.70549C56.5922 3.84027 57.4087 3.40768 58.3447 3.40768C59.1818 3.40331 59.9199 3.74851 60.5426 4.43457ZM59.0581 8.80874C59.4127 8.42856 59.5901 7.89544 59.5901 7.21812C59.5901 6.54518 59.4086 6.01643 59.0581 5.63189C58.7035 5.25172 58.2623 5.05945 57.7344 5.05945C57.2025 5.05945 56.7448 5.2561 56.3819 5.64501C56.0149 6.03392 55.8293 6.57141 55.8293 7.25746C55.8293 7.94352 56.0108 8.47663 56.3737 8.83495C56.7407 9.19328 57.1819 9.3768 57.7097 9.3768C58.254 9.38117 58.7035 9.18891 59.0581 8.80874Z" fill={fill ? fill : 'white'}/>
				<path d="M69.2764 3.58761C69.4414 3.58761 69.528 3.675 69.528 3.85416V10.5924C69.528 10.7715 69.4455 10.8589 69.2764 10.8589H67.9486C67.8084 10.8589 67.7259 10.7715 67.6971 10.5924L67.6064 9.88447C66.959 10.6448 66.1549 11.025 65.2106 11.025C63.5611 11.025 62.6746 9.80145 62.6746 7.54226V3.84978C62.6746 3.67499 62.7571 3.58323 62.9261 3.58323H64.357C64.5261 3.58323 64.6085 3.67062 64.6085 3.84978V7.54226C64.6085 8.58228 65.124 9.19406 66.0023 9.19406C66.9095 9.19406 67.5363 8.5211 67.594 7.10528V3.84544C67.594 3.67064 67.6765 3.57886 67.8456 3.57886H69.2764V3.58761Z" fill={fill ? fill : 'white'}/>
				<path d="M72.035 10.6885C71.5484 10.4919 71.1608 10.2471 70.8845 9.94126C70.7691 9.8189 70.7608 9.69655 70.8598 9.56546L71.3422 8.74394C71.4247 8.59536 71.5525 8.57352 71.7134 8.6915C71.936 8.90125 72.2329 9.07167 72.5917 9.20276C72.9504 9.33385 73.305 9.39941 73.6556 9.39941C74.3731 9.39941 74.7854 9.11973 74.7854 8.63032C74.7854 8.29821 74.4762 8.07973 74.1999 8.00544C74.0638 7.97048 73.8329 7.91806 73.5071 7.85688C72.6741 7.69957 72.0309 7.45486 71.5896 7.12276C71.1484 6.79066 70.9216 6.30122 70.9216 5.65449C70.9216 4.95532 71.1732 4.4091 71.668 4.01582C72.1628 3.62253 72.8308 3.4259 73.6844 3.4259C74.6164 3.4259 75.4287 3.69246 76.1297 4.22121C76.2699 4.30861 76.2905 4.43969 76.1833 4.59701L75.6967 5.39231C75.6143 5.52341 75.4906 5.54962 75.3421 5.47534C74.872 5.14323 74.3525 4.97281 73.7752 4.97718C73.0288 4.97718 72.6577 5.19566 72.6577 5.62827C72.6577 5.92542 72.9587 6.12643 73.2556 6.20509C73.404 6.24441 73.6102 6.29249 73.8865 6.3493C73.9442 6.35367 73.9937 6.37115 74.0267 6.38426C74.8597 6.56779 75.4823 6.82123 75.8988 7.14023C76.3153 7.45922 76.5215 7.94865 76.5215 8.59538C76.5215 9.30766 76.2699 9.88883 75.7792 10.3302C75.2803 10.7715 74.5793 10.99 73.6721 10.99C73.07 10.9813 72.5216 10.8851 72.035 10.6885Z" fill={fill ? fill : 'white'}/>
				<path d="M78.7564 2.37717C78.4471 2.37717 78.1874 2.26356 77.9688 2.02322C77.7503 1.79162 77.6431 1.51196 77.6431 1.1886C77.6431 0.869603 77.7503 0.589921 77.9688 0.353952C78.1874 0.117983 78.4513 0 78.7564 0C79.0574 0 79.3214 0.113614 79.544 0.353952C79.7626 0.589921 79.8698 0.869603 79.8698 1.1886C79.8698 1.51633 79.7626 1.79162 79.544 2.02322C79.3214 2.25919 79.0574 2.37717 78.7564 2.37717ZM78.0307 10.859C77.8657 10.859 77.7791 10.7715 77.7791 10.5924V3.85416C77.7791 3.67937 77.8616 3.58761 78.0307 3.58761H79.4616C79.6265 3.58761 79.7131 3.675 79.7131 3.85416V10.5924C79.7131 10.7715 79.6306 10.859 79.4616 10.859H78.0307Z" fill={fill ? fill : 'white'}/>
				<path d="M87.6306 4.32189C88.113 4.92492 88.3563 5.78577 88.3563 6.88696V10.5925C88.3563 10.7717 88.2739 10.8591 88.1048 10.8591H86.6739C86.5048 10.8591 86.4224 10.7717 86.4224 10.5925V6.88696C86.4224 5.84695 85.9234 5.24829 85.041 5.24829C84.0926 5.24829 83.4493 5.90813 83.3998 7.32394V10.5969C83.3998 10.7761 83.3173 10.8635 83.1482 10.8635H81.7174C81.5524 10.8635 81.4658 10.7761 81.4658 10.5969V3.8587C81.4658 3.6839 81.5483 3.59214 81.7174 3.59214H83.0575C83.1812 3.59214 83.2637 3.67953 83.3091 3.8587L83.3874 4.5535C84.0101 3.79753 84.7729 3.42609 85.6801 3.42609C86.4966 3.41735 87.144 3.71449 87.6306 4.32189Z" fill={fill ? fill : 'white'}/>
				<path d="M95.3913 4.45285C96.0181 5.15202 96.3356 6.06531 96.3356 7.19272C96.3356 7.31944 96.3274 7.42432 96.3232 7.49861C96.2985 7.66903 96.2078 7.75204 96.0469 7.75204H91.2924C91.4656 8.9144 92.1378 9.49123 93.313 9.49123C94.0635 9.49123 94.682 9.17661 95.1562 8.56047C95.28 8.42937 95.3995 8.42499 95.5109 8.54734L96.1212 9.17222C96.2366 9.31642 96.2243 9.45626 96.0841 9.6092C95.3459 10.5487 94.3769 11.0163 93.1645 11.0163C92.0883 11.0163 91.2141 10.6623 90.5378 9.96318C89.8657 9.26401 89.5234 8.35946 89.5234 7.25828C89.5234 6.17894 89.8368 5.26565 90.4677 4.52278C91.0945 3.77992 91.9316 3.40849 92.9625 3.40849C93.948 3.40412 94.7563 3.74931 95.3913 4.45285ZM91.2636 6.51103H94.6903C94.5707 5.47102 93.9522 4.91167 93.0202 4.91167C92.0718 4.91167 91.3997 5.48413 91.2636 6.51103Z" fill={fill ? fill : 'white'}/>
				<path d="M98.4053 10.6885C97.9188 10.4919 97.5312 10.2471 97.2549 9.94126C97.1394 9.8189 97.1312 9.69655 97.2301 9.56546L97.7126 8.74394C97.7951 8.59536 97.9229 8.57352 98.0837 8.6915C98.3064 8.90125 98.6033 9.07167 98.962 9.20276C99.3208 9.33385 99.6754 9.39941 100.026 9.39941C100.743 9.39941 101.156 9.11973 101.156 8.63032C101.156 8.29821 100.847 8.07973 100.57 8.00544C100.434 7.97048 100.203 7.91806 99.8775 7.85688C99.0445 7.69957 98.4012 7.45486 97.96 7.12276C97.5188 6.79066 97.292 6.30122 97.292 5.65449C97.292 4.95532 97.5435 4.4091 98.0383 4.01582C98.5332 3.62253 99.2012 3.4259 100.055 3.4259C100.987 3.4259 101.799 3.69246 102.5 4.22121C102.64 4.30861 102.661 4.43969 102.554 4.59701L102.067 5.39231C101.985 5.52341 101.861 5.54962 101.712 5.47534C101.242 5.14323 100.723 4.97281 100.145 4.97718C99.3991 4.97718 99.028 5.19566 99.028 5.62827C99.028 5.92542 99.329 6.12643 99.6259 6.20509C99.7744 6.24441 99.9805 6.29249 100.257 6.3493C100.315 6.35367 100.364 6.37115 100.397 6.38426C101.23 6.56779 101.853 6.82123 102.269 7.14023C102.686 7.45922 102.892 7.94865 102.892 8.59538C102.892 9.30766 102.64 9.88883 102.15 10.3302C101.651 10.7715 100.95 10.99 100.042 10.99C99.4404 10.9813 98.8961 10.8851 98.4053 10.6885Z" fill={fill ? fill : 'white'}/>
				<path d="M104.908 10.6885C104.422 10.4919 104.034 10.2471 103.758 9.94126C103.642 9.8189 103.634 9.69655 103.733 9.56546L104.216 8.74394C104.298 8.59536 104.426 8.57352 104.587 8.6915C104.809 8.90125 105.106 9.07167 105.465 9.20276C105.824 9.33385 106.178 9.39941 106.529 9.39941C107.246 9.39941 107.659 9.11973 107.659 8.63032C107.659 8.29821 107.349 8.07973 107.073 8.00544C106.937 7.97048 106.706 7.91806 106.38 7.85688C105.547 7.69957 104.904 7.45486 104.463 7.12276C104.022 6.79066 103.795 6.30122 103.795 5.65449C103.795 4.95532 104.046 4.4091 104.541 4.01582C105.036 3.62253 105.704 3.4259 106.558 3.4259C107.49 3.4259 108.302 3.69246 109.003 4.22121C109.143 4.30861 109.164 4.43969 109.057 4.59701L108.57 5.39231C108.488 5.52341 108.364 5.54962 108.215 5.47534C107.745 5.14323 107.226 4.97281 106.648 4.97718C105.902 4.97718 105.531 5.19566 105.531 5.62827C105.531 5.92542 105.832 6.12643 106.129 6.20509C106.277 6.24441 106.484 6.29249 106.76 6.3493C106.818 6.35367 106.867 6.37115 106.9 6.38426C107.733 6.56779 108.356 6.82123 108.772 7.14023C109.189 7.45922 109.395 7.94865 109.395 8.59538C109.395 9.30766 109.143 9.88883 108.652 10.3302C108.154 10.7715 107.453 10.99 106.545 10.99C105.943 10.9813 105.395 10.8851 104.908 10.6885Z" fill={fill ? fill : 'white'}/>
			</svg>
		</React.Fragment>
	)
}

export default Logo
