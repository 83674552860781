exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".FormGroup_form__2DlUC {\n  display: flex;\n  flex-direction: column;\n  width: 100%; }\n\n.FormGroup_input__ZygE2 {\n  margin: 4px 0;\n  width: 100%;\n  padding: 12px 16px;\n  border: 1px solid #D7D7D7;\n  background-color: #FFFFFF;\n  height: 44px;\n  color: #1D1D1D;\n  font-size: 16px;\n  line-height: 19px;\n  border-radius: 8px;\n  outline: none; }\n  .FormGroup_input__ZygE2::placeholder {\n    color: #8E8E8E; }\n  .FormGroup_input__ZygE2:hover {\n    border: 1px solid #8E8E8E; }\n  .FormGroup_input__ZygE2:focus, .FormGroup_input__ZygE2:focus:hover {\n    border: 1px solid #00ADEE; }\n  .FormGroup_input__ZygE2[aria-invalid='true'], .FormGroup_input__ZygE2[aria-invalid='true']:focus, .FormGroup_input__ZygE2[aria-invalid='true']:focus:hover {\n    border: 1px solid #E5342A; }\n  .FormGroup_input__exchangeLeft__3miJL, .FormGroup_input__exchangeRight__1eWea {\n    margin: 0;\n    width: 142px; }\n  .FormGroup_input__exchangeLeft__3miJL {\n    border-radius: 8px 0 0 8px; }\n  .FormGroup_input__exchangeRight__1eWea {\n    border-radius: 0 8px 8px 0; }\n  .FormGroup_input__paginate__1fYdC, .FormGroup_input__send__1O9Ce {\n    border-radius: 8px 0 0 8px;\n    margin: 0; }\n  .FormGroup_input__paginate__1fYdC {\n    width: 50px;\n    height: 30px; }\n  .FormGroup_input__qty__3KfbF {\n    width: 68px;\n    margin: 0;\n    border-radius: unset;\n    text-align: center; }\n  .FormGroup_input__leftRounded__30T1M {\n    width: 284px;\n    margin: 0;\n    border-radius: 8px 0 0 8px; }\n  .FormGroup_input__checkbox__3KCS6 {\n    display: none; }\n\n.FormGroup_label__2UBmV {\n  color: #8E8E8E;\n  font-size: 13px;\n  line-height: 15px;\n  margin-left: 6px;\n  margin-bottom: 0; }\n\n.FormGroup_errorText__1rtJ8 {\n  margin-left: 6px;\n  font-size: 13px;\n  line-height: 15px;\n  color: #E5342A; }\n", ""]);

// exports
exports.locals = {
	"form": "FormGroup_form__2DlUC",
	"input": "FormGroup_input__ZygE2",
	"input__exchangeLeft": "FormGroup_input__exchangeLeft__3miJL",
	"input__exchangeRight": "FormGroup_input__exchangeRight__1eWea",
	"input__paginate": "FormGroup_input__paginate__1fYdC",
	"input__send": "FormGroup_input__send__1O9Ce",
	"input__qty": "FormGroup_input__qty__3KfbF",
	"input__leftRounded": "FormGroup_input__leftRounded__30T1M",
	"input__checkbox": "FormGroup_input__checkbox__3KCS6",
	"label": "FormGroup_label__2UBmV",
	"errorText": "FormGroup_errorText__1rtJ8"
};