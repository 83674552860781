import React from 'react';
import { OperationProvider } from "../../../../Store/OperationProvider";
import SaleOperation from "./SaleOperation"

const NewSaleOperation = (props) => {
  return (
    <OperationProvider operation='Sale'>
      <SaleOperation {...props} />
    </OperationProvider>
  )
}

export default NewSaleOperation;
