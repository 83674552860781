import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import CodePush from './CodePush';
import * as serviceWorker from './serviceWorker';

// import * as Sentry from '@sentry/browser';


serviceWorker.unregister();

// if (process.env.NODE_ENV === 'production') {
// 	Sentry.init({ dsn: "https://610db4da6ea84dc1931c1a91a6b84447@sentry.kassa24.kz/7" });
// 	axios.interceptors.response.use((response) => response, (error) => {
// 		Sentry.captureException(error);
// 		return Promise.reject(error);
// 	});
// }

const startApp = () => {

	if (window.app && window.device) {
		ReactDOM.render(<CodePush><Router><App /></Router></CodePush>, document.getElementById('root'));
		return;
	}
	// if (window.matchMedia("(min-width: 740px)").matches) console.log('"(min-width: 740px)").matches')
	// if (window.matchMedia("(min-height: 500px)").matches) console.log('"(min-height: 500px)").matches')
	// if (window.matchMedia("(max-height: 800px)").matches) console.log('"(max-height: 800px)").matches')
	// if (window.matchMedia("(max-width: 1100px)").matches) console.log('"(max-width: 1100px)").matches')
	if (
		window.matchMedia("(min-width: 740px)").matches
		&& window.matchMedia("(min-height: 500px)").matches
		&& window.matchMedia("(max-height: 800px)").matches
		// && window.matchMedia("(max-width: 800px)").matches
	) {
		document.documentElement.style = "font-size: 11px";
	}

	ReactDOM.render(<Router><App /></Router>, document.getElementById('root'));
}

if (!window.cordova) {
	startApp()
} else {
	document.addEventListener('deviceready', startApp, false);
}
