import React from 'react';
import { ReactComponent as LogoFiscal } from '../Svg/logo.svg';
import { ReactComponent as Done } from '../Svg/done-green.svg';
// import { Link, Redirect } from 'react-router-dom';
// import axios from 'axios';


export default class FinalStep extends React.Component {

    handleClick = () => {
        window.location.replace('/');
    }
    

    async componentDidMount() {
        // var userInfo = JSON.parse(sessionStorage.getItem("USER_DATA"))
        // await axios.post(`https://api.amplitude.com/2/httpapi`,
        // {
        //     api_key: "d314c6311f60a96a3d7ec5a856b85143",
        //     events: [
        //         {
        //             event_type: "registration congratulations",
        //             event_category: "signup",
        //             event_name: "registration congratulations",
        //             device_id: "10001",
        //             user_id: userInfo.Phone,
        //         }
        //     ]
        // })
    }

    // async componentDidMount() {
    //     Token = localStorage.getItem('token');
    //     headers = {
    //         'Authorization': `Bearer ${Token}`
    //     }
    // }

    render(){
        
        const userData = JSON.parse(sessionStorage.getItem("USER_DATA"));

        return(
            <div className="auth text-center">
                <form className="form-signin">
                    <div className="final-step-header">
                        <LogoFiscal/>
                        <span style={{color: "#726D85", fontWeight: "600", marginTop: "8px"}}>Онлайн-касса</span>
                    </div>
                    <div className="final-step-body">
                        <Done style={{marginBottom: "38px"}}/>
                        <div>
                            <h1 className='h3 mb-1' style={{fontSize: "20px", fontWeight: "600"}}>Поздравляем с регистрацией</h1>
                        </div>
                        <p style={{
                            fontSize: "14px",
                            margin: "30px 0 30px",
                        }} className="text-muted">
                            Вы успешно зарегистрировались в Fiscal24. В течение 15 минут с Вами свяжется менеджер для дальнейшей настройки кассы.
                        </p>
                    </div>
                    <p style={{
                        fontSize: "16px",
                        margin: "50px 0 30px",
                    }} className="text-muted">
                        {userData.Name}
                    </p>
                    <button
                        className="btn btn-secondary btn-block mt-4"
                        type="button"
                        onClick={this.handleClick}
                    >
                            Выйти
                    </button>
                    <div className="bottomPhrases">
                        <p className={`mb-3 text-muted`}>
                            &copy; 2016-2020 Fiscal24
                        </p>
                        <p className="text-muted">
                            v 0.1.3
                        </p>
                    </div>
                </form>
            </div>
        )
    }
}