import React from 'react';
// import { BASE_URL } from '../../Store/snippets';
import PrintButton from '../Buttons/PrintButton';
import { Link } from 'react-router-dom';
import MobileNav from '../MobileNav/MobileNav';
// import Header from '../Header/Header';
import HeaderVersion from "../NewDesign/HeaderVersion/HeaderVersion";

const Dublicate = ({ report: Report, formatDate, showDuplicate: backToList, idKkm }) => {
	return (
		<React.Fragment>
			{
				window.matchMedia("(min-width: 1100px)").matches ?
					<HeaderVersion isActive={true} />
					:
					<MobileNav isActive={true} />
			}

			<div className="container pt-5 pb-5">
				<div className="heading">
					<h1 className="text-center">Дубликат Z-отчёта</h1>
					<h3 className="text-center">
						<span className="badge badge-primary">
							Смена открыта<br className="d-md-none" />{formatDate(new Date(Report.DateOpen))} <br /><br />↓↓↓<br /><br />
							Смена закрыта<br className="d-md-none" />{formatDate(new Date(Report.DateClose))}
						</span>
					</h3>
				</div>
				<div className="pt-2 pb-3 d-flex justify-content-between align-items-center">
					<div><button className="btn btn-link" onClick={backToList}>← Вернуться к архиву Z-отчетов</button> <span className="d-none d-md-inline">|</span><br className="d-md-none" />
						<Link className="btn btn-link d-none d-lg-inline-block" to={`/www/kkms/${idKkm}`}>← Вернуться в кассу</Link>
						{/* <Link className="btn btn-link" onClick={() => { history.push(`/www/kkms/${idKkm}`); }}>← Вернуться в кассу</Link> */}
					</div>
					<PrintButton className="ml-auto" />
				</div>


				<div className="row pt-0">
					<div className="col-md-6">

						<div className="card bReportCard" style={{display: 'block'}}>
							<div className="card-header">
								Основная информация
						</div>
							<div className="card-body">
								<div className="table-wrapper">
									<table className="table table-sm table-borderless table-responsive-sm">
										<tbody>
											<tr>
												<td><strong>Наименование организации</strong></td>
												<td>{Report.Kkm.Company.FullName}</td>
											</tr>
											<tr>
												<td><strong>ИИН/БИН</strong></td>
												<td>{Report.Kkm.Company.Bin}</td>
											</tr>
											<tr>
												<td><strong>ККМ</strong></td>
												<td>{Report.Kkm.Id}</td>
											</tr>
											<tr>
												<td><strong>Регистрационный номер в КГД</strong></td>
												<td>{Report.Kkm.Rnm}</td>
											</tr>
											<tr>
												<td><strong>Название кассы</strong></td>
												<td>{Report.Kkm.Name}</td>
											</tr>
											<tr>
												<td><strong>Адрес торговой точки</strong></td>
												<td>{Report.Kkm.Address.Town.Name}, {Report.Kkm.Address.Street}
													{Report.Kkm.Address.House} {Report.Kkm.Address.Flat}</td>
											</tr>
											<tr>
												<td><strong>Кассир</strong></td>
												<td>{Report.User.Name}</td>
											</tr>
										</tbody>
									</table>
								</div>
								<hr />
								<div className="table-wrapper">
									<table className="table table-sm table-borderless table-responsive-sm">
										<tbody>
											<tr>
												<td><strong>Баланс на момент открытия смены</strong></td>
												<td style={{ whiteSpace: 'nowrap' }}>{new Intl.NumberFormat('ru-RU').format(Report.BalanceOpen)} ₸</td>
											</tr>
											<tr>
												<td><strong>Баланс на момент закрытия смены</strong></td>
												<td style={{ whiteSpace: 'nowrap' }}>{new Intl.NumberFormat('ru-RU').format(Report.BalanceClose)} ₸</td>
											</tr>
											<tr>
												<td><strong>Баланс на момент запроса Z-отчета</strong></td>
												<td style={{ whiteSpace: 'nowrap' }}>{new Intl.NumberFormat('ru-RU').format(Report.BalanceClose)} ₸</td>
											</tr>
											<tr>
												<td><strong>Оформлено чеков</strong></td>
												<td>{Report.Count}</td>
											</tr>
										</tbody>
									</table>
								</div>
								<hr />
								<div className="table-wrapper">
									<table className="table table-sm table-borderless table-responsive-sm">
										<tbody>
											<tr>
												<td><strong>Необнуляемая сумма Продаж</strong></td>
												<td style={{whiteSpace2: 'nowrap'}}>{new Intl.NumberFormat('ru-RU').format(Report.CumulativeSales)} ₸</td>
											</tr>
											<tr>
												<td><strong>Необнуляемая сумма Покупок</strong></td>
												<td style={{whiteSpace2: 'nowrap'}}>{new Intl.NumberFormat('ru-RU').format(Report.CumulativePurchases)} ₸</td>
											</tr>
											<tr>
												<td><strong>Необнуляемая сумма Служебных расходов</strong></td>
												<td style={{whiteSpace2: 'nowrap'}}>{new Intl.NumberFormat('ru-RU').format(Report.CumulativeExpenses)} ₸</td>
											</tr>
											<tr>
												<td><strong>Необнуляемая сумма Служебных приходов</strong></td>
												<td style={{whiteSpace2: 'nowrap'}}>{new Intl.NumberFormat('ru-RU').format(Report.CumulativeIncomes)} ₸</td>
											</tr>
											<tr>
												<td><strong>Необнуляемая сумма Возвратов продаж</strong></td>
												<td style={{whiteSpace2: 'nowrap'}}>{new Intl.NumberFormat('ru-RU').format(Report.CumulativeRefunds)} ₸</td>
											</tr>
											<tr>
												<td><strong>Необнуляемая сумма Возвратов покупок</strong></td>
												<td style={{whiteSpace2: 'nowrap'}}>{new Intl.NumberFormat('ru-RU').format(Report.CumulativePurchaseRefunds)} ₸</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>

					<div className="col-md-6">
						<div className="card bReportCard" style={{display: 'block'}}>
							<div className="card-header">
								Оформленные чеки
						</div>
							<div className="card-body bReportCard__body">

								<h5 className="border-bottom pb-2">Служебный приход</h5>
								<div className="table-wrapper pb-2">
									<table className="table table-sm table-borderless table-responsive-sm">
										{Report.IncomesQty > 0 ?
											<tbody>
												<tr>
													<td><strong>Кол-во чеков</strong></td>
													<td style={window.matchMedia("(min-width: 1100px)").matches ? { width: '40%' } : {}}>{Report.IncomesQty}</td>
												</tr>
												<tr>
													<td><strong>Общая сумма</strong></td>
													<td>{new Intl.NumberFormat('ru-RU').format(Report.IncomesAmount)} ₸</td>
												</tr>
											</tbody>
											:
											<tbody>
												<tr>
													<td><strong>Чеков нет</strong></td>
												</tr>
											</tbody>
										}
									</table>
								</div>

								<h5 className="border-bottom pb-2">
									Служебный расход
							</h5>
								<div className="table-wrapper pb-2">
									<table className="table table-sm table-borderless table-responsive-sm">
										{Report.ExpensesQty > 0 ?
											<tbody>
												<tr>
													<td><strong>Кол-во чеков</strong></td>
													<td style={window.matchMedia("(min-width: 1100px)").matches ? { width: '40%' } : {}}>{Report.ExpensesQty}</td>
												</tr>
												<tr>
													<td><strong>Общая сумма</strong></td>
													<td>{new Intl.NumberFormat('ru-RU').format(Report.ExpensesAmount)} ₸</td>
												</tr>
											</tbody>
											:
											<tbody>
												<tr>
													<td><strong>Чеков нет</strong></td>
												</tr>
											</tbody>
										}
									</table>
								</div>

								<h5 className="border-bottom pb-2">
									Продажи
								</h5>
								<div className="table-wrapper pb-2">
									<table className="table table-sm table-borderless table-responsive-sm">
										{Report.SalesQty > 0 ?
											<tbody>
												<tr>
													<td><strong>Кол-во чеков</strong></td>
													<td style={window.matchMedia("(min-width: 1100px)").matches ? { width: '40%' } : {}}>{Report.SalesQty}</td>
												</tr>
												<tr>
													<td><strong>Общая сумма</strong></td>
													<td>{new Intl.NumberFormat('ru-RU').format(Report.SalesAmount)} ₸</td>
												</tr>
											</tbody>
											:
											<tbody>
												<tr>
													<td><strong>Чеков нет</strong></td>
												</tr>
											</tbody>
										}
									</table>
								</div>

								<h5 className="border-bottom pb-2">
									Возвраты продаж
								</h5>
								<div className="table-wrapper pb-2">
									<table className="table table-sm table-borderless table-responsive-sm">
										{Report.RefundsQty > 0 ?
											<tbody>
												<tr>
													<td><strong>Кол-во чеков</strong></td>
													<td style={window.matchMedia("(min-width: 1100px)").matches ? { width: '40%' } : {}}>{Report.RefundsQty}</td>
												</tr>
												<tr>
													<td><strong>Общая сумма</strong></td>
													<td>{new Intl.NumberFormat('ru-RU').format(Report.RefundsAmount)} ₸</td>
												</tr>
											</tbody>
											:
											<tbody>
												<tr>
													<td><strong>Чеков нет</strong></td>
												</tr>
											</tbody>
										}

									</table>
								</div>

								<h5 className="border-bottom pb-2">
									Покупки
							</h5>
								<div className="table-wrapper pb-2">
									<table className="table table-sm table-borderless table-responsive-sm">
										{Report.PurchasesQty > 0 ?
											<tbody>
												<tr>
													<td><strong>Кол-во чеков</strong></td>
													<td style={window.matchMedia("(min-width: 1100px)").matches ? { width: '40%' } : {}}>{Report.PurchasesQty}</td>
												</tr>
												<tr>
													<td><strong>Общая сумма</strong></td>
													<td>{new Intl.NumberFormat('ru-RU').format(Report.PurchasesAmount)} ₸</td>
												</tr>
											</tbody>
											:
											<tbody>
												<tr>
													<td><strong>Чеков нет</strong></td>
												</tr>
											</tbody>
										}
									</table>
								</div>
								<h5 className="border-bottom pb-2">
									Возвраты покупок
								</h5>
								<div className="table-wrapper pb-2">
									<table className="table table-sm table-borderless table-responsive-sm">
										{Report.PurchaseRefundsQty > 0 ?
											<tbody>
												<tr>
													<td><strong>Кол-во чеков</strong></td>
													<td style={window.matchMedia("(min-width: 1100px)").matches ? { width: '40%' } : {}}>{Report.PurchaseRefundsQty}</td>
												</tr>
												<tr>
													<td><strong>Общая сумма</strong></td>
													<td>{new Intl.NumberFormat('ru-RU').format(Report.PurchaseRefundsAmount)} ₸</td>
												</tr>
											</tbody>
											:
											<tbody>
												<tr>
													<td><strong>Чеков нет</strong></td>
												</tr>
											</tbody>
										}

									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="pt-5">
					<div className="card">
						<div className="card-header" style={{textAlign: "center"}}>
							Отчет по секциям
						</div>
						<div className="card-body">
							<div className="table-wrapper">
								{
									Report.Count > 0 ?
										<table className="table table-responsive-sm">
											<thead>
												<tr>
													<th>Секция</th>
													<th>Сумма продаж</th>
													<th>Сумма покупок</th>
													<th>Сумма возврата продаж</th>
													<th>Сумма возврата покупок</th>
												</tr>
											</thead>
											<tbody>
												{
													Object.entries(Report.Sections)
														.filter(section => 
																section[1].Sales || 
																section[1].Purchases || 
																section[1].Refunds || 
																section[1].PurchaseRefunds
															)
														.map(section => (
															<tr key={section[0]}>
																<td>{section[0]} {section[1].Name ? `(${section[1].Name})` : ""}</td>
																<td>{section[1].Sales ? `${new Intl.NumberFormat('ru-RU').format(section[1].Sales)} ₸` : "0 ₸"}</td>
																<td>{section[1].Purchases ? `${new Intl.NumberFormat('ru-RU').format(section[1].Purchases)} ₸` : "0 ₸"}</td>
																<td>{section[1].Refunds ? `${new Intl.NumberFormat('ru-RU').format(section[1].Refunds)} ₸` : "0 ₸"}</td>
																<td>{section[1].PurchaseRefunds ? `${new Intl.NumberFormat('ru-RU').format(section[1].PurchaseRefunds)} ₸` : "0 ₸"}</td>
															</tr>
														))
												}
											</tbody>
										</table>
										:
										<table>
											<thead>
												<tr>
													<th>
														Операций не производилось
													</th>
												</tr>
											</thead>
										</table>
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	)
}

export default Dublicate;
