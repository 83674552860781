import React from 'react';
import { OperationProvider } from "../../../../Store/OperationProvider";
import ExpenseOperation from "./ExpenseOperation"

const NewExpenseOperation = (props) => {
  return (
    <OperationProvider operation='Expense'>
      <ExpenseOperation {...props} />
    </OperationProvider>
  )
}

export default NewExpenseOperation;
