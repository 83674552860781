import React from "react"
import s from './cashboxcomponent.module.scss'
import { SpinnerPrimary } from '../../../Common/Spinner';
import { Danger, OpenShift, Success, Warning, Copied } from "../../../Icons";
import { useTranslation } from "react-i18next";

const CashboxComponent = React.memo(({ kkm, openKkm, copied, copyZnm, znmForCopy, pending, idx, id, expireDate }) => {
	const { t } = useTranslation()


	const getKKmLabelText = (text) => {
		switch (text) {
			case 'Касса в процессе регистрации':
				return t('other.registration_in_progress')
			case 'Касса заблокирована в ОФД и в K24B':
				return t('other.cash_register_blocked_in_ofd_kassa')
			case 'Касса заблокирована в ОФД':
				return t('other.cash_register_blocked_in_ofd')
			case 'Касса заблокирована в K24B':
				return t('other.k24b_blocked')
			case 'Касса доступна':
				return t('other.cash_register_available')
			case 'Смена открыта другим кассиром':
				return t('other.shift_opened_by_another_cashier')
			case 'Ваша смена':
				return t('other.your_shift')
			default:
				return text
		}
	}

	return <div className={`${s.wrapper} ${kkm.LabelType === 'success' && s.wrapper_opened}`}  onClick={openKkm} data-idx={idx} id={id}>
		<section className={s.cashbox_container}>
			<div className={s.cashbox_name}>
				<div className={s.znm}>
					{ ((znmForCopy !== kkm.Znm) || !copied) &&
						<div className={s.znm_container} onClick={(e) => copyZnm(e, kkm.Znm)}>
							<span className={s.znm_title}>ЗНМ</span>
							{kkm.Znm}
						</div>
					}
					{ (znmForCopy === kkm.Znm ) && copied &&
						<span className={s.copied} onClick={e => e.stopPropagation()}>
							<Copied size={12}/>&nbsp;{ t('main.copied') }
						</span>
					}
				</div>
				<h5
					data-idx={idx}
					id={kkm.Id}
					className={kkm.LabelType === 'success' ? (
							kkm.Name && (kkm.Name.length) < 22 ? s.cashbox_name_exactGreen : `${s.cashbox_name_exactGreen} + ${s.overflow}`
						) : (
							kkm.Name && (kkm.Name.length) < 22 ? s.cashbox_name_exact : `${s.cashbox_name_exact} + ${s.overflow}`
						)
					}
				>
					{kkm.Name}
				</h5>
			</div>

			<span className={`${s.address} ${kkm.LabelType === 'success' && s.address_opened}`}>
				{`${kkm.Address.Town.Name}, ${kkm.Address.Street}, ${kkm.Address.House}, ${kkm.Address.Flat} `}
			</span>

		</section>

		<section className={`${s.cashbox_container} ${s.cashbox_container_right}`}>
			<p className={`${s.status} ${kkm.LabelType === 'success' && s.status_opened}`}>
				<i className={s.status_logo}>
					{kkm.LabelType === 'success' ? <OpenShift fill="#FFFFFF" /> : kkm.LabelType === 'warning' ? <Warning /> : kkm.LabelType === 'primary' ? <Success fill='#28A745' /> : kkm.LabelType === 'available' ? <Success fill='#28A745' /> : <Danger />}
				</i>
				{getKKmLabelText(kkm.LabelText)}
			</p>
			<span className={`${s.expire_date} ${kkm.LabelType === 'success' && s.expire_date_opened}`}>
				{expireDate && !pending ? `${expireDate}` : !expireDate && !pending ? t('main.payment_required') : <SpinnerPrimary />}
			</span>
		</section>
	</div>
})

export default CashboxComponent
