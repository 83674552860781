import React from 'react'
import s from './companycomponent.module.scss'
import { useTranslation } from "react-i18next";

const CompanyComponent = ({ company, choosenCompanyId, idx, kkms, selectCompany}) => {
	const { i18n } = useTranslation()

	function kkmsTitle(val) {
		if (!val) return i18n.language === 'ru' ? "нет кассы" : "касса жоқ"
		if (val) {
			if (val === 1) return `${val} касса`
			if (val < 5) return `${val} ${i18n.language === 'ru' ? "кассы" : "касса"}`
			if (val > 4 && val < 21) return `${val} ${i18n.language === 'ru' ? "касс" : "касса"}`
			if (val >= 20) {
				let x = "" + val
				x = x.split("")[x.length - 1]
				if (x === 1) return `${val} касса`
				if (x < 5) return `${val} ${i18n.language === 'ru' ? "кассы" : "касса"}`
				if (x > 4) return `${val} ${i18n.language === 'ru' ? "касс" : "касса"}`
			}
		}
		return ''
	}

	return <button className={`${s.company} ${choosenCompanyId === company.Id && s.company_active}`} id={company.Id} data-idx={idx} onClick={selectCompany} key={company.Id}>
		<div className={`${s.company_container} ${s.company_container_top}`}>
			<h5 className={s.company_name}>{company.ShortName}</h5>
			<span>{kkmsTitle(company.KkmsAmount)}</span>
		</div>
		<div className={s.company_container}>
			<p className={s.company_address}>{`${company.Address.Town.Name}, ${company.Address.Street}, ${company.Address.House}, ${company.Address.Flat} `}</p>
		</div>
	</button>
}

export default CompanyComponent
