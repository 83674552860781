import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import QRCode from "qrcode.react";

const { Merchant } = require("emv-qrcps");
// const { Constants } = Merchant;

export default class Example extends React.Component {
	render() {

		const company = JSON.parse(localStorage.getItem("COMPANY"));
		const EMVQR = Merchant.buildEMVQR();

		const merchantAccountInformationId27 = "27";
		const merchantAccountInformationId26 = "26";
		const paymentNetworkSpecificId = "01";
		const merchantAccountPayNetSpec = company ? company.Bin : "";
		const shortName = company ? company.ShortName : "";
		const merchantAccountInformation27 = Merchant.buildMerchantAccountInformation();
		const merchantAccountInformation26 = Merchant.buildMerchantAccountInformation();
		const name = company ? company.FullName : "";
		const country = "KZ";
		const city = company ? company.Address.Town.Region.Name : "";
		const categoryCode = "8999";
		const currencyCode = "398";
		const transactionAmount = this.props.total.toString();
		// const walletNumber = Merchant.buildUnreservedTemplate();
		// const id = "80";
		// walletNumber.setGloballyUniqueIdentifier(company.Bin);

		const payId = "01";
		const crc = "007B";
		const point = "12";

		var a = {};
		var latName = "";
		var latCity = "";
		// var shortLatName="";

		a["Ё"]="YO";a["Й"]="I";a["Ц"]="TS";a["У"]="U";a["К"]="K";a["Е"]="E";a["Н"]="N";a["Г"]="G";a["Ш"]="SH";a["Щ"]="SCH";a["З"]="Z";a["Х"]="H";a["Ъ"]="'";
		a["ё"]="yo";a["й"]="i";a["ц"]="ts";a["у"]="u";a["к"]="k";a["е"]="e";a["н"]="n";a["г"]="g";a["ш"]="sh";a["щ"]="sch";a["з"]="z";a["х"]="h";a["ъ"]="'";
		a["Ф"]="F";a["Ы"]="Y";a["В"]="V";a["А"]="A";a["П"]="P";a["Р"]="R";a["О"]="O";a["Л"]="L";a["Д"]="D";a["Ж"]="ZH";a["Э"]="E";
		a["ф"]="f";a["ы"]="y";a["в"]="v";a["а"]="a";a["п"]="p";a["р"]="r";a["о"]="o";a["л"]="l";a["д"]="d";a["ж"]="zh";a["э"]="e";
		a["Я"]="Ya";a["Ч"]="CH";a["С"]="S";a["М"]="M";a["И"]="I";a["Т"]="T";a["Ь"]="'";a["Б"]="B";a["Ю"]="YU";
		a["я"]="ya";a["ч"]="ch";a["с"]="s";a["м"]="m";a["и"]="i";a["т"]="t";a["ь"]="'";a["б"]="b";a["ю"]="yu";
	
		for (var i in name){
			if (name.hasOwnProperty(i)) {
				if (a[name[i]] === undefined){
					latName += name[i];
				} else {
					latName += a[name[i]];
				}
			}
		}

		// eslint-disable-next-line no-redeclare
		for (var i in city){
			if (city.hasOwnProperty(i)) {
				if (a[city[i]] === undefined){
					latCity += city[i];
				} else {
					latCity += a[city[i]];
				}
			}
		}

		// eslint-disable-next-line no-redeclare
		for (var i in shortName){
			if (shortName.hasOwnProperty(i)) {
				if (a[shortName[i]] === undefined){
					// shortLatName += shortName[i];
				} else {
					// shortLatName += a[shortName[i]];
				}
			}
		}
		
		merchantAccountInformation27.setGloballyUniqueIdentifier("180740012608");
		merchantAccountInformation26.setGloballyUniqueIdentifier("kz.plus24");
		merchantAccountInformation27.addPaymentNetworkSpecific(paymentNetworkSpecificId, merchantAccountPayNetSpec)
		merchantAccountInformation26.addPaymentNetworkSpecific(paymentNetworkSpecificId, merchantAccountPayNetSpec)
		EMVQR.addMerchantAccountInformation(merchantAccountInformationId27, merchantAccountInformation27)
		EMVQR.addMerchantAccountInformation(merchantAccountInformationId26, merchantAccountInformation26)
		EMVQR.setMerchantName(latName);
		EMVQR.setCountryCode(country);
		EMVQR.setMerchantCity(latCity);
		EMVQR.setMerchantCategoryCode(categoryCode);
		EMVQR.setTransactionCurrency(currencyCode);
		EMVQR.setTransactionAmount(transactionAmount);
		EMVQR.setPayloadFormatIndicator(payId);
		EMVQR.setCRC(crc);
		EMVQR.setPointOfInitiationMethod(point)
		// EMVQR.addUnreservedTemplates(id, walletNumber);

		EMVQR.toBinary().split(" ").join("").replace(/(\r\n|\n|\r)/gm, "")
		// console.log(EMVQR.rawData());

		const emvqrStringFormat = EMVQR.generatePayload();
		// console.log(emvqrStringFormat);

		return (
			<Modal show={this.props.show}
				centered
                onHide={this.props.onClose}
                style={{textAlign: "center"}}
			>
				<Modal.Header style={{display: "flex", justifyContent: "center", flexFlow: "column", alignItems: "center", marginTop: "30px"}}>
					<Modal.Title style={{fontWeight: "900"}}>{this.props.companyName}</Modal.Title>
                    {/* <span>БИН: {this.props.bin}</span> */}
					<span>БИН: {company ? company.Bin : ""}</span>
                    <span>СУММА ЧЕКА: {this.props.total} тг</span>
				</Modal.Header>
				<Modal.Body>
					<p>
                        Отсканируйте QR-код для оплаты <br/> через систему Plus24
					</p>
					<QRCode
						value={`${emvqrStringFormat}`}
						// value={`https://kassa24.kz/action/dopay?account=${this.props.bin}&pay_type=10000&amount=${this.props.total}`}
						size={180}
						level={"H"}
						includeMargin={true}
					/>
				</Modal.Body>
				<Modal.Footer style={{display: "flex", justifyContent: "center"}}>
					<Button variant="secondary" onClick={this.props.onSuccess} style={{backgroundColor: "#32CD32", color: "#fff"}}>
						Подтвердить
                	</Button>
					<Button variant="secondary" onClick={this.props.onClose}>
						Закрыть
                	</Button>
				</Modal.Footer>
			</Modal>
		);
	} 
}