import React from 'react';
import { Modal, Button } from 'react-bootstrap';
// import style from './Modal.module.css'

class ModalCustomContent extends React.Component {
	render() {
		return (
			<div className={{ backgroundColor: '#fff' }}>
				{this.props.children}
			</div>
		);
	}
}

export default class Example extends React.Component {

	render() {
		return (
			<Modal show={this.props.show} dialogClassName={{
				width: '100%',
				maxWidth: 'none!important',
				margin: '0'
			}} onHide={this.props.onClose} dialogAs={ModalCustomContent}
			>
				<Modal.Body>
					<img className={{ maxWidth: '250px' }} src={'data:image/png;base64, ' + this.props.img} alt="Check" />
				</Modal.Body>
				<Modal.Footer className="d-print-none">
					<Button variant="secondary" onClick={this.props.onClose}>
						Закрыть
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}
