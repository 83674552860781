/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import BarcodeScanner from '../../../Modal/BarcodeScanner';
import { Store } from '../../../../Store/OperationProvider'

export default class Precheck extends Component {

    state = {
      open: false,
      showScanner: false,
    }

    setShowScanner = () => {
        this.setState({
          showScanner: !this.state.showScanner
        })
    }

    handleInputText = (e, functionHandler, val) => {
        functionHandler({ value: e.target.value, name: val });
    };

    // handleInputNumber = (e, functionHandler, val) => {
    //     if (e.target.value == Number(e.target.value)) {
    //         functionHandler({ value: e.target.value, name: val });
    //     }
    // };

    handleInputNumber = (e, functionHandler, val) => {
      if(e.target.value.slice(-4, -3) !== "."){
        functionHandler({ value: e.target.value, name: val });
      }
    };

    handleInputQty = (e, functionHandler, val) => {
        if (e.target.value == Number(e.target.value)) {
            let qty = Number(e.target.value);
            // if (e.target.value < 1) {
            //     qty = 1;
            // }
            functionHandler({ value: qty, name: val });
        }
    };

    handleFocus = (event) => event.target.select();

    handleOpenToggle = () => {
        // document.getElementById("myDropdown").classList.toggle("show");
      this.setState({
        open: true
      })
    };

    handleCloseToggle = () => {
      setTimeout(() =>
        this.setState({
          open: false
        }), 180
      )
    }

    handleClick = context => e => {
      context.Articles.map(
        art =>
          art.Id === e.target.value &&
          this.setState({
            selectedOption: {
              label: art.Name,
              value: art.Id
            },
            currentArticle: art
          }, () => {
            context.setArticle(art);
          })
      );
    }

    setQr = context => code => {
        context.Articles.map(art => (
          art.Qr === code &&
          this.setState({
            currentArticle: art
          }, () => {
            context.setArticle(art);
          })
        ))
    }

    render() {
        return (
            <Store.Consumer>
                {
                    context => (
                        <React.Fragment>
                            <h4 className="mb-3">
                                Добавить позицию
                                <span />
                            </h4>

                            {!context.PositionErrors.isValid &&
                                (
                                    <div className="NewSale__alert alert alert-warning mb-3 shadow">
                                        <h5 className="alert-heading">Ошибка при добавлении позиции</h5>
                                        <p className="text-danger mb-1">{context.PositionErrors.Name}</p>
                                        <p className="text-danger mb-1">{context.PositionErrors.Price}</p>
                                        <p className="text-danger mb-1">{context.PositionErrors.Markup}</p>
                                        <p className="text-danger mb-1">{context.PositionErrors.Discount}</p>

                                    </div>
                                )
                            }

                            <form className="needs-validation" noValidate onSubmit={(e) => context.addPreCheckPosition(e)}>

                                <div className="form-row pt-2">
                                    <div className="form-group col-md-7">
                                        <label htmlFor="inputEmail4">Наименование</label>
                                        <div className="input-wrapper-barcode">
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                className={`barcode-input form-control form-control-lg ${context.PositionErrors.Name && 'is-invalid'}`}
                                                id="inputEmail4"
                                                placeholder="Название товара или услуги"
                                                value={context.Position.Name ? context.Position.Name : ""}
                                                onChange={e => { this.handleInputText(e, context.positionInputHandler, 'Name') }}
                                                onFocus={this.handleOpenToggle}
                                                onBlur={() => this.handleCloseToggle()}
                                            />
                                            <div className="sale-barcode" onClick={this.setShowScanner}>
                                                <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M0.666992 0.333374H2.33366V13.6667H0.666992V0.333374ZM4.00033 0.333374H5.66699V13.6667H4.00033V0.333374ZM6.50033 0.333374H9.00033V13.6667H6.50033V0.333374ZM9.83366 0.333374H11.5003V13.6667H9.83366V0.333374ZM12.3337 0.333374H14.0003V13.6667H12.3337V0.333374ZM14.8337 0.333374H17.3337V13.6667H14.8337V0.333374Z" fill="#575757"/>
                                                </svg>
                                            </div>
                                            <BarcodeScanner setCode={this.setQr(context)} setShowScanner={this.setShowScanner} showScanner={this.state.showScanner} />
                                        </div>
                                        {this.state.open && (
                                          <div id="myDropdown2" className="dropdownContainer">
                                          {context.Articles.map(art => (
                                            <li key={art.Id} onClick={this.handleClick(context)} value={art.Id} type="none" className="select-row">
                                              {art.Name}
                                            </li>
                                          ))}
                                        </div>
                                        )}

                                    </div>
                                    <div className="form-group col-md-3">
                                        <label htmlFor="inputPassword4">Цена</label>
                                        <input min="0" type="number" className={`form-control form-control-lg ${context.PositionErrors.Price && 'is-invalid'}`}
                                            inputMode="numeric"
                                            id="" placeholder="0"
                                            value={context.Position.Price === 0 ? '' : context.Position.Price}
                                            onChange={e => { this.handleInputNumber(e, context.priceInputHandler, 'Price') }}
                                        // onFocus={this.handleFocus}
                                        />
                                    </div>
                                    <div className="form-group col-md-2">
                                        <label htmlFor="">Количество</label>
                                        <input min="1" type="number" className={`form-control form-control-lg ${context.PositionErrors.Qty && 'is-invalid'}`}
                                            inputMode="numeric"
                                            id=""
                                            placeholder="1.00"
                                            value={context.Position.Qty === 0 ? '' : context.Position.Qty}
                                            onChange={e => { this.handleInputQty(e, context.positionInputHandler, 'Qty') }}
                                            onFocus={(e) => { e.target.placeholder = ''; }}
                                            onBlur={(e) => { e.target.placeholder = '1.00'; }}
                                        />
                                    </div>
                                </div>
                                <div className="form-row d-md-flex">
                                    <div className="form-group col-md-12">
                                        <label htmlFor="selectSection">Единица измерения</label>
                                        <select id="selectSection" className="form-control"
                                            value={context.Position.Unit.Id}
                                            onChange={e => context.unitChangeHandler(Number(e.target.value))}>
                                            {
                                                context.Units.map((Unit) => {
                                                    return <option key={Unit.Id} value={Unit.Id}>{Unit.NameRU}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>

                                <div className="form-row d-md-block">
                                    <div className="form-group col-md-12">
                                        <label htmlFor="selectSection">Секция</label>
                                        <select id="selectSection" className="form-control"
                                            value={context.Position.Section.Id}
                                            onChange={e => context.sectionChangeHandler(Number(e.target.value))}>
                                            {/*<option value="" disabled hidden>Выбирите секцию...</option>*/}
                                            {
                                                context.Sections.map((Section, index) => {
                                                    return <option key={index} value={Section.Id}>{Section.Name}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>


                                <div className="pt-2 pb-3">
                                    <button type="button" className="btn btn-primary btn-sm mr-2 btn-sm-block"
                                        onClick={(e) => context.addPreCheckPosition(e, "refund")}>
                                        Добавить в предчек
                                        <span className="d-none d-md-inline" role="img" aria-label="right-emoji">👉</span>
                                        <span className="d-md-none" role="img" aria-label="up-emoji">👆</span>
                                    </button>

                                    <small className="d-none d-sm-inline">или&#8194;</small>

                                    <button type="button" className="btn btn-outline-secondary btn-sm btn-sm-block"
                                        onClick={() => context.resetPosition()}>
                                        Очистить форму
                                    </button>
                                    <input className="invisible" type="submit" style={{ position: 'absolute' }} />
                                </div>

                                <div className="form-group d-md-block">
                                    <label htmlFor="inputDomain">Вид деятельности</label>
                                    <select id="inputDomain" className="form-control"
                                        value={context.Position.Domain.Id}
                                        onChange={e => context.domainChangeHandler(Number(e.target.value))}>
                                        {/*<option value="" disabled hidden>Выбирите вид деятельности...</option>*/}
                                        {
                                            context.Domains.map((Domain, index) => {
                                                return <option key={index} value={Domain.Id}>{Domain.Name}</option>
                                            })
                                        }

                                    </select>
                                </div>
                            </form>
                        </React.Fragment>
                        
                    )
                }

            </Store.Consumer>
        )
    }
}
