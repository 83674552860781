exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Sale_NewSale__3eX2Y {\n  @media (min-width: 1100px) {\n      padding-bottom: 3rem;\n  }\n  overflow-x: hidden;\n}\n", ""]);

// exports
exports.locals = {
	"NewSale": "Sale_NewSale__3eX2Y"
};