import React, { useState } from 'react';
import style from '../../styles/pages/cashbox/tabs/NewOfdSettings.module.scss'
import OutsideClicker from "../../NewOperations/helpers/OutsideClicker";
import { ReactComponent as ArrowDropDownFill } from '../../svg/arrow-drop-down-fill.svg'
import { ReactComponent as LoaderLine } from '../../svg/loader-line.svg'
import { useTranslation } from "react-i18next";


const NewOfdSettings = ({ ofds, changeOfdSettings, pending }) => {
  const { t } = useTranslation()

  let kkm = (JSON.parse(localStorage.getItem('KKM')))
  const [showOfd, setShowOfd] = useState(false)
  const [ofdSettings, setOfdSettings] = useState(
    {
      ofdId: kkm.Ofd.Id,
      ofdName: kkm.Ofd.Name,
      rnm: kkm.Rnm,
      kkmid: kkm.IdCPCR,
      token: kkm.TokenCPCR
    }
  )

  const makeChanges = (key, value) => {
    if (key === 'ofdId') {
      setOfdSettings(
        {
          ...ofdSettings,
          ofdId: value
        }
      )
    }
    if (key === 'rnm') {
      setOfdSettings(
        {
          ...ofdSettings,
          rnm: value
        }
      )
    }
    if (key === 'kkmid') {
      setOfdSettings(
        {
          ...ofdSettings,
          kkmid: value
        }
      )
    }
    if (key === 'token') {
      setOfdSettings(
        {
          ...ofdSettings,
          token: value
        }
      )
    }
  }

  const onlyNumber = (e) => {
    let keyCode = (e.keyCode ? e.keyCode : e.which);
    if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
      e.preventDefault();
    }
  }

  return (
    <div className={style.wrapper}>
      <div method="POST" className={`${style.card} ${style['card--white']} ${style['card--md']}`}>
        <div className={style['form__group']}>
          <label htmlFor="OFD" className={style['form__label']}>{ t('kassa_settings.ofd') }</label>
          <OutsideClicker setShow={setShowOfd}>
            <div
              className={style['form__select-wrapper']}
              onClick={() => setShowOfd(!showOfd)}
            >
              <div className={style['form__select']}>
                <p className={style['paragraph--lead']}>
                  {ofds.find(ofd => ofd.Id === ofdSettings.ofdId) ? ofds.find(ofd => ofd.Id === ofdSettings.ofdId).Name : t('other.not_specified')}
                </p>
                <ArrowDropDownFill/>
              </div>
              {
                showOfd && (
                  <div className={style['form__select-list']}>
                    {
                      ofds.map(ofd => {
                        return (
                          <div
                            className={style['form__select-item']}
                            key={ofd.Id}
                            onClick={() => makeChanges('ofdId', ofd.Id)}
                          >
                            {ofd.Name}
                          </div>
                        )
                      })
                    }
                  </div>
                )
              }
            </div>
          </OutsideClicker>
        </div>

        <div class={style['form__group']}>
          <label htmlFor="RNMOFD" class={style['form__label']}>{ t('kassa_settings.rnm') }</label>
          <input
            type="text"
            class={style['form__input']}
            onKeyPress={(e) => onlyNumber(e)}
            onChange={e => makeChanges('rnm', e.target.value)}
            value={ofdSettings.rnm}
            name="RNMOFD"
            id="RNMOFD"
            required={true}
          />
        </div>

        <div class={style['form__group']}>
          <label htmlFor="IdOFD" class={style['form__label']}>{ t('kassa_settings.kkm_id') }</label>
          <input
            type="text"
            class={style['form__input']}
            onChange={e => makeChanges('kkmid', e.target.value)}
            onKeyPress={(e) => onlyNumber(e)}
            value={ofdSettings.kkmid}
            name="IdOFD"
            id="IdOFD"
            required={true}
          />
        </div>


        <div class={style['form__group']}>
          <label htmlFor="TokenOFD" class={style['form__label']}>{ t('kassa_settings.token') }</label>
            <input
              type="text"
              class={style['form__input']}
              onKeyPress={(e) => onlyNumber(e)}
              onChange={e => makeChanges('token', e.target.value)}
              value={ofdSettings.token}
              name="TokenOFD"
              id="TokenOFD"
              required={true}
            />
        </div>

        {
          pending ?
            <button
              className={`${style.btn} ${style['btn--primary']} ${style['btn--block']} ${style['mt-20']}`}
            >
              { t('kassa_settings.saving_changes') }
              <LoaderLine/>
            </button>
            :
            <button className={`${style.btn} ${style['btn--primary']} ${style['btn--block']} ${style['mt-20']}`}
                    onClick={() => changeOfdSettings(ofdSettings)}>{ t('kassa_settings.save_changes') }
            </button>
        }
      </div>
    </div>
  )
}

export default NewOfdSettings;
