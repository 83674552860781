/* eslint-disable eqeqeq */
import React from 'react';
import { Link } from 'react-router-dom';

// import Subnav from '../ReportsSubnav';
// import ReportsSubnav from '../ReportsSubnav';
// import { BASE_URL } from '../../Store/snippets';
// import MobileNav from '../MobileNav/MobileNav';
// import Header from '../Header/Header';
import Workbook from 'react-excel-workbook';
//styles
import "../../Styles/layout/dateRange.scss"
//svg
import { ReactComponent as DonwloadSvg } from '../Svg/download.svg'
// import {setAppVersion} from "../../Store/appVersion";
import HeaderVersion from "../NewDesign/HeaderVersion/HeaderVersion";
const ShiftsLogLayout = ({ report: Report, handleChangeDateFrom, handleChangeDateTo, dateFrom, dateTo, idKkm, error, formatDate, user, showDuplicate, history, shiftNumber }) => {

	// const goNew = () => {
	// 	history.push(`/www/shifts/${idKkm}/report`)
	// 	localStorage.setItem('reportStep', '3')
	// 	setAppVersion(true)
	// }

	// const oldVersion = {
	// 	float: "right",
	// 	marginTop: "10px",
	// 	width: "106px",
	// 	height: "38px",
	// 	background: "#FFFFFF",
	// 	borderRadius: "8px",
	// 	display: "flex",
	// 	justifyContent: "space-between",
	// 	alignItems: "center",
	// 	padding: "4px 13px 4px 8px",
	// 	border: "1px solid #D7D7D7",
	// 	cursor: "pointer"
	// }
	// const oldVersionText = {
	// 	color: "#575757",
	// 	lineHeight: "15px",
	// 	fontSize: "12px",
	// 	margin: "0"
	// }


	return (

		<React.Fragment>
			<HeaderVersion isActive={true} />
			<div className="container pt-5 pb-5">
				<div className="d-flex justify-content-end">
					{/* <div style={oldVersion} onClick={goNew}>
						<p style={oldVersionText}>Новый вид<br />сайта</p>
						<svg width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M4.6665 4L0.666504 8V0L4.6665 4Z" fill="#8E8E8E"/>
						</svg>
					</div> */}
				</div>
				<Link to={`/www/kkms/${idKkm}`} className="back-to-kassa" style={{width: '196px'}}>← Вернуться в кассу</Link>
				<div className="heading">
					<h1 className="text-center">Архив Z-отчётов</h1>
				</div>

				<div className="dateRange-wrapper">
					<div className="excelButton">
						<Workbook filename={`${dateFrom} - ${dateTo}.xlsx`} element={<button className="downloadExcel">Скачать Excel-файл &nbsp; {<DonwloadSvg alt=""/>}</button>}>
							<Workbook.Sheet data={Report} name="Архив Z-отчетов">
								<Workbook.Column label="Номер смены" value={row => row.ShiftIndex} />
								<Workbook.Column label="Открыта" value={row => formatDate(new Date(row.DateOpen))} />
								<Workbook.Column label="Закрыта" value={row => formatDate(new Date(row.DateClose))} />
								<Workbook.Column label="Принято" value={row => String(row.BalanceOpen).replace(/(\d)(?=(\d{3})+$)/g, '$1 ')} />
								<Workbook.Column label="Сдано" value={row => String(row.BalanceClose).replace(/(\d)(?=(\d{3})+$)/g, '$1 ')} />
								<Workbook.Column label="Кол-во операций" value={row => row.Count} />
								<Workbook.Column label="Кассир" value={row => row.User.Name} />
							</Workbook.Sheet>
						</Workbook>
					</div>

					<div className="firstRaw">
						<span className="firstRawSpan" style={{height: "42px"}}>
							Последняя смена: &nbsp;
							<strong>{shiftNumber && shiftNumber}</strong>
						</span>
					</div>
					<div className="date_range">
						<span>Выберите<br></br>дату от: </span>
						<input className="date-from-input" type="date" min="2019-01-01" value={dateFrom} onChange={handleChangeDateFrom} />
						<span> до: </span>
						<input className="date-to-input" type="date" value={dateTo} onChange={handleChangeDateTo}/>
					</div>
				</div>
				{
					!error.status ?
						<div className="card">
							<div className="card-body">
								{
									Report !== undefined && Report.length > 0 ?
										<div className="table-wrapper">
											<table className="table table-responsive-sm">
												<thead>
													<tr>
														<th>Номер смены</th>
														<th>Открыта</th>
														<th>Закрыта</th>
														<th>Принято ₸</th>
														<th>Сдано ₸</th>
														<th>Кол-во операций</th>
														<th>Кассир</th>
														<th>Действия</th>
													</tr>
												</thead>
												<tbody>
													{
														Report.map((report, idx) => {
															return (
																<tr key={report.Id} style={{background: report.ShiftIndex == shiftNumber && "#E6E6FA"}}>
																	<td>{report.ShiftIndex}</td>
																	<td>{formatDate(new Date(report.DateOpen))}</td>
																	<td>{formatDate(new Date(report.DateClose))}</td>
																	<td>{String(report.BalanceOpen).replace(/(\d)(?=(\d{3})+$)/g, '$1 ')}</td>
																	<td>{String(report.BalanceClose).replace(/(\d)(?=(\d{3})+$)/g, '$1 ')}</td>
																	<td>{report.Count}</td>
																	<td>{report.User.Name}</td>
																	<td>
																		<button onClick={() => showDuplicate(idx)} className="btn btn-outline-primary btn-sm">Дубликат</button>
																	</td>
																</tr>
															)
														})
													}
												</tbody>
											</table>
										</div>
										:
										<div className="alert alert-light" role="alert">
											За данный  промежуток времени кассы не закрывались
										</div>
								}

							</div>
						</div>
						:
						<div className="alert alert-danger" style={{ margin: 'auto', maxWidth: '800px' }}>
							<h5>{error.title}</h5>
							<p>{error.text}</p>
						</div>
				}
			</div>
		</React.Fragment>
	)
}
export default ShiftsLogLayout;
