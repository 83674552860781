import React, { useState, useEffect } from 'react';
import { useDebounce } from 'use-debounce';
import { SpinnerPrimary } from '../../Common/Spinner';
import CashboxComponent from './CashboxComponent';
import SearchField from './SearchField';
import HeaderVersion from '../HeaderVersion/HeaderVersion';
import CompanyComponent from './CompanyComponent';
import MobileNav from '../../MobileNav/MobileNav';
import MobileUserExit from './MobileUserExit';
import s from './companylist.module.scss';
import "../../../Styles/layout/companiesCustom.scss";
import axios from 'axios';
import { BASE_URL } from '../../../Store/snippets';
import Error503Modal from '../Modals/Error500Modal'
import { useTranslation } from "react-i18next";

const CompaniesList = ({ getKkmsWithLabels, companies, companyName, copied, znmForCopy, usersCompanyId, choosenCompanyId, pending, kkms, selectCompany, copyZnm, openKkm, onlyKkms, error, errorData, kkmLoading, history, balances }) => {
	const { t } = useTranslation()
	const [searchOpen, setSearchOpen] = useState(false)
	const [loading, setLoading] = useState(false)
	const [searchValue, setSearchValue] = useState('')
	const [queryResult, setQueryResult] = useState([])
  const [value] = useDebounce(searchValue, 500)

	useEffect(() => {
    let controller = new AbortController()
		const searchKkms = async () => {
			try {
				setLoading(true)
				const result = await axios.get(BASE_URL + `/kkms?search=${value}`,
				{ headers:
					{
						'Authorization': `Bearer ${localStorage.getItem('tokken')}`
					}
				})
				const kkms = result.data.Data.Kkms
				const queriedKkms = kkms ? getKkmsWithLabels(kkms) : []
				console.log(queriedKkms)
				setQueryResult(queriedKkms)
				setLoading(false)
			} catch (error) {
				console.error(error)
			}
		}
		if(value.length > 0) searchKkms()

		return () => controller && controller.abort()
	}, [value])

	useEffect(() => {
		const kkm = localStorage.getItem('KKM')
		!kkm && localStorage.setItem('KKM', JSON.stringify(kkms.length ? kkms[0] : []))
	}, [])

	const query = async () => {
		const searchKkms = async () => {
			try {
				setLoading(true)
				const result = await axios.get(BASE_URL + `/kkms?search=${value}`,
				{ headers:
					{
						'Authorization': `Bearer ${localStorage.getItem('tokken')}`
					}
				})
				const kkms = result.data.Data.Kkms
				const queriedKkms = kkms ? getKkmsWithLabels(kkms) : []
				setQueryResult(queriedKkms)
				setLoading(false)
			} catch (error) {
				console.error(error)
			}
		}
		if(value.length > 0) searchKkms()
	}

	const kkm = localStorage.getItem('KKM')

	// CHECKS
	const showAllCashBoxes = value.length === 0 && kkms.length > 0
	const showQueriedCashBoxes = value.length > 0 && kkms.length > 0 && queryResult.length > 0
	const noKkmsFound = value.length > 0 && queryResult.length === 0 && kkms.length > 0 && !loading
	// CHECKS

	return (
		<div className={s.wrapper}>
			<nav className={s.header}>
				<HeaderVersion isActive={true} balances={balances}/>
			</nav>
			<MobileUserExit />
			<main className={s.container}>
				<section className={s.container_top}>
					<div className={!searchOpen ? s.text_top : `${s.text_top} ${s.search_open}`}>
						{ !onlyKkms ? t('main.select_company_and_cash_register') : t('main.select_cash_register') }
					</div>
					<SearchField
						searchValue={searchValue}
						setSearchValue={setSearchValue}
						searchOpen={searchOpen}
						setSearchOpen={setSearchOpen}
						query={query}
						placeholder={ t('main.search_cash_register') }
					/>
				</section>
				<div className={s.container_sub}>
					{ Array.isArray(companies) && !companies.length &&
						<div className="col-md-5">
							<div className="alert alert-secondary" role="alert">
								{ t('main.getting_data') }
							</div>
						</div>
					}
					{ error && error.Status < 500 &&
							<div className="col-md-5">
								<div className="alert alert-danger" role="alert">
									{ t('main.error') } {errorData.Status}
									<p> {errorData.Message}</p>
								</div>
							</div>
					}

					{ error && error.Status === 503 &&
							<Error503Modal />
					}

					{(!onlyKkms && companies.length > 0) && (
							<section className={`${s.row_container} `}>
								<div className={s.row_desktop_companies}>
									<h6 className={s.row_header}>{ t('main.list_of_companies') }</h6>
									<div className={s.row_list}>
										{ companies.map((company, idx) =>
											<CompanyComponent
												company={company}
												idx={idx}
												choosenCompanyId={choosenCompanyId}
												kkms={kkms}
												selectCompany={selectCompany}
												key={company.Id}
											/>
										)}
									</div>
								</div>
							</section>
					)}

					{ usersCompanyId !== null && (
						kkmLoading || loading ?
							<section className={`${s.row_container} ${onlyKkms && 'companies--translated'}`}>
								<div className={s.row_desktop_cashboxes}>
									<div className={s.row_header}>{ t('main.loading') }</div>
									<div className={s.row_list}>
										<div className="company__select">
											<span>
												<div className="company__name text-center" style={{ width: '100%' }}>
													<SpinnerPrimary />
												</div>
											</span>
										</div>
									</div>
								</div>
							</section>
							:
							<section className={`${s.row_container} ${onlyKkms && 'companies--translated'}`}>
								<div className={s.row_desktop_cashboxes}>
									<h6 className={s.row_header}>
										{ showAllCashBoxes && `${ t('main.cash_registers') } ${companyName}` }
										{ (showQueriedCashBoxes || noKkmsFound) && t('main.found_cash') }
									</h6>
									<div className={s.row_list}>
										{ showAllCashBoxes && kkms.map((kkm, idx) =>
											<CashboxComponent id={kkm.Id} key={kkm.Id} kkm={kkm} openKkm={openKkm} copied={copied} copyZnm={copyZnm} znmForCopy={znmForCopy} pending={pending} idx={idx} expireDate={kkm.ExpiredDate} />
										)}
										{ showQueriedCashBoxes && queryResult.map((kkm, idx) =>
											<CashboxComponent id={kkm.Id} key={kkm.Id} kkm={kkm} openKkm={openKkm} copied={copied} copyZnm={copyZnm} znmForCopy={znmForCopy} pending={pending} idx={idx} />
										)}
										{ kkms.length === 0 &&
											<div className="company__select">
												<span>
													<div className="company__title">
														<div className="company__name">{ t('main.no_cash') }</div>
													</div>
												</span>
											</div>
										}
										{ noKkmsFound &&
											<div className={s.no_results}>
												<p>{ t('main.not_found') }</p>
											</div>
										}
									</div>
								</div>
							</section>
					)}
				</div>
			</main>
			<div className={s.nav}>
				{ kkm && <MobileNav isActive={true} kkm={kkm} /> }
			</div>
		</div>
	);
};

export default CompaniesList;
