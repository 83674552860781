import React from 'react';

function Total(props) {
    return (
        <React.Fragment>
            <li className="list-group-item list-group-item-warning">
                <div className="d-flex justify-content-between s-t">
                    <span className="text-dark">К оплате</span>
                    <strong>{props.final}</strong>
                </div>
                {/*<hr/>*/}
                {/*<h5>Оплачено</h5>*/}
                {/*<div className="d-flex justify-content-between">*/}
                    {/*<span className="text-dark">Безналичными</span>*/}
                    {/*<strong>{props.nonCash}</strong>*/}
                {/*</div>*/}

                {/*<div className="d-flex justify-content-between">*/}
                                        {/*<span className="text-dark">*/}
                                          {/*Наличными*/}
                                        {/*</span>*/}
                    {/*<strong>{props.cash}</strong>*/}
                {/*</div>*/}

                {/*<div className="d-flex justify-content-between">*/}
                    {/*<span className="text-dark">Сдача</span>*/}
                    {/*<strong>{props.change}</strong>*/}
                {/*</div>*/}
            </li>
        </React.Fragment>
    );
}

export default Total;