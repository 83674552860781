import React from 'react';
import { OperationProvider } from "../../../../Store/OperationProvider";
import IncomeOperation from "./IncomeOperation"

const NewIncomeOperation = (props) => {
  return (
    <OperationProvider operation='Income'>
      <IncomeOperation {...props} />
    </OperationProvider>
  )
}

export default NewIncomeOperation;
