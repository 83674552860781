import React from 'react';

// import { ReactComponent as Logo } from './icon_fiscal.svg';
import { ReactComponent as LogoFiscal } from '../Svg/logo.svg';
import MaskedInput from 'react-text-mask'
// import { Link, Redirect } from 'react-router-dom';

//styles

import '../../Styles/layout/_newAuth.scss';

export default ({ login, register, password, kassaNumber, passrecover, errorMsg, errorHead, submitAttempt = false, isAlert = false, handleLogin, handlePassword, handleKassaNumber, beforeMaskedValueChange, passField, submit = () => { } }) => {
    // return (
    //     <div className="code-push p-3">
    //         <img src={Logo} />
    //         <div className="text-center">Обновление установлено и будет применено после запуска.</div>
    //         {
    //             true && <div style={{ whiteSpase: 'nowrap' }}>Прогресс <span>99</span></div>
    //         }
    //     </div>
    // )
    // let isElectron = require("is-electron");

    return (
        <div className="auth text-center">
            <form className="form-signin" onSubmit={submit}>
                <LogoFiscal className="mb-5" />
                <h1 className='h3 mb-1 font-weight-normal'>Онлайн-касса</h1>
                {/* <h5 className="mb-4 text-muted">с бесплатным ОФД</h5> */}
                <p style={{
                    fontSize: "15px",
                    marginBottom: "30px"
                }} className="text-muted">
                    Авторизуйтесь, используя данные коммерсанта Kassa24 Business или кассира
                </p>
                {isAlert && (
                    <div className="alert alert-danger text-left" role="alert">
                        <div className={`alert-heading`}>
                            <strong>{errorHead}</strong>
                        </div>
                        {/* Unauthorized: Неверный логин или пароль */}
                        {errorMsg}
                    </div>
                )
                }
                <fieldset disabled={false} >
                    <label htmlFor="inputPhone" className="sr-only">
                        Номер телефона
                    </label>
                    <MaskedInput
                        showMask={false}
                        guide={true}
                        // placeholderChar={'*'}
                        placeholderChar={'\u2000'}
                        mask={['+', '7', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]}
                        inputMode="tel"
                        id="inputPhone"
                        name="login"
                        className="form-control"
                        placeholder="Номер телефона"
                        autoComplete="phone"
                        required
                        value={login}
                        onChange={handleLogin}
                        // autoFocus
                        // onFocus={(e) => e.target.value = "+7"}
                        onBlur={(e) => e.target.placeholder = "Номер телефона"}
                    />
                    {/* <input
                        type="text"
                        inputMode="tel"
                        id="inputPhone"
                        name="login"
                        className="form-control"
                        placeholder="Номер телефона"
                        autoComplete="phone"
                        required
                        autoFocus
                        value={login}
                        onChange={handleLogin}
                    /> */}

                    <label htmlFor="inputPassword" className="sr-only">
                        Пароль
                    </label>
                    <input
                        type="password"
                        id="inputPassword"
                        name="password"
                        className="form-control"
                        placeholder="Пароль"
                        autoComplete="current-password"
                        required
                        value={password}
                        onChange={handlePassword}
                        ref={passField}
                    />
                    <div>
                        <label htmlFor="kassaID" className="sr-only">
                            Номер Кассы
                        </label>
                        <input
                            type="number"
                            id="kassaID"
                            name="kassaID"
                            className="form-control"
                            placeholder="Номер кассы"
                            autoComplete="off"
                            required
                            value={kassaNumber}
                            onChange={handleKassaNumber}
                            />
                    </div>

                </fieldset>
                
                {submitAttempt ?
                    (
                        <button className={`btn btn-secondary btn-block `} type="button" disabled={true}>
                            <span className={`spinner-border spinner-border-sm text-light`} role="status" aria-hidden="true" />
                        </button>
                    )
                    :
                    (
                        <button className={`btn btn-primary btn-block`} type="submit" id="sign-in-button">
                            Войти
                        </button>
                    )
                }
                <div className="forgotPass">
                    <span onClick={passrecover} style={{ cursor: "pointer" }}>Забыли пароль?</span>
                </div>
                {/* {
                    process.env.NODE_ENV === 'production' && (
                        !!window.cordova ?
                            <a href={"https://fiscal24.kz/?utm_source=android_app&utm_medium=cpc&utm_campaign=register_button"} target="_blank" className="btn btn-secondary btn-block mt-4">Зарегистрироваться</a>
                            : <a href="https://pult24.kz/register" target="_blank" className="btn btn-secondary btn-block mt-4">Зарегистрироваться</a>
                    )
                } */}
                <div className="register-button">
                    <button
                        className="btn btn-secondary btn-block mt-4"
                        type="button"
                        onClick={register}
                    >
                        Зарегистрироваться
                    </button>
                </div>
                <div className="bottomPhrases">
                    <p className={`mb-3 text-muted`}>
                        &copy; TOO "КАССА 24/7" <br/> 2005-2020
                    </p>
                    <p className="text-muted">
                        v 0.1.3
                    </p>
                </div>
            </form>
        </div>
    )
}
