import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import "../../Styles/layout/_opersLogModal.scss"

export default class Example extends React.Component {
    render() {
        return (
            <Modal show={this.props.show}
                centered
                onHide={this.props.onClose}
                style={{zIndex: 100}}
                // className="operLogModal"
                // style={{position: "fixed", top: "20px", minWidth: "300px"}}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {this.props.header}
                    </Modal.Title>
                </Modal.Header>
                { this.props.img &&
                    <Modal.Body>
                        <p className='text-center d-print-block'>
                            {
                                this.props.img &&
                                <img style={{ width: "250px" }} id="reciept" src={'data:image/png;base64, ' + this.props.img} alt="Check" />
                            }
                        </p>
                    </Modal.Body>
                }
                <Modal.Footer>
					<Button variant="secondary" onClick={this.props.onClose}>
						Закрыть
                	</Button>
				</Modal.Footer>
            </Modal>
        );
    }
}