import React from 'react';
import s from './mobileUserExit.module.scss';
import {useHistory} from "react-router-dom";
import { useTranslation } from "react-i18next";

const MobileUserExit = () => {
	const { t } = useTranslation()
  const history = useHistory()
  const userName = JSON.parse(localStorage.getItem('USER')).Name

  const logout = () => {
		if (localStorage.getItem('KKM_GARANT') !== null) {
			history.push('/www/garant')
			const phone = window.localStorage.getItem('phone_login');
			window.localStorage.clear();
			window.localStorage.setItem('phone_login', phone)
		} else if (localStorage.getItem('KKM_LOMBARD') !== null){
			history.push('/www/lombard')
			const phone = window.localStorage.getItem('phone_login');
			window.localStorage.clear();
			window.localStorage.setItem('phone_login', phone)
		} else{
			history.push('/')
			const phone = window.localStorage.getItem('phone_login');
			window.localStorage.clear();
			window.localStorage.setItem('phone_login', phone)
		}
	}

  return (
    <div className={s.wrapper}>
      <div className={s.leftSide}>
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 18C0 8.05888 8.05888 0 18 0C27.9411 0 36 8.05888 36 18C36 27.9411 27.9411 36 18 36C8.05888 36 0 27.9411 0 18Z" fill="#ECECEC"/>
          <path d="M10 28C10 25.8783 10.8429 23.8434 12.3431 22.3431C13.8434 20.8429 15.8783 20 18 20C20.1217 20 22.1566 20.8429 23.6569 22.3431C25.1571 23.8434 26 25.8783 26 28H10ZM18 19C14.685 19 12 16.315 12 13C12 9.685 14.685 7 18 7C21.315 7 24 9.685 24 13C24 16.315 21.315 19 18 19Z" fill="#8E8E8E"/>
        </svg>
        <div className={s.userBlock}>
          <p className={s.name}>{userName}</p>
          <p className={s.type}>{ t('main.cashier') }</p>
        </div>
      </div>
      <div className={s.rightSide}>
        <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={ logout }>
          <rect width="44" height="44" rx="8" fill="white"/>
          <path d="M14 24.9999H16V29.9999H28V13.9999H16V18.9999H14V12.9999C14 12.7347 14.1054 12.4804 14.2929 12.2928C14.4804 12.1053 14.7348 11.9999 15 11.9999H29C29.2652 11.9999 29.5196 12.1053 29.7071 12.2928C29.8946 12.4804 30 12.7347 30 12.9999V30.9999C30 31.2652 29.8946 31.5195 29.7071 31.707C29.5196 31.8946 29.2652 31.9999 29 31.9999H15C14.7348 31.9999 14.4804 31.8946 14.2929 31.707C14.1054 31.5195 14 31.2652 14 30.9999V24.9999ZM20 20.9999V17.9999L25 21.9999L20 25.9999V22.9999H12V20.9999H20Z" fill="#8E8E8E"/>
        </svg>
      </div>
    </div>
  )
}

export default MobileUserExit;
