import React from 'react';
import { OperationProvider, Store } from "../../../../Store/OperationProvider";
import PurchaseRefundOperation from "./PurchaseRefundOperation"

const NewPurchaseRefundOperation = (props) => {
  return (
    <OperationProvider operation='Purchase_Refund'>
      <Store.Consumer>
        { context => ( <PurchaseRefundOperation context={context} {...props} /> ) }
      </Store.Consumer>
    </OperationProvider>
  )
}

export default NewPurchaseRefundOperation;
