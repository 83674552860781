import React, { useState, useEffect } from 'react';
// import ArticleEdit from '../edit/ArticleEdit';
import DropzoneModal from '../modules/DropzoneModal'
import Pagination from "react-js-pagination";
import { ReactComponent as ButtonExcell } from "../images/buttonexcel.svg";
import { ReactComponent as SearchIcon } from "../images/searchicon.svg";
import Modal from '../../Modal/articlesEdit';
import "../styles/articles.css"

const Articles = ({ articles, showDropzone, setShowDropzone, fetchArticles, addArticle, sections, units, searchArticles, setSnackbar, setSnackText }) => {

  useEffect(() => {
    setArticlesList(articles.slice(10 * (activePage-1), 10 * activePage))
  }, [articles])

  const openDropzone = () => {
    setShowDropzone(true)
  }

  const [activePage, setActivePage] = useState(1)
  const [currentArticle, setCurrentArticle] = useState({})
  const [searchKey, setSearchKey] = useState("")
  const [articlesList, setArticlesList] = useState(articles.slice(0, 10))
  const [show, setShow] = useState(false)

  const createArticle = () => {
    setCurrentArticle({})
    setShow(true)
  }

  const editArticle = art => {
    setCurrentArticle(art)
    setShow(true)
  }

  const onClose = () => {
    setShow(false)
  }

  const doSearch = () => {
    searchArticles(searchKey)
    setSearchKey("")
  }

  const setPage = (page) => {
    setActivePage(page)
    setArticlesList(articles.slice(10 * (page-1), 10 * page))
  }

  // const resetStyles = {
  //   marginLeft: "65%",
  //   color: "#00adee",
  //   cursor: "pointer"
  // }

  return (
    <div>
      <React.Fragment>
        <div className="heading">
          <h1 className="text-center display-4">Номенклатура</h1>
        </div>
        <div className="pt-2">
          <p className="btn btn-primary mb-2" onClick={() => createArticle()}>
              Добавить товар в номенклатуру →
          </p>
          <button className="excelBtn" onClick={() => openDropzone()}>
            <ButtonExcell id="excelBtn" />
            Импорт товаров
          </button>
          <span className="search-wrapper">
            <input className="searchinput" id="searchinput" type="text" placeholder="Поиск по названию товара" value={searchKey} onChange={e => setSearchKey(e.target.value)} />
          </span>
          <button className="btn btn-primary mb-2 doSearch" onClick={() => doSearch()}>
            <SearchIcon className="searchicon" />
          </button>
        </div>
        <div class="pt-3">
          <div class="table-wrapper">
            <div className="table">
              <div className="table-head">
                <p className="barcode">Штрихкод</p>
                <p className="name">Название товара</p>
                <p className="unit">Ед. измерения</p>
                <p className="price">Цена</p>
                <p className="section">Секция</p>
                <p className="discount">Скидка %</p>
                <p className="markup">Наценка %</p>
                <p className="status">Статус</p>
                <p className="reset" onClick={() => doSearch()}>Сбросить</p>
              </div>
              <div className="table-body">
                {
                  articlesList.length ? (
                    articlesList.map(art => (
                      <div className="body-row" key={art.Id}>
                        <div className="barcode">
                          {
                            art.Qr ? (
                              <span>{art.Qr}</span>
                            ) : (
                              <span className="noBarcode">Не указан</span>
                            )
                          }
                        </div>
                        <div className="name">{art.Name}</div>
                        <div className="unit">
                          {
                            units.map(u => u.Id === art.IdUnit && u.NameRU)
                          }
                        </div>
                        <div className="price">{art.Price}</div>
                        <div className="section">
                          {
                            sections.map(section => section.Id === art.IdSection && section.Name)
                          }
                        </div>
                        <div className="discount">{art.Discount}</div>
                        <div className="markup">{art.Markup}</div>
                        <div className="status">
                          {
                            art.Active ? (
                              <span class="badge badge-success">Доступно</span>
                            ) : (
                              <span class="badge badge-danger">Отключено</span>
                            )
                          }
                        </div>
                        <div className="reset">
                          <svg onClick={() => editArticle(art)} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.276 17.6666L18.0373 10.9053L17.0947 9.96264L10.3333 16.724V17.6666H11.276ZM11.8287 19H9V16.1713L16.6233 8.54797C16.7484 8.42299 16.9179 8.35278 17.0947 8.35278C17.2714 8.35278 17.441 8.42299 17.566 8.54797L19.452 10.434C19.577 10.559 19.6472 10.7285 19.6472 10.9053C19.6472 11.0821 19.577 11.2516 19.452 11.3766L11.8287 19ZM9 20.3333H21V21.6666H9V20.3333Z" fill="#00ADEE"/>
                            <rect x="0.5" y="0.5" width="29" height="29" rx="5.5" stroke="#00ADEE"/>
                          </svg>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="emptyArticles">
                      <p className="title">Нет товаров</p>
                      <p>На данный момент список товаров пуст.<br/>Добавьте первый товар или<br/>импортируйте товары списком</p>
                    </div>
                  )
                }
              </div>
            </div>
            {
              articlesList.length ? (
                <Pagination
                  activePage={activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={articles.length}
                  onChange={(p) => setPage(p)}
                  hideFirstLastPages={true}
                  prevPageText={<span>‹</span>}
                  nextPageText={<span>›</span>}
                  itemClass="li-item"
                  itemClassPrev="prev-next-btn"
                  itemClassNext="prev-next-btn"
                />
              ) : null
            }
          </div>
        </div>
        {
          showDropzone ? (
            <div className="articles-modal-wrapper">
              <DropzoneModal
                setShowDropzone={setShowDropzone}
                fetchArticles={() => fetchArticles()}
                setSnackbar={setSnackbar}
                setSnackText={setSnackText}
              />
            </div>
          ) : null
        }
        {show && (
          <Modal
            onClose={() => onClose()}
            addArticle={(article) => addArticle(article)}
            sections={sections}
            units={units}
            currentArticle={currentArticle}
          />
        )}
      </React.Fragment>
    </div>
  )
}

export default Articles;
