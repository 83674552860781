/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import style from "../../styles/pages/addPositionService.module.scss"
import { ReactComponent as Spinner } from "../images/spinner.svg"
import { useTranslation } from "react-i18next";
import { OPERATION_TYPES } from "../../../../constants/app";

const AddPositionService = ({ context, history, status }) => {
  const { t } = useTranslation()

  let kkm = (JSON.parse(localStorage.getItem('KKM')))

  useEffect(() => {
    if (context.CheckImg) {
      history.push(`/www/kkms/${kkm.Id}/check/success`)
    }
  }, [context])

  const [error, setError] = useState(false)
  const [paying, setPaying] = useState(false)

  const handleInputNumber = (value) => {
    if (status === OPERATION_TYPES.INCOME.KEY) {
      context.amountHandler({ value: value }, status);
    } else {
      context.amountHandlerExpense({ value: value }, status);
    }
  };

  const onlyNumber = (e) => {
    let keyCode = (e.keyCode ? e.keyCode : e.which);
    if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
      e.preventDefault();
    }
  }

  const pay = () => {
    setPaying(true)
    if (context.Amount) {
      setError(false)
      if (status === OPERATION_TYPES.INCOME.KEY) {
        context.makeAmount('incomes').finally(() => setPaying(false))
      } else {
        context.makeAmount('expenses').finally(() => setPaying(false))
      }
    } else {
      setPaying(false)
      setError(true)
    }
  }

  return (
    <div className={style.addPosition}>
      <div className={style.inputWrapper}>
        <label htmlFor="">
          { status === OPERATION_TYPES.INCOME.KEY ? t('receipt.income_amount') : t('receipt.expense_amount') }
          </label>
        <input
          type="text"
          placeholder="0"
          inputMode="numeric"
          className={error && style.errorInput}
          value={context.Amount == 0 ? '' : context.Amount}
          onKeyPress={(e) => onlyNumber(e)}
          onChange={e => handleInputNumber(e.target.value)}
        />
        {error && <p className={style.errorText}>{ t('other.enter_amount') }</p>}
      </div>
      <button
        disabled={paying} 
        onClick={() => pay()}
      >
        {
          paying ? (
            <Spinner />
          ) : (
            t('receipt.issue_receipt')
          )
        }
      </button>
    </div>
  )
}

export default AddPositionService;
