import React, { Component } from 'react';
import { Store } from "../../../../../../Store/OperationProvider";
import style from "../../Prechek.module.css";

class PreCheckPosition extends Component {
    render() {
        return (
            <Store.Consumer>
                {
                    context => (
                        <React.Fragment>
                            {
                                context.PreCheck[this.props.index].Storno
                                    ?
                                    <div className={`pt-2 d-none ${style.s_p}`}>
                                        <button type="submit" className="btn btn-outline-secondary btn-sm" onClick={() => context.stornoToggle(this.props.index)}>
                                            &larr; Отменить сторно
                                    </button>
                                    </div>
                                    :
                                    <div className={`pt-2 d-none ${style.s_p}`}>
                                        <button type="submit" className="btn btn-danger btn-sm mr-1" onClick={() => context.stornoToggle(this.props.index)} >
                                            &#10006; Сторно
                                    </button>
                                        {/*<button type="submit" className="btn btn-primary btn-sm mr-1" onClick={() => context.setQty(this.props.index, 1)} >*/}
                                        {/*&#10010; Добавить*/}
                                        {/*</button>*/}
                                        {/*{context.PreCheck[this.props.index].Qty > 1*/}
                                        {/*&&*/}
                                        {/*<button type="submit" className="btn btn-warning btn-sm"*/}
                                        {/*onClick={() => context.setQty(this.props.index, -1)}>*/}
                                        {/*- Убавить*/}
                                        {/*</button>*/}
                                    </div>
                            }
                        </React.Fragment>
                    )
                }

            </Store.Consumer>
        )
    }
}

export default PreCheckPosition;