import React, { Component } from 'react';
import axios from 'axios';
import { BASE_URL, formatDate, getUser, getKkm, getHeaders } from '../../Store/snippets';
import OpersLogLayout from './OpersLogLayout';
import OpersLogLayoutMobile from './OpersLogLayoutMobile';
import PreloaderFullScreen from '../Preloader/Preloader';
import { getError } from '../../Store/snippets';

let headers;

const dateFrom = new Date();
		var dd = dateFrom.getDate();
		var mm = dateFrom.getMonth() + 1;
		var yyyy = dateFrom.getFullYear();
		var hh = dateFrom.getHours();
		var min = dateFrom.getMinutes();
		if(dd < 10){
			dd = "0"+ dd;
		}
		if(mm < 10){
			mm = "0"+ mm;
		}
		if(hh < 10){
			hh = "0"+ hh;
		}
		if(min < 10){
			min = "0"+ min;
		}

const today = yyyy+ '-' + mm + '-' + dd;

class OpersLog extends Component {
	constructor(props) {
		super(props);
		this.state = {
			report: {},
			user: {},
			date: '',
			idKkm: null,
			dateFrom: today,
			dateTo: today,
			checkModal: {
				status: false,
				checkImg: '',
				header: '',
			},
			modal: {
				Status: false,
				Header: '',
				Body: '',
				CheckImg: ''
			},
			mounted: false,
			error: {
				status: false,
				title: '',
				text: ''
			}
		};
	}


	closeModal = () => {
		if (this.state.CheckImg) {
			// document.location.reload(true);
		}
		this.setState({
			modal: {
				...this.state.Modal,
				Status: false
			},
			checkModal: false,
			modalCheck: {
				...this.state.modalCheck,
				Status: false
			},
			IsSaleButtonEnabled: true,
			CheckImg: '',
		})
	}

	getDuplicate = async(docId) => {
		await axios.get(`${BASE_URL}/www/document/${docId}`, { headers })
		.then(resp => {
			const reciept = resp.data.Data.Reciept
			this.setState({
				checkModal: {
					status: true,
					checkImg: reciept,
					header: 'Дубликат чека'
				}
			})
			}
		)

		.catch(error => {
			const reworkedError = getError(error, this.props.history);
			this.setState({
				...this.state,
				mounted: true,
				checkModal: {
					header: `Ошибка ${reworkedError.Status}`
				},
				modal: {
					...this.state.modal,
					Status: true,
					Header: `Ошибка ${reworkedError.Status}`,
					Body: reworkedError.Message,
				}
			})
		});
	}

	// getDuplicate = async (docId) => {
	// 	await this.setState({
	// 		...this.state,
	// 		mounted: false
	// 	})

	// 	axios.get(`${BASE_URL}/www/document/${docId}`, { headers }).then(resp => {
	// 		const receipt = resp.data.Data.Reciept;
	// 		if (window.matchMedia("(min-width: 1100px)").matches) {
	// 			this.setState({
	// 				...this.state,
	// 				mounted: true
	// 			})
	// 			// const img = document.createElement("img");
	// 			// img.setAttribute('src', `data:image/png;base64,${receipt}`);
	// 			const img = new Image();
	// 			img.src = `data:image/png;base64, ${receipt}`;
	// 			img.style.width = '250px';
	// 			// document.body.appendChild(img);
	// 			const printWindow = window.open("");
	// 			// console.log(printWindow);
	// 			printWindow.document.write(img.outerHTML);
	// 			// Почему-то без интервала картинка не отрисовывалась
	// 			setInterval(() => {
	// 				printWindow.print();
	// 				// printWindow.close();
	// 			}, 100);
	// 		} else {
	// 			this.setState({
	// 				...this.state,
	// 				modal: {
	// 					...this.state.modal,
	// 					Status: true,
	// 					Header: 'Дубликат чека',
	// 					Body: '',
	// 					CheckImg: receipt,
	// 				},
	// 				mounted: true
	// 			})

	// 		}

	// 	}).catch(error => {
	// 		const reworkedError = getError(error, this.props.history);
	// 		this.setState({
	// 			...this.state,
	// 			mounted: true,
	// 			modal: {
	// 				...this.state.modal,
	// 				Status: true,
	// 				Header: `Ошибка ${reworkedError.Status}`,
	// 				Body: reworkedError.Message,
	// 			}
	// 		})
	// 	});
	// }

	handleChangeDateFrom = (e) => {
		this.setState({
			dateFrom: e.target.value
		})
	}

	handleChangeDateTo = (e) => {
		this.setState({
			dateTo: e.target.value
		})
	}

	async componentDidMount() {
		headers = getHeaders(this.props.history);
		const idKkm = JSON.parse(localStorage.getItem('CURRENT_KKM_ID'));
		const user = getUser(this.props.history);
		const kkm = getKkm(this.props.history);

		const dates = {
			"FromDate": this.state.dateFrom + "T00:00:00+07:00",
			"ToDate": this.state.dateTo + "T23:59:59+07:00"
		}

		// getError({ response: { status: 401 } }, this.props.history);

		if (kkm.Lock === false) {
			await this.setState({
				...this.state,
				user,
				idKkm,
				error: {
					...this.state.error,
					status: 410,
					title: `Отчеты недоступны`,
					text: `Вы не можете просматривать отчеты при закрытой смене. Пожалуйста, откройте смену для просмотра отчетов`
				},
				mounted: true
			})
			return
		}

		axios.post(`${BASE_URL}/kkms/${idKkm}/reports`, dates, {headers: {...headers}} )
		.then(resp => {
			this.setState({
				...this.state,
				report: resp.data.Data.Operations,
				idKkm,
				user: user,
				mounted: true
			})
		}).catch(err => {
			const resp = getError(err, this.props.history);
			this.setState({
				...this.state,
				user,
				idKkm,
				error: {
					...this.state.error,
					status: true,
					title: `Ошибка: ${resp.Status}`,
					text: resp.Message
				},
				mounted: true
			})
		});
	}

	componentDidUpdate(prevProps, prevState) {

		headers = getHeaders(this.props.history);
		const idKkm = JSON.parse(localStorage.getItem('CURRENT_KKM_ID'));
		const user = getUser(this.props.history);

		const dates = {
			"FromDate": this.state.dateFrom + "T00:00:00+07:00",
			"ToDate": this.state.dateTo + "T23:59:59+07:00"
		}

		if(prevState.dateFrom !== this.state.dateFrom || prevState.dateTo !== this.state.dateTo) {
			axios.post(`${BASE_URL}/kkms/${idKkm}/reports`, dates,
				{headers: {...headers}}
			)
			.then(resp => {
				this.setState({
					...this.state,
					report: resp.data.Data.Operations,
					idKkm,
					user: user,
					mounted: true
				})
			}).catch(err => {
				const resp = getError(err, this.props.history);
				this.setState({
					...this.state,
					user,
					idKkm,
					error: {
						...this.state.error,
						status: true,
						title: `Ошибка: ${resp.Status}`,
						text: resp.Message
					},
					mounted: true
				})
			});
		}
	}

	shareSocial = (file) => {
		const optionsShare = {
			// message: 'share this', // not supported on some apps (Facebook, Instagram)
			// subject: 'the subject', // fi. for email
			files: [file], // an array of filenames either locally or remotely
			// url: 'https://www.website.com/foo/#bar?a=b',
			// chooserTitle: 'Pick an app', // Android only, you can override the default share sheet title
			// appPackageName: 'com.apple.social.facebook', // Android only, you can provide id of the App you want to share with
			// iPadCoordinates: '0,0,0,0' //IOS only iPadCoordinates for where the popover should be point.  Format with x,y,width,height
		};
		window.plugins.socialsharing.shareWithOptions(optionsShare, this.onSuccess, this.onError);
	}

	onSuccess = function (result) {
		console.log("Share completed? " + result.completed); // On Android apps mostly return false even while it's true
		console.log("Shared to app: " + result.app); // On Android result.app since plugin version 5.4.0 this is no longer empty. On iOS it's empty when sharing is cancelled (result.completed=false)
	};

	onError = function (msg) {
		console.log("Sharing failed with message: " + msg);
	};

	showCheck = async () => {
		// await this.setState({
		//     Modal: {
		//         ...this.state.Modal,
		//         Status: false
		//     },
		//     ModalCheck: {
		//         ...this.state.ModalCheck,
		//         Status: true
		//     }
		// })
		// window.print();
		const receipt = document.getElementById("reciept");
		const printWindow = window.open("");
		printWindow.document.write(receipt.outerHTML);
		printWindow.print();
		// printWindow.close();
	}

	render() {
		return (
			this.state.mounted ? window.matchMedia("(min-width: 1100px)").matches ?
				<OpersLogLayout {...this.state} handleChangeDateFrom={this.handleChangeDateFrom} handleChangeDateTo={this.handleChangeDateTo} formatDate={formatDate} getDuplicate={this.getDuplicate} {...this.props} showCheck={this.showCheck} show={this.state.checkModal.status} header={this.state.checkModal.header} closeModal={this.closeModal} img={this.state.checkModal.checkImg} />
				:
				<OpersLogLayoutMobile {...this.state} handleChangeDateFrom={this.handleChangeDateFrom} handleChangeDateTo={this.handleChangeDateTo} formatDate={formatDate} getDuplicate={this.getDuplicate} closeModal={this.closeModal} share={this.shareSocial} {...this.props} showCheck={this.showCheck} show={this.state.checkModal.status} header={this.state.checkModal.header} img={this.state.checkModal.checkImg} />
				: <PreloaderFullScreen />

		)
	}
}

export default OpersLog;
