import React from "react";
import { ReactComponent as PrintLine } from '../../svg/print-line.svg'
import style from '../../styles/components/printBtn.module.scss'
import { useTranslation } from "react-i18next";

const NewPrintButton = ({ absolutePosition }) => {
	const { t } = useTranslation()

	const print =() =>{
		window.print();
	}

	return (
		<button
			className={`${style.btn} ${style['btn--primary']} ${absolutePosition ? style['btn--absolute'] : ''}`}
			onClick={print}
		>
			<PrintLine/>
			<span className={style['ml-10']}>{ t('close_shift.print') }</span>
		</button>
	)
}

export default NewPrintButton
