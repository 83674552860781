import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import style from '../styles/pages/report/NewReportTop.module.scss'
import {ReactComponent as ArrowLeftLine} from "../svg/arrow-left-line.svg";
import {ReactComponent as ArrowLeftSLine} from "../svg/arrow-left-s-line.svg";
// import {getAppModuleUrl} from "../../../Store/appVersion";
import { useTranslation } from "react-i18next";

const pad = (d) => {
	d = Number(d);
	return (d < 10) ? '0' + d.toString() : d.toString();
}

const NewReportTop = ({ step, idKkm  }) => {
	const { t } = useTranslation()

	const history = useHistory()
	const [dateInfo, setDateInfo] = useState(true)

	const shiftNumber = localStorage.getItem("SHIFT_INDEX");

	const currentDate = new Date();
	const date = `${pad(currentDate.getDate())}.${pad(currentDate.getMonth() + 1)}.${currentDate.getFullYear()}`
	const hours = `${pad(currentDate.getHours())}.${pad(currentDate.getMinutes())}`

	useEffect(() => {
		if (step === 1 || step === 3) {
			setDateInfo(false)
		} else {
			setDateInfo(true)
		}
	}, [step])

	const goBack = () => {
		history.push(`/www/kkms/${idKkm}/shift_new`)
	}

	return (
		<div className={`${style['flex-2']} ${style['report__top']}`}>
			<div className={style['report__back']}>
				<button
					onClick={goBack}
					className={`${style.btn} ${style['btn--back']}`}
					style={{ 'textDecoration': 'none' }}
				>
					<ArrowLeftLine className={style['arrow-left-line']}/>
					{ t('report.back') }
				</button>
				<button
					onClick={goBack}
					className={`${style.btn} ${style['btn--icon']}`}
				>
					<ArrowLeftSLine className={style['arrow-left-s-line']}/>
				</button>
			</div>
			<div className={style['report__title']}>
				<h1 className={style['heading-primary']}>{ t('report.reports') }</h1>
			</div>
			<div className={style['report__date-wrapper']}>
				<div className={style['report__date']}>
					{dateInfo && <React.Fragment><span>{date}</span><div></div><span>{hours}</span></React.Fragment>}
					{ !dateInfo && <span>{ t('report.last_shift') } {shiftNumber}</span> }
				</div>
			</div>
		</div>
	)
}

export default NewReportTop
