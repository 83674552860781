/* eslint-disable eqeqeq */
import React from 'react';
import { ReactComponent as Arrow } from '../Svg/arrow-right.svg';
import { ReactComponent as CopiedZnm } from '../Svg/copied-znm.svg';
import { SpinnerPrimary } from '../Common/Spinner';
import { Link } from 'react-router-dom';
// import Header from '../Header/Header';
import "../../Styles/layout/companiesCustom.scss";
import { ReactComponent as Success } from "../Svg/success.svg";
import { ReactComponent as Warning } from "../Svg/warning.svg";
import { ReactComponent as Danger } from "../Svg/danger.svg";
import { ReactComponent as OpenShift } from "../Svg/open-shift.svg";
import HeaderVersion from "../NewDesign/HeaderVersion/HeaderVersion";

const CompaniesList = ({ companies, companyName, copied, znmForCopy, usersCompanyId, choosenCompanyId, pending, finishing, kkms, selectCompany, copyZnm, openKkm, onlyKkms, error, errorData, kkmLoading, user, history }) => {
	return (
		<React.Fragment>
			<HeaderVersion isActive={false} />
			<div className="container pt-4">
				<h4>Выбрать {!onlyKkms && 'компанию и'} кассу</h4>
				<div className="row d-lg-none">
					<div className="col-12">
						<div className="d-flex justify-content-between align-items-center pb-3">
							<div className="m-cashbox__name">{user.Name}</div>
							<Link to="/" className="btn btn-secondary">Выйти</Link>
						</div>
					</div>
				</div>
				<div className="row">
					{
						companies === [] && (
							<div className="col-md-5">
								<div className="alert alert-secondary" role="alert">
									Получение данных...
						</div>
							</div>
						)
					}
					{
						error && (
							<div className="col-md-5">
								<div className="alert alert-danger" role="alert">
									Ошибка {errorData.Status}
									<p> {errorData.Message}</p>
								</div>
							</div>
						)
					}

					{

						(!onlyKkms && companies.length > 0) && (
							<div className={`col-lg-6 mb-3`}>
								<div className="card">
									<div className="card__header-gray">Список компаний</div>
									<div className="card__body">
										{
											companies.map((company, idx) => {
												return (
													<button className={`company__select ${choosenCompanyId === company.Id && 'active'}`} id={company.Id} data-idx={idx} onClick={selectCompany} key={company.Id}>
														<span>
															<div className="company__title">
																<div className="company__name">{company.ShortName}</div>
																<div className="company__address">{`${company.Address.Town.Name}, ${company.Address.Street}, ${company.Address.House}, ${company.Address.Flat} `}</div>
																{/* <div className="company__cashboxes">Заглушка>2 кассы</div> */}
															</div>
															<div className="arrow d-none d-md-flex align-items-center ">
																{
																	user.Lock && company.Id == usersCompanyId && (
																		usersCompanyId == choosenCompanyId ?
																			<div className="company__label badge-primary-invert mr-3">Есть открытая смена</div>
																			:
																			<div className="company__label badge-primary mr-3">Есть открытая смена</div>
																	)
																}
																<Arrow
																	style={{
																		width: '5px',
																		height: '8px'
																	}}
																/>
															</div>
														</span>

													</button>
												)
											})
										}
									</div>
								</div>

								{/* MOBILE COMPANIES LIST */}
								<div className="card-mobile-body-companies">
									{
										companies.map((company, idx) => {
											return (
												<div className="company-wrapper" id={company.Id} data-idx={idx} onClick={selectCompany} key={company.Id}>
													<div className={`company-name ${choosenCompanyId === company.Id && 'active'}`} >{company.ShortName}</div>
													{
														choosenCompanyId === company.Id && <div className="active-company"></div>
													}
												</div>
											)
										})
									}
								</div>
							</div>
						)
					}

					{
						usersCompanyId !== null && (
							kkmLoading ?
								<div className={`col-lg-6 position-relative ${onlyKkms && 'companies--translated'}`}>
									<div className="card cashbox-reveal">
										<div className="card__header-gray">Загрузка...</div>
										<div className="card__body">
											<div className="company__select">
												<span>
													<div className="company__name text-center" style={{ width: '100%' }}>
														<SpinnerPrimary />
													</div>
												</span>
											</div>
										</div>
									</div>
								</div>
								:
								<div className={`col-lg-6 position-relative ${onlyKkms && 'companies--translated'}`}>
									<div className="card cashbox-reveal">
										<div className="card__header-gray">Кассы {companyName}</div>
										<div className="card__body">
											{
												kkms.length > 0 ?
													kkms.map((kkm, idx) => {
														return (
															<button className={`kkm__select ${(kkm.Lock && kkm.LabelType == 'success' && 'kkm__select--success')}`} id={kkm.Id} onClick={openKkm} key={kkm.Id} data-idx={idx}>
																<span>
																	<div className="company__title">
																		<div className="company__name">
																			<span className="company__exactName">{kkm.Name}</span>
																			<div className={`${kkm.LabelType !== 'success' && 'blur'}`}></div>
																			{
																				copied && znmForCopy === kkm.Znm ?
																				<div className="copied__section">
																					<CopiedZnm/>&nbsp;Скопировано
																				</div>
																				:
																				<div className="znm__section" onClick={(e) => copyZnm(e, kkm.Znm)}>
																					<div className="znm__title">ЗНМ</div>
																					<input className="znm__number" readOnly value={kkm.Znm}></input>
																				</div>
																			}
																		</div>
																		<div className="company__address hidden__section">{`${kkm.Address.Town.Name}, ${kkm.Address.Street}, ${kkm.Address.House}, ${kkm.Address.Flat} `}</div>
																	</div>
																	<div className="status__section">
																		<div className={`company__label badge-${kkm.LabelType}y`}>
																			<div className="status__logo">
																				{kkm.LabelType === 'success' ? <OpenShift/> : kkm.LabelType === 'warning' ? <Warning/> : kkm.LabelType === 'primary' ? <Success/> : kkm.LabelType === 'available' ? <Success/> : <Danger/>}
																			</div>
																			{kkm.LabelText}
																		</div>
																		<div className="hidden__section expired__status">{kkm.ExpiredDate && !pending ? `${kkm.ExpiredDate}` : !kkm.ExpiredDate && !pending ? 'Требуется оплата' : <SpinnerPrimary />}</div>
																	</div>
																</span>
															</button>
														)
													}) :
													<div className="company__select">
														<span>
															<div className="company__title">
																<div className="company__name">Нет созданных касс</div>
															</div>
														</span>
													</div>
											}
										</div>
									</div>

									{/* MOBILE KKMS LIST */}
									<div className="card-mobile-body-kkms">
										{
											kkms.length > 0 ?
												kkms.map((kkm, idx) => {
													return (
														<div className="kkm" id={kkm.Id} onClick={openKkm} key={kkm.Id} data-idx={idx}>
															<div className="header">
																<p className={`name ${copied && znmForCopy === kkm.Znm && 'copied'}`}>{kkm.Name}</p>
																{
																	copied && znmForCopy === kkm.Znm ?
																		<div className="copied__section">
																			<CopiedZnm/>&nbsp;Скопировано
																		</div>
																	:
																		<div className="znm__section" onClick={(e) => copyZnm(e, kkm.Znm)}>
																			<div className="znm__title">ЗНМ</div>
																			<input className="znm__number" readOnly value={kkm.Znm}></input>
																		</div>
																}
															</div>
															<div className="body">
																<p>{`${kkm.Address.Town.Name}, ${kkm.Address.Street}, ${kkm.Address.House}, ${kkm.Address.Flat} `}</p>
																<p>{kkm.ExpiredDate && !pending ? `${kkm.ExpiredDate}` : !kkm.ExpiredDate && !pending ? 'Требуется оплата' : <SpinnerPrimary />}</p>
															</div>
															<div className="footer">
																<div className={`info info-${kkm.LabelType}`}>
																	<div className="status__logo">
																		{kkm.LabelType === 'success' ? <OpenShift/> : kkm.LabelType === 'warning' ? <Warning/> : kkm.LabelType === 'primary' ? <Success/> : kkm.LabelType === 'available' ? <Success/> : <Danger/>}
																	</div>
																	<p>{kkm.LabelText}</p>
																</div>
															</div>
														</div>
													)
												}) :
												<div className="company__select">
													<span>
														<div className="company__title">
															<div className="company__name">Нет созданных касс</div>
														</div>
													</span>
												</div>
										}
									</div>
								</div>

						)
					}

				</div>
			</div>
			{/* <Modal show={modalPopupShow} style="error"/> */}
		</React.Fragment >
	);
};

export default CompaniesList;
